import { useState } from "react";
import GlobalContext from "./GlobalContext";
import Cookies from "js-cookie";


const GlobalContextState = (props) => {

    //console.log("global state is rendering !!");


    let LocalStorageisUserLoggedIn = Cookies.get('isUserLoggedIn');

    let UserLogin = JSON.stringify(LocalStorageisUserLoggedIn);


    const [isloginModelOpen, setIsLoginModeOpen] = useState(false);
    const [multiMarketList, setMultiMarketList] = useState([1]);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(UserLogin);
    const [quickStakes, setQuickStakes] = useState({ stakeOne: 100, stakeTwo: 200, stakeThree: 500, stakeFour: 1000, stakeFive: 5000, stakeSix: 10000 });

    ////console.log(isUserLoggedIn);


    const [LoginUserDetail, setLoginUserDetail] = useState({});  // this will store the data of the logined user
    const [selectedEvent, setSelectedEvent] = useState({ isTvAvailable: true });

    const [betSlipData, setBetSlipData] = useState();  // this is for storing data that is taken by bookmaker and match odd for bet slip

    const [remount, setRemount] = useState(false);  // whenever you want to re-render the component -- it play a role of wire if someone will pull , than somebody will be pushing (bad example)

    const [betSend, setBetSend] = useState(false); // when bet succsessfully placed set it true and it calls the header balance again
    const [fancyBetSend, setFancyBetSend] = useState(false); // when bet succsessfully placed set it true and it calls the header balance again

    const [betEventId, setBetEventId] = useState(null)
    const [isLoader, setIsLoader] = useState(false); // for set loader enable disable
    const [loginBalanceContext, setLoginBalanceContext] = useState(0);
    const [loginExpoContext, setLoginExpoContext] = useState(0)
    const [errorInBet, setErrorInBet] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [reFetchLoginBalance, setReFetchLoginBalance] = useState(false);


    return (
        <GlobalContext.Provider value={{ isloginModelOpen, quickStakes, remount, setRemount, setQuickStakes, betSlipData, isUserLoggedIn, setIsUserLoggedIn, selectedEvent, setBetSlipData, setIsLoginModeOpen, multiMarketList, setMultiMarketList, LoginUserDetail, setLoginUserDetail, betSend, setBetSend, fancyBetSend, setFancyBetSend, betEventId, setBetEventId, isLoader, setIsLoader, loginBalanceContext, setLoginBalanceContext, loginExpoContext, setLoginExpoContext, errorInBet, setErrorInBet, errorMessage, setErrorMessage, isSuccess, setIsSuccess, reFetchLoginBalance, setReFetchLoginBalance }} >
            {props.children}
        </GlobalContext.Provider>
    )
}

export default GlobalContextState;