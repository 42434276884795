import React, { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import LoginModel from '../pages/auth/LoginModel'
import GlobalContext from '../context/GlobalContext'
import Loader from '../components/Loader'
import News from '../components/News'
import Cookies from "js-cookie";


const Layout = () => {
  //console.log(`layout component render !!`);

  const navigate = useNavigate();

  let userId2 = Cookies.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON.parse(userId2);

  const context = useContext(GlobalContext);
  const { isloginModelOpen, isLoader, setIsLoader } = context;

  return (
    <>
      <div className="layout-container">
        {isloginModelOpen ? <LoginModel /> : <h1 className='hidden'>1</h1>}
        <Header key={1} />
        <News />
        {userId.selfUser === 1 && (
          <div className='homeDepositWithdrawBanner'>
            <div className='flex justify-between' onClick={() => { navigate('/wallet-gateway') }}>
              <div className='flex w-full justify-center items-center font-bold text-white text-sm'
                style={{ backgroundColor: 'green', padding: '5px 5px 6px 5px' }}>
                <img src="/images/bank-deposit.svg" alt="" style={{ width: '25px', marginRight: '5px', height: '25px' }} />
                <span>DEPOSIT</span>
              </div>
              <div className='flex w-full justify-center items-center font-bold text-white text-sm'
                style={{ backgroundColor: '#8b0000', padding: '5px 5px 6px 5px' }}>
                <img src="/images/bank-withdraw.svg" alt="" style={{ width: '25px', marginRight: '5px', height: '25px' }} />
                <span>WITHDRAWAL</span>
              </div>
            </div>
          </div>
        )}
        <div className="content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default React.memo(Layout) 
