import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react'
import Loader from '../../components/Loader';
import Cookies from "js-cookie";
import moment from 'moment';



const AcitivityLog = () => {

  //console.log(`acitivity log page rendering !!!`);


  let userId2 = Cookies.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON.parse(userId2);



  const pageSize = 15; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const fetchActivityLog = async () => {
    let res = await axios.get(`https://api.betcare.live/getLoginData/${userId.Id}`);
    let resData = await res.data;
    return resData;
  }
  const { data, isLoading, isError } = useQuery({ queryKey: ["ActivityLogData"], queryFn: fetchActivityLog, staleTime: 10000 });

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  let fetchedData = data?.data;
  const sortedData = fetchedData?.sort((a, b) => new Date(b.loginTime) - new Date(a.loginTime));  // getting data from today to  yesterday sequence
  let AcitivityLogs = sortedData?.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(fetchedData?.length / pageSize)));
  };

  // {isLoading ? <Loader/> : <Loader/>}
  // {<Loader/>}
  return (
    <>
      {isLoading && <Loader styling={`absolute w-fit right-[40%] top-[50%]`} />}
      <section className=''>
        {/* <section className=' w-screen'> */}
        <h1 className='text-sm  font-bold m-2'>Activity Log</h1>
        {/* </section> */}
        <section className='w-[100vw] lg:w-[75vw] overflow-auto' >
          <table className='mt-5 table text-[12px] border-b border-[#7e97a7] lg:w-[75vw]' >
            <thead className='' >
              <tr className='border-y py-3 px-[10px] bg-[#e4e4e4] border-[#7e97a7]' >
                <th className='text-center py-3 px-[10px]'>Login Date & Time</th>
                <th className='py-3 px-[10px]'>Login Status</th>
                <th className='py-3 px-[10px]'>IP Address</th>
                <th className='py-3 px-[10px]'>ISP</th>
                <th className='py-3 px-[10px]'>City/State/Country</th>
                <th className='py-3 px-[10px]'>User Agent Type</th>
              </tr>
            </thead>
            {isError && <tr>Data is un-available</tr>}
            {
              AcitivityLogs?.map((activity) => {
                const parsedTimestamp = moment(activity.LoginTime);

                // Format date and time
                const formattedDate = parsedTimestamp.format('DD/MM/YYYY');
                const formattedTime = parsedTimestamp.format('HH:mm:ss');
                // console.log(formattedDate);
                return (
                  <tr key={activity.id} className='bg-white border-b border-black'>
                    <td className='text-center py-3 px-[10px]' >{formattedDate + " " + formattedTime}</td>
                    <td className='text-[#508d0e] text-center' >Login Success</td>
                    <td className='text-center' >{activity.IpAddress}</td>
                    <td className='text-center' >--</td>
                    <td className='text-center' >{activity.BrowserInfo?.length <= 0 ? "-- not available --" : activity.BrowserInfo === null ? "-- not available --" : activity.BrowserInfo}</td>
                    <td className='text-center' >Browser</td>
                  </tr>
                )
              })
            }
          </table>
        </section>

        <div className='flex gap-6 mt-2 justify-center'>
          <button className={`disabled:text-[#cacaca]`} onClick={handlePreviousPage} disabled={currentPage === 1}> {"<< "}Previous</button>
          <button className={`  disabled:text-[#cacaca] `} onClick={handleNextPage} disabled={currentPage === Math.ceil(data?.length / pageSize)}>Next{" >>"}</button>
        </div>

      </section>
    </>
  )
}



export default AcitivityLog
