import React, { useContext, useEffect, useRef, useState } from 'react'
import MinusIcon from "../images/minus-icon-white.png";
import PlusIcon from "../images/plus-icon-white.png";

import circleIcon from "../images/circle-icon-green.png";
import pinIconGrey from "../images/pin-grey.svg";
// import pinIconGreen from "../images/pin-icon-green.png";
import searchIcon from "../images/search-icon-white.png";
import searchIconBlack from "../images/search-icon.svg";
import CutIcon from "../images/cut-icon.svg";
import LeftArrowIcon from "../images/left-arrow-icon.svg";
import Loader from '../components/Loader';
import SportListItem from '../components/SportListItem';
import BetSlipListItem from '../components/BetSlipListItem';
import GlobalContext from '../context/GlobalContext';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';




const InPlay = () => {

    //console.log("In-Play is rendering !!");

    const fetchingInPlayData = async () => {
        let res = await axios.get(`https://api.s2bet.in/GetInPlaySportEvents`);
        let resData = await res.data;
        //console.log('inlay data : ', resData)
        return resData;

    }

    const { isPending, isLoading, isError, data: sports, error } = useQuery({
        queryKey: ['inplaySports'],
        queryFn: fetchingInPlayData,
    });

    let inPlayData = sports?.data?.sportsEventModelInPlay !== undefined && sports?.data?.sportsEventModelInPlay.length > 0 ? sports?.data?.sportsEventModelInPlay : [];
    let todayData = sports?.data?.sportsEventModelToday !== undefined && sports?.data?.sportsEventModelToday.length > 0 ? sports?.data?.sportsEventModelToday : [];
    let tomorrowData = sports?.data?.sportsEventModelTomorrow !== undefined && sports?.data?.sportsEventModelTomorrow.length > 0 ? sports?.data?.sportsEventModelTomorrow : [];

    ////console.log("in play data length : ", inPlayData.length);
    ////console.log("in today data length : ", todayData);
    ////console.log("in tomorrow data length : ", tomorrowData.length);

    let inPlayCricket = inPlayData?.filter((game) => {
        return game?.eid === "4";
    });


    let inPlaySoccer = inPlayData?.filter((game) => {
        return game?.eid === "1";
    });

    let inPlayTennis = inPlayData?.filter((game) => {
        return game?.eid === "2";
    });


    //  console.log('inPlayCricket : ', inPlaySoccer);


    const [currentPage, setCurrentPage] = useState("In-Play");
    const [CricketPageOpen, setCricketPageOpen] = useState(true);
    const [TennisPageOpen, setTennisPageOpen] = useState(true);
    const [SoccerPageOpen, setSoccerPageOpen] = useState(true);


    const [PinImg, setPinImg] = useState(pinIconGrey);
    const [TodayPageFilterIsOpen, setTodayPageFilterIsOpen] = useState(false);
    const [TomorrowPageFilterIsOpen, setTomorrowPageFilterIsOpen] = useState(false);
    const [mobileSearchBar, setMobileSearchBar] = useState(false);
    const [height, setHeight] = useState(0);
    const referenceDivRef = useRef(null);


    // data of today
    // cricket
    const [cricketToday, setCricketToday] = useState([]);
    // soccer
    const [soccerToday, setSoccerToday] = useState([]);
    // tennis 
    const [tennisToday, setTennisToday] = useState([]);



    // data of tomorrow
    // cricket
    const [cricketTomorrow, setCricketTomorrow] = useState([]);
    // soccer
    const [soccerTomorrow, setSoccerTomorrow] = useState([]);
    // tennis 
    const [tennisTomorrow, setTennisTomorrow] = useState([]);

    const [todaysData, setTodayData] = useState([]);
    const [tomorrowsData, setTomorrowData] = useState([]);


    // bets list
    const [bets, setBets] = useState(["testing"]);

    const context = useContext(GlobalContext);
    const { betSlipData, isUserLoggedIn } = context;

    // useEffect(() => {
    //    //console.log("use effect start ")
    //     if (currentPage == 'Today') {
    //         inPlayCricket = todayData?.filter((game) => {
    //             return game?.eid === "4";
    //         });

    //         inPlaySoccer = todayData?.filter((game) => {
    //             return game?.eid === "1";
    //         });

    //         inPlayTennis = todayData?.filter((game) => {
    //             return game?.eid === "2";
    //         });


    //        //console.log("in play cricket : ", inPlayCricket.length);
    //        //console.log("in play soccer : ", inPlaySoccer.length);
    //        //console.log("in play tennis : ", inPlayTennis.length);
    //     } else if (currentPage == 'Tomorrow') {
    //         inPlayCricket = tomorrowData?.filter((game) => {
    //             return game?.eid === "4";
    //         })

    //         inPlaySoccer = tomorrowData?.filter((game) => {
    //             return game?.eid === "1";
    //         });

    //         inPlayTennis = tomorrowData?.filter((game) => {
    //             return game?.eid === "2";
    //         });


    //        //console.log("in play cricket : ", inPlayCricket.length);
    //        //console.log("in play soccer : ", inPlaySoccer.length);
    //        //console.log("in play tennis : ", inPlayTennis.length);
    //     }
    //     else {

    //         inPlayCricket = inPlayData?.filter((game) => {
    //             return game?.eid === "4";
    //         });

    //         inPlaySoccer = inPlayData?.filter((game) => {
    //             return game?.eid === "1";
    //         });

    //         inPlayTennis = inPlayData?.filter((game) => {
    //             return game?.eid === "2";
    //         });

    //        //console.log("in play cricket : ", inPlayCricket.length);
    //        //console.log("in play soccer : ", inPlaySoccer.length);
    //        //console.log("in play tennis : ", inPlayTennis.length);
    //     }
    // }, [currentPage])

    useEffect(() => {
        if (Array.isArray(todayData) && todayData.length > 0) {
            setTodayData(todayData);
            const filterCricket = todayData[0].filter(item => item.eid == 4)
            const filterSoccer = todayData[0].filter(item => item.eid == 1)
            const filterTennis = todayData[0].filter(item => item.eid == 2)

            setCricketToday(filterCricket)
            setSoccerToday(filterSoccer)
            setTennisToday(filterTennis)

        }
    }, [todayData]);

    useEffect(() => {
        if (Array.isArray(tomorrowData) && tomorrowData.length > 0) {
            setTomorrowData(tomorrowData);

            const filterCricket = tomorrowData[0].filter(item => item.eid == 4)
            const filterSoccer = tomorrowData[0].filter(item => item.eid == 1)
            const filterTennis = tomorrowData[0].filter(item => item.eid == 2)

            setCricketTomorrow(filterCricket)
            setSoccerTomorrow(filterSoccer)
            setTennisTomorrow(filterTennis)
        }
    }, [tomorrowData]);






    return (
        // style={ contentHeight < screenHeight ? {height:"80vh",backgroundColor:"#eee"} : {height:"auto"}}
        // className={`${height < 500 && "h-screen"}`}
        <div className=' bg-[#eee] overflow-scroll h-screen lg:h-80vh' ref={referenceDivRef} >
            {isLoading && <Loader styling={`absolute w-fit right-[40%] lg:right-[60%] top-[50%]`} />}

            <div className={`flex`}>

                {/*  search bar for mobile view */}
                {mobileSearchBar && <>
                    {/* {`transition-all duration-300 ease-in-out ${isSearchOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} bg-white p-4 absolute top-0 left-0 w-full`} */}
                    <div className='lg:hidden fixed top-0 bg-white  z-[60]'>
                        <div className='w-[100vw] flex gap-2 absolute py-4 top-0 items-center bg-white' >
                            <img src={LeftArrowIcon} onClick={() => { setMobileSearchBar(false) }} className='' alt="" />
                            <input type="text" className='w-[70vw] px-2' placeholder='Search Events' />
                            <img src={CutIcon} className='h-[15px]' alt="" />
                            <img src={searchIconBlack} className='h-4' alt="" />
                        </div>
                    </div>
                </>}


                <div className={`bg-[#eeeeee] ${mobileSearchBar ? "bg-black" : ""} lg:ml-2 lg:w-3/4 lg:h-[85vh] lg:pr-5 lg:overflow-y-scroll`}>
                    <div onClick={() => { setMobileSearchBar(false) }} style={{ height }} className={`h-screen overflow-y-hidden w-full lg:hidden absolute opacity-60 bg-black ${mobileSearchBar ? "" : "-z-10"}`} ></div>
                    <div className='w-[100vw] px-2 lg:px-0 lg:w-full lg:bg-[#eeeeee] bg-navyblue-500 flex items-center justify-between' style={{ borderTop: '1px solid #fff' }}>
                        <div>

                            <ul className='flex  text-sm font-semibold w-[90vw] my-2 lg:w-full cursor-pointer'>
                                <li onClick={() => { setCurrentPage("In-Play"); setTodayPageFilterIsOpen(false); setTomorrowPageFilterIsOpen(false) }} className={`border-[1px] lg:mb-3   ${currentPage === "In-Play" ? "lg:bg-[#3b5160] bg-white lg:text-white" : "lg:bg-white text-white lg:text-black"} rounded-l-md   py-1 px-4 lg:px-10 border-white  lg:border-[#3b5160]`}>In-Play</li>
                                <li onClick={() => { setCurrentPage("Today"); setTodayPageFilterIsOpen(false); setTomorrowPageFilterIsOpen(false) }} className={`border-[1px] lg:mb-3 ${currentPage === "Today" ? "lg:bg-[#3b5160] bg-white lg:text-white" : "lg:bg-white text-white lg:text-black"}  py-1 px-4 lg:px-10 border-white  lg:border-[#3b5160]`}>Today</li>
                                <li onClick={() => { setCurrentPage("Tomorrow"); setTodayPageFilterIsOpen(false); setTomorrowPageFilterIsOpen(false) }} className={`border-[1px] lg:mb-3 ${currentPage === "Tomorrow" ? "lg:bg-[#3b5160] bg-white lg:text-white" : "lg:bg-white text-white lg:text-black"} ${isUserLoggedIn != 'true' ? 'rounded-r-md' : ''} py-1 px-4 lg:px-10 border-white  lg:border-[#3b5160]`}>Tomorrow</li>
                                {isUserLoggedIn == 'true' &&
                                    <li className={`border-[1px] lg:mb-3 lg:bg-white text-white lg:text-black rounded-r-md  py-1 px-4 lg:px-10 border-white  lg:border-[#3b5160]`}>Result</li>}

                            </ul>
                        </div>
                        <a style={{
                            flex: '0 1 12.5333333333vw',
                            position: 'relative',
                            background: 'linear-gradient(-180deg, rgba(255, 255, 255, 0.15) 0 %, rgba(0, 0, 0, 0.15) 100 %)'
                        }}>
                            <img src={searchIcon}
                            //  onClick={() => { setMobileSearchBar(true) }} 
                             className='lg:hidden h-7' alt="" style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '5.8666666667vw',
                                height: '5.8666666667vw',
                                backgroundSize: 'cover'
                            }} />
                        </a>
                    </div>

                    {
                        currentPage === "In-Play" && <>
                            <div>
                                <div>
                                    <h1 onClick={() => { setCricketPageOpen((prev) => !prev) }} className='text-white bg-[#294253] font-bold lg:bg-black p-2 text-sm hidden lg:flex items-center justify-center lg:justify-between'>Cricket <img className='h-4 hidden lg:block' src={CricketPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                    <h1 className='text-white bg-[#294253] font-bold lg:bg-black p-2 text-sm lg:hidden flex items-center justify-center lg:justify-between'>Cricket <img className='h-4 hidden lg:block' src={CricketPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                    {
                                        CricketPageOpen && <>

                                            <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                                <thead className='hidden lg:table-header-group' >
                                                    <tr className='bg-[#ced5da] text-sm font-light '>
                                                        <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]'>Matched</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                        <th className='text-transparent' >{"pin"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {inPlayCricket?.length === 0 && (<div className='text-black px-5 py-1'>Data is not available</div>)} */}
                                                    {inPlayCricket?.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate))?.map((e) => {
                                                        let matchName = "example";
                                                        matchName = e.eventName;
                                                        let eventName = matchName.substring(0, 35);
                                                        return <SportListItem key={e.SrNo} eid={e.eid} marketId={e.marketId} eventId={e.gameId} greyLine="In-Play" title={e.eventName?.length > 30 ? eventName + "..." : e.eventName}
                                                            matchName={e.eventName} series={e.tournamentId} OneBackValue={e.back11} OneLayValue={e.lay11} XBackValue={e.back1} XLayValue={e.lay1} TwoBackValue={e.back12} TwoLayValue={e.lay12} isDisabled={false} isFancy={e.Fancy === 1 ? true : false} isBookMarked={e.BookMaker === 1 ? true : false} isInPlay={e.inPlay ? true : false} IsBetLock={e.IsBetLock == 1 ? true : false} widthOfTr="60px" isLive={true} eventDate={e.eventDate} totalMatched={e.totalMatched} />
                                                    })}
                                                </tbody>
                                            </table>

                                        </>
                                    }

                                    <h1 onClick={() => { setSoccerPageOpen((prev) => !prev) }} className='text-white mt-5 bg-[#294253] font-bold lg:bg-black p-2 text-sm hidden lg:flex items-center justify-center lg:justify-between'>Soccer <img className='h-4 hidden lg:block' src={SoccerPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                    <h1 className='text-white mt-5 bg-[#294253] font-bold lg:bg-black p-2 text-sm lg:hidden flex items-center justify-center lg:justify-between'>Soccer <img className='h-4 hidden lg:block' src={SoccerPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                    {
                                        SoccerPageOpen && <>

                                            <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                                <thead className='hidden lg:table-header-group' >
                                                    <tr className='bg-[#ced5da] text-sm font-light '>
                                                        {/* <th className='py-2 bg-[#ced5da]  text-[#1e1e1e] pr-20 border-gray-400' ></th> */}
                                                        <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]' >Matched</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                        <th className='text-transparent' >{"pin"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {inPlaySoccer?.length === 0 && (<div className='text-black px-5 py-1'>Data is not available</div>)} */}
                                                    {inPlaySoccer?.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate))?.map((e) => {
                                                        let matchName = "example";
                                                        matchName = e.eventName;
                                                        let eventName = matchName.substring(0, 35);
                                                        return <SportListItem key={e.SrNo} eid={e.eid} marketId={e.marketId} eventId={e.gameId} greyLine="In-Play" title={eventName?.length > 30 ? eventName + "..." : eventName}
                                                            matchName={e.eventName} series={e.tournamentId} OneBackValue={e.back11} OneLayValue={e.lay11} XBackValue={e.back1} XLayValue={e.lay1} TwoBackValue={e.back12} TwoLayValue={e.lay12} isDisabled={false} isFancy={e.Fancy === 1 ? false : false} isBookMarked={e.BookMaker === 1 ? false : false} isInPlay={e.inPlay ? true : false} XValue={"--"} XValue2={"--"} IsBetLock={e.IsBetLock == 1 ? true : false} widthOfTr="60px" isLive={true} eventDate={e.eventDate} totalMatched={e.totalMatched} />
                                                    })}
                                                </tbody>
                                            </table>


                                        </>
                                    }

                                    <h1 onClick={() => { setTennisPageOpen((prev) => !prev) }} className='text-white mt-5 bg-[#294253] font-bold lg:bg-black p-2 text-sm hidden lg:flex items-center justify-center lg:justify-between'>Tennis <img className='h-4 hidden lg:block' src={TennisPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                    <h1 className='text-white mt-5 bg-[#294253] font-bold lg:bg-black p-2 text-sm lg:hidden flex items-center justify-center lg:justify-between'>Tennis <img className='h-4 hidden lg:block' src={TennisPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                    {
                                        TennisPageOpen && <>

                                            <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                                <thead className='hidden lg:table-header-group' >
                                                    <tr className='bg-[#ced5da] text-sm font-light '>
                                                        {/* <th className='py-2 bg-[#ced5da]  text-[#1e1e1e] pr-20 border-gray-400' ></th> */}
                                                        <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]' >Matched</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                        <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                        <th className='text-transparent' >{"pin"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {inPlayTennis?.length === 0 && (<div className='text-black px-5 py-1'>Data is not available</div>)} */}
                                                    {inPlayTennis?.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate))?.map((e) => {
                                                        let matchName = "example";
                                                        matchName = e.eventName;
                                                        let eventName = matchName.substring(0, 35);
                                                        return <SportListItem key={e.SrNo} eid={e.eid} marketId={e.marketId} eventId={e.gameId} greyLine="In-Play" title={eventName?.length > 30 ? eventName + "..." : eventName} matchName={e.eventName} series={e.tournamentId} OneBackValue={e.back11} OneLayValue={e.lay11} XBackValue={e.back1} XLayValue={e.lay1} TwoBackValue={e.back12} TwoLayValue={e.lay12} isDisabled={false} isFancy={e.Fancy === 1 ? false : false} isBookMarked={e.BookMaker === 1 ? false : false} isInPlay={e.inPlay ? true : false} XValue={"--"} XValue2={"--"} IsBetLock={e.IsBetLock == 1 ? true : false} widthOfTr="60px"
                                                            isLive={true} eventDate={e.eventDate} totalMatched={e.totalMatched} />
                                                    })}
                                                </tbody>
                                            </table>


                                        </>
                                    }

                                </div>
                            </div>
                        </>
                    }
                    {/* ‣‣‣• */}

                    {
                        currentPage === "Today" && <>


                            {/* for mobile view */}
                            <div className="lg:hidden">
                                <h1 className='text-white bg-[#294253] font-bold lg:bg-black p-2 text-sm flex items-center justify-center lg:justify-between'>Cricket <img className='h-4 hidden lg:block' src={CricketPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                <div className='lg:hidden bg-white'>

                                    <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                        <thead className='hidden lg:table-header-group' >
                                            <tr className='bg-[#ced5da] text-sm font-light '>
                                                <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]'>Matched</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                <th className='text-transparent' >{"pin"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {/* {cricketToday?.length === 0 && (<div className='text-black px-5 py-1'>Data is not available</div>)} */}
                                            {cricketToday?.filter(item => new Date(item.eventDate) > new Date)?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((e) => {
                                                let matchName = "example";
                                                matchName = e.eventName;
                                                let eventName = matchName.substring(0, 35);
                                                const eventDate = new Date(e.eventDate);

                                                // Format eventDate to only include the date part
                                                const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                                                return (

                                                    <>
                                                        <  SportListItem
                                                            greyLine={formattedEventDate}
                                                            eventId={e.gameId} eid={e.eid} marketId={e.marketId} title={eventName.length > 30 ? eventName + " ..." : eventName} matchName={e.eventName} series={e.tournamentId} isFancy={e.Fancy === 1 ? true : false} isBookMarked={e.BookMaker === 1 ? true : false} isInPlay={e.inPlay ? true : false} IsBetLock={e.IsBetLock == 1 ? true : false} active="green"
                                                            isLive={false} eventDate={e.eventDate} totalMatched={e.totalMatched} />

                                                    </>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                    {/* <div className='flex justify-between items-center p-1 border-b border-[#7E97A7]'>
                                        <div>
                                            <p className='pl-4 text-xs flex items-center gap-1 text-[#777777]'>Tomorrow 01:30<img src={PlayIconBlue} className='h-4 bg-blue-500 rounded-sm p-[2px]' alt="" /></p>
                                            <h1 className='text-[#2789ce] font-bold flex gap-1 items-center'><img className='h-3 w-3' src={circleIcon} alt="" />Northern District v Central Districts</h1>
                                        </div>
                                        <img src={pinIconGrey} className='w-7 h-7' alt="" />
                                    </div> */}

                                </div>


                                <h1 className='text-white mt-5 bg-[#294253] lg:bg-black p-2 text-sm flex items-center justify-center lg:justify-between'>Soccer <img className='h-4 hidden lg:block' src={SoccerPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                <div className='lg:hidden bg-white'>

                                    <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                        <thead className='hidden lg:table-header-group' >
                                            <tr className='bg-[#ced5da] text-sm font-light '>
                                                <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]'>Matched</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                <th className='text-transparent' >{"pin"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {/* {soccerToday?.length === 0 && <div className='px-5 py-1'>Data is not available</div>} */}

                                            {
                                                soccerToday?.filter(item => new Date(item.eventDate) > new Date)?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((e) => {
                                                    let matchName = "example";
                                                    matchName = e.eventName;
                                                    let eventName = matchName.substring(0, 35);
                                                    const eventDate = new Date(e.eventDate);

                                                    // Format eventDate to only include the date part
                                                    const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                                                    return (
                                                        <>
                                                            <SportListItem
                                                                greyLine={formattedEventDate}
                                                                eventId={e.gameId} eid={e.eid} marketId={e.marketId} title={eventName.length > 30 ? eventName + " ..." : eventName} matchName={e.eventName} series={e.tournamentId} isFancy={e.Fancy === 1 ? false : false} isBookMarked={e.BookMaker === 1 ? false : false} isInPlay={e.inPlay ? true : false} IsBetLock={e.IsBetLock == 1 ? true : false} active="green"
                                                                isLive={false} eventDate={e.eventDate} totalMatched={e.totalMatched} />

                                                        </>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                    {/* <div className='flex justify-between items-center p-1 border-b border-[#7E97A7]'>
                                        <div>
                                            <p className='pl-4 text-xs flex items-center gap-1 text-[#777777]'>Tomorrow 01:30<img src={PlayIconBlue} className='h-4 bg-blue-500 rounded-sm p-[2px]' alt="" /></p>
                                            <h1 className='text-[#2789ce] font-bold flex gap-1 items-center'><img className='h-3 w-3' src={circleIcon} alt="" />Northern District v Central Districts</h1>
                                        </div>
                                        <img src={pinIconGrey} className='w-7 h-7' alt="" />
                                    </div> */}

                                </div>


                                <h1 className='text-white mt-5 bg-[#294253] lg:bg-black p-2 text-sm flex items-center justify-center lg:justify-between'>Tennis <img className='h-4 hidden lg:block' src={TennisPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                <div className='lg:hidden bg-white'>

                                    <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                        <thead className='hidden lg:table-header-group' >
                                            <tr className='bg-[#ced5da] text-sm font-light '>
                                                <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]'>Matched</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                <th className='text-transparent' >{"pin"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {/* {tennisToday?.length === 0 && <div className='px-5 py-1'>Data is not available</div>} */}
                                            {
                                                tennisToday?.filter(item => new Date(item.eventDate) > new Date)?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((e) => {
                                                    let matchName = "example";
                                                    matchName = e.eventName;
                                                    let eventName = matchName.substring(0, 35);
                                                    const eventDate = new Date(e.eventDate);

                                                    // Format eventDate to only include the date part
                                                    const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                                                    return (
                                                        <>
                                                            <SportListItem greyLine={formattedEventDate} eventId={e.gameId} eid={e.eid} marketId={e.marketId} title={eventName.length > 30 ? eventName + " ..." : eventName} matchName={e.eventName} series={e.tournamentId} isFancy={e.Fancy === 1 ? false : false} isBookMarked={e.BookMaker === 1 ? false : false} isInPlay={e.inPlay ? true : false} IsBetLock={e.IsBetLock == 1 ? true : false} active="green"
                                                                isLive={false} eventDate={e.eventDate} totalMatched={e.totalMatched} />

                                                        </>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                    {/* <div className='flex justify-between items-center p-1 border-b border-[#7E97A7]'>
                                        <div>
                                            <p className='pl-4 text-xs flex items-center gap-1 text-[#777777]'>Tomorrow 01:30<img src={PlayIconBlue} className='h-4 bg-blue-500 rounded-sm p-[2px]' alt="" /></p>
                                            <h1 className='text-[#2789ce] font-bold flex gap-1 items-center'><img className='h-3 w-3' src={circleIcon} alt="" />Northern District v Central Districts</h1>
                                        </div>
                                        <img src={pinIconGrey} className='w-7 h-7' alt="" />
                                    </div> */}

                                </div>
                            </div>


                            {/* for pc view */}
                            <div className='hidden bg-[#e0e6e6] lg:flex justify-between py-2 border-b border-black px-5 items-center'>
                                <h1>
                                    <span className='font-semibold text-sm'>Sport Filters: </span>
                                    {/* <span className='font-normal text-sm'> E-Soccer </span> */}
                                    {/* <span className='font-normal text-sm'><span className='text-gray-500 pl-1' >&#x2688;</span> Kabaddi</span> */}
                                    <span className='font-normal text-sm '><span className='text-gray-500 pl-1' >&#x2688;</span> Cricket</span>
                                    <span className='font-normal text-sm '><span className='text-gray-500 pl-1' >&#x2688;</span> Soccer</span>
                                    <span className='font-normal text-sm '><span className='text-gray-500 pl-1' >&#x2688;</span> Tennis</span></h1>
                                <button onClick={() => { setTodayPageFilterIsOpen(prev => !prev) }} className='filterbtn py-[2px] px-8 border border-gray-400 text-sm bg-[#f7f7f7] rounded-md'>Filter</button>
                            </div>
                            <div className='hidden lg:block h-[70vh] overflow-y-scroll  bg-white mt-2 py-1 relative mx-2'>
                                <div className={`sticky top-0 ${TodayPageFilterIsOpen ? "block" : "hidden"}`}>
                                    <div className=' w-[35vw] bg-white text-sm absolute right-0  rounded-md' style={{ boxShadow: "0 4px 5px rgba(0,0,0,.5)" }}>
                                        <div className='flex w-full py-2 pl-2 border-b border-black'>
                                            <ul className='w-1/2'>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />All</li>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />Tennis</li>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />E-Soccer</li>
                                            </ul>

                                            <ul>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />Soccer</li>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />Cricket</li>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />Kabaddi</li>
                                            </ul>
                                        </div>
                                        <div className='flex w-full mx-2 p-2 gap-3'>
                                            <button className='py-1 px-8 bg-black text-[#ffb600] font-bold rounded-md'>Save</button>
                                            <button className='py-1 px-5 bg-[#f7f7f7] filterbtn border-gray-400 font-bold border text-black rounded-md' onClick={() => {
                                                setTodayPageFilterIsOpen(false)
                                            }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>

                                {todaysData.length > 0 && todaysData[0]?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((item, index) => (
                                    <div className='flex items-center gap-10 border-b border-[#e0e6e6] hover:bg-[#eeee]' key={index}>
                                        <h1 className='text-sm font-bold'>{format(new Date(item.eventDate), 'HH:mm')}</h1>
                                        <p className='flex items-center text-sm'>{item.eid == 1 ? 'Soccer' : item.eid == 2 ? 'Tennis' : item.eid == 4 ? 'Cricket' : ''} <span className='text-3xl text-[#c2c4c4]'>‣</span><span className='text-sm'>{item.seriesName}</span> <span className='text-3xl text-[#c2c4c4]'>‣</span><span className='text-[#3987be] font-semibold text-sm hover:underline cursor-pointer'><Link to={`/event/${item.gameId}/${item.eid}`} state={{ marketId: item.marketId, IsBetLock: item.IsBetLock == 1 ? true : false, matchName: item.eventName, series: item.tournamentId, eventDate: item.eventDate, isLive: false }}  >{item.eventName}</Link></span></p>
                                    </div>
                                ))}
                            </div>
                        </>
                    }



                    {
                        currentPage === "Tomorrow" && <>


                            {/* for mobile view */}
                            <div className="lg:hidden">
                                <h1 className='text-white bg-[#294253] font-bold lg:bg-black p-2 text-sm flex items-center justify-center lg:justify-between'>Cricket <img className='h-4 hidden lg:block' src={CricketPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                <div className='lg:hidden bg-white'>

                                    <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                        <thead className='hidden lg:table-header-group' >
                                            <tr className='bg-[#ced5da] text-sm font-light '>
                                                <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]'>Matched</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                <th className='text-transparent' >{"pin"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>



                                            {/* {cricketTomorrow?.length === 0 && <div className='px-5 py-1'>Data is not available</div>} */}
                                            {
                                                cricketTomorrow?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((e) => {
                                                    let matchName = "example";
                                                    matchName = e.eventName;
                                                    let eventName = matchName.substring(0, 35);
                                                    const eventDate = new Date(e.eventDate);

                                                    // Format eventDate to only include the date part
                                                    const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                                                    return (
                                                        <>
                                                            <SportListItem
                                                                // title={}
                                                                greyLine={`Tomorrow ${formattedEventDate}`} eid={e.eid} eventId={e.gameId} marketId={e.marketId} title={eventName.length > 30 ? eventName + " ..." : eventName} matchName={e.eventName} series={e.tournamentId} isFancy={e.Fancy === 1 ? true : false} isBookMarked={e.BookMaker === 1 ? true : false} isInPlay={e.inPlay ? true : false} IsBetLock={e.IsBetLock == 1 ? true : false} active="green" isLive={false} eventDate={e.eventDate} totalMatched={e.totalMatched} />

                                                        </>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>

                                    {/* <div className='flex justify-between items-center p-1 border-b border-[#7E97A7]'>
                                        <div>
                                            <p className='pl-4 text-xs flex items-center gap-1 text-[#777777]'>Tomorrow 01:30<img src={PlayIconBlue} className='h-4 bg-blue-500 rounded-sm p-[2px]' alt="" /></p>
                                            <h1 className='text-[#2789ce] font-bold flex gap-1 items-center'><img className='h-3 w-3' src={circleIcon} alt="" />Northern District v Central Districts</h1>
                                        </div>
                                        <img src={pinIconGrey} className='w-7 h-7' alt="" />
                                    </div> */}

                                </div>


                                <h1 className='text-white mt-5 bg-[#294253] lg:bg-black p-2 text-sm flex items-center justify-center lg:justify-between'>Soccer <img className='h-4 hidden lg:block' src={SoccerPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                <div className='lg:hidden bg-white'>

                                    <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                        <thead className='hidden lg:table-header-group' >
                                            <tr className='bg-[#ced5da] text-sm font-light '>
                                                <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]'>Matched</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                <th className='text-transparent' >{"pin"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {/* {soccerTomorrow?.length === 0 && <div className='px-5 py-1'>Data is not available</div>} */}
                                            {
                                                soccerTomorrow?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((e) => {
                                                    let matchName = "example";
                                                    matchName = e.eventName;
                                                    let eventName = matchName.substring(0, 35);
                                                    const eventDate = new Date(e.eventDate);

                                                    // Format eventDate to only include the date part
                                                    const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                                                    return (
                                                        <>
                                                            <SportListItem greyLine={`Tomorrow ${formattedEventDate}`} eid={e.eid} eventId={e.gameId} marketId={e.marketId} title={eventName.length > 30 ? eventName + " ..." : eventName} matchName={e.eventName} series={e.tournamentId} isFancy={e.Fancy === 1 ? false : false} isBookMarked={e.BookMaker === 1 ? false : false} isInPlay={e.inPlay ? true : false} IsBetLock={e.IsBetLock == 1 ? true : false} active="green" isLive={false} eventDate={e.eventDate} totalMatched={e.totalMatched} />

                                                        </>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                    {/* <div className='flex justify-between items-center p-1 border-b border-[#7E97A7]'>
                                        <div>
                                            <p className='pl-4 text-xs flex items-center gap-1 text-[#777777]'>Tomorrow 01:30<img src={PlayIconBlue} className='h-4 bg-blue-500 rounded-sm p-[2px]' alt="" /></p>
                                            <h1 className='text-[#2789ce] font-bold flex gap-1 items-center'><img className='h-3 w-3' src={circleIcon} alt="" />Northern District v Central Districts</h1>
                                        </div>
                                        <img src={pinIconGrey} className='w-7 h-7' alt="" />
                                    </div> */}

                                </div>


                                <h1 className='text-white mt-5 bg-[#294253] lg:bg-black p-2 text-sm flex items-center justify-center lg:justify-between'>Tennis <img className='h-4 hidden lg:block' src={TennisPageOpen ? MinusIcon : PlusIcon} alt="" /> </h1>
                                <div className='lg:hidden bg-white'>

                                    <table className='text-white  w-full border-collapse border bg-white border-gray-400'>
                                        <thead className='hidden lg:table-header-group' >
                                            <tr className='bg-[#ced5da] text-sm font-light '>
                                                <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]'>Matched</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >1</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >x</th>
                                                <th className={`font-light text-[#1e1e1e] border-gray-400 `} >2</th>
                                                <th className='text-transparent' >{"pin"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {/* {tennisTomorrow?.length === 0 && <div className='px-5 py-1'>Data is not available</div>} */}
                                            {
                                                tennisTomorrow?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((e) => {
                                                    let matchName = "example";
                                                    matchName = e.eventName;
                                                    let eventName = matchName.substring(0, 35);
                                                    const eventDate = new Date(e.eventDate);

                                                    // Format eventDate to only include the date part
                                                    const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                                                    return (
                                                        <>
                                                            <SportListItem greyLine={`Tomorrow ${formattedEventDate}`} eid={e.eid} eventId={e.gameId} marketId={e.marketId} title={eventName.length > 30 ? eventName + " ..." : eventName} matchName={e.eventName} series={e.tournamentId} isFancy={e.Fancy === 1 ? false : false} isBookMarked={e.BookMaker === 1 ? false : false} isInPlay={e.inPlay ? true : false} IsBetLock={e.IsBetLock == 1 ? true : false} active="green" isLive={false} eventDate={e.eventDate} totalMatched={e.totalMatched} />

                                                        </>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                    {/* <div className='flex justify-between items-center p-1 border-b border-[#7E97A7]'>
                                        <div>
                                            <p className='pl-4 text-xs flex items-center gap-1 text-[#777777]'>Tomorrow 01:30<img src={PlayIconBlue} className='h-4 bg-blue-500 rounded-sm p-[2px]' alt="" /></p>
                                            <h1 className='text-[#2789ce] font-bold flex gap-1 items-center'><img className='h-3 w-3' src={circleIcon} alt="" />Northern District v Central Districts</h1>
                                        </div>
                                        <img src={pinIconGrey} className='w-7 h-7' alt="" />
                                    </div> */}

                                </div>



                            </div>

                            {/* for pc view */}
                            <div className='bg-[#e0e6e6] hidden lg:flex justify-between py-2 border-b border-black px-5 items-center'>
                                <h1>
                                    <span className='font-semibold text-sm'>Sport Filters: </span>
                                    {/* <span className='font-normal text-sm'> E-Soccer </span> */}
                                    {/* <span className='font-normal text-sm'><span className='text-gray-500 pl-1' >&#x2688;</span> Kabaddi</span> */}
                                    <span className='font-normal text-sm '><span className='text-gray-500 pl-1' >&#x2688;</span> Cricket</span>
                                    <span className='font-normal text-sm '><span className='text-gray-500 pl-1' >&#x2688;</span> Soccer</span>
                                    <span className='font-normal text-sm '><span className='text-gray-500 pl-1' >&#x2688;</span> Tennis</span></h1>
                                <button className='py-[2px] px-8  border border-gray-400 text-sm filterbtn rounded-md' onClick={() => { setTomorrowPageFilterIsOpen(prev => !prev) }}>Filter</button>
                            </div>
                            <div className='h-[70vh] hidden lg:block overflow-y-scroll bg-white mt-2 py-1 mx-2'>
                                <div className={`sticky top-0 ${TomorrowPageFilterIsOpen ? "block" : "hidden"}`}>
                                    <div className=' w-[35vw] bg-white text-sm absolute right-0  rounded-md' style={{ boxShadow: "0 4px 5px rgba(0,0,0,.5)" }}>
                                        <div className='flex w-full py-2 pl-2 border-b border-black'>
                                            <ul className='w-1/2'>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />All</li>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />Tennis</li>
                                                {/* <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />E-Soccer</li> */}
                                            </ul>

                                            <ul>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />Soccer</li>
                                                <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />Cricket</li>
                                                {/* <li className='flex items-center gap-1' ><input type="checkbox" name="" id="" />Kabaddi</li> */}
                                            </ul>
                                        </div>
                                        <div className='flex w-full mx-2 p-2 gap-3'>
                                            <button className='py-1 px-8 bg-black text-[#ffb600] font-bold rounded-md'>Save</button>
                                            <button className='py-1 px-5 bg-[#f7f7f7] filterbtn border-gray-400 font-bold border text-black rounded-md' onClick={() => {
                                                setTomorrowPageFilterIsOpen(false)
                                            }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>

                                {tomorrowsData.length > 0 && tomorrowsData[0]?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((item, index) => (

                                    <div className='flex items-center gap-10 border-b border-[#e0e6e6] hover:bg-[#eeee]' key={index}>
                                        <h1 className='text-sm font-bold'>{format(new Date(item.eventDate), 'HH:mm')}</h1>
                                        <p className='flex items-center text-sm'>{item.eid == 1 ? 'Soccer' : item.eid == 2 ? 'Tennis' : item.eid == 4 ? 'Cricket' : ''}<span className='text-3xl text-[#c2c4c4]'>‣</span><span className='text-sm'>{item.seriesName}</span> <span className='text-3xl text-[#c2c4c4]'>‣</span><span className='text-[#3987be] font-semibold text-sm hover:underline cursor-pointer'><Link to={`/event/${item.gameId}/${item.eid}`} state={{ marketId: item.marketId, IsBetLock: item.IsBetLock == 1 ? true : false, matchName: item.eventName, series: item.tournamentId, eventDate: item.eventDate, isLive: false }}  >{item.eventName}</Link></span></p>
                                    </div>
                                ))}

                            </div>
                        </>
                    }



                </div>
                <div className='lg:block hidden bg-white w-1/4 border-t'>
                    <h1 className='bg-[#274252] text-white text-xs  font-semibold p-1 flex justify-between'>Bet Slip <img src={MinusIcon} className='h-4' alt="" /></h1>
                    {bets.length === 0 && <h1 className='p-3 text-xs text-center'>Click on the odds to add selections to the betslip.</h1>}


                    {
                        betSlipData !== undefined && <BetSlipListItem teamName={betSlipData?.SelectedTeam} matchName={betSlipData?.matchName} type={betSlipData?.type} value={betSlipData?.value} series={betSlipData?.series} eventDate={betSlipData?.eventDate} />
                    }

                </div>



            </div>
        </div>
    )
}

export default InPlay
