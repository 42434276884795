import React, { useState } from 'react';
import { FaPen } from "react-icons/fa";
import Modal from '../../components/Modal';
import Cookies from "js-cookie";
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const Profile = () => {

   //console.log(`profile page is rendering!!`);

    const [isOpen, setIsOpen] = useState(false);

    let userId2 = Cookies.get("LoginUserDetail");
    let userId = userId2 === undefined ? "" : JSON.parse(userId2);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const { data: currency, isLoading, isError } = useQuery({
        queryKey: ["currency"], queryFn: async () => {
          let res = await axios.get(`https://api.betcare.live/api/getActiveCurrency/currency`);
          let resData = await res.data;
          return resData;
        }
      })

  return (
    <>
     <Modal isOpen={isOpen} setIsOpen={setIsOpen} />
      <h1 className='text-sm font-bold m-2'>Account Details</h1>
      
    <div className='w-screen lg:w-full flex flex-col lg:flex-row gap-4'>

        <div className='lg:w-1/2 mx-auto lg:mx-0' >
            <h1 className='bg-[#7e97a7] text-sm p-1 font-bold text-white' >About You</h1>
            <ul className='text-xs bg-white border-b border-[#7e97a7]'>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >First Name <span className='ml-40'>{userId?.FullName}</span> </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Last Name  <span className='ml-40'>---</span> </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Birthday  <span className='ml-44'>--</span> </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >E-mail <span className='ml-44'>---</span>  </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6] flex justify-between' >Password <span className='mr-10'>***************</span> <span onClick={openModal} className='flex items-center cursor-pointer gap-1 text-blue-500 hover:underline' >Edit <FaPen /> </span> </li>
            </ul>

            <h1 className='bg-[#7e97a7] text-sm p-1 font-bold mt-4 text-white' >Address</h1>
            <ul className='text-xs bg-white border-b border-[#7e97a7]'>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Address <span className='ml-40'>---</span> </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Town/City  <span className='ml-[150px]'>---</span> </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Country  <span className='ml-40'>---</span> </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Country/State <span className='ml-32'>---</span>  </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Postcode <span className='ml-[154px]'>---</span>  </li>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Timezone <span className='ml-[154px]'>IST</span>  </li>
            </ul>
           
        </div>

        <div className='lg:w-1/2 mb-10 lg:mb-0 mx-auto lg:mx-0 lg:mr-10'>
        <h1 className='bg-[#7e97a7] text-sm p-1 font-bold text-white' >Contact Details</h1>
            <ul className='text-xs bg-white border-b border-[#7e97a7]'>
                <li className='p-1 px-2 border-b border-[#e0e6e6]' >Primary number <span className='ml-40'>---</span> </li>
            </ul>

        <h1 className='bg-[#7e97a7] text-sm p-1 font-bold mt-4 text-white' >Setting</h1>
        <ul className='text-xs bg-white border-b border-[#7e97a7]'>
            <li className='p-1 px-2 border-b border-[#e0e6e6]' >Currency <span className='ml-40'>{currency?.[0]?.CurrencyName}</span> </li>
            <li className='p-1 px-2 border-b border-[#e0e6e6]' >Odds Format  <span className='ml-[146px]'>--</span> </li>
        </ul>

        <h1 className='bg-[#7e97a7] text-sm p-1 font-bold mt-4 text-white' >Commision</h1>
        <ul className='text-xs bg-white border-b border-[#7e97a7]'>
            <li className='p-1 px-2 border-b border-[#e0e6e6]' >Comm charged <span className='ml-40'>---</span> </li>
        </ul>
        </div>
    </div>
    </>
  )
}

export default Profile
