import React, { useContext, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import GlobalContext from '../context/GlobalContext';
import Loading from "../images/loading.gif";
import axios from 'axios';
import Cookies from "js-cookie";
import { toast } from 'react-toastify';

const Modal = ({ isOpen, setIsOpen }) => {

    let [errorMessage , setErrorMessage] = useState();
    let [errorPlace , setErrorPlace] = useState();

    const newPasswordRef = useRef();
    const confirmNewPasswordRef = useRef();
    const oldPasswordRef = useRef();

    let userId2 = Cookies.get("LoginUserDetail");
    let userId = userId2 === undefined ? "" : JSON.parse(userId2);


    const ChangePassword = async ()=>{
        let newPass = newPasswordRef.current;
        let confirmPass = confirmNewPasswordRef.current;
        let oldPass = oldPasswordRef.current;

        let newPassValue = newPass?.value;
        let confirmPassValue = confirmPass?.value;
        let oldPassValue = oldPass?.value;

       //console.log(newPass?.value);
         
        if(newPass?.value === "" ){
            setErrorPlace(0);
            setErrorMessage("Enter the new password!");
        }
        else if(newPass?.value?.length <=5 || newPass?.value?.length > 15 ){
            setErrorPlace(0);
            setErrorMessage(`password Must be have more than 5 char and less than 15 !`);
        }
        else if(confirmPass?.value === ""){
            setErrorPlace(1);
            setErrorMessage(`Enter the confirm password!`);
        }
        else if(newPass?.value !== confirmPass?.value){
            setErrorPlace(1);
            setErrorMessage(`confirm password not matched!`);
        }
        else if(oldPass?.value === ""){
            setErrorPlace(2);
            setErrorMessage(`Enter your password!`);
        }
        else{

            try {  
                // alert(typeof(oldPassValue) + " : " + oldPassValue) ;
                let res = await fetch(`https://api.betcare.live/api/changePassword/users/${userId.Id}`, {
                    method:'PUT',
                    headers:{ "Content-Type":"application/json" },
                    body: JSON.stringify({oldPassword:oldPassValue, newPassword:newPassValue}) 
                });

                // alert("12"); 

                if(res.status === 200){
                 setIsOpen(false);
                 setErrorMessage(``);
                 toast.success(`Password changed successfully!`,{
                    autoClose: 1500,
                    theme: "colored",
                });
                }else{
                    setErrorPlace(2);
                    setErrorMessage(`entered wrong password!`);
                }

            } catch (error) {
               //console.log("error in changing the password! try again later!");
                setErrorMessage(``);

            }

        }

       
    }

  


    return (
        <ReactModal
            isOpen={isOpen}
            // onRequestClose={closeModal}  // it is used to close the modal by clicking anywhere outside of modal
            ariaHideApp={false} // This is necessary to prevent an accessibility issue
            className={`w-[95vw] lg:w-[400px] absolute right-[2%] lg:right-[40%] rounded-md top-3 lg:top-1 bg-[#eee] outline-none`}
            style={{overlay:{background:"rgba(0, 0, 0, 0.5)",zIndex:101}}}
        >
            <div className='flex justify-between mt-3 items-center mx-3' >
            <h2 className='text-lg text-[#3b5160] font-bold' >Change Password</h2>
            <RxCross2 onClick={()=>{setIsOpen(false)}} className='text-white text-lg bg-black rounded' />
            </div>

            <div className='flex flex-col gap-2 mt-5 items-end mr-12'>
            <div>
            <label className='text-xs pr-2' htmlFor="">New Password</label> <input type="password" ref={newPasswordRef} className='text-center text-xs py-1 rounded outline-none' style={{boxShadow:"inset 0px 1px 0px rgba(0,0,0,0.5)"}} /> <span className='text-red-500'>*</span>
            <h1 className='text-[10px] text-right text-red-500'>{errorPlace === 0 && errorMessage }</h1>
            </div>

            <div>
            <label className='text-xs pr-2' htmlFor="">New Password Confirm</label> <input type="password" ref={confirmNewPasswordRef} className='text-center text-xs py-1 rounded outline-none' style={{boxShadow:"inset 0px 1px 0px rgba(0,0,0,0.5)"}} /><span className='text-red-500'>*</span>
            <h1 className='text-[10px] text-right text-red-500'>{errorPlace === 1 && errorMessage }</h1>
            </div>

            <div>
            <label className='text-xs pr-2' htmlFor="">Your Password</label> <input type="password" ref={oldPasswordRef} className='text-center text-xs py-1 rounded outline-none' style={{boxShadow:"inset 0px 1px 0px rgba(0,0,0,0.5)"}} /><span className='text-red-500'>*</span>
            <h1 className='text-[10px] text-right text-red-500'>{errorPlace === 2 && errorMessage }</h1>
            </div>
            </div>
            <button onClick={ChangePassword} className='text-[#ffb600] text-sm py-1 px-2 mb-5 mt-3 mx-auto font-bold w-24 justify-center flex rounded' style={{background:"linear-gradient(180deg, #474747 0%, #070707 100%)"}} >Change</button>
        </ReactModal>
    );
}

export default Modal;
