import React, { useContext, useEffect, useRef, useState } from 'react'
import circleIcon from "../images/circle-icon-green.png";
import GlobalContext from '../context/GlobalContext';
import axios from 'axios';
import Cookies from "js-cookie";
import { toast } from 'react-hot-toast';


const BetSlipListItem = ({ SelectedRowName, EventNameBetSlip, type, setLoading, priceBetSlip, TypeOfData, minStake, maxStake, eid, eventId, marketId, market, selectionId, oddRequest }) => {


  //console.log("betSlipListItem is rendering!!");

  //console.log('EventId : ', eventId, " eid : ", eid)
  // const StakeRef = useRef();
  const [stakeValue, setStakeValue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [liability, setLiability] = useState(0);
  const [placeBet, setPlaceBet] = useState({});   // this object will contain the information about the bet that user want to place.
  // const [errorInBet, setErrorInBet] = useState(false);  // this state is for set , there is an error or not while setting bet
  // const [errorMessage, setErrorMessage] = useState("");   // this state will hold the message of error while setting bet
  // const [isSuccess, setIsSuccess] = useState(false);
  const [betId, setBetId] = useState('')

  const context = useContext(GlobalContext);
  const { quickStakes, setBetSlipData, betSlipData, isUserLoggedIn, setRemount, setBetSend, errorInBet, setErrorInBet, errorMessage, setErrorMessage, isSuccess, setIsSuccess } = context;

  const ArrQuickStakes = Object.values(quickStakes);

  let userId2 = Cookies.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON.parse(userId2);
  // //console.log(`userId value is : ${userId.Id}`);


  function generateRandomAlphaNumeric(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    setBetId(result)
    return result;
  }


  // function to set the data of placeBet
  const placeBetFuc = async () => {



    console.log('place bet in betSlip table into bet slip list item component...', betSlipData)


    let DelayedTime = betSlipData?.DelayTime * 1000;

    console.log('Delayed time in BetSlip : ', DelayedTime);


    const convertToIST = (dateString) => {
      const date = new Date(dateString);
      const ISTOffset = 330; // IST offset is UTC+5:30
      const ISTTime = new Date(date.getTime() + (ISTOffset * 60000));
      return ISTTime.toISOString().slice(0, 19).replace('T', ' '); // Format the date-time string 
    }


    let matchTimeData = convertToIST(new Date());

    if (stakeValue < minStake || stakeValue > maxStake) {
      setErrorInBet(true);
      setErrorMessage(`Enter stake between ${minStake} - ${maxStake}`);
      setIsSuccess(false);
      // alert(`Enter stake between ${minStake}  --  ${maxStake}`);
      setStakeValue(0);

      setTimeout(() => {
        setErrorInBet(false);
      }, 3000);
      return;
    }

    const currentBhaw = document.getElementById(betSlipData?.indexId).innerHTML;
    console.log('index Id is : ', currentBhaw)

    if (betSlipData?.market == 'BookMaker') {
      if (currentBhaw != betSlipData?.value2) {
        toast.error("Odds change..!", {
          duration: 1000
        })
        return;
      }
    } else {

      if (currentBhaw != betSlipData?.value) {
        toast.error("Odds change..!", {
          duration: 1000
        })
        return;
      }
    }


    setLoading(true);

    setTimeout(() => {

      ; (async () => {
        let res = await axios.post("https://api.betcare.live/saveBet", {
          BetId: generateRandomAlphaNumeric(30),
          SportId: eid,
          SeriesId: betSlipData?.series,
          EventId: eventId,
          Event: betSlipData?.market === "Fancy" ? betSlipData?.EventName : betSlipData?.matchName,
          MarketId: marketId,
          Market: betSlipData?.market,
          SelectionId: betSlipData?.selectionId,
          Selection: betSlipData?.EventName,
          OddsType: betSlipData?.market === "BookMaker" ? 2 : market === "Fancy" ? 3 : 1,
          Type: type,
          OddsRequest: betSlipData?.value,
          AmountStake: stakeValue,
          BetType: 2,
          IsSettlement: 2,
          IsDelete: 0,
          Status: true,
          UserId: userId.Id,
          ResultType: null,
          ResultAmount: 0,
          IpAddress: "",
          IsMatched: true,
          EventName: betSlipData?.matchName,
          Profit: profit.toFixed(2),
          Liability: liability.toFixed(2),
          MatchedTime: matchTimeData,
          settleTime: null,
          PlaceTime: convertToIST(new Date())
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (res.data.isSuccess) {

          console.log('bet placed : ', res.data);
          //  alert(res.status);
          let checkBal = await fetch(`https://api.betcare.live/expo2/${userId?.Id}/${userId?.selfUser}`)
          let resCheckBal = await checkBal.json();

          if (resCheckBal.isSuccess) {
            if (resCheckBal.mainBalanceafterExpo <= 0) {
              setLoading(false);
              setErrorInBet(true);
              setErrorMessage(`Insufficient balance..`);

              //console.log('balance block delete bet id : ', betId)
              const deleteQuery = await fetch(`https://api.betcare.live/deleteUnmatchBet`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId.Id })
              });

              const queryResp = await deleteQuery.json();


              setTimeout(() => {
                setErrorInBet(false);
                setBetSlipData();
                setRemount((prev) => !prev); // it will update the value in match odds
              }, 3000)
              return;
            } else {
              const updateQuery = await fetch(`https://api.betcare.live/updateUnmatchBet`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId.Id, BetId: res.data.betId })
              });

              const queryResp2 = await updateQuery.json()
              if (queryResp2.isSuccess) {

                setLoading(false);
                setErrorInBet(true);
                setErrorMessage(`Bet placed successfull`);
                setIsSuccess(true);
                setBetSend(true);
                // alert(`Enter stake between ${minStake}  --  ${maxStake}`);
                setStakeValue(0)

              }
            }
          }

        }

      })();
      setTimeout(() => {
        setErrorInBet(false);
        setBetSlipData();
        setRemount((prev) => !prev); // it will update the value in match odds
      }, 3000)


    }, DelayedTime);
  }









  // function to change and update the profit and liability of bet-slip
  const updatingBetSlip = (e) => {
    let value = e.target.value;
    setStakeValue(value);

    if (betSlipData?.dataType === "match odds") {

      if (type === "back") {
        setProfit(((betSlipData?.value - 1) * value));      // setting value of profit   
        setLiability(value < 0 ? 0 : value * -1);    // setting value of liability
      }
      else {
        setProfit(value * 1);   // setting value of profit
        setLiability(((betSlipData?.value - 1) * value) * -1);    // setting value of liability
      } return;
    }

    else if (betSlipData?.dataType === "bookmaker") {
      if (type === "back") {

        setProfit(betSlipData?.value * value);      // setting value of profit      
        setLiability(value * -1);    // setting value of liability
      }
      else {
        setProfit(value * 1);   // setting value of profit  
        setLiability(-1 * (betSlipData?.value * value));    // setting value of liability
      } return;
    }

  }


  useEffect(() => {
    setStakeValue(0);
    setProfit(0);
    setLiability(0);

  }, [type])

  return (
    <>
      {/* {errorInBet && <div className={`${isSuccess ? "bg-green-500" : "bg-red-500"} font-bold text-white p-2 text-sm text-center`}> {errorMessage} </div>} */}

      <table className='w-full border-collapse'>
        <thead>
          <tr className='text-xs font-mono text-[#1e1e1e] bg-[#ced5da] py-1'>
            <th className='text-left pl-2' >Back (BetFor)</th>
            <th>Odds</th>
            <th>Stake</th>
            <th>Profits</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='' colSpan={4}>
              <h1 className='text-xs p-1 flex items-center gap-1'> <img src={circleIcon} className='h-3' alt="" />{EventNameBetSlip}</h1>
            </td>
          </tr>
          <tr className={`text-xs p-1  ${type === "back" ? "bg-[#beddf4] border-[#7dbbe9]" : "bg-[#f7e7eb] border-[#dfa3b3]"}  border-b `}>
            <td className='py-1'>{betSlipData?.EventName} Odds</td>
            <td><input type="number" value={betSlipData?.value} readOnly name="" className='py-1 w-[48px] h-[18px] p-1 rounded-sm' style={{ boxShadow: "inset 0px 1px 0px rgba(0,0,0,.5)" }} id="" /></td>
            <td><input type="number" name="" value={stakeValue < 0 ? 0 : stakeValue === "" ? 0 : stakeValue} onChange={updatingBetSlip} min={1} className='py-1 w-[48px] h-[18px] p-1 rounded-sm' style={{ boxShadow: "inset 0px 1px 0px rgba(0,0,0,.5)" }} id="" /></td>
            <td className='text-right pr-2 py-1'>{profit?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <div className={`text-xs p-1 ${type === "back" ? "bg-[#beddf4] border-[#7dbbe9]" : "bg-[#f7e7eb] border-[#dfa3b3]"}  border-b flex flex-wrap gap-1 `}>
        {ArrQuickStakes?.map((e) => {
          return <button onClick={updatingBetSlip} key={e} value={e} className='py-[2px] px-4 text-xs filterbtn border border-gray-400 rounded-md ' >{e}</button>
        })}
      </div>

      <div className={`text-xs p-[2px] ${type === "back" ? "bg-[#beddf4] border-[#7dbbe9]" : "bg-[#f7e7eb] border-[#dfa3b3]"}  border-b text-right pr-2 `}>
        Min Bet : 1
      </div>
      <div>
        <div className='text-xs text-right pr-2 p-1'> Liability : <span className='text-red-500'>{liability?.toFixed(2)}</span></div>
        <div className='text-sm font-semibold flex px-2 justify-between border-b border-gray-300 pb-2' >
          <button onClick={() => { setBetSlipData() }} className='py-[2px] px-10 border border-gray-400 rounded-md filterbtn' >Cancel All</button>
          <button onClick={placeBetFuc} disabled={isUserLoggedIn !== "true" ? true : stakeValue < 1 ? true : false} className={`py-[2px] px-10 border border-gray-400 rounded-md ${isUserLoggedIn !== "true" ? "bg-[#a6a6a6] text-[#ffd5a6]" : stakeValue < 1 ? "bg-[#a6a6a6] text-[#ffd5a6]" : "bg-gradient-placeBet text-[#ffb200]"}`} style={isUserLoggedIn !== "true" ? {} : stakeValue < 1 ? {} : { background: "linear-gradient(180deg, #474747 0%, #070707 100%" }}  >Place Bets</button>
        </div>
        <div className='text-xs pt-1'>
          <input type="checkbox" name="" id="" /> Please confirm your bets.
        </div>
      </div>
      <div>

      </div>
    </>
  )
}

export default BetSlipListItem
