import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from '../../components/Loader';

function SignUp() {


    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    console.log('isMobile : ', isMobile);

    const navigate = useNavigate();


    const UserNameRef = useRef();
    const PasswordRef = useRef();
    const ConfirmPassRef = useRef();
    // const FirstNameRef = useRef();
    // const LastNameRef = useRef();
    const PhoneRef = useRef();
    const referralCodeRef = useRef();
    const ValidationCodeRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState();
    const [errorMessage, setErrorMessage] = useState("Please Fill all * fields!");

    const [errorUName, setErrorUName] = useState('');
    const [errorPass, setErrorPass] = useState('');
    const [errorConfPass, setErrorConfPass] = useState('');
    const [errorPhone, setErrorPhone] = useState('');
    const [errorValidCode, setErrorValidCode] = useState('');



    const submitLoginForm = async () => {
        setIsLoading(true);
        let username = UserNameRef.current.value;
        let password = PasswordRef.current.value;
        let confirmPass = ConfirmPassRef.current.value;
        // let firstName = FirstNameRef.current.value;
        // let lastName = LastNameRef.current.value;
        let phone = PhoneRef.current.value;
        // let email = EmailRef.current.value;
        // let validationcode = ValidationCodeRef.current.value;
        let referral = referralCodeRef.current.value;

        console.log("username , password, confirmPass, phone, validationcode : ", username, password, confirmPass, phone);

        if (username?.length === 0) {
            setErrorUName("Enter Username first!!");
            setIsLoading(false);
            return;
        }

        if (password?.length === 0) {
            setErrorPass("Enter password first!!");
            setIsLoading(false);
            return;
        }
        if (confirmPass?.length === 0) {
            setErrorConfPass("Enter password first!!");
            setIsLoading(false);
            return;
        }
        if (password !== confirmPass) {
            setErrorConfPass("Not matched!!");
            setIsLoading(false);
            return;
        }
        if (phone?.length === 0) {
            setErrorPhone("Enter Phone Number!!");
            setIsLoading(false);
            return;
        }
        if (phone?.length !== 10) {
            setErrorPhone("Invalid Phone Number!!");
            setIsLoading(false);
            return;
        }
        // if (validationcode !== code) {
        //     setErrorValidCode("Invalid Code !!");
        //     setIsLoading(false);
        //     return;
        // }

        try {
            const res = await fetch(`https://api.betcare.live/api/selfuser/signUp`, {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    username,
                    password,
                    // fullName: firstName + lastName,
                    phone,
                    referral : referral == '' ? null : referral
                    // email
                })
            });

            const contentType = res.headers.get("Content-Type");

            let resData;
            if (contentType && contentType.includes("application/json")) {
                resData = await res.json();
            } else {
                // If the response is not JSON, assume it's text
                resData = await res.text();
            }

            console.log('Response Status: ', res.status);
            console.log('Response Data: ', resData);

            if (res.status === 201) {
                toast.success('Sign Up Successful, Please Login..', {
                    autoClose: 1500,
                    theme: "colored",
                    position: 'top-center'
                });

                // Clear the input fields
                UserNameRef.current.value = '';
                PasswordRef.current.value = '';
                ConfirmPassRef.current.value = '';
                // FirstNameRef.current.value = '';
                // LastNameRef.current.value = '';
                PhoneRef.current.value = '';
                // EmailRef.current.value = '';
                // ValidationCodeRef.current.value = '';
                referralCodeRef.current.value = '';

                // Clear any error messages
                setErrorUName('');
                setErrorPass('');
                setErrorConfPass('');
                setErrorPhone('');
                // setErrorValidCode('');
                setErrorMessage('');

                const isMobile = window.matchMedia("(max-width: 768px)").matches;
                console.log('isMobile : ', isMobile);
                if (isMobile) {
                    navigate("/login");
                } else {
                    navigate("/");
                }

            } else if (res.status === 409) {
                setErrorUName("Try another username!");
                setIsLoading(false);
                UserNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
                toast.error('Try again later!', {
                    autoClose: 1500,
                    theme: "colored",
                    position: 'top-right'
                });
                // setIsLoading(false);
            }

        } catch (error) {
            console.error('Error during fetch: ', error);
            setErrorMessage("Can't sign up now! Try again later!!");
        } finally {
            setIsLoading(false);
        }
    }




    useEffect(() => {
        let number1 = Math.floor(Math.random() * 10);
        let number2 = Math.floor(Math.random() * 10);
        let number3 = Math.floor(Math.random() * 10);
        let number4 = Math.floor(Math.random() * 10);
        setCode(String(number1) + String(number2) + String(number3) + String(number4));

    }, [])

    return (
        <>
            {isLoading && <Loader styling={`absolute w-fit right-[40%] lg:right-[60%] top-[50%]`} />}
            <div
                className='w-[100%] h-[100%] flex justify-center'
                style={{
                    backgroundImage: `url('/images/velki-desktop-bg.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className='border border-black rounded bg-white custom-width-container overflow-auto no-scrollbar' style={{ height: '100vh', overflowY: 'auto' }}>
                    <div className='overflow-auto no-scrollbar'>

                        <div className=' flex flex-col'>
                            <div className='mb-4' style={{ background: "#67f0c2" }}>
                                <div className='flex p-2'>
                                    <Link to='/'>
                                        <span className='flex justify-center items-center border p-1 cursor-pointer' style={{ borderRadius: '100%', backgroundColor: '#fff', width: '1.5em', height: '1.5em', fontSize: '20px' }}><MdOutlineArrowBackIos /></span>
                                    </Link>
                                </div>
                                <div
                                    className='flex flex-col justify-center items-center'
                                    style={{ padding: '1em', textAlign: 'center' }}
                                >
                                    <img
                                        src="https://ag.betcare.live/uploads/panel-logo.png"
                                        alt="Logo"
                                        style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            height: 'auto'
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '1em',
                                        }}
                                    >
                                        Get your Cricket Id
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '0.8em',
                                        }}
                                    >
                                        Play with the Best Betting Website in India.
                                    </span>
                                </div>
                            </div>
                            <div className='flex flex-col h-[] min-h-[400px] justify-center items-center w-full p-1' style={{ height: '' }}>
                                <h1 className='text-xl font-bold'>Sign Up</h1>
                                <form className="w-full max-w-sm h-full" style={{ padding: '1em 3em', height: '100%' }}>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label
                                                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                                htmlFor="inline-username-name"
                                            >
                                                Username
                                                <span className='text-sm font-bold text-red-500'>&nbsp;{errorUName}</span>
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                style={{
                                                    backgroundColor: '#e5e7eb',
                                                    // border: '0.5px solid #e20000',
                                                    borderRadius: '0.375rem',
                                                    width: '100%',
                                                    padding: '0.5rem 1rem',
                                                    color: '#374151',
                                                    fontSize: '1rem',
                                                    outline: 'none',
                                                }}
                                                id="inline-username-name"
                                                type="text"
                                                ref={UserNameRef}
                                                onChange={() => { setErrorUName('') }}
                                            />
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label
                                                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                                htmlFor="inline-password"
                                            >
                                                Password
                                                <span className='text-sm font-bold text-red-500'>&nbsp;{errorPass}</span>
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                id="inline-password"
                                                type="password"
                                                placeholder="***********"
                                                ref={PasswordRef}
                                                onChange={() => { setErrorPass('') }}
                                            />
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label
                                                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                                htmlFor="inline-confirm-password"
                                            >
                                                Confirm Password <span className='text-sm font-bold text-red-500'>&nbsp;{errorConfPass}</span>
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                id="inline-confirm-password"
                                                type="password"
                                                ref={ConfirmPassRef}
                                                onChange={() => { setErrorConfPass('') }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="md:flex md:items-center mb-6">
                                    <div className="md:w-1/3">
                                        <label
                                            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                            htmlFor="inline-full-name"
                                        >
                                            First Name
                                        </label>
                                    </div>
                                    <div className="md:w-2/3">
                                        <input
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name"
                                            type="text"
                                            ref={FirstNameRef}
                                        />
                                    </div>
                                </div> */}
                                    {/* <div className="md:flex md:items-center mb-6">
                                    <div className="md:w-1/3">
                                        <label
                                            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                            htmlFor="inline-last-name"
                                        >
                                            Last Name
                                        </label>
                                    </div>
                                    <div className="md:w-2/3">
                                        <input
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-last-name"
                                            type="text"
                                            ref={LastNameRef}
                                        />
                                    </div>
                                </div> */}
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label
                                                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                                htmlFor="inline-phone-number"
                                            >
                                                Phone Number<span className='text-sm font-bold text-red-500'>&nbsp;{errorPhone}</span>
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                id="inline-phone-number"
                                                type="text"
                                                ref={PhoneRef}
                                                pattern="[0-9]*"
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                }}
                                                onChange={() => { setErrorPhone('') }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="md:flex md:items-center mb-6">
                                    <div className="md:w-1/3">
                                        <label
                                            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                            htmlFor="inline-email"
                                        >
                                            Email
                                        </label>
                                    </div>
                                    <div className="md:w-2/3">
                                        <input
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-email"
                                            type="email"
                                            ref={EmailRef}
                                        />
                                    </div>
                                </div> */}
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label
                                                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                                htmlFor="inline-phone-number"
                                            >
                                                Referral Code<span className='text-sm font-bold text-red-500'>&nbsp;{}</span>
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                id="inline-phone-number"
                                                type="text"
                                                ref={referralCodeRef}
                                                pattern="[0-9]*"
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                }}
                                                // onChange={() => { setErrorPhone('') }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label
                                                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                                htmlFor="inline-validation-code"
                                            >
                                                Validation Code<span className='text-sm font-bold text-red-500'>&nbsp;{errorValidCode}</span>
                                            </label>
                                        </div>
                                        <div className='relative flex md:w-2/3 border rounded'>
                                            <input
                                                type="text"
                                                maxLength={4}
                                                id="inline-validation-code"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                placeholder='Validation Code'
                                                ref={ValidationCodeRef}
                                                onChange={() => { setErrorValidCode('') }}
                                            />
                                            <div
                                                style={{ fontSize: '150%' }}
                                                className='bg-white py-[9.5px] w-1/3 h-full rounded-r-lg px-3  text-end font-bold text-base'
                                            >
                                                {code}
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="md:flex md:items-center mb-6">
                                    <div className="md:w-1/3" />
                                    <label className="md:w-2/3 block text-gray-500 font-bold">
                                        <input className="mr-2 leading-tight" type="checkbox" />
                                        <span className="text-sm">I agree and understand the <span className='text-blue-500 underline'>Terms & Conditions </ span></span>
                                    </label>
                                </div> */}
                                    <div className="flex items-center">
                                        <div className="md:w-1/3" />
                                        <div className="md:w-2/3">
                                            <button
                                                className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-black font-bold py-2 px-4 border rounded"
                                                type="button"
                                                style={{
                                                    backgroundColor: '#67f0c2',
                                                    border: '1px solid black',
                                                }}
                                                onClick={submitLoginForm}
                                            >
                                                Sign Up
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp