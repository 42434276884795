import React, { useContext, useEffect, useState } from 'react'
import NewsArrowIcon from "../images/news-arrow-icon.png";
import { IoMicOutline } from "react-icons/io5";
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import GlobalContext from '../context/GlobalContext';

const News = () => {

  const context = useContext(GlobalContext);
  const { errorInBet, setErrorInBet, errorMessage, setErrorMessage, isSuccess, setIsSuccess } = context;
  //console.log("News component is rendering !!!");
  const [data, setData] = useState([]);


  const fetchMasterData = async () => {
    try {
      let res = await axios.get(`https://api.betcare.live/api/getMasterData`);
      let resData = await res.data;

      setData(resData)
      return resData;
    } catch (error) {
      console.log('Error While fetching getMasterData Error : ', error)
    }
  }

  useEffect(() => {
    fetchMasterData();
  }, [])


  return (
    <>
      <div className='text-white flex  items-center ' style={{ background: "linear-gradient(180deg, #2a3a43 27%, #1c282d 83%)" }}>
        <div className='px-2 py-1 flex items-center'>
          <IoMicOutline className='h-5 w-5 ' />
          <h1 className='font-bold text-xs ' >News</h1>
        </div>
        <img src={NewsArrowIcon} alt="" />
        <marquee behavior="scroll" direction="left" scrollamount="2" scrolldelay="10">
          <p style={{ color: '#6ac2ff', fontSize: '14.90px', fontWeight: 'bold', fontFamily: 'Helvetica, Tahoma, sans-serif', marginRight: '21.3333333333vw' }}>{data && data.length > 0 && data[0]?.SiteMessage}</p>
        </marquee>
      </div>

      {errorInBet && <div style={{ width: '100%' }} className={`${isSuccess ? "bg-green-500" : "bg-red-500"} text-sm font-bold text-white p-2 text-center`}>
        {errorMessage}
      </div>}
    </>
  )
}

export default React.memo(News)
