import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../context/GlobalContext';
import axios from 'axios';
import Cookies from "js-cookie";
import Loader from '../../components/Loader';
import moment from 'moment';

const AccountStatement = () => {

  //console.log("AccountStatement rendering!!");


  let userId2 = Cookies.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON.parse(userId2);


  const [datas, setData] = useState([]);

  const context = useContext(GlobalContext);
  const { LoginUserDetail } = context;

  const { isPending, isLoading, isError, data, error } = useQuery({
    queryKey: ["accountStatement"], queryFn: async () => {
      // ${LoginUserDetail?.Id}
      let res = await axios.get(`https://api.betcare.live/GetAccountStatement/${userId.Id}`);
      let resData = await res.data;
      return resData;
    },
    staleTime: 7000,
  });

  const statements = data?.data?.accountstatement;
  const sortedData = statements?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));  // getting data from today to  yesterday sequence
  // console.log('statemnet data : ', statements);

  useEffect(() => {
    (async () => {
      let res = await fetch(`https://api.betcare.live/api/getUserDetail/users/${userId.Id}`);
      let resData = await res.json();
      // console.log('uiser detail : ', resData)
      setData(resData);
    })()

    //  ;(async()=>{
    //     let res = await fetch(`https://api.betcare.live/api/admin/getTransactionData/${state?.id}`);
    //     let resData = await res.json();
    //     setTransactionData(resData);
    //  })()

  }, [])

  // { isLoading && <Loader/> }

  // { isLoading &&  <Loader styling={`absolute w-fit right-[40%] top-[50%]`} />}

  return (
    <>
      {isLoading && <Loader styling={`absolute w-fit right-[40%] top-[40%]`} />}
      <h1 className='text-sm font-bold m-2 static'>Account Statement</h1>

      <table className='mt-5 table border-b text-[12px] border-[#7e97a7] w-[95%]' >
        <thead>
          <tr className='border-y font-medium py-2 bg-[#e4e4e4] border-[#7e97a7]'>
            <th className='text-left py-3 px-[10px]'>Date/Time</th>
            <th className='py-3 px-[10px]'>Deposit</th>
            <th className='py-3 px-[10px]'>Withdraw</th>
            <th className='py-3 px-[10px]'>Balance</th>
            <th className='py-3 px-[10px]'>Remark</th>
            <th className='py-3 px-[10px]'>From/To</th>
          </tr>
        </thead>
        {sortedData?.map((statement) => {

          const parsedTimestamp = moment(statement.createdDate);

          // Format date and time
          const formattedDate = parsedTimestamp.format('DD/MM/YYYY');
          const formattedTime = parsedTimestamp.format('HH:mm:ss');
          return (
            <tr key={statement.id} className='border-b border-black bg-white'>
              <td className='py-3 px-[10px]' >{formattedDate + " " + formattedTime}</td>
              <td className='text-center ' >{statement.deposit}</td>
              <td className='text-center' >{statement.withdraw}</td>
              <td className='text-center' >{statement.balance}</td>
              <td className='text-center'>{`Balance ${statement.deposit !== 0 ? "Deposit" : "Withdraw"} to ${statement.toUserName} by ${statement.byUserName}`}</td>
              <td className='text-center'>{`${statement.deposit === 0 ? datas?.length === 0 ? ".." : datas[0].UserName : statement.toUserName} > ${statement.deposit !== 0 ? datas.length === 0 ? ".." : datas[0].UserName : statement.toUserName}`}</td>

            </tr>
          )
        })}
      </table>
    </>
  )
}

export default AccountStatement
