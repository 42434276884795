import React, { useContext, useEffect, useState, useRef } from 'react'
import BgImgPoster from "../../assets/Slide2.png";
import ExitIcon from "../../images/exit-gold2.png";
import GlobalContext from '../../context/GlobalContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Loader from '../../components/Loader';



const LoginModel = () => {


  //console.log("Login Model is rendering !!");

  // const { data } = useQuery({
  //   queryKey: ['ipsearch'], queryFn: async () => {
  //     let res = await axios.get(`https://api.ipify.org/?format=json`);
  //     let resData = await res.data;
  //     return resData;
  //   }, staleTime: 3000
  // });

  //console.log(data);

  const [isLoading, setIsLoading] = useState(false)
  const [code, setCode] = useState("");
  const [contentHeight, setContentHeight] = useState("100vh");

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [validationCode, setValidationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const context = useContext(GlobalContext);
  const { isloginModelOpen, setIsLoginModeOpen, setLoginUserDetail, setIsUserLoggedIn } = context;

  let location = useLocation();
  let currentPath = location.pathname;

  // for login form submission
  const [ip, setIp] = useState("");
  const [BrowerInfo, setBrowerInfo] = useState("");
  const UserNameRef = useRef();
  const PasswordRef = useRef();
  const ValidationCodeRef = useRef();

  const navigate = useNavigate();



  const submitLoginForm = async () => {

    setIsLoading(true);
    let username = UserNameRef.current.value;
    let password = PasswordRef.current.value;
    // let validationcode = ValidationCodeRef.current.value;


    if (username?.length === 0) {
      setErrorMessage("Enter Username first!!");
      setIsLoading(false);
      return;
    }

    if (password?.length === 0) {
      setErrorMessage("Enter password first!!");
      setIsLoading(false);
      return;
    }


    // if (validationcode !== code) {
    //   setErrorMessage("Invalid validation Code !!");
    //   setIsLoading(false);
    //   return;
    // }

    ////console.log(`the username : ${userName} \n the password : ${password}  \n the validation code : ${validationCode}`);
    try {
      const res = await fetch(`https://api.betcare.live/userlogin`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: username, password, userIP: ip, geoLocationn: BrowerInfo })
      });

      const resData = await res.json();

      ////console.log(res.status);

      ////console.log(resData);

      if (res.status !== 200 && resData.isSucces !== true) {
        setErrorMessage(resData.message);
        console.log('login error message : ', resData.message)
        return;
      }

      setIsLoginModeOpen(false);
      setIsUserLoggedIn("true");
      localStorage.setItem('isUserLoggedIn', "true");
      localStorage.setItem("LoginUserDetail", JSON.stringify(resData?.data));

      Cookies.set('isLoggedIn', 'true', { expires: 1 });
      Cookies.set('userIP', ip, { expires: 1 });
      Cookies.set('userId', resData?.data?.Id, { expires: 1 });
      Cookies.set('LoginUserDetail', JSON.stringify(resData?.data), { expires: 1 });
      setLoginUserDetail(resData.data);
      navigate("/");
      setIsLoading(false);


    } catch (error) {
      //console.log(error);
      setErrorMessage("Can't able to login now ! Try again later!!")
    } finally {
      setIsLoading(false);
    }


  }

  const submitDemoLogin = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(`https://api.betcare.live/Demologin`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: 'demo', password: '123456', userIP: ip, geoLocationn: BrowerInfo })
      });

      const resData = await res.json();

      ////console.log(res.status);

      console.log('login respomse : ', resData);

      if (res.status !== 200 && resData.isSucces !== true) {
        setErrorMessage(resData.message);
        return;
      }

      setIsLoginModeOpen(false);
      setIsUserLoggedIn("true");
      localStorage.setItem('isUserLoggedIn', "true");
      localStorage.setItem("LoginUserDetail", JSON.stringify(resData?.data));

      Cookies.set('isLoggedIn', 'true', { expires: 1 });
      Cookies.set('userIP', ip, { expires: 1 });
      Cookies.set('userId', resData?.data?.Id, { expires: 1 });
      Cookies.set('LoginUserDetail', JSON.stringify(resData?.data), { expires: 1 });

      setLoginUserDetail(resData.data);
      navigate("/");
      setIsLoading(false);

    } catch (error) {
      //console.log(error);
      setErrorMessage("Can't able to login now ! Try again later!!")
    } finally {
      setIsLoading(false);
    }

  }

  useEffect(() => {
    ; (async () => {
      let res = await fetch(`https://api.ipify.org/?format=json`);
      let resData = await res.json();
      console.log('api.ipify : ', resData);
      setIp(resData.ip);

    })()

  }, [])


  useEffect(() => {
    (async () => {
      console.log(`ip of this brower is : ${ip}`);
      let res = await fetch(`https://freeipapi.com/api/json/${ip}`);
      let resData = await res.json();
      console.log('ip-api : ', resData);

      let city = await resData.cityName;
      let region = await resData.regionName;
      let country = await resData.countryName;
      let zip = await resData.zipCode;
      console.log(city + region + country + zip);
      setBrowerInfo(`${city}/${region}/${country}/${zip}`);

    })();
  }, [ip])


  useEffect(() => {
    ; (async () => {
      console.log('Browser info : ', BrowerInfo);

    })()
  }, [ip])



  useEffect(() => {
    let number1 = Math.floor(Math.random() * 10);
    let number2 = Math.floor(Math.random() * 10);
    let number3 = Math.floor(Math.random() * 10);
    let number4 = Math.floor(Math.random() * 10);
    setCode(String(number1) + String(number2) + String(number3) + String(number4));


    let a = window.document.body.scrollHeight;

    let b = "h-[" + a + 'px]';

    ////console.log(typeof(b));
    ////console.log(b);
    setContentHeight(b);

    //    let totalHeight = Math.max(document.body.scrollHeight,document.body.offsetHeight,document.documentElement.clientHeight,document.documentElement.scrollHeight,document.documentElement.offsetHeight);
    // //console.log(`total height of page is : ${a}`);



  }, [])

  // useEffect(()=>{

  // },[])

  ////console.log(`contentHeight value is ${contentHeight}`);

  return (
    <>
      {isLoading && <Loader styling={`absolute w-fit right-[40%] lg:right-[60%] top-[50%]`} />}
      <div className={`hidden lg:block absolute ${currentPath == "/" ? " h-[3655px]" : "h-full"}  w-full z-40 overflow-hidden bg-black opacity-35`}>
      </div>

      <div style={{ boxShadow: "0 4px 5px rgba(0,0,0,.5)" }} className='w-[540px] z-50 fixed overflow-hidden top-28 rounded-lg right-96 h-[408px] flex '>

        <div className='w-[250px] rounded-l-lg bg-black'>
          <img src={BgImgPoster} className='' alt="" />
        </div>

        <div className='bg-[#ffb600] relative rounded-r-lg w-[290px]'>
          <div className='absolute text-[#ffb600] w-5 h-5 pb-1 pl-1 flex justify-center items-center bg-black right-5 top-5'>
            <h1 className='text-3xl rotate-45 cursor-pointer' onClick={() => { setIsLoginModeOpen(!isloginModelOpen) }}>+</h1>
          </div>
          <div className='mx-10 h-full flex flex-col justify-center' >
            <h1 className='mb-2 text-lg font-semibold'>Please login to continue</h1>
            <input type="text" id='name' ref={UserNameRef} className='mb-2 py-1 px-1 border border-[#aaa] rounded' style={{ boxShadow: "inset 0px 2px 0px 0px rgba(0,0,0,.1)" }} placeholder='Username' />
            <input type="password" ref={PasswordRef} className='mb-2 py-1 px-1 border border-[#aaa] rounded' style={{ boxShadow: "inset 0px 2px 0px 0px rgba(0,0,0,.1)" }} name="" placeholder='Password' id="password" />
            {/* <div className='flex w-full'>
              <input type="text" maxLength={4} ref={ValidationCodeRef} className='mb-2 py-1 px-1 w-2/3 border border-r-0 border-[#aaa] rounded-l' style={{ boxShadow: "inset 0px 2px 0px 0px rgba(0,0,0,.1)" }} name="" id="validition-code" placeholder='Validation Code' />
              <div className='bg-white mb-2 w-1/3 text-end pr-2 p-[2px] border border-l-0 border-[#aaa] rounded-r font-bold' style={{ boxShadow: "inset 0px 2px 0px 0px rgba(0,0,0,.1)", fontSize: '150%', fontFamily: 'Helvetica, Tahoma, sans-serif' }}>{code}</div>
            </div> */}
            <button onClick={submitLoginForm} className='bg-black text-[#ffb600] font-semibold py-2 flex items-center justify-center'>Login </button>
            <button onClick={submitDemoLogin} className='bg-black text-[#ffb600] font-semibold py-2 mt-2 flex items-center justify-center'>Login with Demo Id </button>
            <h1 className='text-red-500 text-center'>{errorMessage}</h1>
          </div>
        </div>

      </div>
    </>
  )
}

export default LoginModel
