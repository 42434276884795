import React, { useContext, useEffect, useState } from 'react'
import { FaCircle, FaHandLizard } from "react-icons/fa6";
import { MdPushPin } from "react-icons/md";
import PlayIconBlue from "../images/play-icon-blue.svg";
import ClockIconGreen from "../images/clock-icon-green.png";
import fIconBlue from "../images/f-icon-blue.svg";
import bIconBlue from "../images/b-icon-blue.svg";
import DisableImg from "../images/bg-disabled.png";
import { Link, useNavigate, useParams } from 'react-router-dom';
import GlobalContext from '../context/GlobalContext';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';



const SportListItem = ({ title, matchName, isDisabled, eid, marketId, eventId, isFancy, isBookMarked, OneBackValue, OneLayValue, TwoBackValue, TwoLayValue, isInPlay, XBackValue, XLayValue, widthOfTr, greyLine, blueLine, IsBetLock, isLive, series, eventDate, totalMatched }) => {    // this componet is used in various pages it is a table row that has been used in in-play page data and in various individual sports pages

  //console.log("Sport List Item is logged!");

  // console.log("SportList matchName is  : ", matchName, ' series Id is : ', series, ' event Date : ', eventDate)

  //  console.log('is in play : ', isInPlay)

  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);


  const [render, setRender] = useState(false);
  const [bookmakerData, setBookmakerData] = useState([]);





  let navigate = useNavigate();
  const context = useContext(GlobalContext);
  const { setIsLoginModeOpen, isUserLoggedIn } = context;

  const BookmarkingFunc = async (status) => {

    if (isUserLoggedIn === undefined) {
      window.innerWidth < 1000
        ?
        navigate(`/login`)
        :
        setIsLoginModeOpen(true)
      return;
    }

    try {
      //console.log("still trying....");
      let res = await axios.post(`https://api.betcare.live/api/events/bookmark/${userId?.Id}/${eventId}`, {
        marketId: marketId,
        sportId: eid,
        eventName: title,
        isActive: status == 1 ? 0 : 1,
        series: series,
        isInPlay: isInPlay,
        eventDate: eventDate,
        isLive: isLive,
        IsBetLock: IsBetLock
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (res.status === 200) {
        // toast.success(`${status === 1 ? "removed successfully!" : "bookmarked successfully!"}`, {
        //   autoClose: 1500,
        //   theme: "colored",
        // });
        setRender((prev) => !prev);
      } else {
        alert(`failed to update bookmark!`);
      }

    }
    catch (error) {
      alert(`error in api!`);
    }
  }

  const { data: currency, isLoading, isError } = useQuery({
    queryKey: ["currency"], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/api/getActiveCurrency/currency`);
      let resData = await res.data;
      return resData;
    }
  })


  useEffect(() => {
    //console.log(isUserLoggedIn+"wee");

    const getBookmarkDataFunc = async () => {
      //console.log(isUserLoggedIn);


      try {
        //console.log("not-stopped");
        let res = await axios.get(`https://api.betcare.live/api/getBookmarkedData/${userId?.Id}`);
        let resData = await res.data;
        setBookmakerData(resData);
        //console.log(resData);
      } catch (error) {
        //console.log('error in fetching bookmarked data!!');
      }

    };



    if (isUserLoggedIn === "true") {
      ////console.log("stopped");
      getBookmarkDataFunc();
      return;
    }



  }, [render])



  return (
    <tr className='border-[#e0e6e6] border hover:bg-[#eee]' >
      <td className=" px-1 py-1 text-sm font-bold  lg:border-r lg:border-[#e0e6e6] flex items-center justify-between  ">
        <div className='flex items-end lg:items-start  gap-1'>
          {/* <img src={circleIcon} className='h-3 mt-1' alt="" /> */}
          <FaCircle style={{ color: isLive == true ? '#60ba1e' : '#c5d0d8' }} className='mb-1 h-3 mt-1' />
          <span className='flex flex-col-reverse  lg:flex-col' >
            <Link to={`/event/${eventId}/${eid}`} state={{ marketId: marketId, IsBetLock: IsBetLock, matchName: matchName, series: series, isInPlay: isInPlay, eventDate: eventDate, isLive: isLive }}  >
              <p className={`text-[#2789ce] flex items-center ${title?.length < 20 ? "pr-32" : ""} `} style={{ fontSize: 'medium' }}>{title}{blueLine}
              </p>
            </Link>
            <p className={`text-[#777] lg:text-[${isLive ? '#508d0e' : '#c5d0d8'}] font-normal lg:font-bold text-[3.2vw] lg:text-xs flex items-center gap-1`}>{greyLine}

              {/* if isInPlay than this icon will be show */}
              {isInPlay ? <img src='https://ik.imagekit.io/bmaxmbpyx/https://skyexcchange.com/assets/images/tv.webp' className='h-4 p-[1px] bg-[#1876b2]' alt="" style={{ borderRadius: '4px' }} /> : <>
              </>}
              <span className='flex'>

                {/* if isFancy than this icon will be show */}
                {isFancy ? <>  <img src='https://ik.imagekit.io/bmaxmbpyx/https://skyexcchange.com/assets/images/f-icon.webp' className='h-4 bg-[#1876b2]' alt="" style={{ borderRadius: '4px' }} /></> : <></>}
              </span>
              <span className='flex'>

                {/* if isBookmarked than this icon will be show */}
                {isBookMarked ? <> <img src='https://ik.imagekit.io/bmaxmbpyx/https://skyexcchange.com/assets/images/b-icon.webp' className='h-4 bg-[#1876b2]' alt="" style={{ borderRadius: '4px' }} /> </> : <></>}
              </span>
            </p>
          </span>
        </div>
        <div className='text-[#777777] hidden lg:block  text-xs font-medium '>{currency?.[0]?.CurrencyName}{totalMatched}
        </div>
      </td>

      <td className="hidden lg:table-cell px-1 py-1 lg:border-[#e0e6e6] text-center border-r">
        <div className={`flex gap-[1px] justify-center ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"} `}>
          <div className={`bg-[#72bbef] ${isDisabled ? "" : "hover:brightness-[1.1]"} `}>
            <div className={` ${widthOfTr === "60px" ? "w-[60px]" : "w-[37px]"}  h-[34px]  flex justify-center items-center text-black text-sm font-semibold hover:brightness-[1.1] relative`} style={isDisabled ? { background: ` #33333333 url(${DisableImg})` } : {}} > {OneBackValue}
            </div>
          </div>
          <div className={`bg-[#faa9ba] ${isDisabled ? "" : "hover:brightness-[1.1]"} `} >
            <div className={`${widthOfTr === "60px" ? "w-[60px]" : "w-[37px]"} h-[34px] flex justify-center items-center text-black text-sm font-semibold hover:brightness-[1.1]`} style={isDisabled ? { background: ` #33333333 url(${DisableImg})` } : {}}>{OneLayValue}
            </div>
          </div>
        </div>
      </td>

      <td className="hidden lg:table-cell px-1 py-1  border-[#e0e6e6] border-r">
        <div className={`flex gap-[1px] justify-center ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"} `}>
          <div className={`bg-[#72bbef] ${isDisabled ? "" : "hover:brightness-[1.1]"}`}>
            <div className={`${widthOfTr === "60px" ? "w-[60px]" : "w-[37px]"} h-[34px] hover:brightness-[1.1] flex justify-center text-sm font-bold items-center text-black`} style={isDisabled ? { background: ` #33333333 url(${DisableImg})` } : {}}>{XBackValue}
            </div>
          </div>
          <div className={`bg-[#faa9ba] ${isDisabled ? "" : "hover:brightness-[1.1]"}`} >
            <div className={`${widthOfTr === "60px" ? "w-[60px]" : "w-[37px]"} h-[34px] hover:brightness-[1.1] flex justify-center text-sm font-bold items-center text-black`} style={isDisabled ? { background: ` #33333333 url(${DisableImg})` } : {}}>{XLayValue}
            </div>
          </div>
        </div>
      </td>

      <td className="hidden lg:table-cell px-1 py-1  border-[#e0e6e6] ">
        <div className={`flex gap-[1px] justify-center ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"} `}>
          <div className={`bg-[#72bbef] ${isDisabled ? "" : "hover:brightness-[1.1]"}`}>
            <div className={`${widthOfTr === "60px" ? "w-[60px]" : "w-[37px]"} h-[34px] hover:brightness-[1.1] flex justify-center text-sm font-bold items-center text-black`} style={isDisabled ? { background: ` #33333333 url(${DisableImg})` } : {}}> {TwoBackValue}
            </div>
          </div>
          <div className={`bg-[#faa9ba] ${isDisabled ? "" : "hover:brightness-[1.1]"}`} >
            <div className={`${widthOfTr === "60px" ? "w-[60px]" : "w-[37px]"} h-[34px] hover:brightness-[1.1] flex justify-center text-sm font-bold items-center text-black`} style={isDisabled ? { background: ` #33333333 url(${DisableImg})` } : {}}>{TwoLayValue}
            </div>
          </div>
        </div>
      </td>

      <td className="px-1 py-1  text-end border-[#e0e6e6]">

        {bookmakerData.length == 0 ? <div className='rounded-full cursor-pointer p-[1px] h-5 w-5 border hover:text-[#6bbd11] border-[#7e97a7] text-[#7e97a7]' onClick={() => { BookmarkingFunc(0) }} > <MdPushPin className=' ' />    </div>
          :
          bookmakerData.find(event => Number(eventId) == event.EventId) == undefined ? <div className='rounded-full cursor-pointer p-[1px] h-5 w-5 border hover:text-[#6bbd11] border-[#7e97a7] text-[#7e97a7]' onClick={() => { BookmarkingFunc(0) }} > <MdPushPin className=' ' />    </div>
            :
            bookmakerData?.map((event) => {
              return Number(eventId) == event.EventId ? <section key={event.SrNo} >
                {event.isActive == 1 ?
                  <div className='rounded-full cursor-pointer p-[1px] h-5 w-5 border bg-[#6bbd11] border-[#7e97a7] text-white' onClick={() => { BookmarkingFunc(event.isActive) }} > <MdPushPin className=' ' />    </div>
                  : <div className='rounded-full cursor-pointer p-[1px] h-5 w-5 border hover:text-[#6bbd11] border-[#7e97a7] text-[#7e97a7]' onClick={() => { BookmarkingFunc(event.isActive) }} > <MdPushPin className=' ' />    </div>
                }
              </section> : <h1></h1>
            })
        }


      </td>
    </tr>
  )
}

export default SportListItem
