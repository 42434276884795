import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const BalanceOverview = () => {

   //console.log("Balance overview is rendering !!");

   let userId2 = Cookies.get("LoginUserDetail");
   let userId = userId2 === undefined ? "" : JSON.parse(userId2);

   const [userExposure, setUserExposure] = useState(0);
   const [mainBalance, setMainBalance] = useState(0);

   const { data: currency, isLoading, isError } = useQuery({
    queryKey: ["currency"], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/api/getActiveCurrency/currency`);
      let resData = await res.data;
      return resData;
    }
  })


   const newfetchBalance = async () => {
    try {
      const bal = await fetch(`https://api.betcare.live/expo1/${userId.Id}/${userId.selfUser}`);
      const resultBal = await bal.json();
      // console.log('resullt bal : ', resultBal)
      if (resultBal.isSuccess) {
        // console.log(' main valance : ', resultBal.mainBalanceafterExpo)
        // const calcBalance = apiBalance + exposure
        setUserExposure(resultBal.FinalExpo)
        ////console.log("Balance Api Balance is : ", apiBalance)
        setMainBalance(resultBal.mainBalanceafterExpo)
      }
    } catch (error) {
      console.log('Error Fetching Balance : ', error)
    }
  }

  useEffect(() => {
    newfetchBalance();
  }, [])

  return (
    <>
    <section className='overflow-x-hidden sticky top-0' >
    <h1 className='text-sm font-bold m-2'>Summary</h1>

    <div className='flex p-2 bg-white border-b w-[100vw] overflow-x-hidden lg:flex-row flex-col lg:w-fit border-[#7e97a7] justify-between'>
        <div className=' border-b lg:border-b-0 lg:border-r border-[#d8d8d8]  w-full lg:w-1/2'>
            <h1 className='font-bold text-lg text-[#3b5160]'>Your Balances</h1>
            <h2 className='text-4xl font-bold text-[#2789ce]'>{mainBalance} <span className='text-xs font-medium text-[#7e97a7]'>{currency?.[0]?.CurrencyName}</span> </h2>
        </div>

        <div className='lg:ml-3 mt-3 lg:mt-0 ' >
            <h1 className='font-bold text-[#3b5160]' >Welcome,</h1>
            <p className='text-sm'>View your account details here. You can manage funds, review and change your settings and see the performance of your betting activity.</p>
        </div>
    </div>
    </section>
    <section className='w-[100vw] lg:w-full overflow-auto' > 
    <table className='w-full overflow-x-scroll text-[13px] mt-5' >
        <thead>
            <tr className='border-y font-medium py-2 bg-[#e4e4e4] border-[#7e97a7]'>
                <th className='py-1 px-3'>Date</th>
                <th className='py-1 px-3'>Transaction No.</th>
                <th className='py-1 px-3'>Debits</th>
                <th className='py-1 px-3'>Credits</th>
                <th className='py-1 px-3'>Balance</th>
                <th className='py-1 px-3'>Remarks</th>
                <th className='py-1 px-3'>From/To</th>
            </tr>
        </thead>
    </table>
    </section>
      
    </>
  )
}

export default BalanceOverview
