import React from 'react'
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const Hotspot = ({ inPlayNumber }) => {

  //console.log("HotSpot is rendering");
  const fetchingInPlayData = async () => {
    let res = await axios.get(`https://api.s2bet.in/GetAllEvent`);
    let resData = await res.data;
    return resData;

  }

  const { isPending, isLoading, isError, data: sports, error } = useQuery({
    queryKey: ['inplaySportsForCounting'],
    queryFn: fetchingInPlayData,
  });

  let inPlayData = sports?.data;

  // add more for checking
  // let cricket = inPlayData?.filter((game)=> game.eid === "4");
  // let soccer = inPlayData?.filter((game)=> game.eid === "1");
  // let tennis = inPlayData?.filter((game)=> game.eid === "2")?.length;
  ////console.log(tennis);

  ////console.log(inPlayData);

  return (

    <span className='absolute -top-3 pl-2 rounded-sm flex items-center right-[10%] bg-white  text-white text-xs' style={{zIndex: "9999"}}><svg width="14" height="8" className='animate-pulse' xmlns="http://www.w3.org/2000/svg"><g fill="rgb(255,0,0)" fillRule="evenodd"><path d="M12.012 0l-.698.727c1.734 1.808 1.734 4.738 0 6.546l.698.727c2.117-2.207 2.117-5.79 0-8zM10.3 1.714l-.7.735c.967 1.014.967 2.66 0 3.673l.7.735c1.352-1.418 1.352-3.721 0-5.143zM1.588 0l.698.727c-1.734 1.808-1.734 4.738 0 6.546L1.588 8c-2.117-2.207-2.117-5.79 0-8zM3.3 1.714l.7.735c-.967 1.014-.967 2.66 0 3.673l-.7.735c-1.352-1.418-1.352-3.721 0-5.143z" /><circle cx="6.8" cy="4.4" r="1.6" /></g></svg><span className='text-white ml-2 bg-red-500 px-1 rounded-sm'> {isLoading && "..."}{inPlayData?.filter((game) => game.eid === inPlayNumber)?.filter(item => new Date(item.eventDate) <= new Date())?.length}</span></span>

  )
}

export default React.memo(Hotspot)
