import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import GlobalContext from '../context/GlobalContext';
import Loading from "../images/loading.gif";
import Cookies from "js-cookie";

const FancyModal = ({ fancyModal, setFancyModalOpen, eventId, fancyData }) => {
  // console.log('faancy modal eventId is : ', eventId)

  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);

  const [FancybookbtnArray, setFancyBookBtnArray] = useState([]);
  // const context = useContext(GlobalContext);
  // const { fancyBetSend } = context;

  ////console.log('fancy bet send : ', fancyBetSend)

  const openFancyBtn = async (id) => {

    try {
      console.log('User Id : ', userId.Id)
      const response = await fetch(`https://api.betcare.live/GetBetHistory/${userId.Id}?betStatus=2`);
      const result = await response.json();
      console.log("Bet result : " + JSON.stringify(result))

      // Filter by date and Statu s
      const filteredData = result.data.filter(item =>
        item.eventId == eventId && item.isSettlement == 2 && item.isDelete == 0
      );

      //console.log("Fanncy filteredData : ", filteredData)
      //console.log("fancy state : ", fancy)
      // bm.some(innerArray => innerArray.some(bmitem => bmitem.sid == item.selectionId));

      const filterFancyAll = filteredData.filter(item => item.market == "Fancy" && fancyData.some(facnyitem => facnyitem.SelectionId == item.selectionId))

      //console.log("filterFacnyAll data : " + JSON.stringify(filterFancyAll))

      const filterByIdAll = filterFancyAll.filter(item => item.selectionId == id)

      //console.log("Fancy filter ALl : " + JSON.stringify(filterByIdAll))


      // Find the minimum and maximum oddsRequest in the group
      const minOddsRequest = Math.min(...filterByIdAll.map(item => item.oddsRequest));
      const maxOddsRequest = Math.max(...filterByIdAll.map(item => item.oddsRequest));



      // BET CALCULATION FOR BACK !!!!!!!!!

      const filterByBack = filterByIdAll.filter(item => item.type == "back")

      //console.log("Fancy filter for Back : " + JSON.stringify(filterByBack))


      // Group items by id
      const groupedByIdBack = {};
      filterByBack.forEach(item => {
        if (!groupedByIdBack[item.id]) {
          groupedByIdBack[item.id] = [];
        }
        groupedByIdBack[item.id].push(item);
      });

      // Calculate the range and set the length dynamically
      const rangeBack = maxOddsRequest - minOddsRequest + 10;

      // Create FancyBookArray for each group
      const resultArraysBack = [];
      const calcArrayBack = []
      Object.keys(groupedByIdBack).forEach(groupId => {
        const groupItems = groupedByIdBack[groupId];


        const FancyBookArray = Array.from({ length: rangeBack }, (_, index) => {
          const request = index + minOddsRequest - 5;
          const pl = request >= groupItems[0].oddsRequest ? groupItems[0].profit : groupItems[0].liability;

          return { request, pl };
        });

        resultArraysBack.push({ id: groupId, FancyBookArray });
      });

      // //console.log("Result Arrays For Back:", resultArraysBack);

      const redcBack = resultArraysBack.reduce((acc, current) => {
        current.FancyBookArray.forEach((entry) => {
          const existingEntry = acc.find((item) => item.request == entry.request);

          if (existingEntry) {
            existingEntry.pl += entry.pl;
          } else {
            acc.push({ request: entry.request, pl: entry.pl });
          }
        });

        return acc;
      }, []);

      // //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))



      // BET CALCULATION FOR LAY !!!!!!!!!

      const filterByLay = filterByIdAll.filter(item => item.type == "lay")

      //console.log("Fancy filter for Lay : " + JSON.stringify(filterByLay))


      // Group items by id
      const groupedById = {};
      filterByLay.forEach(item => {
        if (!groupedById[item.id]) {
          groupedById[item.id] = [];
        }
        groupedById[item.id].push(item);
      });

      // Calculate the range and set the length dynamically
      const range = maxOddsRequest - minOddsRequest + 10;

      // Create FancyBookArray for each group
      const resultArrays = [];
      const calcArray = []
      Object.keys(groupedById).forEach(groupId => {
        const groupItems = groupedById[groupId];


        const FancyBookArray = Array.from({ length: range }, (_, index) => {
          const request = index + minOddsRequest - 5;
          const pl = request >= groupItems[0].oddsRequest ? groupItems[0].liability : groupItems[0].profit;

          return { request, pl };
        });

        resultArrays.push({ id: groupId, FancyBookArray });
      });

      //console.log("Result Arrays:", resultArrays);

      const redc = resultArrays.reduce((acc, current) => {
        current.FancyBookArray.forEach((entry) => {
          const existingEntry = acc.find((item) => item.request == entry.request);

          if (existingEntry) {
            existingEntry.pl += entry.pl;
          } else {
            acc.push({ request: entry.request, pl: entry.pl });
          }
        });

        return acc;
      }, []);

      //console.log("Calculated Array for lay : " + JSON.stringify(redc))
      //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))


      let finalCalculatedArray = []
      if (redcBack.length > 0) {

        // Combine redcBack and redc to create the final calculated array
        finalCalculatedArray = redcBack.map((entryBack) => {
          const entryLay = redc.find((entry) => entry.request == entryBack.request);

          if (entryLay) {
            entryBack.pl += entryLay.pl;
          }

          return entryBack;
        });
      } else {
        finalCalculatedArray = redc
      }

     console.log("Final Calculated Array : " + JSON.stringify(finalCalculatedArray));

      setFancyBookBtnArray(finalCalculatedArray)

      // Find the most negative value in finalCalculatedArray
      let mostNegativeValue = null;
      if (finalCalculatedArray.length > 0) {
        mostNegativeValue = finalCalculatedArray.reduce((acc, entry) => {
          if (entry.pl < 0 && (acc === null || entry.pl < acc)) {
            return entry.pl;
          }
          return acc;
        }, null);
      }

     console.log("Most Negative Value: " + mostNegativeValue);


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  useEffect(() => {
    openFancyBtn(fancyModal.Sid);
    // //console.log("useEffect effected !!");
  }, [eventId, fancyModal])


  return (
    <ReactModal
      isOpen={fancyModal?.isOpen}
      // onRequestClose={closeModal}
      ariaHideApp={false} // This is necessary to prevent an accessibility issue
      className={` w-[80%] lg:w-[500px] absolute right-[10%] lg:right-[35%] top-20 overflow-y-scroll ${FancybookbtnArray?.length !== 0 && "h-[80vh]"}  rounded-md bg-[#eee] outline-none`}
      style={{ overlay: { background: "rgba(0, 0, 0, 0.5)", zIndex: 101 } }}
    >
      <div className='flex justify-between items-center ' style={{ background: "linear-gradient(180deg, #474747 0%, #070707 100%" }} >
        <h2 className='text-lg text-white font-bold py-2 px-3' >Run Position</h2>
        <span className='text-white flex items-center border-l pl-3 border-white gap-1'>  <RxCross2 onClick={() => { setFancyModalOpen({ isOpen: false }) }} className='text-white cursor-pointer text-lg bg-black rounded ' /> <span className='py-2 pr-3 cursor-pointer' onClick={() => { setFancyModalOpen({ isOpen: false }) }}>Close</span></span>
      </div>

      <table className="w-[90%] mx-auto mt-5 border-collapse">
        <thead>
          <tr className='border-b-2 border-black'>
            <th className="py-2 px-4 bg-gray-200 text-gray-600 text-left">Run</th>
            <th className="py-2 px-4 bg-gray-200 text-gray-600 text-right">Amount</th>
          </tr>
        </thead>
        <tbody className='bg-white'>
          {
            FancybookbtnArray?.map((e, i) => {
              return <tr key={i}>
                <td className="py-2 px-4  border"> {i === 0 && "-"} {e.request}  {i === (FancybookbtnArray?.length - 1) && "+"} </td>
                <td className={`py-2 px-4 text-right border ${e.pl >= 0 ? "text-[#198754]" : "text-[#d00b20]"}`}>{e.pl.toFixed(2)}</td>
              </tr>
            })
          }
          {/* <tr>
            <td className="py-2 px-4  border">Data 1</td>
            <td className="py-2 px-4 text-right border">Data 2</td>
          </tr>
          <tr>
            <td className="py-2 px-4 text-left border">Data 3</td>
            <td className="py-2 px-4 text-right border">Data 4</td>
          </tr> */}
          {/* Add more rows as needed */}
        </tbody>
      </table>
      <div className='border-b w-[90%] mx-auto border-black h-5 mb-5'></div>

    </ReactModal>
  )
}

export default FancyModal
