import React, { useContext, useEffect, useState } from 'react'
import SettingIcon from "../images/setting-icon-gold.svg";
import { IoCloseSharp } from "react-icons/io5";
import { FaPencil } from "react-icons/fa6";
import GlobalContext from '../context/GlobalContext';


const SettingMobile = ({settingPageOpen , setSettingPageOpen}) => {

 //console.log("setting mobile render !");


    const [OddswitchOn , setOddsetSwitchOn] = useState(true);
    const [FancyBetswitchOn , setFancyBetSwitchOn] = useState(true);
    const [SportsBookSwitchOn , setSportBookSwitchOn] = useState(true);
    const [WinSelectionSwitchOn , setWinSelectionSwitchOn] = useState(true);

    
    // this is for edit btn if it is true than btn style will be changed along with text 
    const [editState , setEditState] = useState(false);
    

       const context = useContext(GlobalContext);
       const {quickStakes, setQuickStakes} = context;



   const stakeChangeHandlerFunc = (e)=>{
       let value = e.target.value;
       let name = e.target.name;

       if(e.target.value === "" || e.target.value < 1){
        value = 1;
       }
       
       setQuickStakes({ ...quickStakes,[name]:value })

   }

    const height = window.innerHeight;

   useEffect(()=>{
      
    //  UpdatingQuickStakes(0,90);
    window.document.body.classList.add("overflow-hidden");

    return ()=>{
        window.document.body.classList.remove("overflow-hidden");
    }
 
   },[])

  return (
    <div className='absolute overflow-hidden w-full z-[60] bg-white' style={{height}} >
      <h1 className='flex bg-black justify-between p-2 items-center' > <h2 className='flex items-center gap-1 font-bold text-[#ffb200]' > <img src={SettingIcon} alt="" /> Setting </h2> <IoCloseSharp className='text-[#ffb200]' onClick={()=>{setSettingPageOpen(false)}} /> </h1>
      <h1 className='text-white font-bold text-sm p-2' style={{background:"linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)"}}>Stake</h1>
      <div>
        <h1 className='border-b p-2 border-[#e0e6e6] text-[#243a48] bg-white'>Default stake <input type="number" dir='rtl' className='w-[30vw] text-right border border-[#aaa] rounded-[1.6vw] text-[4vw] py-[2vw] focus:bg-[#fff0ca] focus:outline-0 px-[1.8vw]' name="" id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}}  /> </h1>
        <div className='bg-white p-1'>
            <h4 className=''>Quick Stakes</h4>

            {/*  if edit state is true that show inputs else show btn  */}
            <div className='grid grid-cols-4 gap-2'>
              {
                editState ? <>
                    <input type="number" value={quickStakes.stakeOne} onChange={stakeChangeHandlerFunc} name='stakeOne' className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} />
                    <input type="number" value={quickStakes.stakeTwo} onChange={stakeChangeHandlerFunc} name='stakeTwo' className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]'  id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} />
                    <input type="number" value={quickStakes.stakeThree} onChange={stakeChangeHandlerFunc} name='stakeThree' className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]'  id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} />
                    <input type="number" value={quickStakes.stakeFour} onChange={stakeChangeHandlerFunc} name='stakeFour' className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} />

                    <input type="number" value={quickStakes.stakeFive} onChange={stakeChangeHandlerFunc} name='stakeFive' className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} />
                    <input type="number" value={quickStakes.stakeSix} onChange={stakeChangeHandlerFunc} name='stakeSix' className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} />
                    {/* <input type="number" value="500" className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' name="" id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} /> */}
                    {/* <input type="number" value="1000" className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' name="" id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} /> */}
                </> : <>
                      
                <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeOne}</button>
                <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeTwo}</button>
                <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeThree}</button>
                <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeFour}</button>
                
                <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeFive}</button>
                <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeSix}</button>
                {/* <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >500</button> */}
                {/* <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >1000</button> */}
                </>
              }
            </div>
            <button onClick={()=>{setEditState(prev => !prev)}} className={`border mb-1 border-[#7e97a7] w-full mt-2 py-2 rounded-md ${editState ? "bg-black text-[#ffb200]" : "bg-[#c5d0d766] text-[#243a48]" }  font-semibold flex justify-center items-center gap-2`} > {editState ? "OK" : "Edit Stake"} {editState ? "" : <FaPencil /> }</button>
        </div>
      </div> 
     
     {/*   odds  */}
      <h1 className='text-white font-bold text-sm p-1' style={{background:"linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)"}}>Odds</h1>
      <div className='flex bg-white items-center justify-between p-2'>
       <h3 className='text-[#243a48] text-sm' >Highlight when odds change</h3>
      {/* toggle-btn */}
          <div onClick={()=>{setOddsetSwitchOn(prev => !prev)}} className={`relative w-[9.3vw] h-[9.3vw] ${OddswitchOn ? "bg-[#6bbd11]" : "bg-[#a2b1ba]"}  rounded-[1.6vw] overflow-hidden`} style={{boxShadow:"inset 0 0.2666666667vw 0.8vw 0 rgba(0,0,0,.5)"}} >
             <span className={`absolute ${OddswitchOn ? "right-[1.06vw]" : "left-[1.06vw]"}  top-[1.06vw] w-[2.6vw] h-[7.2vw] bg-[#fff] rounded-[1.06vw] items-center justify-center`} style={{boxShadow:"0 0.5333333333vw 1.0666666667vw 0 rgba(0,0,0,.5), inset 0 -0.8vw 0 0 #cad5d5"}} ></span>
          </div>
      </div>


      {/* fancy-bet */}
      <h1 className='text-white font-bold text-sm p-1' style={{background:"linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)"}}>FancyBet</h1>
      <div className='flex bg-white items-center justify-between p-2'>
       <h3 className='text-[#243a48] text-sm' >Accept Any Odds</h3>
      {/* toggle-btn */}
        <div onClick={()=>{setFancyBetSwitchOn(prev => !prev)}} className={`relative w-[9.3vw] h-[9.3vw] ${FancyBetswitchOn ? "bg-[#6bbd11]" : "bg-[#a2b1ba]"}  rounded-[1.6vw] overflow-hidden`} style={{boxShadow:"inset 0 0.2666666667vw 0.8vw 0 rgba(0,0,0,.5)"}} >
           <span className={`absolute ${FancyBetswitchOn ? "right-[1.06vw]" : "left-[1.06vw]"}  top-[1.06vw] w-[2.6vw] h-[7.2vw] bg-[#fff] rounded-[1.06vw] items-center justify-center`} style={{boxShadow:"0 0.5333333333vw 1.0666666667vw 0 rgba(0,0,0,.5), inset 0 -0.8vw 0 0 #cad5d5"}} ></span>
        </div>
      </div>
     

     {/* SportsBook */}
     <h1 className='text-white font-bold text-sm p-1' style={{background:"linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)"}}>SportsBook</h1>
      <div className='flex bg-white items-center justify-between p-2'>
       <h3 className='text-[#243a48] text-sm' >Accept Any Odds</h3>
      {/* toggle-btn */}
        <div onClick={()=>{setSportBookSwitchOn(prev => !prev)}} className={`relative w-[9.3vw] h-[9.3vw] ${SportsBookSwitchOn ? "bg-[#6bbd11]" : "bg-[#a2b1ba]"}  rounded-[1.6vw] overflow-hidden`} style={{boxShadow:"inset 0 0.2666666667vw 0.8vw 0 rgba(0,0,0,.5)"}} >
           <span className={`absolute ${SportsBookSwitchOn ? "right-[1.06vw]" : "left-[1.06vw]"}  top-[1.06vw] w-[2.6vw] h-[7.2vw] bg-[#fff] rounded-[1.06vw] items-center justify-center`} style={{boxShadow:"0 0.5333333333vw 1.0666666667vw 0 rgba(0,0,0,.5), inset 0 -0.8vw 0 0 #cad5d5"}} ></span>
        </div>
      </div>


      {/* Win Selection forecast */}
     <h1 className='text-white font-bold text-sm p-1' style={{background:"linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)"}}>Win Selection forecast</h1>
      <div className='flex bg-white items-center justify-between p-2'>
       <h3 className='text-[#243a48] text-sm' >With Commission</h3>
      {/* toggle-btn */}
        <div onClick={()=>{setWinSelectionSwitchOn(prev => !prev)}} className={`relative w-[9.3vw] h-[9.3vw] ${WinSelectionSwitchOn ? "bg-[#6bbd11]" : "bg-[#a2b1ba]"}  rounded-[1.6vw] overflow-hidden`} style={{boxShadow:"inset 0 0.2666666667vw 0.8vw 0 rgba(0,0,0,.5)"}} >
           <span className={`absolute ${WinSelectionSwitchOn ? "right-[1.06vw]" : "left-[1.06vw]"}  top-[1.06vw] w-[2.6vw] h-[7.2vw] bg-[#fff] rounded-[1.06vw] items-center justify-center`} style={{boxShadow:"0 0.5333333333vw 1.0666666667vw 0 rgba(0,0,0,.5), inset 0 -0.8vw 0 0 #cad5d5"}} ></span>
        </div>
      </div>

      <div className='flex h-[60px] p-2 border-t border-[#e0e6e6] bg-white gap-1' >
        <button onClick={()=>{setSettingPageOpen(false)}} className='w-1/2 border rounded-md font-semibold border-[#aaa]' style={{background:"linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)"}} >Cancel</button>
        <button className='w-1/2 border rounded-md font-semibold border-[#222] text-[#ffb200]' style={{background:"linear-gradient(180deg, #474747 0%, #070707 100%)"}} >Save</button>
      </div>

    </div>
  )
}

export default SettingMobile
