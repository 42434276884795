import React, { useCallback, useContext, useEffect, useState } from 'react'

import MinusIcon from "../../images/minus-icon-white.png";
import ClockIcon from "../../images/clock-icon-green.png";
import PIcon from "../../images/p-icon.svg";
import ReloadIcon from "../../images/reload-icon.svg";
import BackAllImg from "../../images/bg-backall.png";
import BackAllDisableImg from "../../images/bg-backall-disabled.png";
import LayAllImg from "../../images/bg-layall.png";
import LayAllDisableImg from "../../images/bg-layall-disabled.png";
import DisabledImg from "../../images/bg-disabled.png";
import { Link, useNavigate } from 'react-router-dom';
import { MdPushPin } from "react-icons/md";
import { TbArrowBigRightFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineBarChart } from "react-icons/ai";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { MdOutlineBackspace } from "react-icons/md";
import { MdOutlineInfo } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import GlobalContext from '../../context/GlobalContext';
import axios from "axios";
import Cookies from "js-cookie";
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

const BookmakerTable = ({ BookmakerData, EventName, minStake, setLoading, bookMBetProfits, EventNameBetSlip, maxStake, eid, eventId, marketId, IsBetLock, CountBMRunner, matchName, series, delayTime }) => {

  const [selectedBet, setSelectedBet] = useState({});
  const [inputValue, setInputValue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [liability, setLiability] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  // const [errorInBet, setErrorInBet] = useState(false);  // this state is for set , there is an error or not while setting bet
  // const [errorMessage, setErrorMessage] = useState("");   // this state will hold the message of error while setting bet
  // const [isSuccess, setIsSuccess] = useState(false);
  const [openBetTable, setOpenBetTable] = useState([]);
  const [odds, setOdds] = useState([]);
  const [bm, setBM] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [matchOddBetProfit, setmatchOddBetProfit] = useState(0)
  const [bookMBetProfit, setBookMBetProfit] = useState(0);

  const context = useContext(GlobalContext);
  const { isUserLoggedIn, setBetSlipData, quickStakes, remount, setIsLoginModeOpen, setBetSend, errorInBet, setErrorInBet, errorMessage, setErrorMessage, isSuccess, setIsSuccess } = context;

  const ArrQuickStakes = Object.values(quickStakes);

  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);
  ////console.log(`userId value is : ${userId?.Id}`);

  const navigate = useNavigate();

  const GetDataForQuery = async () => {
    let res = await axios.get(`https://api.betcare.live/GetBetHistory/${userId?.Id}?betStatus=2`);
    let resData = await res.data;
    return resData;
  }

  // console.log('bookMBetProfits from sport detail is : ', bookMBetProfits)

  const { data, isLoading, isError } = useQuery({ queryKey: ["totalReturn"], queryFn: GetDataForQuery });


  const setBettingDataFunc = useCallback((RowName, MatchName, Type, Value, selectionId, indexId) => {

    setBetSlipData({ matchName: matchName, series: series, EventName: MatchName, type: Type, value: (Value / 100), value2: Value, dataType: "bookmaker", minStake: minStake, maxStake: maxStake, market: "BookMaker", selectionId: selectionId, DelayTime: delayTime, indexId: indexId });   // dataType refer to which type of data it is like - bookmaker or match odds
    setSelectedBet({ title: MatchName, type: Type, value: Value, selectionId: selectionId, selection: RowName, DelayTime: delayTime, indexId: indexId });
  }, [])




  //  function that set the value of profit and liability 
  const funcToSetProfitAndLiability = (value) => {
    let betValue = selectedBet?.value / 100;

    if (selectedBet?.type === "back") {
      setProfit(betValue * value);      // setting value of profit      
      setLiability(value * -1);    // setting value of liability
    }
    else {
      setProfit(-1 * (betValue * value));   // setting value of profit  
      setLiability(value * 1);    // setting value of liability
    } return;
  };


  // function to set the data of placeBet
  const placeBetFuc = async () => {

    //console.log('place bet in betSlip table into BookMaker component...')

    let DelayedTime = selectedBet?.DelayTime * 1000;
    ////console.log(DelayedTime);

    // function to genrate BetId
    function generateRandomAlphaNumeric(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    }

    // function to get the date-format  
    const convertToIST = (dateString) => {
      const date = new Date(dateString);
      const ISTOffset = 330; // IST offset is UTC+5:30
      const ISTTime = new Date(date.getTime() + (ISTOffset * 60000));
      return ISTTime.toISOString().slice(0, 19).replace('T', ' '); // Format the date-time string 
    }

    let matchTimeData = convertToIST(new Date());

    if (inputValue < minStake || inputValue > maxStake) {
      setErrorInBet(true);
      setErrorMessage(`Enter stake between ${minStake} - ${maxStake}`);
      setIsSuccess(false);
      setInputValue(0);

      setTimeout(() => {
        setErrorInBet(false);
        // fetchBet();
      }, 3000);
      return;
    }


    const currentBhaw = document.getElementById(selectedBet?.indexId).innerHTML;
    console.log('index Id is : ', currentBhaw)

    if (currentBhaw != selectedBet?.value) {
      toast.error("Odds change..!", {
        duration: 1000
      })
      return;
    }



    ////console.log(marketId);

    setLoading(true);

    try {
      setTimeout(() => {
        ; (async () => {
          let res = await axios.post("https://api.betcare.live/saveBet", {
            BetId: generateRandomAlphaNumeric(30),
            SportId: eid,
            SeriesId: series,
            EventId: eventId,
            Event: matchName,
            MarketId: marketId,
            Market: "BookMaker",
            SelectionId: selectedBet?.selectionId,
            Selection: selectedBet?.title,
            OddsType: 1,
            Type: selectedBet?.type,
            OddsRequest: selectedBet?.value,
            AmountStake: inputValue,
            BetType: 2,
            IsSettlement: 2,
            IsDelete: 0,
            Status: true,
            UserId: userId?.Id,
            ResultType: null,
            ResultAmount: 0,
            IpAddress: "",
            IsMatched: true,
            EventName: matchName,
            Profit: selectedBet?.type == 'back' ? profit.toFixed(2) : liability.toFixed(2),
            Liability: selectedBet?.type == 'back' ? liability.toFixed(2) : profit.toFixed(2),
            MatchedTime: matchTimeData,
            settleTime: null,
            PlaceTime: convertToIST(new Date())
          }, {
            headers: {
              'Content-Type': 'application/json',
            }
          });

          // //console.log(res.status);
          //  alert(res.status);
          if (res.status !== 200) {
            setLoading(false);
            setErrorInBet(true);
            setErrorMessage(`ERROR !!`);
            setInputValue(0);
          }

          if (res.data.isSuccess) {
            // //console.log(res.status);
            //  alert(res.status);
            let checkBal = await fetch(`https://api.betcare.live/expo2/${userId?.Id}/${userId?.selfUser}`)
            let resCheckBal = await checkBal.json();

            if (resCheckBal.isSuccess) {
              if (resCheckBal.mainBalanceafterExpo <= 0) {
                setLoading(false);
                setErrorInBet(true);
                setIsSuccess(false);
                setErrorMessage(`Insufficient balance..`);
                setInputValue(0);

                //console.log('balance block delete bet id : ', betId)
                const deleteQuery = await fetch(`https://api.betcare.live/deleteUnmatchBet`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ userId: userId.Id })
                });

                const queryResp = await deleteQuery.json();


                setTimeout(() => {
                  setErrorInBet(false);
                  setSelectedBet({});
                  // setBetSlipData();
                  // setRemount((prev) => !prev); // it will update the value in match odds
                }, 3000)
                return;
              } else {
                const updateQuery = await fetch(`https://api.betcare.live/updateUnmatchBet`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ userId: userId.Id, BetId: res.data.betId })
                });

                const queryResp2 = await updateQuery.json()
                if (queryResp2.isSuccess) {
                  setLoading(false);
                  setErrorInBet(true);
                  setErrorMessage(`Bet is set successfull`);
                  setIsSuccess(true);
                  // alert(`Enter stake between ${minStake}  --  ${maxStake}`);
                  setInputValue(0);
                  setBetSend(true);
                }
              }
            }
          }



        })();

        setTimeout(() => {
          setErrorInBet(false);
          // fetchBet();
          setSelectedBet({});
        }, 3000);

      }, DelayedTime)

    }
    catch (err) {
      alert("ERROR");
      setLoading(false);
      setErrorInBet(true);
      setIsSuccess(false);
      setErrorMessage(`Failed to bet! try again later`);

      setTimeout(() => {
        setErrorInBet(false);
        setSelectedBet({});
      }, 3000);
    }
  };


  const tableApi = async () => {
    try {
      // const response = await fetch(`https://api.betcare.live/checkGetMatchOdds/${marketId}/${eid}`);
      const response = await fetch(`https://api.betcare.live/checkGetMatchOdds/${marketId}/${eventId}`);

      const result = await response.json();

      // console.log("Table api data of matchOdds : ", result);

      if (result.isSuccess) {

        if (Array.isArray(result.Odds)) {
          const firstOddsItem = result.Odds[0];
          // setTotalMatched(firstOddsItem.totalMatched)

          if (Array.isArray(firstOddsItem.runners)) {
            const runnersData = firstOddsItem.runners;
            setOdds(runnersData);
            //console.log('odds data : ', runnersData)
          } else {
            console.error('No runners data available.');
          }
        } else {
          console.error('Invalid data format:', result);
        }

        if (Array.isArray(result.Bookmaker)) {
          //console.log("BookMaker data:", JSON.stringify(result.Bookmaker));
          setBM(result.Bookmaker);
          // console.log('BM data : ', result.Bookmaker);
        } else {
          console.error('No Bookmaker data available.');
        }

        if (Array.isArray(result.Fancy)) {
          //console.log("Fancy data:", JSON.stringify(result.Fancy));
          setFancy(result.Fancy);
          //  console.log('Fancy data : ', result.Fancy)
        } else {
          console.error('No Fancy data available.');
        }

      }


    } catch (error) {
      console.error('Error fetching Table API data:', error);
    }
  };

  const fetchBet = async () => {
    //console.log("calling fetchbet..");
    //  console.log('Bookmaker fetcg bet marketID : ', marketId,' eid is : ', eid, ' eventId : ',eventId, " user Id is : ", userId.Id)

    try {
      const response = await fetch(`https://api.betcare.live/fetchLastBet/${userId.Id}/${eventId}`);
      const result = await response.json();
      // console.log("Bet result : " + JSON.stringify(result))

      if (result.isSuccess && result.data.length > 0) {

        const filteredData = result.data;
        ////console.log('result data : ', result.data)

        // NEXT TEAM For BACK /////////////

        const filterODD = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "Matchodds" &&
            item.type == "back" &&
            odds.some(oddsitem => oddsitem.selectionId == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterODDSelectionIds = new Set(filterODD.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterOddAgainForOdd = odds.filter(item => !filterODDSelectionIds.has(item.selectionId));

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterOddAgainWithProfitLossOdd = filterOddAgainForOdd.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayForOdd = [...filterODD, ...filterOddAgainWithProfitLossOdd];

        // //console.log("Same Id data: " + JSON.stringify(selectionItems))

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionId = {};
        const totalLossBySelectionId = {};

        // Calculate net profit for each selectionId
        combinedArrayForOdd.forEach(item => {
          const selectionId = item.selectionId;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionId.hasOwnProperty(selectionId)) {
            totalProfitBySelectionId[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionId.hasOwnProperty(selectionId)) {
            totalLossBySelectionId[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionId[selectionId] += profit;
          totalLossBySelectionId[selectionId] += loss;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionId = {};
        Object.keys(totalProfitBySelectionId).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelections = Object.keys(totalLossBySelectionId)
            .filter(otherSelectionId => otherSelectionId != selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionId[otherSelectionId], 0);

          const profit = totalProfitBySelectionId[selectionId];
          // const loss = totalLossBySelectionId[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionId[selectionId] = profit + totalLossToOtherSelections;
        });

        // NEXT TEAM For LAY /////////////

        const filterODDLay = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "Matchodds" &&
            item.type == "lay" &&
            odds.some(oddsitem => oddsitem.selectionId == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterODDLaySelectionIds = new Set(filterODDLay.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterOddAgain = odds.filter(item => !filterODDLaySelectionIds.has(item.selectionId));

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterOddAgainWithProfitLoss = filterOddAgain.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArray = [...filterODDLay, ...filterOddAgainWithProfitLoss];

        // console.log("Combined Both Array: " + JSON.stringify(combinedArray));

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdLay = {};
        const totalLossBySelectionIdLay = {};

        // Calculate net profit for each selectionId
        combinedArray.forEach(item => {
          const selectionId = item.selectionId;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdLay.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdLay[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdLay.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdLay[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdLay[selectionId] += loss;
          totalLossBySelectionIdLay[selectionId] += profit;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdLay = {};
        Object.keys(totalProfitBySelectionIdLay).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelections = Object.keys(totalLossBySelectionIdLay)
            .filter(otherSelectionId => otherSelectionId !== selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLay[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdLay[selectionId];
          // const loss = totalLossBySelectionIdLay[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdLay[selectionId] = profit + totalLossToOtherSelections;
        });

        // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
        const finalProfitMatchOdd = {};

        Object.keys(netProfitBySelectionId).forEach(selectionId => {
          // Sum the corresponding values for each selectionId
          finalProfitMatchOdd[selectionId] = (netProfitBySelectionId[selectionId] || 0) + (netProfitBySelectionIdLay[selectionId] || 0);
        });

        if (Object.keys(netProfitBySelectionId).length === 0) {
          // Object is empty
          Object.keys(netProfitBySelectionIdLay).forEach(selectionId => {
            // Sum the corresponding values for each selectionId
            //console.log("Selection ID of final profit match odd: ", selectionId);
            finalProfitMatchOdd[selectionId] = netProfitBySelectionIdLay[selectionId] || 0;
          });
        }
        // console.log("Final Profit for Match OddbookMaker on BokkmakerTbl : ", finalProfitMatchOdd);

        setmatchOddBetProfit(finalProfitMatchOdd)
        // mop = finalProfitMatchOdd;
        // setmatchOddBetLoss(finalLossMatchOdd)
        ////console.log('matchodds profit : ', finalProfitMatchOdd)

        // NEXT TEAM For BACK /////////////

        const filterBook = filteredData.filter(item => {

          return item.market == "BookMaker" &&
            item.type == "back" &&
            bm.some(innerArray => innerArray.some(bmitem => bmitem.sid == item.selectionId));
          // bm.some(bmitem => bmitem.sid == item.selectionId);
        });

        // console.log("Next team filter back bookMaker on BokkmakerTbl : " + JSON.stringify(filterBook))

        // Get unique selectionIds from filterODDLay
        const filterBookSelectionIds = new Set(filterBook.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterBookAgainForBooks = bm.filter(item => !filterBookSelectionIds.has(item.sid));

        const filterBookAgainForBook = filterBookAgainForBooks.flat();

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterBookAgainWithProfitLossBook = filterBookAgainForBook.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayForBook = [...filterBook, ...filterBookAgainWithProfitLossBook];

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdBook = {};
        const totalLossBySelectionIdBook = {};

        // Calculate net profit for each selectionId
        combinedArrayForBook.forEach(item => {
          const selectionId = item.selectionId || item.sid;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdBook.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdBook[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdBook.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdBook[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdBook[selectionId] += profit;
          totalLossBySelectionIdBook[selectionId] += loss;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdBook = {};
        Object.keys(totalProfitBySelectionIdBook).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelectionsBook = Object.keys(totalLossBySelectionIdBook)
            .filter(otherSelectionId => otherSelectionId != selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdBook[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdBook[selectionId];
          // const loss = totalLossBySelectionIdBook[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdBook[selectionId] = profit + totalLossToOtherSelectionsBook;
        });


        // NEXT TEAM For LAY /////////////

        const filterBookLayNextTeam = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "BookMaker" &&
            item.type == "lay" &&
            bm.some(innerArray => innerArray.some(bmitem => bmitem.sid == item.selectionId));
        });

        // Get unique selectionIds from filterODDLay
        const filterBookLaySelectionIds = new Set(filterBookLayNextTeam.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterBookAgains = bm.filter(item => !filterBookLaySelectionIds.has(item.sid));
        //console.log("filter Book Again : " + JSON.stringify(filterBookAgains.flat()));

        const filterBookAgain = filterBookAgains.flat();

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterBookAgainWithProfitLoss = filterBookAgain.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));


        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayBook = [...filterBookLayNextTeam
          , ...filterBookAgainWithProfitLoss];

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdLayBook = {};
        const totalLossBySelectionIdLayBook = {};

        // Calculate net profit for each selectionId
        combinedArrayBook.forEach(item => {
          const selectionId = item.selectionId || item.sid;
          const profit = item.profit;
          const loss = item.liability;


          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdLayBook.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdLayBook[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdLayBook.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdLayBook[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdLayBook[selectionId] += loss;
          totalLossBySelectionIdLayBook[selectionId] += profit;
        });


        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdLayBook = {};
        Object.keys(totalProfitBySelectionIdLayBook).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelectionsBook = Object.keys(totalLossBySelectionIdLayBook)
            .filter(otherSelectionId => otherSelectionId !== selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLayBook[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdLayBook[selectionId];
          // const loss = totalLossBySelectionIdLayBook[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdLayBook[selectionId] = profit + totalLossToOtherSelectionsBook;
        });


        // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
        const finalProfitBook = {};

        Object.keys(netProfitBySelectionIdBook).forEach(selectionId => {
          // Sum the corresponding values for each selectionId
          finalProfitBook[selectionId] = (netProfitBySelectionIdBook[selectionId] || 0) + (netProfitBySelectionIdLayBook[selectionId] || 0);
        });

        setBookMBetProfit(finalProfitBook)
        // setmatchOddBetLoss(finalLossMatchOdd)
        //console.log('net Profit By SelectionId Book : ', netProfitBySelectionIdBook)
        //console.log('net Profit By SelectionId Lay Book : ', netProfitBySelectionIdLayBook)
        //  console.log('BookMaker profit on BokkmakerTbl : ', finalProfitBook)
      } else {
        setmatchOddBetProfit(0)
        setBookMBetProfit(0)
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  //console.log('bookmaker data : ', BookmakerData)





  // const onClickOfSettingBet = (event, n) => {
  //   let name = event.target.name;

  //   if (name === "fixedData") {
  //     setInputValue(n);
  //     funcToSetProfitAndLiability(n);
  //     return;
  //   }

  //   if (n === "x") {
  //     setInputValue(prev => prev <= 9 ? prev : Math.floor(prev / 10));
  //     let num = inputValue <= 9 ? inputValue : Math.floor(inputValue / 10);
  //     funcToSetProfitAndLiability(num);
  //     return;
  //   }

  //   if (n === "-") {
  //     setInputValue(prev => prev <= 0 ? prev : prev - 1);
  //     let num = inputValue <= 0 ? 0 : inputValue - 1;
  //     funcToSetProfitAndLiability(num);
  //     return;
  //   }

  //   if (n === "+") {
  //     setInputValue(prev => prev >= 1000000 ? prev : prev + 1);
  //     let num = inputValue >= 1000000 ? 1000000 : inputValue + 1;
  //     funcToSetProfitAndLiability(num);
  //     return;
  //   }

  //   if (inputValue > 1000000) {
  //     return;
  //   }

  //   if (n === "00") {
  //     setInputValue(prev => prev >= 100000 ? prev : prev * 100);
  //     let num = inputValue >= 100000 ? inputValue : inputValue * 100;
  //     funcToSetProfitAndLiability(num);
  //     return;
  //   }

  //   setInputValue(prev => prev + n);
  //   let num = (inputValue + n);
  //   funcToSetProfitAndLiability(num);

  // }

  // function to set a bet
  const onClickOfSettingBet = (event, n, indexNumber) => {
    let name = event.target.runnerName;

    //console.log(indexNumber);

    if (name === "fixedData") {
      setInputValue(n);
      funcToSetProfitAndLiability(n);

      return;
    }

    if (n === "x") {
      setInputValue(prev => prev <= 9 ? prev : Math.floor(prev / 10));
      let num = inputValue <= 9 ? inputValue : Math.floor(inputValue / 10);
      funcToSetProfitAndLiability(num);
      return;
    }

    if (n === "-") {
      setInputValue(prev => prev <= 0 ? prev : prev - 1);
      let num = inputValue <= 0 ? 0 : inputValue - 1;
      funcToSetProfitAndLiability(num);
      return;
    }

    if (n === "+") {
      setInputValue(prev => prev >= 1000000 ? prev : prev + 1);
      let num = inputValue >= 1000000 ? 1000000 : inputValue + 1;
      funcToSetProfitAndLiability(num);
      return;
    }

    if (inputValue > 1000000) {
      return;
    }

    if (n === "00") {
      setInputValue(prev => prev >= 100000 ? prev : prev * 100);
      let num = inputValue >= 100000 ? inputValue : inputValue * 100;
      funcToSetProfitAndLiability(num);
      return;
    }

    if (n == 1 || n == 2 || n == 3 || n == 4 || n == 5 || n == 6 || n == 7 || n == 8 || n == 9 || n == 0) {
      setInputValue(prev => {
        const prevDigits = Array.from(String(prev), Number);
        prevDigits.push(n);
        const newValue = Number(prevDigits.join(''));
        funcToSetProfitAndLiability(newValue);
        return newValue;
      });
      return;
    }

    setInputValue(prev => prev + n);
    let num = (inputValue + n);
    funcToSetProfitAndLiability(num);
  }

  const betLockNotify = () => {
    toast.error("You are not able to place bets..", {
      duration: 1000
    })

  }


  // useEffect(() => {
  //   tableApi();
  // });

  // useEffect(() => {
  //   // tableApi();
  //   // fetchBet();
  // }, []);

  useEffect(() => {
    setInputValue(0);
    setProfit(0);
    setLiability(0);

  }, [selectedBet, errorInBet])

  // useEffect(() => {
  //   fetchBet();
  // }, [BookmakerData, remount])


  return (
    <>

      <style>
        {`
          @media (max-width: 768px) {
            .mobile-view-td {
             font-size: 3.5vw;
             font-weight: 600;
            }
          }
        `}
      </style>

      {BookmakerData?.length > 0 ?

        <div className='bg-white border-b mb-4 border-[#7e97a7] mt-5' >
          <div className={`bg-[#243a48] cursor-pointer text-white flex justify-between items-center relative`} >
            <div className='flex gap-1 text-xs items-center'>
              <div className='p-1' style={!isBookmarked ? {} : { background: "linear-gradient(0deg, rgba(101,183,19,1) 7%, rgba(73,155,30,1) 61%)" }} >
                <MdPushPin className={`text-white ${!isBookmarked ? "opacity-[0.7]" : ""}  h-5 w-5`} />
              </div>
              <h1 className='font-bold text-sm' >Bookmaker Market</h1>
              <p className=' text-white pl-3 opacity-[0.7]' > Zero Commission</p>
              {/* <img src={ClockIcon} className='rounded-sm' alt="" /> */}
            </div>
            {/* <img src={ReloadIcon} className='h-4 w-4 mr-2' alt="" />   */}
            <div className='lg:flex items-center hidden gap-4 mx-2' >
              <h1 className='text-[10px] '><span className='bg-[#bed5d8] mx-1 px-1 rounded-[3px] text-[#3b5160]'>Min</span>{minStake}</h1>
              <h1 className='text-[10px]'><span className='bg-[#bed5d8] mx-1 px-1 rounded-[3px] text-[#3b5160]' >Max</span>{maxStake}</h1>
            </div>
            <div className="lg:hidden" style={{marginRight:'2%'}}>
              <MdOutlineInfo
                className='size-4 cursor-pointer'
                style={{left:'3%'}}
                onClick={(e) => {
                  e.preventDefault();
                  const getId = document.getElementById(`BookMinMax`);

                  // Toggle display of the clicked one
                  getId.style.display =
                    getId.style.display === 'none' ? 'flex' : 'none';
                }}
              />
              <div
                id={`BookMinMax`}
                style={{ display: 'none', width: '8rem', left: '65%', padding: '1%', justifyContent: 'space-between', bottom: '-10%', borderRadius: '5px', boxShadow: '0 6px 10px rgba(0, 0, 0)' }}
                className="absolute top-full mt-2 border border-gray-400 bg-white text-left z-50 "
              >
                <div><p className='text-[11px] text-[#7e97a7]'>Min/Max</p>
                  <p className='text-[12px] text-black'><span className=' font-bold'>{minStake}</span>/ <span className='font-normal'>{maxStake}</span></p></div>
                <div><RxCross2 className='text-black' style={{marginTop: '50%'}} onClick={(e) => {
                  e.preventDefault();
                  const getId = document.getElementById(`BookMinMax`);
                  getId.style.display = 'none'
                }} />
                </div>


              </div>
            </div>
          </div>
          <table className='w-full text-xs'>
            <tr>
              <td className='' >{CountBMRunner} selection </td>
              <td className={`w-[150px] hidden lg:table-cell `} ></td>

              <td className='flex justify-between w-[150px]' >
                <div className=' w-1/2 bg-img-back bg-cover font-bold h-[20px] flex justify-center items-center'  > Back all </div>
                <div className=' w-1/2 bg-img-lay bg-cover font-bold  h-[20px] flex justify-center items-center'  > Lay all </div>
              </td>
              <td className={`w-[150px] hidden lg:table-cell text-end`}></td>
            </tr>
            {
              BookmakerData?.map((e, i) => {
                return (
                  <tbody key={i}>
                    {/* {errorInBet && <tr>
                      <td colSpan={4} className={`${isSuccess ? "bg-green-500" : "bg-red-500"}  ${selectedBet?.title === e.nat ? "table-cell" : "hidden"} text-sm font-bold text-white p-2 text-center`}>{errorMessage}
                      </td>
                    </tr>} */}

                    <tr className='border-b border-[#7e97a7]'>

                      <td className='font-normal text-xs pl-2' >
                        <div className='flex items-center gap-1 mobile-view-td'> <AiOutlineBarChart className='text-xl' /> {e.nat}
                        </div>
                        {
                          // first we will checking if the anybody is selected or not any option of matchodds
                          selectedBet.title === undefined ?  // it means not selected so,
                            // we will show the total value by calculating it's previous bets
                            <span className={`${bookMBetProfits[e.sid] === undefined ? "text-[#198754]" : bookMBetProfits[e.sid] >= 0 ? "text-[#198754]" : "text-[#d0021b]"} flex items-center`}>
                              {/* checking if calculation is undifined means 0 s */}
                              <TbArrowBigRightFilled />{bookMBetProfits[e.sid] === undefined ?
                                0   // if 0 
                                : Number(bookMBetProfits[e.sid]).toFixed(2)}</span>   // if not 0
                            // if anybody selected any option from matchodds
                            : bookMBetProfits[e.sid] === undefined ?   // checking if calculated value is undefined or not 
                              // if value is undefined means value is 0 so ,
                              selectedBet.title === e.nat ?   //  now checking if this row is selected or not  
                                <span className={`${profit >= 0 ? "text-[#198754]" : "text-[#d0021b]"} flex items-center`} > <TbArrowBigRightFilled />{profit.toFixed(2)} </span> // row is selected so , we will show the profit to it 
                                : <span className={`${liability >= 0 ? "text-[#198754]" : "text-[#d0021b]"} flex items-center`}><TbArrowBigRightFilled />{liability.toFixed(2)}</span>  // row is not selected so, we will show the liability to it
                              // if calculated value is not 0
                              : <span className={`flex items-center`}> <TbArrowBigRightFilled />  {selectedBet?.title === e.nat ? <span className={`${Number(bookMBetProfits[e.sid] + profit).toFixed(2) >= 0 ? "text-[#198754]" : "text-[#d0021b]"}`}>{Number(bookMBetProfits[e.sid] + profit).toFixed(2)}</span> : <span className={`${Number(bookMBetProfits[e.sid] + liability).toFixed(2) >= 0 ? "text-[#198754]" : "text-[#d0021b]"}`} >{Number(bookMBetProfits[e.sid] + liability).toFixed(2)}</span>}</span>
                        }
                      </td>
                      <td className='w-[150px] hidden lg:table-cell'>
                        {/* ${ ? "cursor-not-allowed" : ""} */}
                        <div className={`flex text-xs  w-full `}>
                          <div className='bg-[#d7e8f4]'>
                            {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                            <div className=' font-bold  text-center w-[75px] h-[40px] pt-[1px] border-r border-white' > {""} <br /> <span className='font-medium text-[11px]' > {""} </span> </div>
                          </div>
                          <div className='bg-[#b7d5eb]'>
                            {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                            <div className=' font-bold text-center w-[75px] h-[40px] pt-[1px]'  > {""} <br /> <span className='font-medium text-[11px]' >  {""} </span> </div>
                          </div>
                        </div>
                      </td>
                      <td className='w-[150px] '>
                        {/*  */}
                        <div className={`flex text-xs ${e.b1 === ("0" || "") ? "cursor-not-allowed" : e.l1 === ("0" || "") ? "cursor-not-allowed" : ""}`}>
                          <button disabled={e.b1 === ("0" || "") ? true : false} className={`${selectedBet?.title === e.nat ? selectedBet?.type === "back" ? "bg-[#2395e6] lg:bg-[#72bbef] shadow-selectedBox lg:shadow-none text-white lg:text-black" : "bg-[#72bbef]" : "bg-[#72bbef]"} `}
                            onClick={
                              () =>
                                isUserLoggedIn !== "true"
                                  ?
                                  window.innerWidth < 1000
                                    ?
                                    navigate(`/login`)
                                    :
                                    setIsLoginModeOpen(true)
                                  :
                                  IsBetLock == true || e.s != 'ACTIVE' || e.b1 <= 1.01
                                    ?
                                    betLockNotify()
                                    :
                                    setBettingDataFunc(EventName, e.nat, "back", e.b1, e.sid, `bookPrice${i}`)
                            }
                          >
                            {/*  */}
                            <div className=' font-bold  text-center w-[75px] h-[40px] pt-[1px] border-r border-white' style={e.b1 === ("0" || "") ? { background: ` #33333333 url(${DisabledImg})`, padding: '5px' } : { padding: '5px' }}  > <span id={`bookPrice${i}`}>{e.b1}</span>  <br /> <span className='font-medium text-[11px]'  > {e.bs1 === "" ? "00" : e.bs1} </span> </div>
                          </button>
                          <button disabled={e.l1 === ("0" || "") ? true : false} className={`${selectedBet?.title === e.nat ? selectedBet?.type === "lay" ? "bg-[#f4496d] lg:bg-[#faa9ba] lg:shadow-none shadow-selectedBox lg:text-black text-white" : "bg-[#faa9ba]" : "bg-[#faa9ba]"} `} onClick={
                            () =>
                              isUserLoggedIn !== "true"
                                ?
                                window.innerWidth < 1000
                                  ?
                                  navigate(`/login`)
                                  :
                                  setIsLoginModeOpen(true)
                                :
                                IsBetLock == true || e.s != 'ACTIVE' || e.l1 <= 1.01
                                  ?
                                  betLockNotify()
                                  :
                                  setBettingDataFunc(EventName, e.nat, "lay", e.l1, e.sid, `booklayPrice${i}`)
                          }
                          >
                            {/*  */}
                            <div className=' font-bold  text-center w-[75px] h-[40px] pt-[1px]' style={e.l1 === ("0" || "") ? { background: ` #33333333 url(${DisabledImg})`, padding: '5px' } : { padding: '5px' }}  >
                              <span id={`booklayPrice${i}`}>
                                {e.l1}
                              </span>
                              <br />
                              <span className='font-medium text-[11px]' >
                                {e.ls1 === "" ? "00" : e.ls1}
                              </span>
                            </div>
                          </button>
                        </div>
                      </td>
                      <td className='w-[150px] hidden lg:table-cell'>
                        {/* ${e.isDisabled ? "cursor-not-allowed" : ""} */}
                        <div className={`flex text-xs`}>
                          <div className='bg-[#efd3d9]' >
                            {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                            <div className=' font-bold  text-center w-[75px]  h-[40px] pt-[1px] border-r border-white'  > {""} <br /> <span className='font-medium text-[11px]' > {""} </span> </div>
                          </div>
                          <div className='bg-[#f6e6ea]' >
                            {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                            <div className=' font-bold  text-center w-[75px] h-[40px] pt-[1px]' > {""} <br /> <span className='font-medium text-[11px]' > {""} </span> </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {selectedBet?.title === e.nat && <tr className={`lg:hidden  `} >
                      <td colSpan={4} className={`${selectedBet?.title === e.nat ? "table-cell" : "hidden"} overflow-hidden`}>
                        <div className={` ${selectedBet?.type === "back" ? "bg-[#dceaf4]" : "bg-[#f2e5e8]"} `}>
                          {/* for min-bet input */}
                          <div className='flex py-2 gap-2 mx-2'>
                            <div className='w-1/2 text-[#999] border border-[#bbb] rounded-md text-sm font-bold text-center py-3 bg-[#dcdcdc]' >{selectedBet?.value}</div>
                            <div className='w-1/2 flex items-center' >
                              <div onClick={(e) => { onClickOfSettingBet(e, "-") }} className='h-full w-10 border border-[#aaa] rounded-l flex items-center justify-center' style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} > <FaMinus className='text-[#1f72ac] text-xl' /> </div>
                              <input type="number" maxLength={7} value={inputValue} readOnly name='' className='h-full w-[85px] border-y border-[#aaa] text-center focus:bg-[#fff0ca] focus:outline-none focus:shadow-3xl' />
                              <div onClick={(e) => { onClickOfSettingBet(e, "+") }} className='h-full w-10 border border-[#aaa] rounded-r flex items-center justify-center' style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} > <FaPlus className='text-[#1f72ac] text-xl' /> </div>
                            </div>
                          </div>

                          <div className='w-full flex mb-3 font-bold' >

                            {
                              ArrQuickStakes?.map((stake) => {
                                return <button onClick={(e) => { onClickOfSettingBet(e, stake) }} value={stake} name='fixedData' className='text-white flex-grow py-2' style={{ background: "linear-gradient(-180deg, #32617f 20%, #1f4258 91%)" }} >{stake}</button>
                              })
                            }
                          </div>

                          <div className='grid bg-white grid-cols-7 w-full text-sm mb-3' >
                            <button onClick={(e) => { onClickOfSettingBet(e, 1) }} disabled={inputValue.length >= 7} value={'1'} className='border border-[#aaa] border-b-0 py-2 ' >1</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, 2) }} disabled={inputValue.length >= 7} value={'2'} className='border border-[#aaa] border-b-0 py-2 ' >2</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, 3) }} disabled={inputValue.length >= 7} value={'3'} className='border border-[#aaa] border-b-0 py-2 ' >3</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, 4) }} disabled={inputValue.length >= 7} value={'4'} className='border border-[#aaa] border-b-0 py-2 ' >4</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, 5) }} disabled={inputValue.length >= 7} value={'5'} className='border border-[#aaa] border-b-0 py-2 ' >5</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, 6) }} disabled={inputValue.length >= 7} value={'6'} className='border border-[#aaa] border-b-0 py-2 ' >6</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, "x") }} value={'x'} name='cut' className='row-span-2 flex justify-center items-center border-y border-[#aaa]' > <MdOutlineBackspace className='text-lg' /> </button>

                            <button onClick={(e) => { onClickOfSettingBet(e, 7) }} disabled={inputValue.length >= 7} value={'7'} className='border border-[#aaa] py-2 ' >7</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, 8) }} disabled={inputValue.length >= 7} value={'8'} className='border border-[#aaa] py-2 ' >8</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, 9) }} disabled={inputValue.length >= 7} value={'9'} className='border border-[#aaa] py-2 ' >9</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, 0) }} disabled={inputValue.length >= 7} value={"0"} className='border border-[#aaa] py-2 ' >0</button>
                            <button onClick={(e) => { onClickOfSettingBet(e, "00") }} disabled={inputValue.length >= 6} value={"00"} className='border border-[#aaa] py-2 ' >00</button>
                            <button className='border border-[#aaa] py-2 ' >.</button>
                          </div>

                          <div className='flex gap-1 mx-2 w-full pb-3' >
                            <button onClick={() => { setSelectedBet({}) }} className={`w-[48%] border border-[#aaa]  rounded py-2 text-sm`} style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} >Cancel</button>
                            <button onClick={placeBetFuc} disabled={isUserLoggedIn !== "true" ? true : false} className={`w-[48%]  text-sm rounded py-2 ${isUserLoggedIn !== "true" ? "bg-[#a6a6a6] text-[#ffd5a6]" : "bg-gradient-placeBet text-[#ffb200]"}  font-semibold`} style={isUserLoggedIn !== "true" ? {} : { background: "linear-gradient(180deg, #474747 0%, #070707 100%" }}  >Place Bet</button>
                          </div>
                        </div>
                      </td>
                    </tr>}
                  </tbody>
                )
              })
            }

          </table>

          {/* <img src={LayAllImg} alt="" /> */}

        </div>

        : ''}

    </>
  )
}

export default React.memo(BookmakerTable)
