import React, { useContext, useEffect, useState, useRef } from 'react'
import BgImgPoster from "../../assets/Slide1.png";
import LoginLogo from "../../images/login-logo.png";
import BetfairMobileLogo from "../../images/betfair-grey.png";
import whatsAppIcon from "../../images/whatsapp-black.png";
import skypeIcon from "../../images/skype-black.png";
import emailIcon from "../../images/mail-black.png";
import igIcon from "../../images/ig-black.png";
import CrossIcon from "../../images/cut-white.svg";
import headphoneIcon from "../../images/headphone-black.svg";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import Cookies from 'js-cookie';
import Loader from '../../components/Loader';

const Login = () => {

  //console.log("Login is rendering !!");

  let location = useLocation();

  // pages where footer will be not shown

  const [isLoading, setIsLoading] = useState(false)
  const [code, setCode] = useState();

  const [screenHeight, SetScreenHeight] = useState(100);

  // for login form submission
  const [ip, setIp] = useState("");
  const [BrowerInfo, setBrowerInfo] = useState("");
  const UserNameRef = useRef();
  const PasswordRef = useRef();
  const ValidationCodeRef = useRef();

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();


  const context = useContext(GlobalContext);
  const { setLoginUserDetail, setIsUserLoggedIn } = context;


  const submitLoginForm = async () => {

    setIsLoading(true);
    let username = UserNameRef.current.value;
    let password = PasswordRef.current.value;
    // let validationcode = ValidationCodeRef.current.value;


    if (username?.length === 0) {
      setErrorMessage("Enter Username first!!");
      setIsLoading(false);
      return;
    }

    if (password?.length === 0) {
      setErrorMessage("Enter password first!!");
      setIsLoading(false);
      return;
    }


    // if (validationcode !== code) {
    //   setErrorMessage("Invalid validations Code !!");
    //   setIsLoading(false);
    //   return;
    // }

    ////console.log(`the username : ${userName} \n the password : ${password}  \n the validation code : ${validationCode}`);
    try {
      const res = await fetch(`https://api.betcare.live/userlogin`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: username, password, userIP: ip, geoLocationn: BrowerInfo })
      });

      const resData = await res.json();
      const data = await resData.data;

      ////console.log(res.status);

      ////console.log(resData);

      if (res.status !== 200 && resData.isSucces !== true) {
        setErrorMessage(resData.message);
        console.log('login error message : ', resData.message)
        return;
      }

      setIsUserLoggedIn("true");
      localStorage.setItem('isUserLoggedIn', "true");
      localStorage.setItem("LoginUserDetail", JSON.stringify(resData?.data));

      Cookies.set('isLoggedIn', 'true', { expires: 1 });
      Cookies.set('userIP', ip, { expires: 1 });
      Cookies.set('userId', resData?.data?.Id, { expires: 1 });
      Cookies.set('LoginUserDetail', JSON.stringify(resData?.data), { expires: 1 });
      setLoginUserDetail(resData.data);
      navigate("/");
      setIsLoading(false);
    } catch (error) {
      //console.log(error);
      setErrorMessage("Can't able to login now ! Try again later!!")
    } finally {
      setIsLoading(false);
    }
  }

  const submitDemoLogin = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(`https://api.betcare.live/Demologin`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: 'demo', password: '123456', userIP: ip, geoLocationn: BrowerInfo })
      });

      const resData = await res.json();

      ////console.log(res.status);

      console.log('login respomse : ', resData);

      if (res.status !== 200 && resData.isSucces !== true) {
        setErrorMessage(resData.message);
        return;
      }

      // setIsLoginModeOpen(false);
      setIsUserLoggedIn("true");
      localStorage.setItem('isUserLoggedIn', "true");
      localStorage.setItem("LoginUserDetail", JSON.stringify(resData?.data));

      Cookies.set('isLoggedIn', 'true', { expires: 1 });
      Cookies.set('userIP', ip, { expires: 1 });
      Cookies.set('userId', resData?.data?.Id, { expires: 1 });
      Cookies.set('LoginUserDetail', JSON.stringify(resData?.data), { expires: 1 });

      setLoginUserDetail(resData.data);
      navigate("/");
      setIsLoading(false);

    } catch (error) {
      //console.log(error);
      setErrorMessage("Can't able to login now ! Try again later!!")
    } finally {
      setIsLoading(false);
    }

  }


  useEffect(() => {
    ; (async () => {
      let res = await fetch(`https://api.ipify.org/?format=json`);
      let resData = await res.json();
      console.log('api.ipify : ', resData);
      setIp(resData.ip);

    })()

  }, [])


  useEffect(() => {
    (async () => {
      console.log(`ip of this brower is : ${ip}`);
      let res = await fetch(`https://freeipapi.com/api/json/${ip}`);
      let resData = await res.json();
      console.log('ip-api : ', resData);

      let city = await resData.cityName;
      let region = await resData.regionName;
      let country = await resData.countryName;
      let zip = await resData.zipCode;
      console.log(city + region + country + zip);
      setBrowerInfo(`${city}/${region}/${country}/${zip}`);

    })();
  }, [ip])


  useEffect(() => {
    ; (async () => {
      console.log('Browser info : ', BrowerInfo);

    })()
  }, [ip])


  useEffect(() => {
    let number1 = Math.floor(Math.random() * 10);
    let number2 = Math.floor(Math.random() * 10);
    let number3 = Math.floor(Math.random() * 10);
    let number4 = Math.floor(Math.random() * 10);
    setCode(String(number1) + String(number2) + String(number3) + String(number4));

    const height = window.innerHeight;
    ////console.log(height);
    SetScreenHeight(height);


  }, [])


  return (
    <div className={` lg:hidden relative h-[${String(screenHeight)}px] w-screen`} style={{ background: "linear-gradient(270deg, rgba(103 240 194) 0%, rgba(103 240 194) 100%)" }} >
      {isLoading && <Loader styling={`absolute w-fit right-[40%] lg:right-[60%] top-[50%]`} />}
      {/*  for back to home page */}
      <div className=' right-5 top-5 fixed rounded-full bg-[#000000b3] h-8 w-8 flex justify-center items-center'>
        <Link to="/"><img src={CrossIcon} className=' h-3' alt="" /></Link>
      </div>

      <img src={BgImgPoster} alt="" />
      {/* <img src={LoginLogo} className='absolute top-20 right-[35%] h-28' alt="" /> */}
      {/* <div className='absolute  top-56  right-[37%]' style={{top: '16rem'}}>
        <p className='text-[#aaaaaa] text-xs text-center'>Powered By</p>
      <img src={BetfairMobileLogo} className='h-5 text-[#aaaaaa]' alt="" />
      </div> */}

      {/* form */}
      <div className='flex flex-col gap-2 m-5'>
        <input type="text" ref={UserNameRef} style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} className='rounded-lg py-2 pl-2 text-[#1e1e1e] focus:bg-[#fff0ca] focus:outline-none' placeholder='Username' />
        <input type="password" ref={PasswordRef} style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} className='rounded-lg py-2 pl-2 focus:bg-[#fff0ca] focus:outline-none' name="" id="" placeholder='Password' />
        {/* <div className='relative flex'>
          <input type="text" maxLength={4} ref={ValidationCodeRef} style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} className='rounded-l-lg w-2/3 py-2 pl-2 m-0  focus:bg-[#fff0ca] focus:outline-none' placeholder='Validation Code' />
          <div style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)", fontSize: '150%' }} className='bg-white py-[9.5px] w-1/3 rounded-r-lg px-3 m-0 text-end font-bold text-base'>{code}</div>
        </div> */}
        <button onClick={submitLoginForm} className='bg-black text-[#ffb200] py-2 rounded-lg'>Login</button>
        <button onClick={submitDemoLogin} className='bg-black text-[#ffb200] py-2 rounded-lg mt-1'>Login with Demo Id </button>
        <h1 className='text-red-500 text-center'> {errorMessage} </h1>
      </div>


      {/* -------------- footer ------------------------------------------*/}
      <div className={`pb-10 lg:px-40`}>

        <div className='flex flex-col justify-center lg:border-t mt-10 lg:mt-0 border-[#0000004d]'>
          {/* for mobile view */}
          <p className='lg:hidden flex justify-center flex-wrap gap-1  text-xs mt-2 text-[#00000099]'>
            <Link className='underline' >Privacy Policy </Link>
            |<Link className='underline' >Terms and Conditions</Link>
            |<Link className='underline' >Rules and Regulations </Link>
            |<Link className='underline' > KYC</Link>
            |<Link className='underline' > Responsible Gaming</Link>
            |<Link className='underline' > About Us </Link>
            |<Link className='underline' >Self-exclusion Policy </Link>
            |<Link className='underline' > Underage Policy </Link>
          </p>
        </div>

        <div className='mx-5 lg:mx-36 pt-7'>
          <div className='lg:flex justify-between mb-2 '>
            <div className='py-3 bg-[#ffffff99] lg:w-[49%] mb-2 lg:mb-0 flex gap-2 justify-center items-center rounded-lg  text-sm text-[#000000b3]'><img src={headphoneIcon} className='w-8' alt="" /> <span className='cursor-pointer hover:text-black' > Customer support1 </span> | <span className='cursor-pointer hover:text-black' > support2 </span></div>
            <div className='py-3 bg-[#ffffff99] lg:w-[49%] flex gap-2 justify-center items-center rounded-lg  text-sm text-[#000000b3]'><img src={whatsAppIcon} className='w-7' alt="" /> <span className='cursor-pointer hover:text-black' >WhatsApp 3 </span> | <span className='cursor-pointer hover:text-black' > WhatsApp 4 </span></div>
          </div>

          <div className='w-full bg-[#ffffff99] h-4 mb-2 rounded-lg'></div>

          {/*  for mobile view */}
          <div className='flex lg:hidden justify-between gap-2'>
            <div className='py-4 bg-[#ffffff99]  w-[48%] flex gap-2 justify-center items-center rounded-lg  text-sm text-[#000000b3] hover:text-black cursor-pointer' > <img src={skypeIcon} className='w-5' alt="" /> Skype</div>
            <div className='py-4 bg-[#ffffff99]  w-[48%] flex gap-2 justify-center items-center rounded-lg  text-sm text-[#000000b3] hover:text-black cursor-pointer' > <img src={emailIcon} className='w-5' alt="" /> Email</div>
            <div className='py-4 bg-[#ffffff99]  w-[48%] flex gap-2 justify-center items-center rounded-lg  text-sm text-[#000000b3] hover:text-black cursor-pointer' > <img src={igIcon} className='w-5' alt="" /> Instagram</div>
          </div>
        </div>


      </div>

    </div>
  )
}

export default Login
