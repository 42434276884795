import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

const AccountLayout = () => {


    const location = useLocation();
    const path = location.pathname;
    ////console.log(path);

  return (
    <div className='flex gap-6 h-screen overflow-scroll bg-[#eee]'>

        <div className='w-1/6 hidden lg:block '>

            <div className='ml-2'>
                <h1 className='text-white text-sm p-1 text-right border-t border-white' style={{background:"linear-gradient(180deg, #000 0%, #000 100%)"}} >My Accounts</h1>
                <ul className='border-b border-[#7e97a7] divide-y divide-[#eee1c0]' >
                   <Link to={"/account"} ><li className={` ${path === "/account" ? "bg-[#00000099] text-white " : "hover:bg-[#eee1c066]"}   text-sm p-1 `} >My Profile</li></Link>
                   <Link  to="/account/balanceOverview" ><li className={`text-sm p-1 ${path === "/account/balanceOverview" ? "bg-[#00000099] text-white " : "hover:bg-[#eee1c066]"}`} >Balance Overview</li></Link>
                   <Link to="/account/accountStatement" ><li className={`text-sm p-1 ${path === "/account/accountStatement" ? "bg-[#00000099] text-white " : "hover:bg-[#eee1c066]"}`} >Account Statement</li></Link>
                   <Link to="/account/myBet" state={{clickedOption: 'current'}} ><li className={`text-sm p-1 ${path === "/account/myBet" ? "bg-[#00000099] text-white " : "hover:bg-[#eee1c066]"}`} >My Bets</li></Link>
                   <Link to="/account/activityLog" ><li className={`text-sm p-1 ${path === "/account/activityLog" ? "bg-[#00000099] text-white " : "hover:bg-[#eee1c066]"}`} >Activity Log</li></Link>
                </ul>
            </div>
        </div>
        

        <div className='w-5/6 lg:w-full '>
             <Outlet/>
        </div>
    </div>
  )
}

export default AccountLayout
