import React, { useEffect, useState } from 'react'
import KabaddiPoster from "../images/kabaddi-page-poster.jpg";
import pinIconGrey from "../images/pin-icon-grey2.png";
import pinIconGreen from "../images/pin-icon-green.png";
import circleIcon from "../images/circle-icon-green.png";
import PlayIconBlue from "../images/play-icon-blue.svg";
import ClockIconGreen from "../images/clock-icon-green.png";
import fIconBlue from "../images/f-icon-blue.svg";
import bIconBlue from "../images/b-icon-blue.svg";
import poster1 from "../images/poster-1.webp";
import poster2 from "../images/poster-2.webp";
import RightArrow from "../images/right-arrow.svg";
import LeftArrow from "../images/left-arrow.svg";
import CricketIcon from "../images/ball-gold.svg";
import CricketIconBlack from "../images/ball-icon-black.svg";
import FootballIcon from "../images/football-icon-gold.svg";
import FootballIconBlack from "../images/football-icon.svg";
import TennisIcon from "../images/tennis-icon-gold.svg";
import TennisIconBlack from "../images/tennis-ball-icon.svg";
import ESoccerIcon from "../images/esoccer-icon-gold.svg";
import ESoccerIconBlack from "../images/e-soccer-icon.svg";
import KabaddiIcon from "../images/kabaddi-icon-gold.svg";
import KabaddiIconBlack from "../images/kabaddi-icon.svg";
import searchIcon from "../images/search-white.png";
import rightSideArrowIcon from "../images/arrow-rightside-icon.png";
import MinusIcon from "../images/minus-icon-white.png";
import { Link } from 'react-router-dom';
import MiniFooter from '../components/MiniFooter';
import SportListItem from '../components/SportListItem';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Hotspot from '../components/Hotspot';




const Sports = ({ CricketEvents, SoccerEvents, TennisEvents }) => {

  //console.log("sport page is rendering ##");

  const [bets, setBets] = useState([]);
  const [count, setCount] = useState(0);
  const posterArr = [poster1, poster2];
  const [currentSport, setCurrentSport] = useState("cricket");
  const [currentFilter, setCurrentFilter] = useState("Time");






  return (
    <>


      {/* for bigger screen */}
      <div className='flex h-screen bg-[#eee]  overflow-y-hidden '>

        <div className='w-[18vw] hidden bg-white lg:block' >
          <h1 className='bg-black text-white text-sm px-4 py-1'>Sports</h1>
          <h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>Cricket</h1>
          <h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>Soccer</h1>
          <h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>Tennis</h1>
          <h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>E-Soccer</h1>
          <h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>Kabaddi</h1>
        </div>

        <div className='lg:w-[57vw] w-full overflow-y-scroll lg:px-3 bg-[#eeeeee]' >
          <div className='h-[74px] lg:h-[178px] w-full flex justify-between bg-contain bg-center bg-no-repeat items-center bg-black' style={{ backgroundImage: `url(${posterArr[count]})` }}>
            <LazyLoadImage effect="blur" src={LeftArrow} onClick={() => { setCount(prev => prev === 0 ? 1 : 0) }} className='h-10' alt="" />
            <LazyLoadImage effect="blur" src={RightArrow} onClick={() => { setCount(prev => prev === 0 ? 1 : 0) }} className='h-10' alt="" />
          </div>
          {/* for pc view */}
          <h1 className='hidden lg:flex justify-between mt-3 text-sm px-2 bg-[#67f0c2] p-1'> <span className='font-semibold'>Sports Highlights</span> <div className='flex gap-2'><p>View by</p><select className='bg-[#67f0c2] border rounded-sm border-black' name="" id=""><option className='bg-white' value="">Time</option><option className='bg-white' value="">Matched</option><option className='bg-white' value="">Competition</option></select></div></h1>
          <ul className='hidden  lg:flex px-5 gap-2' style={{ background: "linear-gradient(180deg, #67f0c2  15%, #67f0c2  100%)" }} >
            <li className={`${currentSport === "cricket" ? "bg-[#dddcd6] text-black" : "bg-[#3a4e5a] text-white"} border border-b-0 border-[#1e1e1e] cursor-pointer  px-7  text-sm rounded-t-md py-1`} onClick={() => { setCurrentSport("cricket") }} >Cricket</li>
            <li className={`${currentSport === "soccer" ? "bg-[#dddcd6] text-black" : "bg-[#3a4e5a] text-white"} border border-b-0 border-[#1e1e1e] cursor-pointer  px-7  text-sm rounded-t-md py-1`} onClick={() => { setCurrentSport("soccer") }} >Soccer</li>
            <li className={`${currentSport === "tennis" ? "bg-[#dddcd6] text-black" : "bg-[#3a4e5a] text-white"} border border-b-0 border-[#1e1e1e] cursor-pointer  px-7  text-sm rounded-t-md py-1`} onClick={() => { setCurrentSport("tennis") }} >Tennis</li>
            {/* <li className={`${currentSport === "esoccer" ? "bg-[#dddcd6] text-black" : "bg-[#3a4e5a] text-white"} border border-b-0 border-[#1e1e1e] cursor-pointer  px-7  text-sm rounded-t-md py-1`} onClick={() => { setCurrentSport("esoccer") }} >E-Soccer</li>
            <li className={`${currentSport === "kabaddi" ? "bg-[#dddcd6] text-black" : "bg-[#3a4e5a] text-white"} border border-b-0 border-[#1e1e1e] cursor-pointer  px-7  text-sm rounded-t-md py-1`} onClick={() => { setCurrentSport("kabaddi") }} >Kabaddi</li> */}
          </ul>

          <div className='flex lg:hidden justify-between'>
            <div className=' relative flex h-12 w-screen gap-3 overflow-scroll ' style={{ background: "linear-gradient(185deg, rgba(103 240 194) 7%, rgba(103 240 194) 61%)" }}>
              <div className={` ${currentSport === "cricket" ? "bg-black text-[#ffb200]" : "text-black"} ml-2 px-5 relative mt-3 rounded-t-md items-center flex gap-2 font-semibold justify-center `} onClick={() => { setCurrentSport("cricket") }} >  <Hotspot inPlayNumber={"4"} /> <img src={currentSport === "cricket" ? CricketIcon : CricketIconBlack} className='h-5' alt="" /> Cricket </div>
              <h6 className={` ${currentSport === "soccer" ? "bg-black text-[#ffb200]" : "text-black"} px-5 mt-3 relative rounded-t-md items-center flex gap-2 font-semibold justify-center `} onClick={() => { setCurrentSport("soccer") }}  > <Hotspot inPlayNumber={"1"} /> <img src={currentSport === "soccer" ? FootballIcon : FootballIconBlack} className='h-5' alt="" /> Soccer</h6>
              <h6 className={` ${currentSport === "tennis" ? "bg-black text-[#ffb200]" : "text-black"} px-5 mt-3 relative rounded-t-md items-center flex gap-2 font-semibold justify-center`} onClick={() => { setCurrentSport("tennis") }}  > <Hotspot inPlayNumber={"2"} /> <img src={currentSport === "tennis" ? TennisIcon : TennisIconBlack} className='h-5' alt="" /> Tennis</h6>
              {/* <h6 className={` ${currentSport === "eSoccer" ? "bg-black text-[#ffb200]" : "text-black"} px-5 mt-3 relative rounded-t-md items-center flex gap-2 font-semibold justify-center`} onClick={() => { setCurrentSport("eSoccer") }}  > <Hotspot inPlayNumber={"3"} /> <img src={currentSport === "eSoccer" ? ESoccerIcon : ESoccerIconBlack} className='h-5' alt="" /> {"ESoccer"}</h6>
              <h6 className={`  ${currentSport === "kabaddi" ? "bg-black text-[#ffb200]" : "text-black"} px-5 mt-3 relative rounded-t-md items-center flex gap-2 font-semibold justify-center `} onClick={() => { setCurrentSport("kabaddi") }}  > <Hotspot inPlayNumber={"5"} /> <img src={currentSport === "kabaddi" ? KabaddiIcon : KabaddiIconBlack} className='h-5' alt="" /> Kabaddi</h6> */}
              <div className='sticky bottom-0 right-0 w-10  flex items-center h-12 mobileSearchSports bg-black' > </div>
            </div>
            <div className='px-3 flex justify-between items-center bg-black'>
              <img src={searchIcon} className='h-6 w-7' alt="" />
            </div>
          </div>

          <h1 className='text-center lg:hidden py-1 text-white font-semibold' style={{ background: "linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)" }} > Hightlights </h1>

          {/*  filter section */}
          <div className='lg:hidden bg-[#eee] py-1' >
            <div className='w-[80%]  mx-auto rounded-[1.3vw] p-[1px] h-9 text-xs font-bold bg-[#e3e3e3] flex items-center' style={{ boxShadow: "inset 0 1px 3px 0 rgba(0,0,0,.15)" }} >
              <div className={`w-1/2 h-full ${currentFilter === "Time" ? "rounded-[1.3vw] bg-white text-[#0074c4]" : " "}  flex items-center justify-center`} onClick={() => { setCurrentFilter("Time") }} > by Time</div>
              <div className={`w-1/2 h-full flex items-center ${currentFilter === "Competition" ? "rounded-[1.3vw] bg-white text-[#0074c4]" : " "} justify-center `} onClick={() => { setCurrentFilter("Competition") }} > by Competition</div>
            </div>
          </div>

          <table className='text-white  w-full border-collapse border-b bg-white border-black'>
            <thead className='hidden lg:table-header-group'>
              <tr className='bg-[#dddcd6] text-sm font-light '>
                {/* <th className='py-2 bg-[#ced5da]  text-[#1e1e1e] pr-20 border-gray-400' ></th> */}
                <th className='font-light text-black text-right  border-gray-400 w-[45vw]' >Matched</th>
                <th className={`font-light text-black border-gray-400`} >1</th>
                <th className={`font-light text-black border-gray-400`} >x</th>
                <th className={`font-light text-black border-gray-400`} >2</th>
                <th className='text-transparent' >{"pinq"}</th>
              </tr>
            </thead>
            <tbody>
              {
                currentSport === "cricket" && CricketEvents?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((match) => {
                  let matchName = "example";
                  matchName = match?.eventName;
                  let eventName = matchName.substring(0, 35);
                  const eventDate = new Date(match.eventDate);
                  const currentDate = new Date();
                  // Format eventDate to only include the date part
                  const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                  // Determine if the eventDate is tomorrow
                  const isTomorrow = (eventDate.getDate() === currentDate.getDate() + 1) &&
                    (eventDate.getMonth() === currentDate.getMonth()) &&
                    (eventDate.getFullYear() === currentDate.getFullYear());

                  // Append "tomorrow" to the formattedEventDate if it's tomorrow
                  const displayDate = isTomorrow ? `Tomorrow ${formattedEventDate}` : formattedEventDate;
                  return <SportListItem key={match.SrNo} eid={4} marketId={match.marketId} eventId={match.gameId} title={eventName?.length > 30 ? eventName + "..." : eventName} OneBackValue={match.back1} OneLayValue={match.lay1} XBackValue={match.back11} XLayValue={match.lay11} TwoBackValue={match.back12} TwoLayValue={match.lay12} isFancy={match.Fancy === 1 ? true : false} isBookMarked={match.BookMaker === 1 ? true : false} greyLine={new Date(match.eventDate) <= new Date ? "In-Play" : displayDate} isInPlay={true} IsBetLock={match.IsBetLock == 1 ? true : false} isLive={new Date(match.eventDate) <= new Date ? true : false} eventDate={match.eventDate} totalMatched={match.totalMatched}/>
                })
              }

              {
                currentSport === "soccer" && SoccerEvents?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((match) => {
                  let matchName = "example";
                  matchName = match?.eventName;
                  let eventName = matchName.substring(0, 35);
                  const eventDate = new Date(match.eventDate);
                  const currentDate = new Date();
                  // Format eventDate to only include the date part
                  const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                  // Determine if the eventDate is tomorrow
                  const isTomorrow = (eventDate.getDate() === currentDate.getDate() + 1) &&
                    (eventDate.getMonth() === currentDate.getMonth()) &&
                    (eventDate.getFullYear() === currentDate.getFullYear());

                  // Append "tomorrow" to the formattedEventDate if it's tomorrow
                  const displayDate = isTomorrow ? `Tomorrow ${formattedEventDate}` : formattedEventDate;
                  return <SportListItem key={match.SrNo} eid={1} marketId={match.marketId} eventId={match.gameId} title={eventName?.length > 30 ? eventName + "..." : eventName} OneBackValue={match.back1} OneLayValue={match.lay1} XBackValue={match.back11} XLayValue={match.lay11} TwoBackValue={match.back12} TwoLayValue={match.lay12} greyLine={new Date(match.eventDate) <= new Date ? "In-Play" : displayDate} isInPlay={true} IsBetLock={match.IsBetLock == 1 ? true : false} isLive={new Date(match.eventDate) <= new Date ? true : false} eventDate={match.eventDate} totalMatched={match.totalMatched} />
                })
              }

              {
                currentSport === "tennis" && TennisEvents?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((match) => {
                  let matchName = "example";
                  matchName = match?.eventName;
                  let eventName = matchName.substring(0, 35);
                  const eventDate = new Date(match.eventDate);
                  const currentDate = new Date();
                  // Format eventDate to only include the date part
                  const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                  // Determine if the eventDate is tomorrow
                  const isTomorrow = (eventDate.getDate() === currentDate.getDate() + 1) &&
                    (eventDate.getMonth() === currentDate.getMonth()) &&
                    (eventDate.getFullYear() === currentDate.getFullYear());

                  // Append "tomorrow" to the formattedEventDate if it's tomorrow
                  const displayDate = isTomorrow ? `Tomorrow ${formattedEventDate}` : formattedEventDate;
                  return <SportListItem key={match.SrNo} eid={2} marketId={match.marketId} eventId={match.gameId} title={eventName?.length > 30 ? eventName + "..." : eventName} OneBackValue={match.back1} OneLayValue={match.lay1} XBackValue={match.back11} XLayValue={match.lay11} TwoBackValue={match.back12} TwoLayValue={match.lay12} greyLine={new Date(match.eventDate) <= new Date ? "In-Play" : displayDate} isInPlay={true} IsBetLock={match.IsBetLock == 1 ? true : false} isLive={new Date(match.eventDate) <= new Date ? true : false} eventDate={match.eventDate} totalMatched={match.totalMatched} />
                })
              }


            </tbody>
          </table>


          {/*  footer for sport pages */}
          <footer className='hidden lg:block'>
            <MiniFooter key={"1"} />
          </footer>

        </div>

        <div className='lg:block hidden bg-white w-[25vw] ml-2 border-t'>
          <h1 className='bg-[#274252] text-white text-xs  font-semibold p-1 flex justify-between'>Bet Slip <img src={MinusIcon} className='h-4' alt="" /></h1>
          {bets.length === 0 && <h1 className='p-3 text-xs text-center'>Click on the odds to add selections to the betslip.</h1>}
        </div>

      </div>
    </>
  )
}

export default Sports
