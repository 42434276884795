import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import poster1 from "../images/poster-1.webp";
import poster2 from "../images/poster-2.webp";
import RightArrow from "../images/right-arrow.svg";
import LeftArrow from "../images/left-arrow.svg";
import sportPoster from "../images/sports-poster.webp";
// import kabaddiPoster from "../images/kabaddi-poster.png";
// import blogPoster from "../images/blog-poster.webp";
import virtualCricketPoster from "../images/virtualsports-poster.webp";
import evoPoster from "../images/evo-poster.png";
import ezugiPoster from "../images/ezugi-poster.png";
import royalGamingPoster from "../images/royalgaming-poster.png";
import liveCasinoPoster from "../images/casino-poster.png";
import blackJackPoster from "../images/blackjack-poster.png";
import SevenUpDownPoster from "../images/7up7down-poster.png";
import anderBaharVrPoster from "../images/andarBahar-poster.png";
import superNovaPoster from "../images/supernowa-poster.png";
import SevenMojosPoster from "../images/7mojos-poster.png";
import horseBookPoster from "../images/horsebook-poster.webp";
import minesweeperPoster from "../images/minesweeper-poster.png";
import skyTraderPoster from "../images/skytrader-poster.webp";
import teenPatti from "../images/teenPatti-poster.png";
import superOver from "../images/superOverVR-poster.png";
import teenPattiTwentyTwenty from "../images/TeenPatti2020-poster.png";
import numberKingPoster from "../images/NumberKing-poster.png";
import bigSmallPoster from "../images/BigSmall-poster.png";
import TeenPattiJokerPoster from "../images/TeenPattiJoker-poster.png";
import SevenUpSevenDownPoster from "../images/7up7down-poster2.png";
import dragonTigerPoster from "../images/DragonNTiger-poster.png";
import AutoRoulettePoster from "../images/autoRoulette-poster.png";
import DusKaDumPoster from "../images/DusKaDumVR-poster.png";
import CallBreakQuickPoster from "../images/CallbreakQuick-poster.png";
import SickBoPoster from "../images/SicBo-Jili-poster.png";
import BaccaratPoster from "../images/banner_Baccarat-poster.png";
import BonusDicePoster from "../images/BonusDice-poster.png";
import HeistPoster from "../images/Heist-poster.png";
import FiveCardPoker from "../images/5CardPoker-poster.png";
import ColorGamePoster from "../images/ColorGame-poster.png";
import ThirtyTwoCardsPoster from "../images/32card-poster.png";
import RummyPoster from "../images/rummy-poster.png";
import DragonTigerPoster2 from "../images/dragonTiger-poster.png";
import WarliMatkaPoster from "../images/worliMatkaVR-poster.png";
import BetGamePoster from "../images/betgames-poster.png";
import AnderBaharPoster from "../images/andarBahar-poster2.png";
import SicBO2Poster from "../images/sicbo-poster.png";
import SevenUp7DownPoster from "../images/sevenUpDown-poster.webp";
import CoinTossPoster from "../images/CoinToss-poster.webp";
import TeenPattiPoster from "../images/teenPatti-poster1.png";
import CardMatkaPoster from "../images/cardMatka-poster.png";
import NumberMatkaPoster from "../images/numberMatka-poster.png";
import BPokerPoster from "../images/bpoker-poster.png";
import Footer from '../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
const ImageFrame = ({ imgSource, colspan, gameName }) => {



  return (
    <div className={`relative ${colspan && "col-span-2"} lazyLoad`}  >
      <LazyLoadImage height="100%" width="100%" effect="blur" src={imgSource} alt="" className="h-full w-full" />
      <div style={{ background: "linear-gradient(270deg, rgba(69, 94, 104, 0) 4%, #000000 97%)" }} className="absolute bottom-0 flex w-full justify-between border-b-[5px] bg-black border-[#ffb80c]">
        <p className="bg-black text-white text-base font-bold pl-2">{gameName}</p>
        <p className="bg-[#ffb80c] pl-3 font-bold text-[12px] p-1 twinkle" style={{ clipPath: "polygon(16% 0, 100% 0, 100% 100%, 0% 100%)" }}>Play now</p>
      </div>
    </div>
  )
}


const Home = () => {

  //console.log("Home page is rendering !!");

  const [count, setCount] = useState(0);
  const posterArr = [poster1, poster2];

  const fetchingInPlayData = async () => {
    let res = await axios.get(`https://api.s2bet.in/GetInPlaySportEvents`);
    let resData = await res.data;
    return resData;

  }

  const { isPending, isLoading, isError, data: sports, error } = useQuery({
    queryKey: ['inplaySportsForCountingHome'],
    queryFn: fetchingInPlayData,
  });

  let inPlayData = sports?.data?.sportsEventModelInPlay;


  // useEffect(()=>{
  //     setInterval(()=>{setCount(prev => prev === 1 ? 0 : 1)},2000)
  // },[])

  return (
    <>

      <style jsx>{`
      .lazyLoad{
        height: 196px;
      }


    .inPlay-sport{
    font-size: .875rem;
    // line-height: 1.25rem;
    }

    .span-style {
       min-width: 1.3vw;
       color: #333;
       background: #fff;
       text-align: center;
       height: 1.3vw;
   }
    @media (max-width: 768px) {
    .lazyLoad{
      height: 148px;
    }
    .inPlay-sport{
      font-size: 11.5px;
    }
      .span-style {
        min-width: 3.7333333333vw;
        color: #333;
        background: #fff;
        text-align: center;
        border-radius: 0.5333333333vw;
        height: 3.7333333333vw;
      }
    }
  `}</style>
      <div className='lg:px-40 flex gap-2 border-t-2 flex-col  bg-[#eee] overflow-hidden'>

        <div className='h-[74px] lg:mx-0 lg:h-[178px] lg:w-full flex justify-between bg-contain lg:bg-cover bg-no-repeat items-center bg-black' style={{ backgroundImage: `url(${posterArr[count]})` }}>
          <LazyLoadImage effect="blur" src={LeftArrow} onClick={() => { setCount(prev => prev === 0 ? 1 : 0) }} className='h-10' alt="" />
          <LazyLoadImage effect="blur" src={RightArrow} onClick={() => { setCount(prev => prev === 0 ? 1 : 0) }} className='h-10' alt="" />
        </div>

        <div className='grid grid-cols-2 lg:grid-cols-4 mb-5 gap-20' style={{ marginLeft: '.3rem', marginRight: '.3rem' }}>


          <div className="relative cursor-pointer col-span-2 lazyLoad">
            <Link to={`/inplay`}>
              <div className='absolute z-[3] text-white right-0 top-0' style={{ background: " linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 82%, rgba(0, 0, 0, 0) 100%)", minWidth: '93px' }}>
                <span className='pl-2 rounded-sm flex justify-start w-fit my-1 mx-2 items-center right-[10%] bg-white  text-white text-xs'><svg width="14" height="8" className='animate-pulse' xmlns="http://www.w3.org/2000/svg"><g fill="rgb(255,0,0)" fillRule="evenodd"><path d="M12.012 0l-.698.727c1.734 1.808 1.734 4.738 0 6.546l.698.727c2.117-2.207 2.117-5.79 0-8zM10.3 1.714l-.7.735c.967 1.014.967 2.66 0 3.673l.7.735c1.352-1.418 1.352-3.721 0-5.143zM1.588 0l.698.727c-1.734 1.808-1.734 4.738 0 6.546L1.588 8c-2.117-2.207-2.117-5.79 0-8zM3.3 1.714l.7.735c-.967 1.014-.967 2.66 0 3.673l-.7.735c-1.352-1.418-1.352-3.721 0-5.143z" /><circle cx="6.8" cy="4.4" r="1.6" /></g></svg><span className='text-white ml-2 bg-red-500 px-1 rounded-sm'>LIVE</span></span>
                <div className='flex my-1 justify-between px-2 inPlay-sport' >Cricket <span className='text-black h-[16px] text-xs bg-white rounded-[3px] span-style' >{inPlayData?.filter((game) => game.eid === "4")?.length}</span> </div>
                <div className='flex my-1 justify-between px-2 inPlay-sport'>Soccer <span className='text-black h-[16px] text-xs bg-white rounded-[3px] span-style' >{inPlayData?.filter((game) => game.eid === "1")?.length}</span> </div>
                <div className='flex my-1 justify-between px-2 inPlay-sport'>Tennis <span className='text-black h-[16px] text-xs bg-white rounded-[3px] span-style' >{inPlayData?.filter((game) => game.eid === "2")?.length}</span> </div>
              </div>
              <LazyLoadImage height="100%" width="100%" effect="blur" src={sportPoster} alt="" className="h-full w-full" />
              <div style={{ background: "linear-gradient(270deg, rgba(69, 94, 104, 0) 4%, #000000 97%)" }} className="absolute bottom-0 flex w-full justify-between border-b-[5px] bg-black border-[#ffb80c]">
                <p className="bg-black text-white text-base font-bold pl-2">Sports</p>
                <p className="bg-[#ffb80c] pl-3 font-bold text-[12px] p-1 twinkle" style={{ clipPath: "polygon(16% 0, 100% 0, 100% 100%, 0% 100%)" }}>Play now</p>
              </div>
            </Link>
          </div>


          {/* <ImageFrame imgSource={kabaddiPoster} colspan={true} gameName={"Kabaddi"} /> */}
          {/* <ImageFrame imgSource={virtualCricketPoster} colspan={true} gameName={"Virtual Cricket"} /> */}
          <ImageFrame imgSource={virtualCricketPoster} gameName={"Virtual Cricket"} colspan={true} />

          <ImageFrame imgSource={evoPoster} gameName={"EVO"} />
          <ImageFrame imgSource={ezugiPoster} gameName={"Ezugi"} />
          <ImageFrame imgSource={royalGamingPoster} colspan={true} gameName={"Royal Gaming"} />

          <ImageFrame imgSource={liveCasinoPoster} gameName={"Live Casino"} />
          <ImageFrame imgSource={blackJackPoster} gameName={"BlackJack"} />
          <ImageFrame imgSource={SevenUpDownPoster} gameName={"7 Up Down"} />
          <ImageFrame imgSource={anderBaharVrPoster} gameName={"Ander Bahar"} />

          <ImageFrame imgSource={superNovaPoster} gameName={"Supernowa"} />
          <ImageFrame imgSource={SevenMojosPoster} gameName={"7mojos"} />
          <ImageFrame imgSource={horseBookPoster} gameName={"HorseBook"} />
          <ImageFrame imgSource={minesweeperPoster} gameName={"MineSweeper"} />

          <ImageFrame imgSource={skyTraderPoster} gameName={"Sky Trader"} />
          <ImageFrame imgSource={teenPatti} gameName={"Teen Patti"} />
          <ImageFrame imgSource={superOver} gameName={"Super Over"} />
          <ImageFrame imgSource={teenPattiTwentyTwenty} gameName={"TeenPatti 20-20"} />

          <ImageFrame imgSource={numberKingPoster} gameName={"NumberKing"} />
          <ImageFrame imgSource={bigSmallPoster} gameName={"Big Small"} />
          <ImageFrame imgSource={TeenPattiJokerPoster} gameName={"TeenPatti Joker"} />
          <ImageFrame imgSource={SevenUpSevenDownPoster} gameName={"7 UP 7 Down"} />

          <ImageFrame imgSource={dragonTigerPoster} gameName={"Dragon & Tiger"} />
          <ImageFrame imgSource={AutoRoulettePoster} gameName={"Auto Roulette"} />
          <ImageFrame imgSource={DusKaDumPoster} gameName={"Dus ka Dum"} />
          <ImageFrame imgSource={CallBreakQuickPoster} gameName={"Callbreak Quick"} />

          <ImageFrame imgSource={SickBoPoster} gameName={"Sic Bo"} />
          <ImageFrame imgSource={BaccaratPoster} gameName={"Baccarat"} />
          <ImageFrame imgSource={BonusDicePoster} gameName={"Bonus Dice"} />
          <ImageFrame imgSource={HeistPoster} gameName={"Heist"} />

          <ImageFrame imgSource={FiveCardPoker} gameName={"5 Card Poker"} />
          <ImageFrame imgSource={ColorGamePoster} gameName={"Color Game"} />
          <ImageFrame imgSource={ThirtyTwoCardsPoster} gameName={"32 Cards"} />
          <ImageFrame imgSource={RummyPoster} gameName={"Rummy"} />

          <ImageFrame imgSource={DragonTigerPoster2} gameName={"dragon Tiger"} />
          <ImageFrame imgSource={WarliMatkaPoster} gameName={"Worli Matka"} />
          <ImageFrame imgSource={BetGamePoster} gameName={"Bet Games"} />
          <ImageFrame imgSource={AnderBaharPoster} gameName={"Ander Bahar"} />

          <ImageFrame imgSource={SicBO2Poster} gameName={"SicBo"} />
          <ImageFrame imgSource={SevenUp7DownPoster} gameName={"7 UP 7 DOWN"} />
          <ImageFrame imgSource={CoinTossPoster} gameName={"Coin Toss"} />
          <ImageFrame imgSource={TeenPattiPoster} gameName={"Teen Patti"} />

          <ImageFrame imgSource={CardMatkaPoster} gameName={"Card Matka"} />
          <ImageFrame imgSource={NumberMatkaPoster} gameName={"Number Matka"} />
          <ImageFrame imgSource={BPokerPoster} gameName={"bPoker"} />
          {/* <ImageFrame imgSource={TeenPattiPoster} /> */}

        </div>


      </div >
      {/* <Footer/> */}
    </>
  )
}

export default Home
