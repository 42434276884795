import React, { useContext, useEffect, useRef, useState } from 'react'
import logo from "../images/logo.png";
import exitWhiteIcon from "../images/exit-white-icon.png";
import settingIcon from "../images/setting-icon.png";
import OpenTvImg from "../images/tv-to-open.png";
import CloseTvImg from "../images/tv-to-close.png";
import TvCoverImg from "../images/cover-live.webp";
import GlobalContext from '../context/GlobalContext';
import Hotspot from './Hotspot';
import BetIcon from "../images/bet-icon-gold.svg";
import SettingMobile from '../pages/SettingMobile';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowDropdown } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { IoPencilSharp } from "react-icons/io5";
import { ImUser } from "react-icons/im";
import { IoSearch } from "react-icons/io5";
import { TbReload } from "react-icons/tb";
import { IoSettingsSharp } from "react-icons/io5";
import { PiMonitorPlayBold } from "react-icons/pi";
import Cookies from "js-cookie";
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';




// header when user is logged-in in pc 
const LoggedInHeader = () => {

  //console.log("this is a Login Header Component!!");

  const { data: currency, isLoading, isError } = useQuery({
    queryKey: ["currency"], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/api/getActiveCurrency/currency`);
      let resData = await res.data;
      return resData;
    }
  })

  const [isMyAccountModelOpen, setIsMyAccountModelOpen] = useState(false);
  const [isSettingModelOpen, setIsSettingModelOpen] = useState(false);
  const [settingPageOpen, setSettingPageOpen] = useState(false);
  const [isTvOpen, setIsTvOpen] = useState(false);
  const [settingPageOpenMobile, setSettingPageOpenMobile] = useState(false);
  const [userExposure, setUserExposure] = useState(0);
  const [mainBalance, setMainBalance] = useState(0);
  // if isEditOpen is true that quick stack can be editable else not
  const [isEditOpen, setisEditOpen] = useState(false);
  const [reloadBal, setReloadBal] = useState(false); //for refresh balance
  const [isBalLoad, setIsBalLoad] = useState(false); //for refresh balance


  const dropdownRef = useRef(null);
  const AccountMenu = useRef(null);

  const navigate = useNavigate();

  const { id, eid } = useParams()

  const context = useContext(GlobalContext);
  const { selectedEvent, LoginUserDetail, setIsUserLoggedIn, quickStakes, setQuickStakes, betSend, setBetSend, setLoginBalanceContext, setLoginExpoContext, reFetchLoginBalance, setReFetchLoginBalance } = context;

  console.log('header LoginUserDetail : ', LoginUserDetail)

  let userId2 = Cookies.get("LoginUserDetail");
  let userIP = Cookies.get('userIP');
  let userId = userId2 === undefined ? "" : JSON.parse(userId2);
  //console.log('User cookie : ', userId.Id)

  const locationData = useLocation();
  let LocationPath = locationData?.pathname;
  console.log('location : ', LocationPath);

  let isEventPage = LocationPath?.slice(1, 6);     // checking if current Page is EventPage or not  
  let EventPageId = LocationPath?.slice(7, -2);    // getting eventId


  let eventId = id;

  console.log('open bets header event Id : ', eventId)


  const isValidEventId = eventId != null && eventId !== '';

  const newfetchBalance = async () => {
    setIsBalLoad(true);
    try {
      console.log('balance call');
      const bal = await fetch(`https://api.betcare.live/expo1/${userId.Id}/${userId.selfUser}`);
      const resultBal = await bal.json();
      // console.log('resullt bal : ', resultBal)
      if (resultBal.isSuccess) {
        // console.log(' main valance : ', resultBal.mainBalanceafterExpo)
        // const calcBalance = apiBalance + exposure
        setUserExposure(resultBal.FinalExpo)
        setLoginExpoContext(resultBal.FinalExpo)
        ////console.log("Balance Api Balance is : ", apiBalance)
        setMainBalance(resultBal.mainBalanceafterExpo)
        setLoginBalanceContext(resultBal.mainBalanceafterExpo)
        setIsBalLoad(false);
      } else {
        setIsBalLoad(false);
      }
    } catch (error) {
      console.log('Error Fetching Balance : ', error)
    } finally {
      setIsBalLoad(false);
    }
  }


  const stakeChangeHandlerFunc = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setQuickStakes({ ...quickStakes, [name]: value })

  }


  const handleLogOut = async () => {

    try {
      console.log('logout user Id : ', userId?.Id)

      let res = await axios.post('https://api.betcare.live/logout', {
        UserId: userId?.Id
      }, {
        headers: { "Content-Type": "application/json" }
      });

      if (res.status === 200) {
        localStorage.removeItem('isUserLoggedIn');
        localStorage.removeItem("LoginUserDetail");

        Cookies.remove('isLoggedIn');
        Cookies.remove('LoginUserDetail');
        Cookies.remove('userIP');
        setIsUserLoggedIn(false);
        navigate("/");
      } else {
        alert(`error in api / failed to logout!`);
      }



    } catch (error) {
      alert("failed to logged out !!");
    }

  }

  // const refreshBalance = asy

  const location = useLocation();
  const path = location.pathname;
  const tvNeededPage = path.substring(0, 6);

  console.log('tvNeededPage : ', tvNeededPage)


  // Check User Status is User logged in or not
  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const res = await fetch(`https://api.betcare.live/GetUserIdandUserStatusDemo/${userId.Id}/${userIP}`);
        const resData = await res.json();

        if (resData.isSuccess) {
          console.log('User login status: ', resData);

          if (!resData.isLoggedIn) {
            localStorage.removeItem('isUserLoggedIn');
            localStorage.removeItem('LoginUserDetail');
            Cookies.remove('isLoggedIn');
            Cookies.remove('LoginUserDetail');
            Cookies.remove('userIP');
            setIsUserLoggedIn(false);
            navigate('/');
          }
        }
      } catch (error) {
        console.error('Error fetching user status:', error);
      }
    };

    fetchUserStatus();
  }, [LocationPath, betSend]);



  useEffect(() => {
    newfetchBalance();
  }, []);

  useEffect(() => {
    //console.log('bet Send is : ', betSend)
    newfetchBalance();
    // fetchExposure();

    setBetSend(false);
    setReloadBal(false);
    setReFetchLoginBalance(false);

  }, [betSend, reloadBal, reFetchLoginBalance])

  useEffect(() => {
    if (tvNeededPage != "/event") {
      setIsTvOpen(false)
    }
  }, [tvNeededPage])


  if (settingPageOpenMobile) {
    return (
      <>
        <SettingMobile settingPageOpen={settingPageOpenMobile} setSettingPageOpen={setSettingPageOpenMobile} />
      </>)
  }

  return (
    <>
      {/* flex lg:hidden py-2 px-2 justify-between text-[#ffb200] */}
      <header className='lg:pt-3 select-none lg:pb-4 py-2 flex z-50 text-[#ffb200] justify-between lg:items-center pr-2 lg:px-2 ' style={{ background: " linear-gradient(180deg, #474747 0%, #070707 100%)" }} >

        {/* for pc view */}
        <div className='hidden lg:flex items-center'>
          <a href="/" >
            <img src="https://ag.betcare.live/uploads/panel-logo.png" alt="Logo" style={{ width: '8rem' }} />
          </a>
          <div className=''>
            {/* <Search/> */}
            <div className='lg:flex bg-white rounded-l lg:pl-1 items-center hidden ' style={{ display: 'none' }}>
              {/* <img src={searchLogo} className=' h-7 p-2  bg-white flex z-30' alt="" /> */}
              <IoSearch className='bg-white h-[25px] text-black' />
              <input type="text" className='h-[25px]  rounded-e-sm w-[270px] pl-2  text-sm' placeholder='Search Events' />
            </div>
          </div>
        </div>

        {/* for mobile view */}
        <div className='flex lg:hidden gap-2 rounded-sm items-center justify-center h-[36px] bg-[#ffffff1a] border border-[#00000040] w-[110px]' style={{ boxShadow: "inset 0 0.2666666667vw 0 0 rgba(255,255,255,.3)", paddingRight: '3%', borderRadius: '1.0666666667vw' }}>
          {/*  need to shown when tv is available */}
          <div className={tvNeededPage === "/event" ? "block" : "hidden"}>
            <div onClick={() => { setIsTvOpen(prev => !prev) }} className={`bg-[#0A7B89] rounded-sm ${selectedEvent?.isTvAvailable ? "block" : "hidden"} px-2 py-2`} >
              <img src={isTvOpen ? CloseTvImg : OpenTvImg} alt="" />
            </div>
          </div>
          {/* <Link className='flex' to={`/currentBetMobile/${isEventPage === "event" ? EventPageId : "0"}`} state={isEventPage === "event" ? { type: "singleEvent" } : { type: "allEvent" }}   > */}
          <Link
            className='flex'
            to={`/currentBetMobile/${isValidEventId ? eventId : "0"}`}
            state={{ type: isValidEventId ? "singleEvent" : "allEvent" }}
          >
            <img src={BetIcon} alt="" style={{ width: '6.3333333333vw', height: '6.0666666667vw', marginRight: '1.3333333333vw' }} />
            <h1 className='font-semibold' style={{ fontSize: '13.208px', marginTop: '5%' }} >Bets</h1>
          </Link>
          {/* <Link className='flex' to={`/currentBetMobile/${betEventId !== null ? betEventId : 0}`} state={isEventPage === "event" ? { type: "singleEvent" } : { type: "allEvent" }}   >
            <img src={BetIcon} alt="" />
            <h1 className='font-semibold' >Bets</h1>
          </Link> */}
        </div>


        {/* for pc view */}
        <div className='flex gap-5'>

          <div className='hidden lg:flex' >
            <div
              className='text-[#ffb600] text-xs p-1 flex gap-1 font-bold h-[25px]  bg-[#ffffff1a] rounded-l-md border border-[#00000040] cursor-pointer' style={{ boxShadow: "inset 0 0.0666666667vw 0 0 rgba(255,255,255,.5)" }}
              onClick={() => {
                if (LoginUserDetail?.selfUser === 1) {
                  navigate('/wallet-gateway');
                } else {
                  console.log('Navigation not allowed');
                }
              }}
            >
              {isBalLoad ?
                <>
                  <span>Loading...</span>
                </>
                :
                <>
                  <span className='opacity-70'>Main Balance </span> {currency?.[0]?.CurrencyName} {mainBalance}
                  <span className='opacity-70'>  Exposure</span> <span style={{ color: 'red' }}>{userExposure.toFixed(2)}</span>
                  {/* <p className='border border-[#ffb600] px-2 flex items-center justify-center mt-[0.5px] py-1 rounded-sm'>+5</p> */}
                </>
              }
            </div>
            <div className='h-[24px] w-[25px] bg-[#535353] flex justify-center items-center px-1 rounded-r-md' style={{ boxShadow: "inset 0 0.0666666667vw 0 0 rgba(255,255,255,.5)" }}> <TbReload className='flipReload' style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); setReloadBal(true) }} /> </div>
          </div>

          {/* for pc view */}
          <div ref={AccountMenu}
            onClick={() => { setIsMyAccountModelOpen(prev => !prev) }} className='hidden lg:flex cursor-pointer px-1 relative rounded-sm text-[#ffb600] gap-2 bg-[#ffffff1a] items-center' style={{ boxShadow: "inset 0 0.0666666667vw 0 0 rgba(255,255,255,.5)" }}>
            {/* <img src={profileGoldIcon} alt="" /> */}
            <ImUser />
            <h1 className='text-sm hover:underline' >My Account
              <div onClick={(e) => e.stopPropagation()} className={`w-[220px]  ${isMyAccountModelOpen ? "block" : "hidden"} right-0 z-[40] mt-2 rounded-[4px] bg-white absolute`} >
                <div className='flex justify-between items-center px-1 border-b border-[#7e97a7]' >
                  <h1 className='text-[#3b5160] p-1 font-bold'>{LoginUserDetail?.FullName}</h1>
                  <p className='text-[#8b98a1] border-l text-xs px-1 font-semibold border-[#c5d0d7]'>GMT+5:30</p>
                </div>
                <Link to="account"><h1 className='text-black border-b border-[#e0e6e6] p-1 px-2 hover:bg-[#eee]' onClick={() => { setIsMyAccountModelOpen(prev => !prev) }}>My Profile</h1></Link>
                <Link to="account/balanceOverview"><h1 className='text-black border-b border-[#e0e6e6] p-1 px-2 hover:bg-[#eee]' onClick={() => { setIsMyAccountModelOpen(prev => !prev) }}>Balance Overview</h1></Link>
                <Link to="/account/accountStatement" ><h1 className='text-black border-b border-[#e0e6e6] p-1 px-2 hover:bg-[#eee]' onClick={() => { setIsMyAccountModelOpen(prev => !prev) }}>Account Statement</h1></Link>
                <Link to="/account/myBet" state={{ clickedOption: 'current' }} > <h1 className='text-black border-b border-[#e0e6e6] p-1 px-2 hover:bg-[#eee]' onClick={() => { setIsMyAccountModelOpen(prev => !prev) }}>My Bets</h1></Link>
                <Link to="/account/myBet" state={{ clickedOption: 'history' }}><h1 className='text-black border-b border-[#e0e6e6] p-1 px-2 hover:bg-[#eee]' onClick={() => { setIsMyAccountModelOpen(prev => !prev) }}>Bets History</h1></Link>
                <Link to="/account/myBet" state={{ clickedOption: 'profitAndLoss' }} ><h1 className='text-black border-b border-[#e0e6e6] p-1 px-2 hover:bg-[#eee]' onClick={() => { setIsMyAccountModelOpen(prev => !prev) }}>Profit & Loss</h1></Link>
                <Link to="/account/activityLog" ><h1 className='text-black border-b border-[#e0e6e6] p-1 px-2 hover:bg-[#eee]' onClick={() => { setIsMyAccountModelOpen(prev => !prev) }}>Activity Log</h1></Link>
                <button onClick={handleLogOut} className='flex justify-center items-center bg-[#7e97a7] w-[90%] rounded-[4px] text-white py-1 m-2' >LOGOUT</button>
              </div>
            </h1>
            <IoMdArrowDropdown className='text-[#ffb600]' />
          </div>
        </div>


        {/* for mobile view */}
        <div className='flex lg:hidden'>
          <div className='h-[36px] rounded-sm w-[172px] justify-between flex items-center px-2 bg-[#ffffff1a] border border-[#00000040]' style={{ boxShadow: "inset 0 0.2666666667vw 0 0 rgba(255,255,255,.5)", borderRadius: '1.0666666667vw' }}>
            <div
              className='cursor-pointer'
              style={{ fontSize: '10.16px', lineHeight: '3.2vw' }}
              onClick={() => {
                if (LoginUserDetail?.selfUser === 1) {
                  navigate('/wallet-gateway');
                } else {
                  console.log('Navigation not allowed');
                }
              }}
            >
              {isBalLoad ?
                <>
                  <p>
                    <span>Loading...</span>
                  </p>
                </>
                :
                <>
                  <p style={{ fontWeight: 'bold' }}>
                    <span className='opacity-70' style={{ fontWeight: '600' }}> Main
                    </span> {currency?.[0]?.CurrencyName} {mainBalance}
                  </p>
                  <p style={{ fontWeight: 'bold' }}>
                    <span className='opacity-70' style={{ fontWeight: '600' }}> Exposure
                    </span> <span style={{ color: 'red' }}>{userExposure.toFixed(2)}</span>
                  </p>
                </>
              }
            </div>
            {/* <p className='h-[16px] w-[31px] border border-[#ffb200] text-[10px] flex justify-center items-center rounded-sm '> +5
            </p> */}
          </div>
          <div className='h-[35px] w-[36px] rounded-sm justify-center flex items-center bg-[#ffffff1a] border border-[#00000040]' style={{ boxShadow: "inset 0 0.2666666667vw 0 0 rgba(255,255,255,.3)", borderRadius: '1.0666666667vw' }}>
            {/* <img src={ReloadIcon} alt="" />  */}
            <TbReload className='flipReload text-2xl' style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); setReloadBal(true) }} />
          </div>
        </div>

        {/* for mobile view */}
        <div className='h-[35px] w-[36px] rounded-sm justify-center flex lg:hidden items-center bg-[#ffffff1a] border border-[#00000040]' onClick={() => { setSettingPageOpenMobile(true) }} style={{ boxShadow: "inset 0 0.2666666667vw 0 0 rgba(255,255,255,.3)", borderRadius: '1.0666666667vw' }}>  <IoSettingsSharp className='text-2xl' /> </div>



      </header>

      <div className='block lg:hidden'>
        {/* <video className={`h-[270px] w-full ${isTvOpen ? "block" : "hidden"}`} autoPlay muted playsInline poster={TvCoverImg} src="blob:https://bxawscf.skyexch.art/a0aa9c3f-afe7-4234-ad13-900a415acd10"></video> */}

        <div className={`h-[270px] w-full ${isTvOpen && tvNeededPage == "/event" ? "block" : "hidden"}`}>
          <iframe
            id="tvFrame"
            style={{ width: "100%", height: 250 }}
          // src={`https://stream.1ex99.in/tv2?EventId=${gameId}`}
          // src={`https://winx777.com/tv/?EventId=${eid}`}
          />
        </div>

      </div>

      <nav className='bg-[#FFC421] lg:flex justify-between items-center hidden'>
        <ul className='flex text-[12px] font-bold cursor-pointer'>
          <NavLink to="/" className='px-3 py-1  border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all'>Home</ NavLink>
          <NavLink to="/inplay" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all'>In-Play</NavLink>
          <NavLink to="/multimarket" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all' >Multi Markets</NavLink>
          <NavLink to="/cricket" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >Cricket <Hotspot inPlayNumber={"4"} /> </NavLink>
          <NavLink to="/soccer" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >Soccer <Hotspot inPlayNumber={"1"} /> </NavLink>
          <NavLink to="/tennis" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >Tennis <Hotspot inPlayNumber={"2"} /> </NavLink>
          {/* <Link className='px-2 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all' >Virtual Cricket</Link> */}
          {/* <Link  className='px-2 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >E-Soccer <Hotspot inPlayNumber={8} /> </Link> */}
          {/* <NavLink to="/kabaddi" className='px-2 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >Kabaddi <Hotspot inPlayNumber={0} /> </NavLink> */}
          {/* <Link  className='px-2 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all' >Sky Trader</Link> */}
        </ul>

        <div className='flex text-[12px] items-center gap-3 pr-1 '>
          <h1 className=''><span style={{ color: "rgba(0,0,0,.6)" }}>Time Zone :</span> <span className='font-bold'>GMT+5:30</span></h1>
          <h1 style={{ background: "linear-gradient(180deg, #4b4b4b 0%, #1e1e1e 100%)" }} className='flex gap-2 font-bold items-center px-2 py-1  text-[#ffc828]'><input type="checkbox" name="" id="" /> One Click Bet</h1>
          <h1 ref={dropdownRef} onClick={() => { setIsSettingModelOpen(prev => !prev) }} className='cursor-pointer font-bold flex items-center gap-2 '>Setting <img src={settingIcon} className='h-4 hidden lg:block' alt="" />
            {
              isSettingModelOpen && (
                <div onClick={(e) => e.stopPropagation()} className={`w-[267px] bg-[#e0e6e6] absolute text-[12px] top-[108px] z-30 right-0 rounded-b-[4px]`} style={{ boxShadow: "0 4px 5px rgba(0,0,0,.5)" }}>
                  <div className='border-b flex mx-2 py-2 gap-2 border-[#7e97a7] text-[#3b5160]'>
                    <h1 className='' >Default stake</h1>
                    <input type="number" className='w-[30%] rounded-[4px]' style={{ boxShadow: "inset 0px 1px 0px rgba(0,0,0,.5)" }} />
                  </div>
                  <div className='mx-2 py-2 border-b border-[#7e97a7]'>
                    <h1 className='text-[#3b5160] '>Stake</h1>

                    <div className='grid grid-cols-5 gap-1' >

                      {
                        isEditOpen ? <>
                          <input type="number" value={quickStakes.stakeOne} onChange={stakeChangeHandlerFunc} name='stakeOne' className='border border-[#aaa] py-[2px] rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} />
                          <input type="number" value={quickStakes.stakeTwo} onChange={stakeChangeHandlerFunc} name='stakeTwo' className='border border-[#aaa] py-[2px] rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} />
                          <input type="number" value={quickStakes.stakeThree} onChange={stakeChangeHandlerFunc} name='stakeThree' className='border border-[#aaa] py-[2px] rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} />
                          <input type="number" value={quickStakes.stakeFour} onChange={stakeChangeHandlerFunc} name='stakeFour' className='border border-[#aaa] py-[2px] rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} />
                          <button onClick={() => { setisEditOpen(prev => !prev) }} className={`row-span-2 border ${isEditOpen ? "bg-black text-[#ffb600]" : ""} rounded-[4px] flex justify-center items-center gap-1 border-[#7e97a7]`}> {isEditOpen ? "Save" : "Edit"} {isEditOpen ? "" : <IoPencilSharp className='text-[#3b5160]' />}  </button>
                          <input type="number" value={quickStakes.stakeFive} onChange={stakeChangeHandlerFunc} name='stakeFive' className='border border-[#aaa] py-[2px] rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} />
                          <input type="number" value={quickStakes.stakeSix} onChange={stakeChangeHandlerFunc} name='stakeSix' className='border border-[#aaa] py-[2px] rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' id="" style={{ boxShadow: "inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)" }} />
                          {/* <input type="number" value="500" className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' name="" id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} /> */}
                          {/* <input type="number" value="1000" className='border border-[#aaa] py-1 rounded-md text-[#1e1e1e] text-center focus:bg-[#fff0ca] focus:outline-0 focus:border-[#be7809]' name="" id="" style={{boxShadow:"inset 0 0.5333333333vw 0 0 rgba(0,0,0,.1)"}} /> */}
                        </> : <>

                          <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeOne}</button>
                          <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeTwo}</button>
                          <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeThree}</button>
                          <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeFour}</button>
                          <button onClick={() => { setisEditOpen(prev => !prev) }} className={`row-span-2 border ${isEditOpen ? "bg-black text-[#ffb600]" : ""} rounded-[4px] flex justify-center items-center gap-1 border-[#7e97a7]`}> {isEditOpen ? "Save" : "Edit"} {isEditOpen ? "" : <IoPencilSharp className='text-[#3b5160]' />}  </button>
                          <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeFive}</button>
                          <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >{quickStakes.stakeSix}</button>
                          {/* <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >500</button> */}
                          {/* <button className={`text-[#ffb200] bg-[#444] py-1 rounded-md`} >1000</button> */}
                        </>
                      }



                      {/* <button className='px-3 border border-[#bbb] rounded-[4px] ' style={{background:"linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)"}}>10</button>
            <button className='px-3 border border-[#222] text-[#ffb600] bg-[#444] rounded-[4px]' >30</button>
            <button className='px-3 border border-[#222] text-[#ffb600] bg-[#444] rounded-[4px]' >50</button>
            <button className='px-3 border border-[#bbb] rounded-[4px] text-xs' style={{background:"linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)"}} >100</button>
            <button onClick={()=>{setisEditOpen(prev => !prev)}} className={`row-span-2 border ${isEditOpen ? "bg-black text-[#ffb600]" : ""} rounded-[4px] flex justify-center items-center gap-1 border-[#7e97a7]`}> {isEditOpen ? "Save" : "Edit"} {isEditOpen ? "" : <IoPencilSharp className='text-[#3b5160]' /> }  </button>

            <button className='px-3 border border-[#222] text-[#ffb600] bg-[#444] rounded-[4px]' >200</button>
            <button className='px-3 border border-[#222] text-[#ffb600] bg-[#444] rounded-[4px]' >300</button>
            <button className='px-3 border border-[#222] text-[#ffb600] bg-[#444] rounded-[4px]' >500</button>
            <button className='px-3 border border-[#222] text-[#ffb600] bg-[#444] rounded-[4px]' >1000</button> */}
                    </div>

                  </div>

                  <div className='border-b  mx-2 py-2  border-[#7e97a7] text-[#3b5160]' >
                    <h1>Odds</h1>
                    <div className='flex items-center'>
                      <input type="checkbox" name="" id="odds" />
                      <label className='text-xs ml-1 text-[#3b5160]' htmlFor="odds">Highlight when odds change</label>
                    </div>
                  </div>

                  <div className='border-b  mx-2 py-2  border-[#7e97a7] text-[#3b5160]' >
                    <h1>FancyBet</h1>
                    <div className='flex items-center'>
                      <input type="checkbox" name="" id="fancybet" />
                      <label className='text-xs ml-1 text-[#3b5160]' htmlFor="fancybet">Accept Any Odds</label>
                    </div>
                  </div>

                  <div className='border-b  mx-2 py-2  border-[#7e97a7] text-[#3b5160]' >
                    <h1>SportsBook</h1>
                    <div className='flex items-center'>
                      <input type="checkbox" name="" id="sportsbook" />
                      <label className='text-xs ml-1 text-[#3b5160]' htmlFor="sportsbook">Accept Any Odds</label>
                    </div>
                  </div>

                  <div className='border-b  mx-2 py-2  border-[#7e97a7] text-[#3b5160]' >
                    <h1>Win Selection forecast</h1>
                    <div className='flex items-center'>
                      <input type="checkbox" name="" id="winselection" />
                      <label className='text-xs ml-1 text-[#3b5160]' htmlFor="winselection">With Commission</label>
                    </div>
                  </div>

                  <div className='border-b  mx-2 py-2 gap-2 flex justify-between'>
                    <button onClick={() => { setIsSettingModelOpen(false) }} className='w-1/2 border border-[#bbb] py-1 rounded-[4px]' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)" }} >Cancel</button>
                    <button onClick={() => { setIsSettingModelOpen(false) }} className='w-1/2 rounded-[4px] text-[#ffb600]' style={{ background: "linear-gradient(180deg, #474747 0%, #070707 100%)" }} >Save</button>
                  </div>

                </div>
              )}
          </h1>

        </div>

      </nav>
    </>
  )
}






const Header = () => {


  const [code, setCode] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  // for login form submission
  const [ip, setIp] = useState("");
  const [BrowerInfo, setBrowerInfo] = useState("");
  const UserNameRef = useRef();
  const PasswordRef = useRef();
  // const ValidationCodeRef = useRef();

  const context = useContext(GlobalContext);
  const { setIsLoginModeOpen, isUserLoggedIn, setLoginUserDetail, setIsUserLoggedIn } = context;



  const submitLoginForm = async () => {

    let username = UserNameRef.current.value;
    let password = PasswordRef.current.value;
    // let validationcode = ValidationCodeRef.current.value;


    if (username?.length === 0) {
      setErrorMessage("Enter Username first!!");


      return;
    }

    if (password?.length === 0) {
      setErrorMessage("Enter password first!!");
      return;
    }


    // if (validationcode !== code) {
    //   setErrorMessage("Invalid validation Code !!");
    //   return;
    // }

    ////console.log(`the username : ${userName} \n the password : ${password}  \n the validation code : ${validationCode}`);
    try {
      const res = await fetch(`https://api.betcare.live/userlogin`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: username, password, userIP: ip, geoLocationn: BrowerInfo })
      });

      const resData = await res.json();

      ////console.log(res.status);

      console.log('login respomse : ', resData);

      if (res.status !== 200 && resData.isSucces !== true) {
        setErrorMessage(resData.message);
        return;
      }

      setIsUserLoggedIn("true");
      localStorage.setItem('isUserLoggedIn', "true");
      localStorage.setItem("LoginUserDetail", JSON.stringify(resData?.data));

      Cookies.set('isLoggedIn', 'true', { expires: 1 });
      Cookies.set('userIP', ip, { expires: 1 });
      Cookies.set('userId', resData?.data?.Id, { expires: 1 });
      Cookies.set('LoginUserDetail', JSON.stringify(resData?.data), { expires: 1 });

      setLoginUserDetail(resData.data);
      navigate("/");



    } catch (error) {
      //console.log(error);
      setErrorMessage("Can't able to login now ! Try again later!!")
    }

  }

  const submitSignUpForm = async () => {
    try {
      const res = await fetch(`https://api.betcare.live/Demologin`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: 'demo', password: '123456', userIP: ip, geoLocationn: BrowerInfo })
      });

      const resData = await res.json();

      ////console.log(res.status);

      console.log('login respomse : ', resData);

      if (res.status !== 200 && resData.isSucces !== true) {
        setErrorMessage(resData.message);
        return;
      }

      setIsUserLoggedIn("true");
      localStorage.setItem('isUserLoggedIn', "true");
      localStorage.setItem("LoginUserDetail", JSON.stringify(resData?.data));

      Cookies.set('isLoggedIn', 'true', { expires: 1 });
      Cookies.set('userIP', ip, { expires: 1 });
      Cookies.set('userId', resData?.data?.Id, { expires: 1 });
      Cookies.set('LoginUserDetail', JSON.stringify(resData?.data), { expires: 1 });

      setLoginUserDetail(resData.data);
      navigate("/");



    } catch (error) {
      //console.log(error);
      setErrorMessage("Can't able to login now ! Try again later!!")
    }

  }


  useEffect(() => {
    ; (async () => {
      let res = await fetch(`https://api.ipify.org/?format=json`);
      let resData = await res.json();
      console.log('api.ipify : ', resData);
      setIp(resData.ip);

    })()

  }, [])


  useEffect(() => {
    (async () => {
      console.log(`ip of this brower is : ${ip}`);
      let res = await fetch(`https://freeipapi.com/api/json/${ip}`);
      let resData = await res.json();
      console.log('ip-api : ', resData);

      let city = await resData.cityName;
      let region = await resData.regionName;
      let country = await resData.countryName;
      let zip = await resData.zipCode;
      console.log(city + region + country + zip);
      setBrowerInfo(`${city}/${region}/${country}/${zip}`);

    })();
  }, [ip])


  useEffect(() => {
    ; (async () => {
      console.log('Browser info : ', BrowerInfo);

    })()
  }, [ip])




  useEffect(() => {
    let number1 = Math.floor(Math.random() * 10);
    let number2 = Math.floor(Math.random() * 10);
    let number3 = Math.floor(Math.random() * 10);
    let number4 = Math.floor(Math.random() * 10);
    setCode(String(number1) + String(number2) + String(number3) + String(number4));



    //  let loggedInStatus = localStorage.getItem('isUserLoggedIn');
    let loggedInCookieValue = Cookies.get('isLoggedIn');
    // //console.log(loggedInCookieValue);

    if (loggedInCookieValue === "true") {
      setIsUserLoggedIn(loggedInCookieValue);
      // let UserData = localStorage.getItem("LoginUserDetail");
      let LoginUserDertailCookieData = Cookies.get(`LoginUserDetail`);
      setLoginUserDetail(JSON.parse(LoginUserDertailCookieData));
    }


  }, [])


  // if user is logged in then this header will be shown to user
  if (isUserLoggedIn === "true") {
    return (
      <>
        {/* <LoginedHeaderMobile/> */}
        <LoggedInHeader />
      </>
    )
  }

  ////console.log("Header component is rendereing !!!");

  return (
    <>

      <header className='bg-black pt-1 text-[12px] lg:pt-2 pb-2 lg:pb-6 flex z-50 justify-between items-center px-2 lg:static sticky top-0'>
        <div className='flex gap-4 items-center'>
          <a href="/">
            <img src="https://ag.betcare.live/uploads/panel-logo.png" alt="Logo" style={{ width: '8rem' }} />
          </a>
          <div className=''>
            {/* <Search/> */}
            <div className='lg:flex bg-white  rounded-l pl-1 items-center hidden ' style={{ display: 'none' }}>
              {/* <img src={searchLogo} className=' h-7 p-2  bg-white flex z-30' alt="" /> */}
              <IoSearch className='bg-white h-[25px]' />
              <input type="text" className='h-[25px]  rounded-e-sm w-[270px] pl-2 ' placeholder='Search Events' />
            </div>
          </div>
        </div>

        <div className='items-center flex gap-3'>
          <div className='flex items-center gap-2'>
            <FaUser className='text-[#ffb600] text-lg hidden lg:block' />
            <div className='relative'>
              <input type="text" ref={UserNameRef} className='w-[130px] lg:block hidden rounded-sm h-[27px] px-1 placeholder:text-slate-500' placeholder='Username' />
              <h1 className='text-yellow-500 absolute -bottom-4 text-xs w-[200px]'>{errorMessage?.length >= 30 ? errorMessage?.slice(0, 25) + ".." : errorMessage}</h1>
            </div>
          </div>
          <input type="password" ref={PasswordRef} className='w-[130px] lg:block hidden rounded-sm h-[27px] px-1 placeholder:text-slate-500' name="" id="" placeholder='Password' />
          {/* <div className='lg:flex hidden'>
            <input type="text" ref={ValidationCodeRef} className='w-[110px] px-1 placeholder:text-slate-500' maxLength={4} placeholder='Validation' />
            <div className='bg-white h-[27px] px-1 flex items-center justify-center font-bold' style={{ fontSize: '125%' }}>{code}</div>
          </div> */}

          <div className='flex lg:flex-row flex-row-reverse gap-1'>

            <div>
              {/* for mobile */}
              <Link to="/login" style={{ background: "linear-gradient(-180deg, #67f0c2 0%, #67f0c2 100%)", boxShadow: "inset 0 0.2666666667vw 0 0 rgba(255,255,255,.5)" }} className='text-black flex  justify-center lg:hidden w-[94px] lg:w-[80px]  py-[6px] rounded-sm items-center font-bold'><ImUser className='text-lg lg:hidden block' /> Login</Link>
              {/* for pc */}
              <Link onClick={submitLoginForm} style={{ background: "linear-gradient(-180deg, #f72424 0%, #bb1c00 100%)" }} className='bg-red-600 text-white hidden lg:flex  justify-center gap-1 w-[80px]  py-1 rounded-sm items-center font-bold'>Login<img src={exitWhiteIcon} className='h-4 hidden lg:block' alt="" /> </Link>
            </div>
            {/* Sign-up Button for Mobile */}
            <Link to="/sign-up" style={{ background: "linear-gradient(-180deg, #666666 0%, #333333 100%)", color: '#67f0c2' }}
              className=' flex  justify-center lg:hidden w-[94px] lg:w-[80px]  py-[6px] rounded-sm items-center font-bold'>
              Sign Up
            </Link>

            {/* Sign-up Button for PC */}
            <Link to="/sign-up"
              style={{ background: "linear-gradient(-180deg, #666666 0%, #333333 100%)" }}
              className='text-white hidden lg:flex  justify-center gap-1 w-[80px]  py-1 rounded-sm items-center font-bold'>

              Sign Up
            </Link>
          </div>
        </div>
      </header>
      <nav className='bg-[#FFC421] lg:flex justify-between items-center hidden'>
        <ul className='flex text-[12px] font-bold cursor-pointer'>
          <NavLink to="/" className='px-3 py-1  border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all'>Home</ NavLink>
          <NavLink to="/inplay" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all'>In-Play</NavLink>
          <NavLink to="/multimarket" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all' >Multi Markets</NavLink>
          <NavLink to="/cricket" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >Cricket <Hotspot inPlayNumber={"4"} /> </NavLink>
          <NavLink to="/soccer" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >Soccer <Hotspot inPlayNumber={"1"} /> </NavLink>
          <NavLink to="/tennis" className='px-3 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >Tennis <Hotspot inPlayNumber={"2"} /> </NavLink>
          {/* <Link className='px-2 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all' >Virtual Cricket</ Link> */}
          {/* <Link className='px-2 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >E-Soccer <Hotspot inPlayNumber={8} /> </Link> */}
          {/* <NavLink to="/kabaddi" className='px-2 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all relative' >Kabaddi <Hotspot inPlayNumber={0} /> </NavLink> */}
          {/* <Link  className='px-2 py-1 border-r-[1px] border-zinc-500 hover:bg-[#ffdc7a] transition-all' >Sky Trader</Link> */}
        </ul>

        <div className='flex items-center text-[12px] gap-3 pr-1'>
          <h1 className=''><span style={{ color: "rgba(0,0,0,.6)" }}>Time Zone :</span> <span className='font-bold'>GMT+5:30</span></h1>
          <h1 style={{ background: "linear-gradient(180deg, #4b4b4b 0%, #1e1e1e 100%)" }} className='cursor-pointer flex gap-2 font-bold items-center px-2 py-1  text-[#ffc828]' onClick={() => { setIsLoginModeOpen(true) }}><input type="checkbox" name="" id="" disabled /> One Click Bet</h1>
          <h1 onClick={() => { setIsLoginModeOpen(true) }} className='cursor-pointer font-bold flex items-center gap-2'>Setting <img src={settingIcon} className='h-4 hidden lg:block' alt="" /></h1>
        </div>

      </nav>
    </>
  )
}

export default React.memo(Header)
