import React, { useContext, useEffect, useRef, useState } from 'react'
import GlobalContext from '../../context/GlobalContext';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Loader from '../../components/Loader';
import moment from 'moment';
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { IoIosPlay } from "react-icons/io";


const BetHistoryTable = ({ betType2 }) => {

    //  //console.log('Bet history page is rendering !!');

    //  //console.log(betType2);

    let today1 = new Date();
    let todayM = moment(today1);
    let today = todayM.format(`YYYY-MM-DD`);

    let Tommorrow1 = new Date();
    let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
    let TommorrowM = moment(Tommorrow12);
    let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

    let Yesterdat1 = new Date();
    let Yesterday12 = Yesterdat1.setDate(Yesterdat1.getDate() - 1);
    let YesterdayM = moment(Yesterday12);
    let yesterday = YesterdayM.format(`YYYY-MM-DD`);


    const [updateData, setUpdateData] = useState(true);  // onclicking the get history btn it will just re-render the page;

    const [betStatus, setBetStatus] = useState(1)
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [fromDate, setFromDate] = useState(yesterday);
    const [fromTime, setFromTime] = useState(`00:00:00`);
    const [toDate, setToDate] = useState(today);
    const [toTime, setToTime] = useState(`23:59:59`);

    const getFilterData = () => {
        setUpdateData((prev) => !prev);
    }

    // const getTodaybetData = () => {
    //     FromDateRef.current.value = formattedDate;
    //     setUpdateData((prev) => !prev);
    //     ////console.log(yesterDay);
    // }

    // const getYesterdayData = () => {
    //     FromDateRef.current.value = yesterDay;
    //     setUpdateData((prev) => !prev);
    // }

    const setDateFunc = async (btnName) => {

        if (btnName === 'today') {
            setFromDate(today);
            setFromTime(`00:00:00`);
            setToDate(Tommorrow);
            setToTime(`12:59:59`);
            // await queryClient.refetchQueries({ type: 'betting-data' });
            // refetch();
            setPage(1);
            setUpdateData((prev) => !prev);

        } else if (btnName === "yesterday") {

            setFromDate(yesterday);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            // await queryClient.refetchQueries({ type: 'betting-data' });
            // refetch();
            setPage(1);
            setUpdateData((prev) => !prev);

        }
    }

    const onChangeHandler = (e, dateTimeType) => {
        if (dateTimeType === "fromDate") {
            setFromDate(e.target.value);

            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "fromTime") {
            setFromTime(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "toDate") {
            setToDate(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "toTime") {
            setToTime(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
    }

    // console.log('from date : ', FromDateRef?.current?.value);
    // console.log('from time : ', FromTimeRef.current.value);
    // console.log('to date : ', ToDateRef.current.value);
    // console.log('to time : ', ToTimeRef.current.value);


    const loginUserId = Cookies.get('userId')
    const context = useContext(GlobalContext);
    const { LoginUserDetail } = context;

    console.log('login user Id : ', LoginUserDetail?.Id)
    // console.log('bet status : ', betStatus)

    // const [betType, setBetType] = useState("current");
    // const [betType2, setBetType2] = useState("exchange");



    const { isPending, isLoading, isError, data: bets, error } = useQuery({
        queryKey: ['betHistory', betStatus, updateData, betType2],
        queryFn: async () => {
            let res = await axios.get(`https://api.betcare.live/GetBetHistoryByDate/${loginUserId}?betStatus=${encodeURIComponent(betStatus)}&market=${encodeURIComponent(betType2)}&fromDateTime=${encodeURIComponent(fromDate + " " + fromTime)}&toDateTime=${encodeURIComponent(toDate + " " + toTime)}`);
            let resData = await res.data;
            console.log('bets History data : ', resData)
            return resData;
        },
        staleTime: 7000
    });

    const betHistoryData = bets?.data;






    // { isLoading && <tr>loading....</tr>}

    return (
        <>
            {isLoading && <Loader styling={`absolute w-fit right-[40%] top-[50%]`} />}
            <div className='border-t-4 text-[12px] w-[100vw] lg:w-[75vw] bg-[#e0e6e6] border-[#3b5160] border-b border-b-[#7e97a7]' >

                <div className='flex flex-col lg:flex-row mt-2 ml-2 lg:ml-0 gap-4'>

                    <section className='flex gap-2'>
                        <p>Bet Status:</p>
                        <select className='border border-black w-fit' name="" id=""
                            value={betStatus} onChange={(event) => {
                                setBetStatus(parseInt(event.target.value))
                            }}>
                            <option value={1}>Settled</option>
                            <option value={2}>UnSettled</option>
                            {/* <option value="">Voided</option> */}
                        </select>
                    </section>

                    <p className='flex gap-2' >Period
                        <input value={fromDate}
                            onChange={(e) => { onChangeHandler(e, "fromDate") }} type="date" name="" id="" />
                        {/* <input value={fromTime} onChange={(e) => { onChangeHandler(e, "fromTime") }} type="time" name="" id="" /> */}
                    </p>

                    <p className='flex gap-2' >to
                        <input type="date" value={toDate}
                            onChange={(e) => { onChangeHandler(e, "toDate") }} name="" id="" />
                        {/* <input value={toTime} onChange={(e) => { onChangeHandler(e, "toTime") }} type="time" name="" id="" /> */}
                    </p>

                </div>


                <div className='flex gap-3 text-[11px] ml-2 lg:ml-0 mb-2 mt-4' >
                    <button onClick={() => { setDateFunc(`today`) }} className='border rounded border-[#bbb] px-2 py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)" }} >Just For Today</button>
                    <button onClick={() => { setDateFunc(`yesterday`) }} className='border rounded border-[#bbb] px-2 py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)" }}>From Yesterday</button>
                    <button onClick={getFilterData} className='border rounded text-[#ffb600] font-bold border-[#bbb] px-2 py-1' style={{ background: "linear-gradient(180deg, #474747 0%, #070707 100%)" }}>Get History</button>
                </div>
            </div>


            <section className='w-[100vw] lg:w-[95%] overflow-auto'>

                <table className='mt-5 table text-[12px] border-b border-[#7e97a7] lg:w-[75vw]'>
                    <thead>
                        <tr className='border-y font-bold bg-[#e4e4e4] border-[#7e97a7]'>
                            <th className='py-3 px-[10px]' >Bet Id</th>
                            <th className='py-3 px-[10px]' >PL Id</th>
                            <th className='py-3 px-[10px]' >Round Id</th>
                            <th className='py-3 px-[10px]' >Market</th>
                            <th className='py-3 px-[10px]' >Selection</th>
                            <th className='py-3 px-[10px]' >Type</th>
                            <th className='py-3 px-[10px]' >Bet Placed</th>
                            <th className='py-3 px-[10px]' >Odds req.</th>
                            <th className='py-3 px-[10px]' >Stake</th>
                            <th className='py-3 px-[10px]' >Avg. odds matched</th>
                            <th className='py-3 px-[10px]' >Profit/Loss</th>
                        </tr>
                    </thead>
                    <tbody>

                        {betHistoryData?.sort((a, b) => new Date(b.placeTime) - new Date(a.placeTime))?.map((bet) => {

                            let betid = bet?.betId;
                            let trimBetId = betid?.substring(0, 13);
                            let resultAmount = bet.resultAmount;
                            let sportName = bet?.sportId === 1 ? "Soccer" : bet?.sportId === 2 ? "Tennis" : "Cricket";
                            if (bet.isSettlement !== 1) { resultAmount = "--"; }

                            return <tr className='border-b bg-white border-black' key={bet.id}>
                                <td className='py-3 px-[10px]'>{trimBetId + "..."}</td>
                                <td className='text-center'>{sportName}</td>
                                <td className='text-center'>---</td>
                                <td className='text-center'>{bet.eventName}</td>
                                <td className='text-center'>{bet?.selection?.length >= 15 ? bet?.selection?.substring(0, 12) + "..." : bet?.selection}</td>
                                <td className='text-center'>{bet.type}</td>
                                <td className='text-center'>--</td>
                                <td className='text-center'>{bet.oddsRequest}</td>
                                <td className='text-center'>{bet.amountStake}</td>
                                <td className='text-center'>--</td>
                                <td className='text-center'>{resultAmount}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </section>
        </>
    )
}




const ProfitAndLossTable = React.memo(({ betType2 }) => {



    let today1 = new Date();
    let todayM = moment(today1);
    let today = todayM.format(`YYYY-MM-DD`);

    let Tommorrow1 = new Date();
    let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
    let TommorrowM = moment(Tommorrow12);
    let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

    let Yesterdat1 = new Date();
    let Yesterday12 = Yesterdat1.setDate(Yesterdat1.getDate() - 1);
    let YesterdayM = moment(Yesterday12);
    let yesterday = YesterdayM.format(`YYYY-MM-DD`);

    ////console.log(`profit and loss table rendering!!`);

    const loginUserId = Cookies.get('userId')

    const context = useContext(GlobalContext);
    const { LoginUserDetail } = context;

    const [plData, setPLData] = useState([]);
    const [currentSport, setCurrentSport] = useState("All");
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [fromDate, setFromDate] = useState(yesterday);
    const [fromTime, setFromTime] = useState(`00:00:00`);
    const [toDate, setToDate] = useState(today);
    const [toTime, setToTime] = useState(`23:59:59`);


    const setDateFunc = async (btnName) => {

        if (btnName === 'today') {
            setFromDate(today);
            setFromTime(`00:00:00`);
            setToDate(Tommorrow);
            setToTime(`12:59:59`);
            // await queryClient.refetchQueries({ type: 'betting-data' });
            // refetch();
            setPage(1);
            setUpdateData((prev) => !prev);

        } else if (btnName === "yesterday") {

            setFromDate(yesterday);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            // await queryClient.refetchQueries({ type: 'betting-data' });
            // refetch();
            setPage(1);
            setUpdateData((prev) => !prev);

        }
    }

    const onChangeHandler = (e, dateTimeType) => {
        if (dateTimeType === "fromDate") {
            setFromDate(e.target.value);

            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "fromTime") {
            setFromTime(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "toDate") {
            setToDate(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "toTime") {
            setToTime(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
    }


    ////console.log(currentDate.toLocaleDateString());

    const [updateData, setUpdateData] = useState(true);  // onclicking the get history btn it will just re-render the page;

    const [calcAllPL, setCalAllPL] = useState(0)

    const getFilterData = () => {
        setUpdateData((prev) => !prev);
    }


    // {isLoading && <Loader/>}

    // { isLoading &&  <Loader styling={`absolute w-fit right-[40%] top-[50%]`} />}

    // const totalAmount = data.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);

    // setCalAllPL(totalPL)


    const { isPending, isLoading, isError, data: bets, error } = useQuery({
        queryKey: ['ProfitAndLoss', updateData, betType2],
        queryFn: async () => {
            let res = await axios.get(`https://api.betcare.live/GetProfitAndLossByDate/${loginUserId}?market=${encodeURIComponent(betType2)}&fromDateTime=${encodeURIComponent(fromDate + " " + fromTime)}&toDateTime=${encodeURIComponent(toDate + " " + toTime)}`);
            let resData = await res.data;
            return resData;
        },
        staleTime: 7000
    });

    // let data = [];
    useEffect(() => {
        const allData = bets?.data?.plMainVMList;
        console.log('Profit Loss Data : ', allData)
        setPLData(allData)
    }, [bets])

    useEffect(() => {
        console.log('Profit Loss Data : ', bets?.data?.plMainVMList)
        if (currentSport == 'All') {
            const allData = bets?.data?.plMainVMList;
            setPLData(allData)
        } else {
            const filterData = bets?.data?.plMainVMList?.filter(item => item.sportName == currentSport)
            setPLData(filterData)
        }
    }, [currentSport])

    useEffect(() => {
        if (plData) {
            console.log('profitLoss Data : ', plData)
            const totalPl = plData.reduce((accumulator, item) => accumulator + (item.amount || 0), 0);
            setCalAllPL(totalPl);
        }
    }, [plData]);

    return (
        <>
            {isLoading && <Loader styling={`absolute w-fit right-[40%] top-[50%]`} />}
            <div className='border-t-4 w-[100vw] lg:w-[75vw]  bg-[#e0e6e6]  border-[#3b5160] border-b border-b-[#7e97a7]' >

                <div className='flex mt-2 text-[12px] flex-col lg:flex-row gap-4'>
                    <p className='ml-2 lg:ml-4 mr-2'>Period :
                        <input value={fromDate}
                            onChange={(e) => { onChangeHandler(e, "fromDate") }} type="date" name="" id="" />
                        {/* <input value={fromTime} onChange={(e) => { onChangeHandler(e, "fromTime") }} type="time" name="" id="" /> */}
                    </p>
                    <p className='ml-2 lg:ml-0'>to :
                        <input type="date" value={toDate}
                            onChange={(e) => { onChangeHandler(e, "toDate") }} name="" id="" />
                        {/* <input value={toTime} onChange={(e) => { onChangeHandler(e, "toTime") }} type="time" name="" id="" /> */}
                    </p>

                </div>


                <div className='flex gap-3 text-[11px] ml-2 lg:ml-0 mb-2 mt-4' >
                    <button onClick={() => { setDateFunc(`today`) }} className='border rounded border-[#bbb] px-2 py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)" }} >Just For Today</button>
                    <button onClick={() => { setDateFunc(`yesterday`) }} className='border rounded border-[#bbb] px-2 py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)" }}>From Yesterday</button>
                    <button onClick={getFilterData} className='border rounded text-[#ffb600] font-bold border-[#bbb] px-2 py-1' style={{ background: "linear-gradient(180deg, #474747 0%, #070707 100%)" }}>Get History</button>
                </div>
            </div>



            <div className='flex w-[100vw] lg:w-[75vw] text-[12px] font-bold p-3 justify-between'>
                <h1>Total P/L: INR</h1>

                <div className='flex  gap-2'>
                    <select name="" onChange={(e) => { setCurrentSport(e.target.value) }} value={currentSport} className='border border-black rounded' id="">
                        <option value="All">All</option>
                        <option value="Cricket">Cricket</option>
                        <option value="Soccer">Soccer</option>
                        <option value="Tennis">Tennis</option>
                    </select>
                    <h2> INR <span className='text-red-500' >{calcAllPL}</span> </h2>
                </div>
            </div>

            <section className='w-[100vw] lg:w-[95%] overflow-auto'>
                <table className=' w-fit lg:w-[75vw] text-[12px] mb-10'>
                    <thead>
                        <tr className='border-y font-bold bg-[#e4e4e4] border-[#7e97a7]'>
                            <th className='py-3 px-[10px] w-3/6 text-left' >Market</th>
                            <th className='py-3 px-[10px] w-1/6' >Start Time</th>
                            <th className='py-3 px-[10px] w-1/6' >Settled Date</th>
                            <th className='py-3 px-[10px] w-1/6' >Profit/Loss</th>
                        </tr>
                        {/* <tbody className='w-full bg-red-400'> */}
                        {plData?.sort((a, b) => new Date(b.settledTime) - new Date(a.settledTime))?.map((bet) => {
                            return <tr className='bg-white  w-full border-b border-black text-center' key={bet.eventId} >
                                <td className=' text-left py-3 px-[10px] pl-5' >{bet.event}</td>
                                <td className=''>{moment(bet.startTime).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td className=''>{moment(bet.settledTime).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td className=''>{bet.amount}</td>
                            </tr>
                        })}
                        {/* </tbody>   */}
                    </thead>
                </table>
            </section>

        </>
    )
})




const MyBet = () => {

    //console.log("Mybet rendering!!");

    //console.log("bethistoryTable is rendering !!");
    const loginUserId = Cookies.get('userId')
    console.log('cookie userId : ', loginUserId)
    const { state } = useLocation();
    const clickedOption = state?.clickedOption;

    const context = useContext(GlobalContext);
    const { LoginUserDetail } = context;



    const [myBetsData, setMyBetsData] = useState([])
    const [betType, setBetType] = useState("current");
    const [betType2, setBetType2] = useState("exchange");
    const [betStatus, setBetStatus] = useState(2)
    const [loading, setLoading] = useState(false);


    console.log('login user Id in my bet compo : ', LoginUserDetail?.Id)
    console.log('bet status in my bet compo : ', betStatus)

    const { isPending, isLoading, isError, data: bets, error } = useQuery({
        queryKey: ['betHistory', betStatus, betType],
        queryFn: async () => {
            setLoading(true);
            try {

                let res = await axios.get(`https://api.betcare.live/GetBetHistory/${loginUserId}?betStatus=2`);

                let resData = await res.data;
                console.log('bets data in my bet compo : ', resData)
                return resData;
            } catch (error) {
                console.log('Error While fetching /GetBetHistory..', error)
            } finally {
                setLoading(false);
            }
        },
        staleTime: 7000
    });

    const betHistoryData = bets?.data;


    // const fetchBetData = async () => {
    //     try {
    //         setLoading(true);

    //         let res = await axios.get(`https://api.betcare.live/GetBetHistory/${loginUserId}?betStatus=2`);

    //         let resData = await res.data;
    //         console.log('bets data in my bet compo : ', resData)
    //         setMyBetsData(resData?.data)
    //         return resData;
    //     } catch (error) {
    //         console.log('Error While fetching /GetBetHistory..', error)
    //     } finally {
    //         setLoading(false);
    //     }
    // }



    // setBetType(clickedOption)
    console.log('clickedOption : ', clickedOption)

    const handleBetType = async (pageOption) => {

        if (pageOption === undefined || pageOption == '') {
            setBetType(clickedOption)
        } else {
            setBetType(pageOption)
        }
    }

    // useEffect(() => {
    //     fetchBetData();
    // }, [betStatus, betType])

    useEffect(() => {
        handleBetType()
    }, [clickedOption])



    return (
        <>
            {loading && <Loader styling={`absolute w-fit right-[40%] top-[50%]`} />}
            <h1 className='text-sm font-bold m-2'>My Bets</h1>

            <ul className='flex cursor-pointer bg-white rounded border border-[#3b5160] divide-x divide-[#3b5160] w-fit' >
                <li onClick={() => { handleBetType("current") }} className={`px-5 ${betType == "current" ? "bg-[#3b5160] text-white" : ""} text-[#3b5160] font-bold text-[12px] py-2`} >Current Bets</li>
                <li onClick={() => { handleBetType("history") }} className={`px-5 ${betType == "history" ? "bg-[#3b5160] text-white" : ""} text-[#3b5160] font-bold text-[12px] py-2`} >Bet History</li>
                <li onClick={() => { handleBetType("profitAndLoss") }} className={`px-5 ${betType == "profitAndLoss" ? "bg-[#3b5160] text-white" : ""} text-[#3b5160] font-bold text-[12px] py-2`} >Profit&Loss</li>
            </ul>

            <ul className='flex mt-5 cursor-pointer w-[98vw] lg:w-fit gap-1' >
                <li onClick={() => { setBetType2("exchange") }} className={`py-1 px-2 ${betType2 === "exchange" ? "bg-[#3b5160] text-white" : ""} rounded-t border border-b-0 border-[#3b5160] text-[#3b5160] text-[12px] font-bold`} >Exchange</li>
                <li onClick={() => { setBetType2("fancyBet") }} className={`py-1 px-2 ${betType2 === "fancyBet" ? "bg-[#3b5160] text-white" : ""} rounded-t border border-b-0 border-[#3b5160] text-[#3b5160] text-[12px] font-bold`} >FancyBet</li>
                <li onClick={() => { setBetType2("casino") }} className={`py-1 px-2 ${betType2 === "casino" ? "bg-[#3b5160] text-white" : ""} rounded-t border border-b-0 border-[#3b5160] text-[#3b5160] text-[12px] font-bold`} >Casino</li>
            </ul>

            {betType === "history" && <BetHistoryTable betType2={betType2} />}


            {betType === "profitAndLoss" && <ProfitAndLossTable betType2={betType2} />}

            {betType === "current" && <div className='border-t-4  w-[100vw] lg:w-[75vw] bg-[#e0e6e6]  border-[#3b5160] border-b border-b-[#7e97a7]' >

                {/* <div className='flex my-2 pl-3  text-[12px] gap-4'>
                    <p>Bet Status:</p>
                    <select className='border border-black' name="" id="" value={betStatus} onChange={(event) => {
                        setBetStatus(parseInt(event.target.value))
                    }}>
                        <option value={2}>All</option>
                        <option disabled value={1}>Matched</option>
                        <option disabled value={2}>UnMatched</option>
                    </select>
                </div> */}
                {/* <h1 className='bg-[#3b5160] text-white py-1 px-2 border-b border-[#7e97a7] font-bold text-[12px]'>All</h1> */}
                <section className='w-[100vw] lg:w-[100%] overflow-auto'>
                    <table className='mt-5 table text-[12px] border-b border-[#7e97a7] lg:w-[75vw]'>
                        <thead>
                            <tr className='border-y  font-bold bg-[#e4e4e4] border-[#7e97a7]' >
                                <th className='py-3 px-[10px]' >Market</th>
                                <th className='w-[30%] py-3 px-[10px]' >Selection</th>
                                <th className='py-3 px-[10px]' >Type</th>
                                <th className='py-3 px-[10px]' >Bet ID</th>
                                <th className='py-3 px-[10px]' >Bet placed</th>
                                <th className='py-3 px-[10px]' >Odds req.</th>
                                <th className='py-3 px-[10px]' >Stack</th>
                                <th className='py-3 px-[10px]' >Date matched</th>
                                <th className='py-3 px-[10px]' >Profit/Loss</th>
                            </tr>
                        </thead>

                        {/* {loading && <Loader />} */}
                        {betHistoryData?.filter((bet) => {

                            if (betType2 === "exchange") {
                                return (bet.market === "Match Odds" || bet.market === "Matchodds" || bet.market === "BookMaker");

                            } else if (betType2 === "fancyBet") {
                                return bet.market === "Fancy";
                            }
                            else {
                                return bet.market === "Casino";
                            }


                        })?.sort((a, b) => new Date(b.placeTime) - new Date(a.placeTime))?.map((bet, index) => {

                            let betid = bet?.betId;
                            let trimBetId = betid?.substring(0, 13);
                            let resultAmount = bet.resultAmount;
                            if (bet.isSettlement !== 1) { resultAmount = "--"; }




                            return <tr className='border-b bg-white border-black' key={bet.id}>
                                <td className='text-left py-3 px-1' style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>

                                    <span className="">{bet.eventName}</span>
                                    <span style={{ margin: '0 4px', fontSize: '10px' }}><IoIosPlay /></span>
                                    <span className="">{bet.market}</span>

                                    {/* {bet.market} */}
                                </td>
                                <td className='w-[30%] text-center'>{bet.selection}</td>
                                <td className='text-center'>{bet.type}</td>
                                <td className='text-center'>{trimBetId + "..."}</td>
                                <td className='text-center'>{moment(bet.placeTime).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td className='text-center'>{bet.oddsRequest}</td>
                                <td className='text-center'>{bet.amountStake}</td>
                                <td className='text-center'>{moment(bet.matchedTime).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td className='text-center'>{bet.resultAmount}</td>


                            </tr>
                        })}

                    </table>
                </section>
            </div>}

        </>
    )
}

export default MyBet
