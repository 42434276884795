import React, { useContext, useEffect, useState } from 'react'
import { BiSolidRightArrow } from "react-icons/bi";
import Tesseract from 'tesseract.js';
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import GlobalContext from '../../context/GlobalContext';




function WalletGateway() {


    let today1 = new Date();
    let todayM = moment(today1);
    let today = todayM.format(`YYYY-MM-DD`);

    let Tommorrow1 = new Date();
    let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
    let TommorrowM = moment(Tommorrow12);
    let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

    let Yesterday1 = new Date();
    let Yesterday12 = Yesterday1.setDate(Yesterday1.getDate() - 1);
    let YesterdayM = moment(Yesterday12);
    let Yesterday = YesterdayM.format(`YYYY-MM-DD`);


    const context = useContext(GlobalContext);
    const { loginBalanceContext, setReFetchLoginBalance } = context;

    // console.log('loginBalanceContext : ', loginBalanceContext)

    const [isWalletHome, setIsWalletHome] = useState(true);
    const [isDeposit, setIsDeposit] = useState(false);
    const [isWithdraw, setIsWthdraw] = useState(false);
    const [isWithdrawBankways, setIsWithdrawBankways] = useState(false);
    const [addBankPage, setAddBankPage] = useState(false);
    const [isWalletHistory, setIsWalletHistory] = useState(false);


    const [editStakeOpen, setEditStakeOpen] = useState(false);
    const [stakeBtn1, setStakeBtn1] = useState('');
    const [stakeBtn2, setStakeBtn2] = useState('');
    const [stakeBtn3, setStakeBtn3] = useState('');
    const [stakeBtn4, setStakeBtn4] = useState('');

    const [editStakeBtn1, seteditStakeBtn1] = useState('');
    const [editStakeBtn2, seteditStakeBtn2] = useState('');
    const [editStakeBtn3, seteditStakeBtn3] = useState('');
    const [editStakeBtn4, seteditStakeBtn4] = useState('');


    const [walletPaySlipOpen, setWalletPaySlipOpen] = useState(false);
    const [walletPaySlipData, setWalletPaySlipData] = useState({ name: '', requestDate: '', StatusChangeDate: '', amount: '', bank: '', paymentSubType: '', requestType: '', UTRNo: '', Status: '', Comment: '' })


    const [isOpen, setIsOpen] = useState(false);
    const [depositAmountBtn, setDepositAmountBtn] = useState('');
    const [withdrawAmountBtn, setWithdrawAmountBtn] = useState('');
    const [isDepositBank, setIsDepositBank] = useState(true);
    const [isQROpen, setIsQROpen] = useState(false);
    const [qrImage, setQrImage] = useState('');
    // const [inputPaymentSlip, setInputPaymentSlip] = useState('No file chosen')
    const [isRule, setIsRule] = useState(true);
    const [langSelect, setLangSelect] = useState('bang');
    const [UTRinput, setUTRinput] = useState('');
    const [inputPaymentSlip, setInputPaymentSlip] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const [utrNumber, setUtrNumber] = useState('');
    const [utrNote, setUtrNote] = useState('');
    const [loading, setLoading] = useState(false);


    const [errorDepositPop, setErrorDepositPop] = useState('');
    const [errorWithdrawAmount, setErrorWithdrawAmount] = useState('');
    const [errorImage, seterrorImage] = useState('');
    const [errorUtR, seterrorUTR] = useState('');

    const [withdrawPaymentMethod, setWithdrawPaymentMethod] = useState('');
    const [withdrawPaymentSubMethod, setWithdrawPaymentSubMethod] = useState('');
    const [withdrawBankImgSrc, setWithdrawBankImgSrc] = useState('');
    const [withdrawAccountHolder, setWithdrawAccountHolder] = useState('');
    const [withdrawAccountNumber, setWithdrawAccountNumber] = useState('');
    const [withdrawIfsc, setWithdrawIfsc] = useState('');
    const [withdrawPhone, setWithdrawPhone] = useState('');
    const [withdrawUpiId, setWithdrawUpiId] = useState('');


    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [checkedBox, setCheckedBox] = useState({
        all: false,
        approved: false,
        rejected: false,
        pending: false,
    });
    const [fromDate, setFromDate] = useState(Yesterday);
    const [toDate, setToDate] = useState(today)
    const [walletHistoryData, setWalletHistoryData] = useState([]);
    const [totalDepositAmount, setTotalDepositAmount] = useState(0);
    const [totalWithdrawAmount, setTotalWithdrawAmount] = useState(0);
    const [totalPL, setTotalPL] = useState(0);
    const [savedBankData, setSavedBankData] = useState([]);


    const [selectSavedBank, setSelectSavedBank] = useState('');
    const [SavedBankDelete, setSavedBankDelete] = useState('');

    const [fetchedMerchantDetail, setFetchedMerchantDetail] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);



    let userId2 = Cookies.get("LoginUserDetail");
    let userId = userId2 === undefined ? "" : JSON.parse(userId2);

    console.log('wallet cookie : ', userId);


    // Handle individual checkbox change
    const handleCheckboxChange = (name) => {
        const updatedState = {
            ...checkedBox,
            [name]: !checkedBox[name], // Toggle the checked state of the clicked checkbox
        };

        // If all individual checkboxes are checked, check "All"
        if (updatedState.approved && updatedState.rejected && updatedState.pending) {
            updatedState.all = true;
        } else {
            updatedState.all = false; // Otherwise, "All" is unchecked
        }

        setCheckedBox(updatedState);
    };

    // Handle "All" checkbox change
    const handleAllChange = () => {
        const newState = {
            all: !checkedBox.all, // Toggle the "All" checkbox
            approved: !checkedBox.all,
            rejected: !checkedBox.all,
            pending: !checkedBox.all,
        };
        setCheckedBox(newState);
    };




    const handleDepositPopUpNext = () => {
        if (!depositAmountBtn) {
            setErrorDepositPop('Please enter deposit amount.');
            return;
        }
        if (depositAmountBtn < 100) {
            setErrorDepositPop('Minimum deposit amount must be at least 100');
            return;
        }
        setIsWalletHome(false);
        setIsOpen(false);
        setIsDeposit(true);
        setIsWthdraw(false);
        setIsDepositBank(true);
        setIsRule(true);
    };

    const handleWithdrawNext = () => {
        if (!withdrawAmountBtn) {
            setErrorWithdrawAmount('Please enter withdraw amount.');
            return;
        };

        if (withdrawAmountBtn < 100) {
            setErrorWithdrawAmount('Minimum withdraw amount must be at least 100');
            return;
        };

        if (withdrawAmountBtn > loginBalanceContext) {
            setErrorWithdrawAmount('Insufficient Funds!');
            return;
        }

        setErrorWithdrawAmount('');
        setIsWalletHome(false);
        setIsOpen(false);
        setIsDeposit(false);
        setIsWthdraw(false);
        setIsWalletHistory(false);
        setIsWithdrawBankways(true);
    };

    const copyToClipboard = (id) => {
        const textToCopy = document.getElementById(id).innerText;
        navigator.clipboard.writeText(textToCopy).then(() => {
            console.log(id, ' copied to clipboard!')
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    // Function to extract UTR from the image
    const extractUtrFromImage = (file) => {
        setLoading(true);
        Tesseract.recognize(
            file,
            'eng',
            {
                logger: (m) => console.log(m), // Logging OCR progress
            }
        ).then(({ data: { text } }) => {
            setLoading(false);
            console.log('Full extracted text from the image : ', text); // Full extracted text from the image
            // Find UTR pattern from the text
            const utrMatch = text.match(/UTR\s*[:\-]?\s*(\d{8,16})/i); // Adjust regex pattern as per your UTR format
            if (utrMatch) {
                setUtrNumber(utrMatch[0]);
                console.log('utrMatch : ', utrMatch[0], 'number is : ', utrMatch[1]);
                setUtrNote('');
                setUTRinput(utrMatch[1]);
                seterrorUTR('')
            } else {
                setUTRinput('');
                seterrorUTR('')
                setUtrNumber('Transaction Id not found');
                setUtrNote("We didn't find any Transaction Id from uploaded screenshot by you, ")

                console.log('UTR number not found')
            }
        }).catch((err) => {
            console.error(err);
            setLoading(false);
            setUtrNumber('Error extracting UTR');
        });
    };


    const handleDepositRequest = async () => {

        if (!imagePreview) {
            seterrorImage('Please upload payment slip!');
            return;
        }
        if (!UTRinput) {
            seterrorUTR('Please enter UTR Number!');
            return;
        }


        // const depositObj = {
        //     userId: userId?.Id,
        //     userName: userId?.UserName,
        //     depositAmount: depositAmountBtn,
        //     paymentMethod: fetchedMerchantDetail[selectedPaymentMethod].PaymentType,
        //     paymentSlip: inputPaymentSlip?.name,
        //     UTR: UTRinput
        // }
        // console.log('inputPaymentSlip : ', inputPaymentSlip)

        // console.log('deposit data : ', depositObj)




        const formData = new FormData();
        formData.append('userId', userId?.Id);
        formData.append('userName', userId.UserName);
        formData.append('image', inputPaymentSlip);
        formData.append('depositAmount', depositAmountBtn);
        formData.append('paymentMethod', fetchedMerchantDetail[selectedPaymentMethod].PaymentType);
        formData.append('DisplayName', fetchedMerchantDetail[selectedPaymentMethod].DisplayName);
        formData.append('AccountNumber', fetchedMerchantDetail[selectedPaymentMethod].AccountNumber);
        formData.append('PaymentSubType', fetchedMerchantDetail[selectedPaymentMethod].PaymentSubType);
        formData.append('bank', fetchedMerchantDetail[selectedPaymentMethod].PaymentType);
        formData.append('IFSC', '');
        formData.append('UPIID', '');
        formData.append('UTR', UTRinput);


        console.log('form data : ', formData);
        // if (isDepositBank) {
        //     formData.append('bank', 'AXIS Bank');
        //     formData.append('AccountNumber', '924020016159014');
        //     formData.append('IFSC', 'UTIB0002887');
        //     formData.append('UPIID', '');
        //     formData.append('UTR', '');
        // } else {
        //     formData.append('bank', '');
        //     formData.append('AccountNumber', '');
        //     formData.append('IFSC', '');
        //     formData.append('UPIID', 'merchant@upi');
        //     formData.append('UTR', UTRinput);
        // }


        try {
            setLoading(true);
            const response = await fetch('https://api.betcare.live/api/selfUser/depositRequest', {
                method: 'POST',
                body: formData,
            });

            console.log('response : ', response);

            if (response.status === 200) {
                toast.success('Request Sent!', {
                    autoClose: 1500,
                    theme: "colored",
                });
                console.log('Upload sucess >..');
                setDepositAmountBtn('');
                setIsDepositBank(true);
                setSelectedPaymentMethod(0);
                setInputPaymentSlip(null);
                setImagePreview(null);
                setUtrNote('');
                setUTRinput('');
                setQrImage('');
                setIsWalletHome(false);
                setIsOpen(false);
                setIsDeposit(false);
                setIsWthdraw(false);
                setIsWalletHistory(true);
            } else {
                toast.error('Request Failed!', {
                    autoClose: 1500,
                    theme: "colored",
                });
                throw new Error('Failed to upload image');
            }

        } catch (error) {
            console.error('Error during file upload:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSaveBankDetail = async () => {

        console.log(userId.Id, withdrawAmountBtn, withdrawPaymentMethod, withdrawBankImgSrc, withdrawAccountNumber, withdrawPhone);

        if (!withdrawAccountNumber || withdrawPhone == '') {
            return toast.error('Please fill all input fields!', {
                autoClose: 1500,
                theme: "colored"
            });
        }

        if (!userId.Id || !withdrawAmountBtn || !withdrawPaymentMethod || !withdrawPaymentSubMethod) {
            return toast.error('Request Failed!', {
                autoClose: 1500,
                theme: "colored",
            });
        }

        const bodyData = {
            userId: userId?.Id, withdrawAmountBtn, withdrawPaymentMethod, withdrawBankImgSrc, withdrawAccountHolder: 'no-name', withdrawAccountNumber, withdrawPaymentSubMethod, withdrawIfsc, withdrawUpiId, withdrawPhone
        }
        console.log('bodyData : ', bodyData);

        try {
            const response = await fetch('https://api.betcare.live/api/selfUser/saveBankDetail', {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            });

            console.log('response : ', response);

            if (response.status === 200) {
                toast.success('Request Sent!', {
                    autoClose: 1500,
                    theme: "colored",
                });
                console.log('Upload sucess >..');
                setAddBankPage(false);
                setIsWithdrawBankways(true);
                setWithdrawPaymentMethod('');
                setWithdrawBankImgSrc('');
                // setWithdrawAccountHolder('');
                setWithdrawAccountNumber('');
                setWithdrawIfsc('');
                setWithdrawPhone('');
                setWithdrawUpiId('');
            } else {
                toast.error('Request Failed!', {
                    autoClose: 1500,
                    theme: "colored",
                });
                throw new Error('Failed to upload Bank details');
            }

        } catch (error) {
            console.error('Error during upload Bank details :', error);
        }
    }


    const fetchWalletHistory = async () => {
        try {

            let statusArray = [];

            if (checkedBox.approved) statusArray.push(1);
            if (checkedBox.rejected) statusArray.push(3);
            if (checkedBox.pending) statusArray.push(2);

            // You can now use statusArray to send these values in your API request
            console.log(statusArray);


            console.log('fromDate, toDate, status : ', fromDate, toDate, statusArray)

            const res = await fetch(`https://api.betcare.live/api/selfUSer/fetchDepositRequest/${userId.Id}?fromDate=${fromDate} 00:00:01&toDate=${toDate} 23:59:59&status=${statusArray}`);
            const resData = await res.json();

            if (res.status === 200) {
                console.log('fetchWalletHistory data : ', resData);
                setWalletHistoryData(resData.data);
                setTotalDepositAmount(resData.totalDepositAmount);
                setTotalWithdrawAmount(resData.totalWithdrawAmount);
                setTotalPL(resData.totalPL);
                setIsFilterOpen(false);
            } else {
                throw new Error('Error while fetching wallet history data!');
            }
        } catch (error) {
            console.log('Error in fetchWalletHistory : ', error);
        }
    };


    const fetchSavedBankDetails = async () => {
        try {
            const res = await fetch(`https://api.betcare.live/api/selfUser/fetchSavedBKDetails/${userId.Id}`);

            const resData = await res.json();

            if (res.status === 200) {
                console.log(resData);
                setSavedBankData(resData?.data);
                if (resData.data.length > 0) {
                    setSelectSavedBank(`0savedBank`);
                }
            }
        } catch (error) {
            console.error('Error while fetching saved bank details : ', error);
        }
    }

    const handleBankRemove = async (id, user) => {
        console.log('id:', id, 'userId:', user);

        if (!id || !user) {
            toast.error('Missing required parameters!', {
                autoClose: 1500,
                theme: "colored",
            });
            return;
        }

        // Show confirmation dialog using Swal.fire
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`https://api.betcare.live/api/selfUser/deleteSavedBank`, {
                        method: 'DELETE',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            id,
                            user
                        }),
                    });

                    console.log('response:', response);

                    if (response.ok) {
                        // Show success message using SweetAlert
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your bank details have been deleted.",
                            icon: "success",
                            timer: 1500,
                            showConfirmButton: false
                        });
                        fetchSavedBankDetails();
                    } else {
                        const errorMsg = await response.json();
                        toast.error(`Delete Failed: ${errorMsg.message}`, {
                            autoClose: 1500,
                            theme: "colored",
                        });
                        throw new Error(errorMsg.message || 'Failed to delete saved bank details');
                    }

                } catch (error) {
                    console.error('Error during deleting saved bank details:', error);
                    toast.error(`Error: ${error.message}`, {
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }
        });
    };

    const handleWithdrawAmount = async (withdrawAmountBtn, paymentMethod, PaymentSubType, accountHName, accountNumber, IFSC, UPIID, phone) => {

        console.log('UserId, withdrawAmountBtn, paymentMethod, accountHName, accountNumber, IFSC, UPIID : ', userId.Id, withdrawAmountBtn, paymentMethod, accountHName, accountNumber, IFSC, UPIID);

        if (!userId.Id || !withdrawAmountBtn || !paymentMethod || !PaymentSubType || !phone) {
            return toast.error('Request Failed!', {
                autoClose: 1500,
                theme: "colored",
            });
        }

        if (paymentMethod !== 'BharatPe' && paymentMethod !== 'GooglePay' && paymentMethod !== 'PhonePe' && paymentMethod !== 'Paytm') {
            if (!accountNumber && !IFSC && !accountHName) {
                return toast.error('Please fill all input fields!', {
                    autoClose: 1500,
                    theme: "colored",
                    position: 'bottom-center'
                });
            }
        } else {
            if (!UPIID && !accountHName) {
                return toast.error('Please fill all input fields!', {
                    autoClose: 1500,
                    theme: "colored",
                    position: 'bottom-center'
                });
            }
        };


        // Show confirmation dialog using Swal.fire
        Swal.fire({
            title: "Confirmation",
            text: `Are you sure want to withdraw ${withdrawAmountBtn} amount in ${paymentMethod} account ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#716add",
            cancelButtonColor: "#6e7881",
            confirmButtonText: "Yes"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true);
                    const response = await fetch(`https://api.betcare.live/api/selfUser/withdrawRequest`, {
                        method: 'POST',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            userId: userId.Id,
                            withdrawAmountBtn,
                            paymentMethod,
                            PaymentSubType,
                            accountHName,
                            accountNumber,
                            IFSC,
                            UPIID,
                            phone
                        }),
                    });

                    console.log('response:', response);

                    if (response.ok) {
                        toast.success('Request Sent!', {
                            autoClose: 1500,
                            theme: "colored",
                        });
                        setReFetchLoginBalance(true);
                        setIsWalletHome(false);
                        setIsOpen(false);
                        setIsDeposit(false);
                        setWithdrawAmountBtn('');
                        setIsWthdraw(true);
                        setIsWalletHistory(false);
                        setIsWithdrawBankways(false);
                    } else {
                        const errorMsg = await response.json();
                        toast.error(`Request Failed: ${errorMsg.message}`, {
                            autoClose: 1500,
                            theme: "colored",
                        });
                        throw new Error(errorMsg.message || 'Failed to send withdraw request');
                    }

                } catch (error) {
                    console.error('Error during  withdraw request:', error);
                    toast.error(`Error: ${error.message}`, {
                        autoClose: 1500,
                        theme: "colored",
                    });
                } finally {
                    setLoading(false);
                }
            }
        });

    }

    const fetchMerchantBankDetails = async () => {
        try {
            const res = await fetch(`https://api.betcare.live/api/selfUser/fetchActiveMerchantDetails`);

            const resData = await res.json();
            console.log('merchant detail response : ', resData);
            if (res.status === 200) {
                console.log(resData);
                setFetchedMerchantDetail(resData?.data);
            }
        } catch (error) {
            console.error('Error while fetching saved bank details : ', error);
        }
    }


    const fetchEditStakeButton = async () => {
        try {
            setLoading(true);
            const res = await fetch(`https://api.betcare.live/api/selfUser/fetchStakeButton?userId=${encodeURIComponent(userId?.Id)}`);
            const resData = await res.json();
            if (res.status === 200) {
                if (resData?.data.length > 0) {
                    setStakeBtn1(resData?.data[0]?.btn1);
                    setStakeBtn2(resData?.data[0]?.btn2);
                    setStakeBtn3(resData?.data[0]?.btn3);
                    setStakeBtn4(resData?.data[0]?.btn4);

                    seteditStakeBtn1(resData?.data[0]?.btn1);
                    seteditStakeBtn2(resData?.data[0]?.btn2);
                    seteditStakeBtn3(resData?.data[0]?.btn3);
                    seteditStakeBtn4(resData?.data[0]?.btn4);

                }
            }
        } catch (error) {
            console.error('Error While fetching stake button : ', error);
        } finally {
            setLoading(false);
        }

    }

    const changeStakeButton = async () => {

        if (editStakeBtn1 == stakeBtn1 && editStakeBtn2 == stakeBtn2 && editStakeBtn3 == stakeBtn3 && editStakeBtn4 == stakeBtn4) {
            console.log('Nothing changed!');
            setEditStakeOpen(false);
            setIsWalletHome(true);
            setDepositAmountBtn('');
            setErrorDepositPop('')
            setErrorWithdrawAmount('');
            return;
        }

        if (editStakeBtn1 == '' || editStakeBtn2 == '' || editStakeBtn3 == '' || editStakeBtn4 == '') {
            toast.error('Please fill all stake Amount!', {
                autoClose: 1500,
                theme: "colored",
            });
            return;
        }


        try {
            setLoading(true);
            const res = await fetch('https://api.betcare.live/api/selfUser/changeStakeButton', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: userId?.Id,
                    btn1: editStakeBtn1,
                    btn2: editStakeBtn2,
                    btn3: editStakeBtn3,
                    btn4: editStakeBtn4
                })
            });
            // const resData = await res.json();
            if (res.status === 200 || res.status === 201) {
                fetchEditStakeButton();
                toast.success('Stake Changed!', {
                    autoClose: 1500,
                    theme: "colored",
                });
                setEditStakeOpen(false);
                setIsWalletHome(true);
                setDepositAmountBtn('');
                setErrorDepositPop('')
                setErrorWithdrawAmount('');
            }
            else {
                toast.error('Try again later!', {
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        } catch (error) {
            console.error('Error While fetching stake button : ', error);
        } finally {
            setLoading(false);
        }

    }



    useEffect(() => {
        if (isWalletHistory) {
            fetchWalletHistory();
        };
        if (isWithdrawBankways) {
            fetchSavedBankDetails();
        };

    }, [isWalletHistory, isWithdrawBankways]);

    useEffect(() => {
        if (isDeposit) {
            fetchMerchantBankDetails();
        }
    }, [isDeposit])

    useEffect(() => {
        fetchEditStakeButton();
    }, [])



    return (
        <div className='overflow-auto no-scrollbar'>
            {loading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}

            <div className=''>
                {isWalletHome && <div className='flex flex-col' style={{ marginLeft: '1rem' }}>
                    <div
                        className='custom-container flex justify-center'
                        style={{ marginTop: '6rem' }}
                    >
                        <div className='deposit-list'
                            onClick={() => { setIsOpen(true); setIsDeposit(false); setIsWthdraw(false); setIsWalletHistory(false); }}
                        >
                            <div>
                                <img src="/images/wallet-deposit-icon.svg" alt="" srcset="" />
                            </div>
                            <div>
                                Wallet Deposit
                            </div>
                        </div>
                        <div className='withdraw-list'
                            onClick={() => { setIsWalletHome(false); setIsOpen(false); setIsDeposit(false); setIsWthdraw(true); setIsWalletHistory(false); }}
                        >
                            <div>
                                <img src="/images/wallet-withdraw-icon.svg" alt="" srcset="" />
                            </div>
                            <div>
                                Wallet Withdraw
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center mt-5 cursor-pointer'>
                        <div className='wallet-history-container' onClick={() => { setIsWalletHome(false); setIsOpen(false); setIsDeposit(false); setIsWthdraw(false); setIsWalletHistory(true); }}>
                            <div className='wallet-history'>
                                <div className='flex'>
                                    <img src="/images/wallet-history.svg" alt="Wallet History Icon" />
                                    <span className='ml-3'>Wallet History</span>
                                </div>
                                <div style={{ color: '#2290C7' }}>
                                    <BiSolidRightArrow />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {isDeposit && <>
                    <div className='flex justify-center mb-10 overflow-auto no-scrollbar scroll-smooth'>
                        <div className='isDeposit-div p-1'>
                            <div className='flex justify-between items-center p-2' style={{
                                borderBottom: '1px solid gray'
                            }}>
                                <span
                                    className='text-sm font-bold cursor-pointer'
                                    style={{ color: '#0d6efd' }}
                                    onClick={() => { setIsWalletHome(true); setIsOpen(false); setIsDeposit(false); setIsWthdraw(false); setIsWalletHistory(false); setIsDepositBank(true); setDepositAmountBtn(''); setErrorDepositPop(''); }}
                                >
                                    Home
                                </span>
                                <img src="/images/cross-mark.svg" alt="" srcset="" />
                            </div>

                            <div className='mt-4 bg-white border border-gray-200 rounded p-2'>
                                <span className='font-bold' style={{ fontSize: '.9rem' }}>Deposit Stake</span>
                                <div className='border border-gray-400 rounded p-1' style={{ fontSize: '.9rem' }}>{depositAmountBtn}</div>
                            </div>
                            <div className='flex justify-between mt-4'>
                                <h2 className='font-bold'>Choose a payment method</h2>
                                <button className='border border-gray-700' style={{ border: '1.5px solid #2290C7', borderRadius: '4px', padding: '6px 10px', fontSize: '.9rem', color: '#2290C7', lineHeight: 'normal' }} onClick={() => { setIsWalletHome(false); setIsOpen(false); setIsDeposit(false); setIsWthdraw(true); setIsWalletHistory(false); setIsDepositBank(true); }}>
                                    Withdraw
                                </button>
                            </div>
                            <div className='flex mt-4'>

                                {fetchedMerchantDetail?.length > 0 && fetchedMerchantDetail?.map((merc, index) => (

                                    <div
                                        key={merc.id}
                                        className='flex flex-col justify-center items-center cursor-pointer mr-2 w-full'
                                        style={{ border: selectedPaymentMethod == index ? '1.5px solid #2290C7' : '', borderRadius: '4px', padding: '8px', fontSize: '.9rem', color: '#2290C7', lineHeight: 'normal', backgroundColor: 'white' }}
                                        onClick={() => setSelectedPaymentMethod(index)}
                                    >

                                        <img src={merc.PaymentType == 'Rocket' ? "/images/banglaRocketLogo.svg" : merc.PaymentType == 'Bkash' ? "/images/BKashLogo.png" : merc.PaymentType == 'Nagad' ? "/images/NagadLogo.png" : ''} alt="" srcset="" style={{ width: '3rem', marginRight: '5px' }} />
                                        <span className='font-bold text-black'>
                                            {merc.PaymentType}
                                        </span>
                                        <span className='font-bold text-black' style={{ fontSize: '.7rem' }}> ({merc.PaymentSubType})</span>
                                    </div>
                                ))}
                                {/* <div className='flex justify-center items-center cursor-pointer' style={{ border: !isDepositBank ? '1.5px solid #2290C7' : '', borderRadius: '4px', padding: '8px', fontSize: '.9rem', color: '#2290C7', lineHeight: 'normal', backgroundColor: 'white', marginLeft: '1rem' }} onClick={() => setIsDepositBank(false)}>

                                    <img src="/images/UPI-Logo-vector.svg" alt="" srcset="" style={{ height: '30px', width: '50px', marginRight: '10px' }} />
                                    <span className='font-bold text-black' style={{ paddingRight: '4rem' }}>
                                        UPI
                                    </span>
                                </div> */}
                            </div>
                            <div className='flex justify-between mt-4'>
                                {/* <h2 className='font-bold'>You can add through {isDepositBank ? `a bank transfer` : `UPI`}</h2> */}
                                <h2 className='font-bold'>You can add through a bank transfer</h2>
                                <button className='border border-gray-700' onClick={() => { setIsRule(true); }} >
                                    <img src="/images/document-details.svg" alt="" srcset="" />
                                </button>
                            </div>

                            <div className='flex justify-between mt-4' style={{ fontSize: '.9rem' }}>
                                {/* <div className='bg-white rounded border border-black w-full p-2'>
                                    <div className='flex '>
                                        <span>Scan QR Code</span>
                                        <div className='cursor-pointer' style={{ width: '150px', height: '150px', transform: 'translate(-50%,0%)', marginLeft: '35%', backgroundColor: '#D9D9D9', borderRadius: '6px', }} onClick={() => { setIsQROpen(true); setQrImage("/images/qrCode.jpeg") }}>
                                            <img src="/images/qrCode.jpeg" alt="" srcset="" style={{ width: '100%', height: '100%', padding: '10px', objectFit: 'cover' }} />
                                        </div>
                                    </div>
                                    {isDepositBank && <>
                                        <div className='flex justify-between mb-2'>
                                            <span>Account : <b id='account-number'>924020016159014</b></span>
                                            <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('account-number')}>
                                                <img src="/images/copy-icon-list.svg" alt="" srcset="" />
                                            </div>
                                        </div>
                                        <div className='flex justify-between mb-2'>
                                            <span>Bank : <b id='bank'>AXIS Bank</b></span>
                                            <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('bank')} >
                                                <img src="/images/copy-icon-list.svg" alt="" srcset="" />
                                            </div>
                                        </div>
                                        <div className='flex justify-between mb-2'>
                                            <span>IFSC Code : <b id='ifsc'>UTIB0002887</b></span>
                                            <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('ifsc')} >
                                                <img src="/images/copy-icon-list.svg" alt="" srcset="" />
                                            </div>
                                        </div>
                                    </>}
                                    <div className='flex justify-between mb-2'>
                                        <span>Display Name : <b id='name'>MITU SAHU</b></span>
                                        <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('name')} >
                                            <img src="/images/copy-icon-list.svg" alt="" srcset="" />
                                        </div>
                                    </div>
                                    <div className='flex justify-between mb-2'>
                                        <span>Phone Number : <b id='phone'>0000000000</b></span>
                                        <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('phone')} >
                                            <img src="/images/copy-icon-list.svg" alt="" srcset="" />
                                        </div>
                                    </div>
                                    {!isDepositBank && <div className='flex justify-between mb-2'>
                                        <span>UPI Id : <b id='upi'>merchant@upi</b></span>
                                        <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('upi')} >
                                            <img src="/images/copy-icon-list.svg" alt="" srcset="" />
                                        </div>
                                    </div>}
                                </div> */}

                                <div className='bg-white rounded border border-black w-full p-2'>
                                    {fetchedMerchantDetail?.length > 0 && fetchedMerchantDetail[selectedPaymentMethod] && (
                                        <>
                                            <div className='flex'>
                                                <span>Scan QR Code</span>
                                                <div
                                                    className='cursor-pointer'
                                                    style={{
                                                        width: '150px',
                                                        height: '150px',
                                                        transform: 'translate(-50%,0%)',
                                                        marginLeft: '35%',
                                                        backgroundColor: '#D9D9D9',
                                                        borderRadius: '6px'
                                                    }}
                                                    onClick={() => {
                                                        setIsQROpen(true);
                                                        setQrImage(`https://ag.betcare.live/bankImage/${fetchedMerchantDetail[selectedPaymentMethod].QRCode}`);
                                                    }}
                                                >
                                                    <img
                                                        src={`https://ag.betcare.live/bankImage/${fetchedMerchantDetail[selectedPaymentMethod].QRCode}`}
                                                        alt=""
                                                        style={{ width: '100%', height: '100%', padding: '10px', objectFit: 'cover' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='flex justify-between mb-2'>
                                                <span>{fetchedMerchantDetail[selectedPaymentMethod].PaymentType} {fetchedMerchantDetail[selectedPaymentMethod].PaymentSubType} Number : <b id='account-number'>{fetchedMerchantDetail[selectedPaymentMethod].AccountNumber}</b></span>
                                                <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('account-number')}>
                                                    <img src="/images/copy-icon-list.svg" alt="" />
                                                </div>
                                            </div>

                                            {/* <div className='flex justify-between mb-2'>
                                                <span>{fetchedMerchantDetail[selectedPaymentMethod].PaymentType} Personal Number : <b id='phone'>{fetchedMerchantDetail[selectedPaymentMethod].PersonalNumber}</b></span>
                                                <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('phone')}>
                                                    <img src="/images/copy-icon-list.svg" alt="" />
                                                </div>
                                            </div> */}

                                            <div className='flex justify-between mb-2'>
                                                <span>Payment Type : <b id='bank'>{fetchedMerchantDetail[selectedPaymentMethod].PaymentType}</b></span>
                                                <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('bank')}>
                                                    <img src="/images/copy-icon-list.svg" alt="" />
                                                </div>
                                            </div>
                                            {/* <div className='flex justify-between mb-2'>
                                                <span>Account Type : <b id='bank'>{fetchedMerchantDetail[selectedPaymentMethod].PaymentSubType}</b></span>
                                                <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('bank')}>
                                                    <img src="/images/copy-icon-list.svg" alt="" />
                                                </div>
                                            </div> */}

                                            <div className='flex justify-between mb-2'>
                                                <span>Display Name : <b id='name'>{fetchedMerchantDetail[selectedPaymentMethod].DisplayName}</b></span>
                                                <div className='font-bold cursor-pointer' onClick={() => copyToClipboard('name')}>
                                                    <img src="/images/copy-icon-list.svg" alt="" />
                                                </div>
                                            </div>


                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='mt-4 bg-white border border-gray-200 rounded p-2'>
                                <h2 className='font-bold mb-2' style={{ fontSize: '1rem' }}>Upload your payment slip below</h2>
                                <p className='mb-2' style={{ fontSize: '14px', color: '#666666' }}>
                                    Take a screenshot of the payment success screen and<br /> upload it below.
                                </p>
                                {errorImage && (
                                    <span style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                        {errorImage}
                                    </span>
                                )}
                                <label htmlFor="file-upload" className='flex text-sm font-bold w-full border border-gray-400 rounded cursor-pointer'>
                                    <div className='flex p-2' style={{ backgroundColor: '#d1d5db' }}>
                                        <img src="/images/upload-img.svg" alt="" className='' />
                                        <span className='ml-3 text-[.8rem]'>Upload Screenshot</span>
                                    </div>
                                    <div className='flex p-2'>
                                        <input
                                            type="file"
                                            id="file-upload"
                                            accept="image/png, image/jpeg, image/jpg, image/heic"
                                            className='hidden'
                                            onChange={(e) => {
                                                seterrorImage('');
                                                const file = e.target.files[0];
                                                console.log('file uplaod : ', e.target.files)
                                                if (file) {
                                                    setInputPaymentSlip(file);
                                                    setImagePreview(URL.createObjectURL(file)); // Create a URL for the file
                                                    extractUtrFromImage(file); // Call function to extract UTR
                                                } else {
                                                    setInputPaymentSlip('No file chosen');
                                                    setImagePreview(null); // Reset the image preview
                                                }
                                            }}
                                        />

                                        <span className='ml-3 text-gray-600 text-sm'>
                                            {inputPaymentSlip?.name || 'No file chosen'}
                                        </span>
                                    </div>
                                </label>
                                {imagePreview && (
                                    <div className='flex justify-center items-center mt-4'>
                                        <div>
                                            <div className='flex justify-end cursor-pointer'
                                                style={{ zIndex: '999', position: 'relative', top: '12px', left: '5px' }}
                                                onClick={() => {
                                                    setInputPaymentSlip('No file chosen'); setImagePreview(null);
                                                    setUtrNote('');
                                                    setUTRinput('');
                                                }} >
                                                <div className='flex justify-center items-center'
                                                    style={{ width: '24px', height: '24px', backgroundColor: '#e9ecef', borderRadius: '100%' }}>
                                                    <img src="/images/utr-remove-icon.svg" alt="" style={{ width: '12px', height: '12px' }} />
                                                </div>
                                            </div>
                                            <img
                                                src={imagePreview}
                                                alt="Uploaded Preview"
                                                className='cursor-pointer'
                                                style={{ maxHeight: '200px', maxWidth: '100%' }}
                                                onClick={() => { setIsQROpen(true); setQrImage(imagePreview) }} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='mt-4 bg-white border border-gray-200 rounded p-2'>
                                <h2 className='font-bold mb-1' style={{ fontSize: '1rem' }}>Enter Transaction Id</h2>
                                <p className='mb-1' style={{ fontSize: '14px', color: '#666666' }}>{utrNote} Kindly enter Transaction Id manually for fast deposit.</p>
                                <div className=''>
                                    {errorUtR && (
                                        <span style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                            {errorUtR}
                                        </span>
                                    )}
                                </div>
                                <input type="text" placeholder='Enter Transaction Id' className='p-1 text-sm border border-gray-400 rounded cursor-pointer'
                                    value={UTRinput} onChange={(e) => { setUTRinput(e.target.value); seterrorUTR(''); }} />


                            </div>
                            <div className='mt-4 p-2'>
                                <button type='button' className='w-full border rounded cursor-pointer' style={{ backgroundColor: '#00143C', color: '#99ACCC', height: '46px' }} onClick={() => { console.log('imagePreview : ', imagePreview); handleDepositRequest(); }}>Submit Request</button>
                            </div>
                            <div className='mt-1 p-2'>
                                <button type='button' className='w-full border rounded cursor-pointer' style={{ backgroundColor: 'white', color: '#00143C ', height: '46px' }} onClick={() => { setIsWalletHome(false); setIsOpen(false); setIsDeposit(false); setIsWthdraw(true); setIsWalletHistory(false); setIsDepositBank(true); }}>Want to Withdraw</button>
                            </div>
                        </div>
                    </div>
                    {/* QR pop start */}
                    <div
                        id="qrImageModal"
                        tabindex="-1"
                        aria-labelledby="ChangeStatusLabel"
                        className="modal fade changepassword-model show"
                        data-backdrop="static"
                        data-keyboard="false"
                        role="dialog"
                        style={{ display: isQROpen ? "block" : "none" }}
                    >
                        <div className="modal-dialog" style={{ marginTop: '10rem' }}>
                            <div className="modal-content" style={{ backgroundColor: 'white', marginBottom: '1rem', width: '100%', }}>
                                <div className="modal-header flex justify-between p-2" style={{ backgroundColor: '#EEEEEF', padding: '0.8rem' }}>
                                    <h6 id="ChangeStatusLabel" className="modal-title">
                                        Preview
                                    </h6>
                                    <img src="/images/close-square.svg" alt="" srcset="" className='cursor-pointer' onClick={() => { setIsQROpen(false); }} />
                                </div>
                                <div className="modal-body change-status" style={{ marginBottom: '1rem' }}>
                                    <div className='flex'>
                                        <img src={qrImage} alt="" srcset="" style={{ width: '100%', height: '100%', padding: '10px', objectFit: 'cover' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Rule pop start */}
                    <div
                        id="RuleModal"
                        tabindex="-1"
                        aria-labelledby="ChangeStatusLabel"
                        className="modal fade changepassword-model show"
                        data-backdrop="static"
                        data-keyboard="false"
                        role="dialog"
                        style={{ display: isRule ? "block" : "none" }}
                    >
                        <div className="modal-dialog" style={{ marginTop: '10rem' }}>
                            <div className="modal-content" style={{ backgroundColor: 'white', marginBottom: '1rem', width: '100%', }}>
                                <div className="modal-header flex justify-between p-2" style={{ backgroundColor: '#EEEEEF', padding: '0.8rem' }}>
                                    <h6 id="ChangeStatusLabel" className="modal-title">
                                        Rule
                                    </h6>
                                    <img src="/images/close-square.svg" alt="" srcset="" className='cursor-pointer' onClick={() => { setIsRule(false); setLangSelect('bang'); }} />
                                </div>
                                <div className="modal-body change-status" style={{ marginBottom: '1rem' }}>
                                    <div className='flex justify-between text-sm' style={{
                                        borderBottom: '3px solid #EEEEEF',
                                        margin: '2px 10px'
                                    }}>

                                        <div
                                            id='bang'
                                            className='cursor-pointer'
                                            style={{
                                                padding: '.5rem 1rem',
                                                color: langSelect == 'bang' ? '#2290C7' : '',
                                                fontWeight: langSelect == 'bang' ? '600' : '',
                                                border: langSelect == 'bang' ? '3px solid #EEEEEF' : '',
                                                borderBottom: langSelect == 'bang' ? 'none' : ''
                                            }}
                                            onClick={() => { setLangSelect('bang') }}
                                        >
                                            বাংলা
                                        </div>
                                        <div
                                            id='eng'
                                            className='cursor-pointer'
                                            style={{
                                                padding: '.5rem 1rem',
                                                color: langSelect == 'eng' ? '#2290C7' : '',
                                                fontWeight: langSelect == 'eng' ? '600' : '',
                                                border: langSelect == 'eng' ? '3px solid #EEEEEF' : '',
                                                borderBottom: langSelect == 'eng' ? 'none' : ''
                                            }}
                                            onClick={() => { setLangSelect('eng') }}
                                        >
                                            English
                                        </div>
                                        <div
                                            id='hin'
                                            className='cursor-pointer'
                                            style={{
                                                padding: '.5rem 1rem',
                                                color: langSelect == 'hin' ? '#2290C7' : '',
                                                fontWeight: langSelect == 'hin' ? '600' : '',
                                                border: langSelect == 'hin' ? '3px solid #EEEEEF' : '',
                                                borderBottom: langSelect == 'hin' ? 'none' : ''
                                            }}
                                            onClick={() => { setLangSelect('hin') }}
                                        >
                                            हिंदी
                                        </div>
                                        {/* <div
                                            id='tel'
                                            className='cursor-pointer'
                                            style={{
                                                padding: '.5rem 1rem',
                                                color: langSelect == 'tel' ? '#2290C7' : '',
                                                fontWeight: langSelect == 'tel' ? '600' : '',
                                                border: langSelect == 'tel' ? '3px solid #EEEEEF' : '',
                                                borderBottom: langSelect == 'tel' ? 'none' : ''
                                            }}
                                            onClick={() => { setLangSelect('tel') }}
                                        >
                                            తెలుగు
                                        </div> */}
                                    </div>

                                    {langSelect == 'eng' && <div className='p-2'>
                                        <ul style={{ fontSize: '13px' }}>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        Deposit money only in the below available accounts to get the fastest
                                                        credits and avoid possible delays.
                                                    </span>
                                                </div>
                                            </li>{" "}
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        Deposits made 45 minutes after the account removal from the site are
                                                        valid &amp; will be added to their wallets.
                                                    </span>
                                                </div>
                                            </li>{" "}
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        Site is not responsible for money deposited to Old, Inactive or Closed
                                                        accounts.
                                                    </span>
                                                </div>
                                            </li>{" "}
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        After deposit, add your UTR and amount to receive balance.
                                                    </span>
                                                </div>
                                            </li>{" "}
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        NEFT receiving time varies from 40 minutes to 2 hours.
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>}

                                    {langSelect == 'hin' && <div className='p-2'>
                                        <ul style={{ fontSize: '13px' }}>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        सबसे तेज़ क्रेडिट प्राप्त करने और संभावित विलंब से बचने के लिए केवल नीचे दिए गए उपलब्ध खातों में पैसा जमा करें।
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        खाता साइट से हटाने के 45 मिनट बाद किए गए जमा वैध हैं और उन्हें उनके वॉलेट में जोड़ा जाएगा।
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        साइट पुरानी, निष्क्रिय या बंद खातों में जमा की गई धनराशि के लिए जिम्मेदार नहीं है।
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        जमा करने के बाद, अपना यूटीआर और राशि जोड़ें ताकि बैलेंस प्राप्त हो सके।
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        एनईएफटी प्राप्त करने का समय 40 मिनट से 2 घंटे तक हो सकता है।
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>}

                                    {/* {langSelect == 'tel' && <div className='p-2'>
                                        <ul style={{ fontSize: '13px' }}>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        వేగవంతమైన క్రెడిట్స్ పొందడానికి మరియు ఆలస్యాలను నివారించడానికి క్రింద ఉన్న ఖాతాల్లో మాత్రమే డిపాజిట్ చేయండి.
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        ఖాతాను సైట్ నుండి తొలగించిన 45 నిమిషాల తర్వాత చేసిన డిపాజిట్లు చెల్లుతాయి మరియు వాటిని వారి వాలెట్‌లో జోడించబడతాయి.
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        పాత, క్రియాశీలం లేని లేదా మూసివేసిన ఖాతాల్లో డిపాజిట్ చేసిన డబ్బుకు సైట్ బాధ్యత వహించదు.
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        డిపాజిట్ తర్వాత, మీ UTR మరియు మొత్తం జోడించండి మరియు బ్యాలెన్స్ అందుకోండి.
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" srcset="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        NEFT స్వీకరణ సమయం 40 నిమిషాల నుండి 2 గంటల వరకు మారవచ్చు.
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    } */}
                                    {langSelect == 'bang' && <div className='p-2'>
                                        <ul style={{ fontSize: '13px' }}>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        দ্রুত ক্রেডিট পেতে এবং দেরি এড়াতে, শুধুমাত্র নীচে উল্লেখিত অ্যাকাউন্টে অর্থ জমা করুন।
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        অ্যাকাউন্ট সাইট থেকে সরানোর 45 মিনিট পর করা জমা বৈধ হবে এবং সেগুলি তাদের ওয়ালেটে যোগ করা হবে।
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        পুরানো, নিষ্ক্রিয় বা বন্ধ অ্যাকাউন্টে জমা করা অর্থের জন্য সাইট দায়ী নয়।
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        জমা করার পর, আপনার UTR এবং পরিমাণ যোগ করুন এবং ব্যালেন্স পান।
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex'>
                                                    <img src="/images/sparkle-icon.svg" alt="" style={{ marginRight: '.5rem' }} />
                                                    <span style={{ lineHeight: '25px' }}>
                                                        NEFT প্রাপ্তির সময় 40 মিনিট থেকে 2 ঘণ্টা পর্যন্ত পরিবর্তিত হতে পারে।
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                {isWithdraw && <div className='flex justify-center mb-6'>
                    <div className='isDeposit-div p-1'>
                        <div className='flex justify-between items-center p-2' style={{
                            borderBottom: '1px solid gray'
                        }}>
                            <span
                                className='text-sm font-bold cursor-pointer'
                                style={{ color: '#0d6efd' }}
                                onClick={() => {
                                    setIsWalletHome(true); setIsOpen(false); setIsDeposit(false); setIsWthdraw(false); setIsWalletHistory(false); setWithdrawAmountBtn(''); setErrorWithdrawAmount('');
                                }}
                            >
                                Home
                            </span>
                            <img src="/images/cross-mark.svg" alt="" srcset="" />
                        </div>

                        <div className="p-2">
                            <div
                                className='flex flex-col mt-4 '
                                style={{ fontSize: '.9rem' }}
                            >
                                <lablel>Amount</lablel>
                                <input
                                    type="text"
                                    name=""
                                    id=""
                                    value={withdrawAmountBtn}
                                    pattern="[0-9]*"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setWithdrawAmountBtn(value);
                                        setErrorWithdrawAmount('');
                                        // if (/^\d+$/.test(value)) {
                                        // }
                                    }}
                                    placeholder='Enter withdraw amount'
                                    className='p-2 mt-2 rounded border border-gray-400'
                                />
                                {errorWithdrawAmount && (
                                    <span style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                        {errorWithdrawAmount}
                                    </span>
                                )}
                                <span
                                    style={{
                                        color: '#99ACCC',
                                        fontSize: '13px',
                                        fontWeight: '400',
                                        textAlign: 'end'
                                    }}
                                >
                                    (Min amount: ₹ 100)
                                </span>
                            </div>
                            <div className='flex justify-between mb-3'>
                                <button type='button'
                                    style={{
                                        backgroundColor: '#2290C7',
                                        padding: '.5rem',
                                        width: '25%',
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontWeight: '800',
                                        marginRight: '8px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const addAmountBtn = withdrawAmountBtn != '' ? Number(withdrawAmountBtn) + Number(stakeBtn1) : stakeBtn1
                                        setWithdrawAmountBtn(addAmountBtn);
                                    }}
                                >
                                    {stakeBtn1}
                                </button>
                                <button type='button'
                                    style={{
                                        backgroundColor: '#2290C7',
                                        padding: '.5rem',
                                        width: '25%',
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontWeight: '800',
                                        marginRight: '8px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const addAmountBtn = withdrawAmountBtn != '' ? Number(withdrawAmountBtn) + Number(stakeBtn2) : stakeBtn2
                                        setWithdrawAmountBtn(addAmountBtn);
                                    }}
                                >
                                    {stakeBtn2}
                                </button>
                                <button type='button'
                                    style={{
                                        backgroundColor: '#2290C7',
                                        padding: '.5rem',
                                        width: '25%',
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontWeight: '800',
                                        marginRight: '8px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const addAmountBtn = withdrawAmountBtn != '' ? Number(withdrawAmountBtn) + Number(stakeBtn3) : stakeBtn3
                                        setWithdrawAmountBtn(addAmountBtn);
                                    }}
                                >
                                    {stakeBtn3}
                                </button>
                                <button type='button'
                                    style={{
                                        backgroundColor: '#2290C7',
                                        padding: '.5rem',
                                        width: '25%',
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontWeight: '800',
                                        marginRight: '8px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const addAmountBtn = withdrawAmountBtn != '' ? Number(withdrawAmountBtn) + Number(stakeBtn4) : stakeBtn4
                                        setWithdrawAmountBtn(addAmountBtn);
                                    }}
                                >
                                    {stakeBtn4}
                                </button>
                            </div>
                            <div className='flex justify-center rounded p-2 mb-2 cursor-pointer' style={{ backgroundColor: '#B5DFFF', color: '#2290C7', fontWeight: '700', fontSize: '.9rem' }} onClick={() => { setIsWthdraw(false); setWithdrawAmountBtn(''); setErrorWithdrawAmount(''); setEditStakeOpen(true) }}>
                                <button type='button' className='flex items-center'>
                                    <img src="/images/edit-btn.svg" alt="" srcset="" /> <span className='ml-1'>Edit Stake</span>
                                </button>
                            </div>
                        </div>
                        <div className='p-2'>
                            <button type='button' className='w-full border rounded cursor-pointer text-sm' style={{ backgroundColor: '#00143C', color: '#99ACCC', height: '46px' }}
                                onClick={() => { handleWithdrawNext(); }}>Continue to Withdraw</button>
                        </div>
                        <div className='p-2'>
                            <button type='button' className='w-full border rounded cursor-pointer text-sm' style={{ backgroundColor: 'white', color: '#00143C ', height: '46px' }} onClick={() => { setIsWalletHome(true); setIsOpen(false); setIsDeposit(false); setIsWthdraw(false); setIsWalletHistory(false); }}>Want to Deposit</button>
                        </div>
                    </div>
                </div>}

                {isWithdrawBankways && <div className='flex justify-center mb-6'>
                    <div className='isDeposit-div p-1'>
                        <div className='flex items-center p-2' style={{
                            borderBottom: '1px solid gray'
                        }}>
                            <span
                                className='text-sm font-bold cursor-pointer'

                                onClick={() => {
                                    setIsWalletHome(false);
                                    setIsOpen(false);
                                    setIsDeposit(false);
                                    setIsWthdraw(true);
                                    setIsWalletHistory(false);
                                    setIsWithdrawBankways(false);
                                }}
                            >
                                <img src="/images/back-arrow.svg" alt="" srcset="" />
                            </span>
                            <span className='font-bold text-sm'>Banks</span>
                        </div>

                        <div className="">
                            <div className='mt-4'>
                                <h2 className='font-bold mb-2' style={{ fontSize: '1rem' }}>Choose from recent accounts</h2>
                                <div className='relative'>
                                    <input
                                        type="text"
                                        placeholder='Search account'
                                        className='w-full p-2 border rounded-lg text-sm'
                                        style={{
                                            height: '44px',
                                            border: '1px solid #ced4da',
                                            outline: 'none',
                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.borderColor = '#0000ff63';
                                        }}
                                        onBlur={(e) => {
                                            e.target.style.border = '1px solid #ced4da';
                                        }}
                                    />
                                    <button
                                        className='absolute flex justify-center items-center border rounded w-10'
                                        style={{ backgroundColor: '#514EF3', borderColor: '#514EF3', top: '0px', bottom: '0px', right: '0px', height: 'auto' }}>
                                        <img
                                            src="/images/search-icon-small.svg"
                                            alt=""
                                            className='text-sm'
                                            style={{ width: '1.2rem', filter: 'brightness(0) invert(1)' }}
                                        />
                                    </button>
                                </div>

                            </div>
                            {savedBankData?.length > 0 && savedBankData?.map((item, index) => {
                                // setSelectSavedBank(`${index}savedBank`);
                                return (
                                    <>
                                        <div key={item.id}
                                            className={`mt-2 bg-white border  rounded-md p-3 cursor-pointer`}
                                            onClick={() => { setSelectSavedBank(`${index}savedBank`); }}

                                            style={{ border: selectSavedBank == `${index}savedBank` ? '1px solid black' : '' }}
                                        >
                                            <div className='flex justify-between'>
                                                <div className='flex justify-center items-center'>

                                                    <div
                                                        className='flex justify-center items-center border rounded'
                                                        style={{ backgroundColor: '#EEEEEF', height: '60px', width: '60px', marginRight: '10px' }}
                                                    >
                                                        <img src={item.bankSrcImage} alt="" srcset="" />
                                                    </div>

                                                    <div>
                                                        <h2 className='font-bold'>{item.paymentMethod}({item.PaymentSubType})</h2>
                                                        {/* <p className='text-sm'><b>Name : </b>{item.accountHName}</p> */}
                                                        <p className='text-sm'><b>Number : </b> {item.accountNumber}</p>
                                                        <p className='text-sm'><b>Phone : </b> {item.phone}</p>

                                                        {/* {item.UPIID == '' ? <>
                                                            <p className='text-sm'><b>Account Number : </b> {item.accountNumber}</p>
                                                            <p className='text-sm'><b>IFSC Code : </b> {item.IFSC}</p>
                                                        </> :
                                                            <p className='text-sm'><b>UPI ID : </b> {item.UPIID}</p>
                                                        } */}

                                                    </div>
                                                </div>
                                                <div className='flex flex-col justify-between'>
                                                    <div
                                                        className='relative'
                                                        onClick={() => { SavedBankDelete == `delete${index}` ? setSavedBankDelete('') : setSavedBankDelete(`delete${index}`) }}
                                                    >
                                                        <img src="/images/more-action.svg" alt="" srcset="" />
                                                        <div id={`delete${index}`} className='absolute '
                                                            style={{ display: SavedBankDelete == `delete${index}` ? 'block' : 'none', left: '-4.2rem' }}>
                                                            <div
                                                                className='flex justify-center items-center p-1 w-24 text-xs font-bold border border-black rounded bg-white'
                                                                onClick={() => { handleBankRemove(item.SrNo, item.UserId) }}
                                                            >
                                                                <img src="/images/Remove-icon.svg" alt="" className='pr-1' style={{ height: '1.5em' }} />
                                                                <p>Remove</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div
                                                        className=''
                                                        style={{
                                                            height: '0.8em',
                                                            width: '0.8em',
                                                            border: '2px solid #2290C7',
                                                            borderRadius: '100%',
                                                            marginLeft: '4px',
                                                            padding: '1px'
                                                        }}>
                                                        {selectSavedBank == `${index}savedBank` && <div
                                                            className='border rounded-full'
                                                            style={{
                                                                height: '0.4em',
                                                                width: '0.4em',
                                                                backgroundColor: '#2290C7'
                                                            }}></div>}
                                                    </div>
                                                </div>
                                            </div>


                                        </div >

                                        {selectSavedBank == `${index}savedBank` ? <div className='mt-5'>
                                            <button
                                                type='button'
                                                className='w-full border rounded cursor-pointer text-sm'
                                                style={{ backgroundColor: '#00143C', color: '#99ACCC', height: '46px' }}
                                                onClick={() => {
                                                    handleWithdrawAmount(withdrawAmountBtn, item.paymentMethod, item.PaymentSubType, item.accountHName, item.accountNumber, '00000', '00000', item.phone)
                                                }}
                                            >
                                                {`Withdraw ${withdrawAmountBtn} to Bank`}
                                            </button>
                                        </div> : ''
                                        }

                                    </>
                                )
                            })}
                        </div>

                        <div className='mt-4'>
                            <h1><b>Add a New Account</b></h1>

                            <div className='mt-4'>

                                <div
                                    className='flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true);
                                        setIsWithdrawBankways(false);
                                        setWithdrawPaymentMethod('Bkash');
                                        setWithdrawPaymentSubMethod('Agent');
                                        setWithdrawBankImgSrc('/images/BkashLogo.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/BkashLogo.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Bkash (Agent)</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className=' mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true);
                                        setIsWithdrawBankways(false);
                                        setWithdrawPaymentMethod('Bkash');
                                        setWithdrawPaymentSubMethod('Personal');
                                        setWithdrawBankImgSrc('/images/BkashLogo.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/BkashLogo.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Bkash (Personal)</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true);
                                        setIsWithdrawBankways(false);
                                        setWithdrawPaymentMethod('Nagad');
                                        setWithdrawPaymentSubMethod('Agent');
                                        setWithdrawBankImgSrc('/images/NagadLogo.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/NagadLogo.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Nagad (Agent)</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true);
                                        setIsWithdrawBankways(false);
                                        setWithdrawPaymentMethod('Nagad');
                                        setWithdrawPaymentSubMethod('Personal');
                                        setWithdrawBankImgSrc('/images/NagadLogo.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/NagadLogo.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Nagad (Personal)</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true);
                                        setIsWithdrawBankways(false);
                                        setWithdrawPaymentMethod('Rocket');
                                        setWithdrawPaymentSubMethod('Agent');
                                        setWithdrawBankImgSrc("/images/banglaRocketLogo.svg");
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/banglaRocketLogo.svg" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Rocket (Agent)</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true);
                                        setIsWithdrawBankways(false);
                                        setWithdrawPaymentMethod('Rocket');
                                        setWithdrawPaymentSubMethod('Personal');
                                        setWithdrawBankImgSrc("/images/banglaRocketLogo.svg");
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/banglaRocketLogo.svg" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Rocket (Personal)</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>

                                {/* <div
                                    className='flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('BharatPe');
                                        setWithdrawBankImgSrc('/images/bharatPe.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/bharatPe.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>BharatPe</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('GooglePay');
                                        setWithdrawBankImgSrc('/images/gpay.jpg');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/gpay.jpg" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>GooglePay</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('PhonePe');
                                        setWithdrawBankImgSrc('/images/phonePe.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/phonePe.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>PhonePe</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('Paytm');
                                        setWithdrawBankImgSrc('/images/paytm.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/paytm.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Paytm</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('Bank');
                                        setWithdrawBankImgSrc('/images/bank.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/bank.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Bank</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('Yes Bank Worldline');
                                        setWithdrawBankImgSrc('/images/yesBank.jpg');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/yesBank.jpg" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Yes Bank Worldline</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('The Development Bank of Singapore Limited');
                                        setWithdrawBankImgSrc('/images/dbsBank.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/dbsBank.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>The Development Bank of Singapore Limited</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('Karnataka Gramin Bank');
                                        setWithdrawBankImgSrc('/images/karnatkaGBank.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/karnatkaGBank.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Karnataka Gramin Bank</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('Induslnd Bank');
                                        setWithdrawBankImgSrc('/images/induslandBank.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/induslandBank.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>Induslnd Bank</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div>
                                <div
                                    className='mt-4 flex justify-between items-center bg-white border rounded-md p-3 cursor-pointer'
                                    onClick={() => {
                                        setAddBankPage(true); setIsWithdrawBankways(false); setWithdrawPaymentMethod('ICICI Bank');
                                        setWithdrawBankImgSrc('/images/iciciBank.png');
                                    }}
                                >
                                    <div className='flex justify-center items-center'>
                                        <img src="/images/iciciBank.png" alt="" srcset="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                                        <span className='text-sm'><b>ICICI Bank</b></span>
                                    </div>
                                    <div style={{ color: '#2290C7', fontSize: '12px' }}><BiSolidRightArrow /></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>}

                {addBankPage && <div className='flex justify-center mb-6'>
                    <div className='isDeposit-div p-1'>
                        <div className='flex items-center p-2' style={{
                            borderBottom: '1px solid gray'
                        }}>
                            <span
                                className='text-sm font-bold cursor-pointer'
                                onClick={() => {
                                    setAddBankPage(false);
                                    setIsWithdrawBankways(true);
                                    setWithdrawPaymentMethod('');
                                    setWithdrawBankImgSrc('');
                                    // setWithdrawAccountHolder('');
                                    setWithdrawAccountNumber('');
                                    setWithdrawIfsc('');
                                    setWithdrawPhone('');
                                    setWithdrawUpiId('');
                                }}
                            >
                                <img src="/images/back-arrow.svg" alt="" srcset="" />
                            </span>
                            <span className='font-bold text-sm'>Add Account</span>
                        </div>

                        <div className='mt-4'>
                            <h1><b>Fill in the required fields</b></h1>

                            <div className='mt-4'>

                                <div className='flex flex-col justify-between items-center bg-white border rounded-md p-3'>

                                    {/* <div className='flex flex-col w-full'>
                                        <label className='text-sm'>Name</label>
                                        <input
                                            type="text"
                                            className='mt-2 w-full p-2 border rounded text-sm'
                                            style={{
                                                height: '44px',
                                                border: '2px solid #EEEEEF',
                                                outline: 'none',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                            }}
                                            value={withdrawAccountHolder}
                                            onChange={(e) => setWithdrawAccountHolder(e.target.value)}
                                        />
                                    </div> */}
                                    <div className='mt-2 flex flex-col w-full'>
                                        <label className='text-sm'>Number</label>
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            className='mt-2 w-full p-2 border rounded text-sm'
                                            style={{
                                                height: '44px',
                                                border: '2px solid #EEEEEF',
                                                outline: 'none',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                            }}
                                            value={withdrawAccountNumber}
                                            onChange={(e) => setWithdrawAccountNumber(e.target.value)}
                                        />
                                    </div>

                                    <div className='mt-2 flex flex-col w-full'>
                                        <label className='text-sm'>Phone Number</label>
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            className='mt-2 w-full p-2 border rounded text-sm'
                                            style={{
                                                height: '44px',
                                                border: '2px solid #EEEEEF',
                                                outline: 'none',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                            }}
                                            value={withdrawPhone}
                                            onChange={(e) => setWithdrawPhone(e.target.value)}
                                        />
                                    </div>


                                    <div className='mt-2 flex flex-col w-full'>
                                        <label className='text-sm'>Requested Amount</label>
                                        <input
                                            type="text"
                                            className='mt-2 w-full p-2 border rounded text-sm'
                                            style={{
                                                height: '44px',
                                                border: '2px solid #EEEEEF',
                                                backgroundColor: '#e9ecef',
                                                outline: 'none',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                            }}
                                            readOnly
                                            value={withdrawAmountBtn}
                                        />
                                    </div>
                                    <div className='mt-2 flex flex-col w-full'>
                                        <label className='text-sm'>Payment Method</label>
                                        <input
                                            type="text"
                                            className='mt-2 w-full p-2 border rounded text-sm'
                                            style={{
                                                height: '44px',
                                                border: '2px solid #EEEEEF',
                                                backgroundColor: '#e9ecef',
                                                outline: 'none',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                            }}
                                            readOnly
                                            value={withdrawPaymentMethod}
                                        />
                                    </div>
                                    <div className='mt-2 flex flex-col w-full'>
                                        <label className='text-sm'>Account Type</label>
                                        <input
                                            type="text"
                                            className='mt-2 w-full p-2 border rounded text-sm'
                                            style={{
                                                height: '44px',
                                                border: '2px solid #EEEEEF',
                                                backgroundColor: '#e9ecef',
                                                outline: 'none',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                            }}
                                            readOnly
                                            value={withdrawPaymentSubMethod}
                                        />
                                    </div>

                                    {/* Conditional Rendering for Bank Account Details */}
                                    {/* {withdrawPaymentMethod !== 'BharatPe' && withdrawPaymentMethod !== 'GooglePay' && withdrawPaymentMethod !== 'PhonePe' && withdrawPaymentMethod !== 'Paytm' && (
                                        <>
                                            <div className='mt-2 flex flex-col w-full'>
                                                <label className='text-sm'>Account Number</label>
                                                <input
                                                    type="number"
                                                    className='mt-2 w-full p-2 border rounded text-sm'
                                                    style={{
                                                        height: '44px',
                                                        border: '2px solid #EEEEEF',
                                                        outline: 'none',
                                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                                    }}
                                                    value={withdrawAccountNumber}
                                                    onChange={(e) => setWithdrawAccountNumber(e.target.value)}
                                                />
                                            </div>
                                            <div className='mt-2 flex flex-col w-full'>
                                                <label className='text-sm'>IFSC Code</label>
                                                <input
                                                    type="text"
                                                    className='mt-2 w-full p-2 border rounded text-sm'
                                                    style={{
                                                        height: '44px',
                                                        border: '2px solid #EEEEEF',
                                                        outline: 'none',
                                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                                    }}
                                                    value={withdrawIfsc}
                                                    onChange={(e) => setWithdrawIfsc(e.target.value)}
                                                />
                                            </div>
                                        </>
                                    )} */}

                                    {/* Conditional Rendering for UPI ID
                                    {(withdrawPaymentMethod === 'BharatPe' || withdrawPaymentMethod === 'GooglePay' || withdrawPaymentMethod === 'PhonePe' || withdrawPaymentMethod === 'Paytm') && (
                                        <div className='mt-2 flex flex-col w-full'>
                                            <label className='text-sm'>UPI ID</label>
                                            <input
                                                type="text"
                                                className='mt-2 w-full p-2 border rounded text-sm'
                                                style={{
                                                    height: '44px',
                                                    border: '2px solid #EEEEEF',
                                                    outline: 'none',
                                                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                                }}
                                                value={withdrawUpiId}
                                                onChange={(e) => setWithdrawUpiId(e.target.value)}
                                            />
                                        </div>
                                    )} */}

                                </div>

                            </div>

                            <div className='mt-5'>
                                <button
                                    type='button'
                                    className='w-full border rounded cursor-pointer text-sm'
                                    style={{ backgroundColor: '#00143C', color: '#99ACCC', height: '46px' }}
                                    onClick={handleSaveBankDetail}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div>}

                {isWalletHistory && <div className='flex justify-center mb-6'>
                    <div className='isDeposit-div p-1'>
                        <div className='flex justify-between items-center p-2' style={{
                            borderBottom: '1px solid gray'
                        }}>
                            <span
                                className='text-sm font-bold cursor-pointer'
                                style={{ color: '#0d6efd' }}
                                onClick={() => { setIsWalletHome(true); setIsOpen(false); setIsDeposit(false); setIsWthdraw(false); setIsWalletHistory(false); }}
                            >
                                Home
                            </span>
                            <img src="/images/cross-mark.svg" alt="" srcset="" />
                        </div>

                        <div
                            className='flex justify-center items-center'
                            style={{ marginTop: '1rem', marginLeft: '1rem' }}
                        >
                            <div className='deposit-list flex flex-col justify-center items-center font-bold text-black rounded-lg w-full' style={{ backgroundColor: '#fff', padding: '18px 0px', marginRight: '2rem' }}>
                                <div>
                                    <img src="/images/total-deposit.svg" alt="" srcset="" />
                                </div>
                                <span className='mt-1 text-black'>{totalDepositAmount}</span>
                                <div className='font-normal text-black' style={{ fontSize: '.9rem' }}>
                                    Total Deposit
                                </div>
                            </div>
                            <div className='withdraw-list flex flex-col justify-center items-center font-bold text-black rounded-lg w-full' style={{ backgroundColor: '#fff', padding: '18px 0px', marginRight: '1.6rem' }}>
                                <div>
                                    <img src="/images/total-withdraw.svg" alt="" srcset="" />
                                </div>
                                <span className='mt-1 text-black'>{totalWithdrawAmount}</span>
                                <div className='font-normal text-black' style={{ fontSize: '.9rem' }}>
                                    Total Withdraw
                                </div>
                            </div>
                            <div className='withdraw-list flex flex-col justify-center items-center font-bold text-black rounded-lg w-full' style={{ backgroundColor: '#fff', padding: '18px 0px' }}>
                                <div>
                                    <img src="/images/Profit-Loss.svg" alt="" srcset="" />
                                </div>
                                <span className='mt-1' style={{ color: totalPL >= 0 ? '#16a34a' : 'red' }}>{totalPL}</span>
                                <div className='font-normal text-black' style={{ fontSize: '.9rem' }}>
                                    Profit/Loss
                                </div>
                            </div>
                        </div>

                        <div className='mt-5 flex justify-between '>
                            <h1 className='font-bold'>Transactions</h1>
                            <div
                                className='flex justify-center items-center cursor-pointer'
                                style={{
                                    backgroundColor: '#fff', width: '30px', height: '30px',
                                    border: '1.5px solid #EEEEEF', borderRadius: '4px'
                                }}
                                onClick={() => setIsFilterOpen(true)}
                            >
                                <img src="/images/filter-icon.svg" alt="" srcset="" />
                            </div>
                        </div>
                        {walletHistoryData?.length > 0 && walletHistoryData?.map((item) => (
                            <div
                                className={`mt-3 flex justify-between items-center bg-white border border-gray-200 rounded p-2 ${item.Status != 2 ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (item.Status != 2) {
                                        setWalletPaySlipOpen(true);
                                        setWalletPaySlipData({
                                            name: item.DisplayName,
                                            requestDate: item.RequestDate,
                                            StatusChangeDate: item.StatusChangeDate,
                                            amount: item.Amount,
                                            bank: item.Bank,
                                            paymentSubType: item.PaymentSubType,
                                            requestType: item.requestType,
                                            UTRNo: item.UTRNo,
                                            Status: item.Status,
                                            Comment: item.Comment
                                        });
                                    }
                                }}
                            >
                                <div className='flex justify-center items-center'>
                                    <div className='flex justify-center items-center' style={{ width: '40px', height: '40px', borderRadius: '4px', backgroundColor: 'rgba(39, 174, 96, 0.12)', marginRight: '10px' }}>
                                        <img src={`/images/${item.Status == 1 ? 'approved-icon.svg' : item.Status == 2 ? 'processing-icon.svg' : 'rejected-icon.svg'}`} alt="" srcset="" />
                                    </div>
                                    <div className='flex flex-col' >
                                        <span className='font-bold' style={{ fontSize: '.9rem' }}>{item.requestType == 'deposit' ? 'Deposit' : 'Withdraw'} request {item.Status == 1 ? 'Approved' : item.Status == 2 ? 'Pending' : `Rejected`}</span>
                                        <div className='flex  items-center'>
                                            <img src="/images/refresh.svg" alt="" srcset="" style={{ height: '16px', marginRight: '5px' }} />
                                            <span style={{ color: '#99ACCC', fontSize: '13px', fontWeight: '400', marginRight: '5px' }}>
                                                {item.StatusChangeDate ? moment(item.StatusChangeDate).format('DD MMM h:mm A') : ''}
                                            </span>
                                            <span style={{ borderRight: '1.5px solid  #EEEEEF' }}>&nbsp;</span>
                                            <img src="/images/created-time-icon.svg" alt="" srcset="" style={{ marginRight: '5px', marginLeft: '5px' }} />
                                            <span style={{ color: '#99ACCC', fontSize: '13px', fontWeight: '400', marginRight: '5px' }}>
                                                {moment(item.RequestDate).format('DD MMM h:mm A')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='font-bold' style={{ color: '#16a34a' }}>{item.Amount}</div>
                            </div>
                        ))}
                    </div>
                </div>}

            </div>

            {/* Deposit Amount Modal */}
            <div
                id="changeStatusModal"
                tabindex="-1"
                aria-labelledby="ChangeStatusLabel"
                className="modal fade changepassword-model show"
                data-backdrop="static"
                data-keyboard="false"
                role="dialog"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog" style={{ marginTop: '10rem' }}>
                    <div className="modal-content" style={{ backgroundColor: 'white' }}>
                        <div className="modal-header flex justify-between p-2" style={{ backgroundColor: '#EEEEEF', padding: '0.8rem' }}>
                            <h6 id="ChangeStatusLabel" className="modal-title">
                                Amount
                            </h6>
                            <img src="/images/close-square.svg" alt="" srcset="" className='cursor-pointer' onClick={() => { setIsOpen(false); setIsDeposit(false); setIsWthdraw(false); setDepositAmountBtn(''); setErrorDepositPop(''); }} />
                        </div>
                        <div className="modal-body change-status">
                            <div
                                className='flex flex-col mb-3'
                                style={{ fontSize: '.9rem' }}
                            >
                                <lablel> Deposit Amount </lablel>
                                <input
                                    type="text"
                                    value={depositAmountBtn}
                                    pattern="[0-9]*"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setDepositAmountBtn(value);
                                        setErrorDepositPop('');
                                        // if (/^\d+$/.test(value)) {
                                        // }
                                    }}
                                    placeholder='Please input amount'
                                    className='p-2 mt-2 rounded border border-gray-400'
                                />
                                {errorDepositPop && (
                                    <span style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                        {errorDepositPop}
                                    </span>
                                )}
                                <span
                                    style={{
                                        color: '#99ACCC',
                                        fontSize: '13px',
                                        fontWeight: '400',
                                        textAlign: 'end'
                                    }}
                                >
                                    (Min amount: ₹ 100)
                                </span>
                            </div>
                            <div className='flex justify-between mb-3'>
                                <button type='button'
                                    style={{
                                        backgroundColor: '#2290C7',
                                        padding: '.5rem',
                                        width: '25%',
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontWeight: '800',
                                        marginRight: '8px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const addAmountBtn = depositAmountBtn != '' ? Number(depositAmountBtn) + Number(stakeBtn1) : stakeBtn1
                                        setDepositAmountBtn(addAmountBtn);
                                    }}
                                >
                                    {stakeBtn1}
                                </button>
                                <button type='button'
                                    style={{
                                        backgroundColor: '#2290C7',
                                        padding: '.5rem',
                                        width: '25%',
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontWeight: '800',
                                        marginRight: '8px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const addAmountBtn = depositAmountBtn != '' ? Number(depositAmountBtn) + Number(stakeBtn2) : stakeBtn2
                                        setDepositAmountBtn(addAmountBtn);
                                    }}
                                >
                                    {stakeBtn2}
                                </button>
                                <button type='button'
                                    style={{
                                        backgroundColor: '#2290C7',
                                        padding: '.5rem',
                                        width: '25%',
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontWeight: '800',
                                        marginRight: '8px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const addAmountBtn = depositAmountBtn != '' ? Number(depositAmountBtn) + Number(stakeBtn3) : stakeBtn3
                                        setDepositAmountBtn(addAmountBtn);
                                    }}
                                >
                                    {stakeBtn3}
                                </button>
                                <button type='button'
                                    style={{
                                        backgroundColor: '#2290C7',
                                        padding: '.5rem',
                                        width: '25%',
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontWeight: '800',
                                        marginRight: '8px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const addAmountBtn = depositAmountBtn != '' ? Number(depositAmountBtn) + Number(stakeBtn4) : stakeBtn4
                                        setDepositAmountBtn(addAmountBtn);
                                    }}
                                >
                                    {stakeBtn4}
                                </button>
                            </div>
                            <div className='flex justify-center rounded p-2 mb-3 cursor-pointer' style={{ backgroundColor: '#B5DFFF', color: '#2290C7', fontWeight: '700', fontSize: '.9rem' }} onClick={() => { setIsOpen(false); setEditStakeOpen(true) }}>
                                <button type='button'>
                                    Edit Stake
                                </button>
                            </div>
                            <div className='flex justify-center rounded p-2 mb-3 cursor-pointer'
                                style={{ backgroundColor: '#00143C', color: '#99ACCC', fontWeight: '700', fontSize: '.9rem' }}
                                onClick={handleDepositPopUpNext}>
                                <button type='button'>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Edit Stake Modal */}
            <div
                id="changeStatusModal"
                tabindex="-1"
                aria-labelledby="ChangeStatusLabel"
                className="modal fade changepassword-model show"
                data-backdrop="static"
                data-keyboard="false"
                role="dialog"
                style={{ display: editStakeOpen ? "block" : "none" }}
            >
                <div className="modal-dialog" style={{ marginTop: '10rem' }}>
                    <div className="modal-content" style={{ backgroundColor: 'white' }}>
                        <div className="modal-header flex justify-between p-2" style={{ backgroundColor: '#EEEEEF', padding: '0.8rem' }}>
                            <h6 id="ChangeStatusLabel" className="modal-title">
                                Edit Stake
                            </h6>
                            <img
                                src="/images/close-square.svg"
                                alt=""
                                className="cursor-pointer"
                                onClick={() => {
                                    setEditStakeOpen(false);
                                    setIsWalletHome(true);
                                    setDepositAmountBtn('');
                                    setErrorDepositPop('')
                                    setErrorWithdrawAmount('');
                                    seteditStakeBtn1(stakeBtn1);
                                    seteditStakeBtn2(stakeBtn2);
                                    seteditStakeBtn3(stakeBtn3);
                                    seteditStakeBtn4(stakeBtn4);
                                }}
                            />
                        </div>
                        <div className="modal-body change-status">
                            <div className="flex flex-col mb-3" style={{ fontSize: '.9rem' }}>
                                <div className="flex justify-between" style={{ width: '100%', marginTop: '3rem', marginBottom: '3rem' }}>
                                    <div className="w-full flex justify-between">
                                        <input
                                            type="text"
                                            className=" w-full mr-2 p-2"
                                            style={{ flex: '1', backgroundColor: '#F6FAFD', border: '1px solid #EEEEEF', borderRadius: '6px', textAlign: 'center' }}
                                            pattern="[0-9]*"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            value={editStakeBtn1}
                                            onChange={(e) => { seteditStakeBtn1(e.target.value) }}
                                        />
                                        <input
                                            type="text"
                                            className=" w-full mr-2 p-2"
                                            style={{ flex: '1', backgroundColor: '#F6FAFD', border: '1px solid #EEEEEF', borderRadius: '6px', textAlign: 'center' }}
                                            pattern="[0-9]*"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            value={editStakeBtn2}
                                            onChange={(e) => { seteditStakeBtn2(e.target.value) }}
                                        />
                                        <input
                                            type="text"
                                            className=" w-full mr-2 p-2"
                                            style={{ flex: '1', backgroundColor: '#F6FAFD', border: '1px solid #EEEEEF', borderRadius: '6px', textAlign: 'center' }}
                                            pattern="[0-9]*"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            value={editStakeBtn3}
                                            onChange={(e) => { seteditStakeBtn3(e.target.value) }}
                                        />
                                        <input
                                            type="text"
                                            className=" w-full p-2"
                                            style={{ flex: '1', backgroundColor: '#F6FAFD', border: '1px solid #EEEEEF', borderRadius: '6px', textAlign: 'center' }}
                                            pattern="[0-9]*"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            value={editStakeBtn4}
                                            onChange={(e) => { seteditStakeBtn4(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-center'>

                                <div
                                    className="flex justify-center rounded p-2 mb-3 mr-2 cursor-pointer"
                                    style={{ backgroundColor: '#00143C', color: '#99ACCC', fontWeight: '700', fontSize: '.9rem', width: '39%' }}
                                    onClick={() => {
                                        setEditStakeOpen(false);
                                        setIsWalletHome(true);
                                        setDepositAmountBtn('');
                                        setErrorDepositPop('')
                                        setErrorWithdrawAmount('');
                                        seteditStakeBtn1(stakeBtn1);
                                        seteditStakeBtn2(stakeBtn2);
                                        seteditStakeBtn3(stakeBtn3);
                                        seteditStakeBtn4(stakeBtn4);
                                    }}
                                >
                                    <button type="button">No</button>
                                </div>

                                <div
                                    className="flex justify-center rounded p-2 mb-3 cursor-pointer"
                                    style={{ backgroundColor: '#00143C', color: '#99ACCC', fontWeight: '700', fontSize: '.9rem', width: '59%' }}
                                    onClick={changeStakeButton}
                                >
                                    <button type="button">Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Wallet Filter Modal */}
            <div
                id="walletFilterModal"
                tabindex="-1"
                aria-labelledby="ChangeStatusLabel"
                className="modal fade changepassword-model show"
                data-backdrop="static"
                data-keyboard="false"
                role="dialog"
                style={{ display: isFilterOpen ? "block" : "none" }}
            >
                <div className="modal-dialog" style={{ marginTop: '10rem' }}>
                    <div className="modal-content" style={{ backgroundColor: 'white' }}>
                        <div className="modal-header flex justify-between p-2" style={{ backgroundColor: '#EEEEEF', padding: '0.8rem' }}>
                            <h6 id="ChangeStatusLabel" className="modal-title">
                                Filter
                            </h6>
                            <img src="/images/close-square.svg" alt="" srcset="" className='cursor-pointer' onClick={() => { setIsFilterOpen(false); }} />
                        </div>
                        <div className="modal-body change-status">
                            <div
                                className='flex justify-between mb-3'
                                style={{ fontSize: '.9rem' }}
                            >
                                <div className='flex flex-col justify-start w-full pr-1'>
                                    <lablel> From </lablel>
                                    <input
                                        type="date"
                                        formcontrolname="periodFormDate"
                                        className='p-2 mt-2 rounded border border-gray-400'
                                        value={fromDate}
                                        onChange={(e) => { setFromDate(e.target.value) }}
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full pr-1'>
                                    <lablel> To </lablel>
                                    <input
                                        type="date"
                                        formcontrolname="periodFormDate"
                                        className='p-2 mt-2 rounded border border-gray-400 '
                                        value={toDate}
                                        onChange={(e) => { setToDate(e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className=' mb-3'>
                                <span className='text-sm font-bold'>Filter by status</span>
                            </div>
                            <div className='flex justify-between items-center text-sm font-bold mb-6'>
                                <div className="radio-container">
                                    <input
                                        type="checkbox"
                                        name="status"
                                        id="all"
                                        checked={checkedBox.all}
                                        onChange={handleAllChange}
                                    />
                                    <label htmlFor="all" className='' style={{ marginLeft: '4px' }}>All</label>
                                </div>
                                <div className="radio-container">
                                    <input
                                        type="checkbox"
                                        name="status"
                                        id="approved"
                                        checked={checkedBox.approved}
                                        onChange={() => handleCheckboxChange('approved')}
                                    />
                                    <label htmlFor="approved" style={{ marginLeft: '4px' }}>Approved</label>
                                </div>
                                <div className="radio-container">
                                    <input
                                        type="checkbox"
                                        name="status"
                                        id="rejected"
                                        checked={checkedBox.rejected}
                                        onChange={() => handleCheckboxChange('rejected')}
                                    />
                                    <label htmlFor="rejected" style={{ marginLeft: '4px' }}>Rejected</label>
                                </div>
                                <div className="radio-container">
                                    <input
                                        type="checkbox"
                                        name="status"
                                        id="pending"
                                        checked={checkedBox.pending}
                                        onChange={() => handleCheckboxChange('pending')}
                                    />
                                    <label htmlFor="pending" style={{ marginLeft: '4px' }}>Pending</label>
                                </div>
                            </div>

                            <div className='flex justify-between items-center mb-3'>
                                <div className='flex justify-center items-center cursor-pointer' style={{ backgroundColor: '#00143C', color: '#99ACCC', fontWeight: '500', borderRadius: '4px', height: '46px', width: '80%', marginRight: '5px' }}
                                    onClick={() => { setIsFilterOpen(false); }}
                                >
                                    <button> Cancel</button>
                                </div>
                                <div
                                    className='flex justify-center items-center w-full cursor-pointer'
                                    style={{ backgroundColor: '#00143C', color: '#99ACCC', fontWeight: '500', borderRadius: '4px', height: '46px' }}
                                    onClick={fetchWalletHistory}
                                >
                                    <button> Apply</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Wallet Pay Slip Modal */}
            <div
                id="changeStatusModal"
                tabindex="-1"
                aria-labelledby="ChangeStatusLabel"
                className="modal fade changepassword-model show"
                data-backdrop="static"
                data-keyboard="false"
                role="dialog"
                style={{ display: walletPaySlipOpen ? "block" : "none", zIndex: '9999' }}
            >
                <div className="modal-dialog" style={{ marginTop: '10rem' }}>
                    <div className="modal-content" style={{ backgroundColor: 'white', borderRadius: '1.3rem', border: 'none' }}>
                        <div className='modal-body'>
                            <div className='flex flex-col justify-center items-center'>
                                <div
                                    className="image flex justify-center"
                                    style={{
                                        width: '82px',
                                        height: '82px',
                                        borderRadius: '100%',
                                        border: '3px solid #d9d9d9',
                                        overflow: 'hidden',
                                        margin: '0 auto',
                                        paddingBottom: ''
                                    }}
                                >
                                    <img src="/images/taka.png" alt="" srcset="" style={{ width: '100%', height: '100%' }} />
                                </div>
                                <div className="flex flex-col justify-center items-center pb-3">
                                    {/* <strong style={{ fontSize: '20px', paddingBottom: '10px' }}>{walletPaySlipData.name}</strong> */}
                                    <span className='text-sm'>{walletPaySlipData.StatusChangeDate ? moment(walletPaySlipData.StatusChangeDate).format('DD MMM YYYY h:mm A') : ''}
                                    </span>
                                </div>
                                <div className='flex justify-center items-center pb-3'>
                                    <img src="/images/rupees-icon.svg" alt="" srcset="" />
                                    <span className='ml-1' style={{ fontSize: '36px', fontWeight: 'bold' }}>{walletPaySlipData.amount}</span>
                                </div>
                                <div className='flex justify-center items-center pb-3'>
                                    <img src={walletPaySlipData.Status == 1 ? "/images/payment-approved-icon.svg" : "/images/payment-rejected-icon.svg"} alt="" srcset="" />
                                    <span className='ml-1' style={{
                                        fontSize: '14px', fontWeight: '700', color: walletPaySlipData.Status == 1 ? '#029754' : '#FA1228'
                                    }}>{walletPaySlipData.Status == 1 ? 'Approved' : 'Rejected'}</span>
                                </div>
                            </div>
                            <div className='flex justify-center items-center mt-3 mb-3'>
                                <div className='' style={{ position: 'absolute', left: '0' }}>
                                    <img src="/images/left-cricle.svg" alt="" srcset="" style={{}} />
                                </div>
                                <div style={{ color: '#b3b2b2' }}>----------------------------------------------------</div>
                                <div className='' style={{ position: 'absolute', right: '0' }}>
                                    <img src="/images/right-cricle.svg" alt="" srcset="" style={{}} />
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <div className=' w-full pt-2'>
                                    <ul>
                                        <li>
                                            <div className='flex justify-start items-center' style={{ paddingTop: '15px' }}>
                                                <span className='flex' style={{ color: '#666666', width: '44%' }}>Requested Amount </span> :<span className='pl-3' style={{ fontWeight: '700', fontSize: '14px' }}> {walletPaySlipData.amount}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='flex justify-start items-center' style={{ paddingTop: '15px' }}>
                                                <span className='flex' style={{ color: '#666666', width: '44%' }}>Requested Date </span> :<span className='pl-3' style={{ fontWeight: '700', fontSize: '14px' }}> {walletPaySlipData.requestDate ? moment(walletPaySlipData.requestDate).format('DD MMM YYYY h:mm A') : ''}
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='flex justify-start items-center' style={{ paddingTop: '15px' }}>
                                                <span className='flex' style={{ color: '#666666', width: '44%' }}>Transfer Type </span> :<span className='pl-3' style={{ fontWeight: '700', fontSize: '14px' }}> {walletPaySlipData.bank}({walletPaySlipData.paymentSubType})</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='flex justify-start items-center' style={{ paddingTop: '15px' }}>
                                                <span className='flex' style={{ color: '#666666', width: '44%' }}>Transaction Id </span> :<span className='pl-3' style={{ fontWeight: '700', fontSize: '14px' }}> {walletPaySlipData.UTRNo}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='flex justify-start items-center' style={{ paddingTop: '15px' }}>
                                                <span className='flex' style={{ color: '#666666', width: '44%' }}>Remark </span> :<span className='pl-3' style={{ fontWeight: '700', fontSize: '14px' }}>{walletPaySlipData.Comment}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='flex justify-between items-center p-2 mt-4 mb-6' style={{}}>
                                    <button
                                        className='p-2 cursor-pointer'
                                        style={{ width: '100%', backgroundColor: '#EEEEEF', color: '#00143C', borderRadius: '4px', fontSize: '14px', fontWeight: '700' }}
                                        onClick={() => {
                                            setWalletPaySlipOpen(false);
                                            setWalletPaySlipData({});
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    {/* <button
                                        className='p-2 cursor-pointer'
                                        style={{ width: '44%', backgroundColor: '#514EF3', color: '#fff', borderRadius: '4px', fontSize: '14px', fontWeight: '700' }}
                                        // onClick={downloadModalContent}
                                    >
                                        Download
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default WalletGateway