import React, { useContext, useEffect, useState, useRef } from 'react'
import Score1 from "../images/scoreOne.jpg";
import Score2 from "../images/score2.webp";
import OpenTvImg from "../images/tv-to-open.png";
import CloseTvImg from "../images/tv-to-close.png";
import TvCoverImg from "../images/cover-live.webp";
import ClockIconGreen from "../images/clock-icon-green.png";
import MinusIcon from "../images/minus-icon-white.png";
import ReloadIcon from "../images/reload-icon.svg";
import PinBg from "../images/sport-detail-pin-bg.svg";
import unPinBg from "../images/sport-detail-unpin-bg.svg";
import ReloadBg from "../images/sport-detail-reload-bg.svg";
import MiniFooter from '../components/MiniFooter';
// import News from '../components/News';
import { MdPushPin } from "react-icons/md";
import MatchOddTable from '../components/tables/MatchOddTable';
import FancyTable from '../components/tables/FancyTable';
import BetSlipListItem from '../components/BetSlipListItem';
import GlobalContext from '../context/GlobalContext';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { PiMonitorPlayBold } from "react-icons/pi";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import BookmakerTable from '../components/tables/BookmakerTable';
import { useLocation } from "react-router-dom";
import Loader from '../components/Loader';
import FancyModal from '../components/FancyModal';
import Cookies from "js-cookie";
import { toast } from 'react-toastify';

const SportDetail = () => {


  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);

  const params = useParams();
  const id = params?.id;
  const eid = params?.eid;

  // console.log('sportDetai id : ', id, " eid : ", eid)

  const { state } = useLocation();
  const marketId = state.marketId;
  const IsBetLock = state.IsBetLock;
  const matchName = state.matchName;
  const series = state.series;
  const isInPlay = state.isInPlay;
  const eventDate = state.eventDate;
  const isLive = state.isLive;

  // console.log('is in eventDate : ', eventDate)
  // console.log('is in isLive : ', isLive)
  // console.log(`sportdetail the location state market id is ${marketId}`);
  // console.log(`sportdetail the location state betlock is ${IsBetLock}`);
  console.log(`sportdetail the location state matchName is ${matchName} and series Id is : ${series}`);



  const [odds, setOdds] = useState([]);
  const [bm, setBM] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [matchOddBetProfit, setmatchOddBetProfit] = useState(0);
  const [bookMBetProfit, setBookMBetProfit] = useState(0);
  // const [isInPlay, setIsInPlay] = useState(false);
  const [totalMatched, setTotalMatched] = useState('0')
  const [checkMatch, setCheckMatch] = useState(0);
  const [render, setRender] = useState(false);
  const [bookmarkerData, setBookmarkerData] = useState([]);
  const [reloadPin, setReloadPin] = useState(false);
  // const [bookMDelay, setBookMDelay] = useState(0);
  // const [fancyDelay, setFancyDelay] = useState(0);



  // const getEventData = async() =>{
  //  //console.log('market id : ', marketId);
  //  //console.log('eventId is : ', id)
  //   let res1 = await fetch(`https://api.betcare.live/checkGetMatchOdds/${marketId}/${eid}`);
  //   const resData1 = await res1.json();
  //   let res = await axios.get(`/api/Data${id}.json`);
  //   const resData = await res?.data;
  //  //console.log('res Data is : ', resData)
  //  //console.log('res Data 1 is : ', resData1)

  //   return resData;
  // };


  // const {data, isLoading, isError} = useQuery({queryKey:["EventDetail"],queryFn:getEventData});
  // const OddMatchData = data?.Odds[0]?.runners; 
  // const OddMatchMatchId = data?.Odds[0]?.marketId;
  // const OddMatchDelay = data?.Odds[0]?.betDelay;   

  // const BookmakerData = data?.Bookmaker;
  // const FancyData = data?.Fancy;

  const fetchingEventsData = async () => {
    let res = await fetch(`https://api.betcare.live/fetchBetPageMatch/${id}`);
    let resData = await res.json();
    if (resData.isSuccess) {
      console.log('aaaa', resData?.data?.ActiveMatch);
      setCheckMatch(resData?.data?.ActiveMatch)
      if (resData?.data?.length < 0 || resData?.data?.Status != 1 || resData?.data?.ActiveMatch != 1) {
        window.location.href = '/';
      }
    }
  }

  const getEventData = async () => {
    console.log(' getEventData market id : ', marketId);
    console.log(' getEventData eventId is : ', id)
    let res1 = await fetch(`https://api.betcare.live/checkGetMatchOdds/${marketId}/${id}`);
    const resData1 = await res1.json();
    //console.log('res Data 1 is : ', resData1)
    // let res = await axios.get(`/api/Data${id}.json`);
    // const resData = await res?.data;
    ////console.log('res Data is : ', resData)

    // if (resData1.isSuccess) {

    //   if (Array.isArray(resData1.Odds)) {
    //     const firstOddsItem = resData1.Odds[0];
    //     setTotalMatched(firstOddsItem.totalMatched)

    //     if (Array.isArray(firstOddsItem.runners)) {
    //       const runnersData = firstOddsItem.runners;
    //       setOdds(runnersData);
    //     } else {
    //       console.error('No runners data available.');
    //     }
    //   } else {
    //     console.error('Invalid data format:', resData1);
    //   }

    //   if (Array.isArray(resData1.Bookmaker)) {
    //     //console.log("BookMaker data:", JSON.stringify(result.Bookmaker));
    //     setBM(resData1.Bookmaker);
    //   } else {
    //     console.error('No Bookmaker data available.');
    //   }

    //   if (Array.isArray(resData1.Fancy)) {
    //     //console.log("Fancy data:", JSON.stringify(result.Fancy));
    //     setFancy(resData1.Fancy);
    //   } else {
    //     console.error('No Fancy data available.');
    //   }

    // }

    return resData1;
  };

  const tableApi = async () => {
    try {
      // console.log("In SportDetail Com, market id : ", marketId, " id : ", id)
      const response = await fetch(`https://api.betcare.live/checkGetMatchOdds/${marketId}/${id}`);
      const result = await response.json();

      // console.log("Table api data of matchOdds : ", result);

      if (result.isSuccess) {

        if (Array.isArray(result.Odds)) {
          const firstOddsItem = result.Odds[0];
          setTotalMatched(firstOddsItem.totalMatched)
          // setIsInPlay(result.Odds[0].inplay)

          // console.log('runner inplay : ', result.Odds[0].inplay)
          if (Array.isArray(firstOddsItem.runners)) {
            const runnersData = firstOddsItem.runners;
            setOdds(runnersData);
            //console.log('odds data : ', runnersData)
          } else {
            console.error('No runners data available.');
          }
        } else {
          console.error('Invalid data format:', result);
        }

        if (Array.isArray(result.Bookmaker)) {
          //console.log("BookMaker data:", JSON.stringify(result.Bookmaker));
          setBM(result.Bookmaker[0]);
          // console.log('BM data : ', result.Bookmaker[0])
        } else {
          console.error('No Bookmaker data available.');
        }

        if (Array.isArray(result.Fancy)) {
          //console.log("Fancy data:", JSON.stringify(result.Fancy));
          setFancy(result.Fancy[0]);
          // console.log('Fancy data : ', result.Fancy[0])
        } else {
          console.error('No Fancy data available.');
        }

      }


    } catch (error) {
      console.error('Error fetching Table API data:', error);
    }
  };

  const fetchBet = async () => {
    //console.log("calling fetchbet..")
    try {
      const response = await fetch(`https://api.betcare.live/fetchLastBet/${userId.Id}/${id}`);
      const result = await response.json();
      // console.log("Bet result : " + JSON.stringify(result))

      if (result.isSuccess && result.data.length > 0) {

        const filteredData = result.data;
        //console.log('result data : ', result.data)
        // NEXT TEAM For BACK /////////////

        const filterODD = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "Matchodds" &&
            item.type == "back" &&
            odds.some(oddsitem => oddsitem.selectionId == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterODDSelectionIds = new Set(filterODD.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterOddAgainForOdd = odds.filter(item => !filterODDSelectionIds.has(item.selectionId));

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterOddAgainWithProfitLossOdd = filterOddAgainForOdd.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayForOdd = [...filterODD, ...filterOddAgainWithProfitLossOdd];

        // //console.log("Same Id data: " + JSON.stringify(selectionItems))

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionId = {};
        const totalLossBySelectionId = {};

        // Calculate net profit for each selectionId
        combinedArrayForOdd.forEach(item => {
          const selectionId = item.selectionId;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionId.hasOwnProperty(selectionId)) {
            totalProfitBySelectionId[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionId.hasOwnProperty(selectionId)) {
            totalLossBySelectionId[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionId[selectionId] += profit;
          totalLossBySelectionId[selectionId] += loss;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionId = {};
        Object.keys(totalProfitBySelectionId).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelections = Object.keys(totalLossBySelectionId)
            .filter(otherSelectionId => otherSelectionId != selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionId[otherSelectionId], 0);

          const profit = totalProfitBySelectionId[selectionId];
          // const loss = totalLossBySelectionId[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionId[selectionId] = profit + totalLossToOtherSelections;
        });

        // NEXT TEAM For LAY /////////////

        const filterODDLay = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "Matchodds" &&
            item.type == "lay" &&
            odds.some(oddsitem => oddsitem.selectionId == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterODDLaySelectionIds = new Set(filterODDLay.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterOddAgain = odds.filter(item => !filterODDLaySelectionIds.has(item.selectionId));

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterOddAgainWithProfitLoss = filterOddAgain.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArray = [...filterODDLay, ...filterOddAgainWithProfitLoss];

        //console.log("Combined Both Array: " + JSON.stringify(combinedArray));

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdLay = {};
        const totalLossBySelectionIdLay = {};

        // Calculate net profit for each selectionId
        combinedArray.forEach(item => {
          const selectionId = item.selectionId;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdLay.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdLay[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdLay.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdLay[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdLay[selectionId] += loss;
          totalLossBySelectionIdLay[selectionId] += profit;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdLay = {};
        Object.keys(totalProfitBySelectionIdLay).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelections = Object.keys(totalLossBySelectionIdLay)
            .filter(otherSelectionId => otherSelectionId !== selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLay[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdLay[selectionId];
          // const loss = totalLossBySelectionIdLay[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdLay[selectionId] = profit + totalLossToOtherSelections;
        });

        // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
        const finalProfitMatchOdd = {};

        Object.keys(netProfitBySelectionId).forEach(selectionId => {
          // Sum the corresponding values for each selectionId
          finalProfitMatchOdd[selectionId] = (netProfitBySelectionId[selectionId] || 0) + (netProfitBySelectionIdLay[selectionId] || 0);
        });

        if (Object.keys(netProfitBySelectionId).length === 0) {
          // Object is empty
          Object.keys(netProfitBySelectionIdLay).forEach(selectionId => {
            // Sum the corresponding values for each selectionId
            //console.log("Selection ID of final profit match odd: ", selectionId);
            finalProfitMatchOdd[selectionId] = netProfitBySelectionIdLay[selectionId] || 0;
          });
        }
        //console.log("Final Profit for Match Odd: ", finalProfitMatchOdd);

        setmatchOddBetProfit(finalProfitMatchOdd)
        // mop = finalProfitMatchOdd;
        // setmatchOddBetLoss(finalLossMatchOdd)
        // console.log('matchodds profit on sportDetail: ', finalProfitMatchOdd)

        // NEXT TEAM For BACK /////////////

        const filterBook = filteredData.filter(item => {

          return item.market == "BookMaker" &&
            item.type == "back" &&
            bm.some(bmitem => bmitem.sid == item.selectionId);
          // bm.some(bmitem => bmitem.sid == item.selectionId);
        });

        // console.log("Next team filter back bookMaker : " + JSON.stringify(filterBook))

        // Get unique selectionIds from filterODDLay
        const filterBookSelectionIds = new Set(filterBook.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterBookAgainForBooks = bm.filter(item => !filterBookSelectionIds.has(item.sid));

        const filterBookAgainForBook = filterBookAgainForBooks.flat();

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterBookAgainWithProfitLossBook = filterBookAgainForBook.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayForBook = [...filterBook, ...filterBookAgainWithProfitLossBook];

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdBook = {};
        const totalLossBySelectionIdBook = {};

        // Calculate net profit for each selectionId
        combinedArrayForBook.forEach(item => {
          const selectionId = item.selectionId || item.sid;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdBook.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdBook[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdBook.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdBook[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdBook[selectionId] += profit;
          totalLossBySelectionIdBook[selectionId] += loss;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdBook = {};
        Object.keys(totalProfitBySelectionIdBook).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelectionsBook = Object.keys(totalLossBySelectionIdBook)
            .filter(otherSelectionId => otherSelectionId != selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdBook[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdBook[selectionId];
          // const loss = totalLossBySelectionIdBook[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdBook[selectionId] = profit + totalLossToOtherSelectionsBook;
        });


        // NEXT TEAM For LAY /////////////

        const filterBookLayNextTeam = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "BookMaker" &&
            item.type == "lay" &&
            bm.some(bmitem => bmitem.sid == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterBookLaySelectionIds = new Set(filterBookLayNextTeam.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterBookAgains = bm.filter(item => !filterBookLaySelectionIds.has(item.sid));
        //console.log("filter Book Again : " + JSON.stringify(filterBookAgains.flat()));

        const filterBookAgain = filterBookAgains.flat();

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterBookAgainWithProfitLoss = filterBookAgain.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));


        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayBook = [...filterBookLayNextTeam
          , ...filterBookAgainWithProfitLoss];

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdLayBook = {};
        const totalLossBySelectionIdLayBook = {};

        // Calculate net profit for each selectionId
        combinedArrayBook.forEach(item => {
          const selectionId = item.selectionId || item.sid;
          const profit = item.profit;
          const loss = item.liability;


          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdLayBook.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdLayBook[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdLayBook.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdLayBook[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdLayBook[selectionId] += loss;
          totalLossBySelectionIdLayBook[selectionId] += profit;
        });

        //console.log("total after combined profit : ", totalProfitBySelectionIdLayBook)
        //console.log("total after combined loss : ", totalLossBySelectionIdLayBook)


        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdLayBook = {};
        Object.keys(totalProfitBySelectionIdLayBook).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelectionsBook = Object.keys(totalLossBySelectionIdLayBook)
            .filter(otherSelectionId => otherSelectionId !== selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLayBook[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdLayBook[selectionId];
          // const loss = totalLossBySelectionIdLayBook[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdLayBook[selectionId] = profit + totalLossToOtherSelectionsBook;
        });


        //console.log("Total Profit by SelectionId Book: ", netProfitBySelectionIdBook)
        //console.log("Total Loss by SelectionId Book: ", totalLossBySelectionIdBook)
        //console.log("Total Profit by SelectionId Lay Book: ", totalProfitBySelectionIdLayBook);
        //console.log("Total Loss by SelectionId Lay Book: ", totalLossBySelectionIdLayBook);
        //console.log("Net Profit by SelectionId Lay Book: ", netProfitBySelectionIdLayBook);

        // const finalProfitMatchOdd = netProfitBySelectionId + netProfitBySelectionIdLay
        // // const finalLossMatchOdd = totalLossBySelectionId + netProfitBySelectionIdLay

        // //console.log("Profit " + finalProfitMatchOdd)


        // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
        const finalProfitBook = {};

        Object.keys(netProfitBySelectionIdBook).forEach(selectionId => {
          // Sum the corresponding values for each selectionId
          finalProfitBook[selectionId] = (netProfitBySelectionIdBook[selectionId] || 0) + (netProfitBySelectionIdLayBook[selectionId] || 0);
        });

        setBookMBetProfit(finalProfitBook)
        // setmatchOddBetLoss(finalLossMatchOdd)
        console.log('BookMaker profit on sportdetail : ', finalProfitBook)
      } else {
        setmatchOddBetProfit(0)
        setBookMBetProfit(0)
      }



    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const BookmarkingFunc = async (status) => {

    if (isUserLoggedIn === undefined) {
      setIsLoginModeOpen(true)
      return;
    }

    try {
      //console.log("still trying....");
      let res = await axios.post(`https://api.betcare.live/api/events/bookmark/${userId?.Id}/${id}`, {
        marketId: marketId,
        sportId: eid,
        eventName: matchName,
        isActive: status == 1 ? 0 : 1,
        series: series,
        isInPlay: isInPlay,
        eventDate: eventDate,
        isLive: isLive,
        IsBetLock: IsBetLock
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (res.status === 200) {
        // toast.success(`${status === 1 ? "removed successfully!" : "bookmarked successfully!"}`, {
        //   autoClose: 1500,
        //   theme: "colored",
        // });
        setRender((prev) => !prev);
      } else {
        alert(`failed to update bookmark!`);
      }

    }
    catch (error) {
      alert(`error in api!`);
    }
  }


  const { data, isLoading, isError } = useQuery({ queryKey: ["EventDetail"], queryFn: getEventData });
  const OddMatchData = data?.Odds?.[0]?.runners;
  const OddMatchMatchId = data?.Odds?.[0]?.marketId;
  const OddMatchDelay = 2;

  const BookmakerData = data?.Bookmaker?.[0];
  const FancyData = data?.Fancy?.[0];
  const CountMORunner = data?.Odds?.[0]?.runners?.length;
  const CountBMRunner = data?.Bookmaker?.[0]?.length;

  //console.log("getEventData data api : ", data)

  //console.log("Bookmaker api : ", BookmakerData)
  //console.log("Fancy api : ", FancyData)


  const { data: minMaxStake } = useQuery({
    queryKey: [`minAndMaxStack`], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/GetStackLimit`);
      let resData = await res?.data;
      console.log('min max stack .. : ', resData)
      return resData;
    }
  });


  const { data: currency, isLoading: currencyLoading, isError: currencyError } = useQuery({
    queryKey: ["currency"], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/api/getActiveCurrency/currency`);
      let resData = await res.data;
      return resData;
    }
  })

  const minMaxData = minMaxStake?.data;
  let sport = minMaxData?.sports;
  let userMatchSetting = minMaxData?.userMatchSettings;

  const filterData = sport?.filter((game) => eid == game.id);
  const FilterData2 = userMatchSetting?.filter((game) => game.gameId == id);

  const as = FilterData2?.[0]?.minBookmakerLimit;

  const betDelay = filterData?.[0]?.betDelayTime
  const bookMDelay = filterData?.[0]?.bookmakerDelayTime
  const fancyDelay = filterData?.[0]?.fancyDelayTime

  // const [betDelay, setBetDelay] = useState(0);
  // const [bookMDelay, setBookMDelay] = useState(0);
  // const [fancyDelay, setFancyDelay] = useState(0);


  // console.log('sport detail filterData : ', filterData);
  // console.log('Bet delay for 3 : ', betDelay, bookMDelay, fancyDelay)
  // console.log('sport detail filterData 2 : ', FilterData2);
  // //console.log('sport Detail minMaxDate : ', minMaxData);
  // console.log('sport Detail sport : ', sport);
  // console.log("sport Detail userMatchSetting : ", userMatchSetting)

  ////////////////////////////////////////////////////////////////////////////////////////





  //console.log("Sport Detatil page render !! ");

  const [bets, setBets] = useState([]);
  const [betSlip, setBetSlip] = useState([{}]);
  const [isScoreCardShown, setIsScoreCardShown] = useState(false);  // this state is for the top score screen to maximize it or not
  const [isTvShown, setIsTvIsShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fancyModalOpen, setFancyModalOpen] = useState({ isOpen: false });

  const context = useContext(GlobalContext);
  const { setIsLoginModeOpen, betSlipData, setBetSlipData, selectedEvent, isUserLoggedIn, betEventId, setBetEventId, betSend } = context;

  setBetEventId(id)

  useEffect(() => {

    return () => {
      setBetSlipData();
    }
  }, [])

  useEffect(() => {
    if (isLive) {
      tableApi();
    }
  });



  useEffect(() => {
    fetchingEventsData();
    tableApi();
    // fetchBet();
  }, [])

  const fetchCount = useRef(0);

  useEffect(() => {
    const callFetchBetWithDelay = () => {
      if (fetchCount.current < 2) {
        setTimeout(() => {
          fetchBet();
          fetchCount.current += 1;
        }, 2000);
      }
    };

    callFetchBetWithDelay();
  }, [odds]);

  useEffect(() => {
    fetchBet();
  }, [betSend])

  useEffect(() => {
    //console.log(isUserLoggedIn+"wee");

    const getBookmarkDataFunc = async () => {
      //console.log(isUserLoggedIn);


      try {
        //console.log("not-stopped");
        let res = await axios.get(`https://api.betcare.live/api/getBookmarkedDatabyEventId/${userId?.Id}/${id}`);
        let resData = await res.data;
        setBookmarkerData(resData);
        //console.log(resData);
      } catch (error) {
        //console.log('error in fetching bookmarked data!!');
      }

    };



    if (isUserLoggedIn === "true") {
      ////console.log("stopped");
      getBookmarkDataFunc();
      return;
    }



  }, [render])

  useEffect(() => {
    if (reloadPin === true) {
      tableApi();
      fetchBet();
      setReloadPin(false);
      setTimeout(()=> {
        setLoading(false);
      }, 2000)
    }
  }, [reloadPin])

  const eventDateC = new Date(eventDate);
  const currentDate = new Date();
  // Format eventDate to only include the date part
  // Get the day of the week
  const options = { weekday: 'short', day: '2-digit', month: 'short' };
  const formattedDate = eventDateC.toLocaleDateString('en-US', options);

  // Get the time in 24-hour format
  const formattedTime = eventDateC.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

  // Combine date and time
  const formattedEventDate = `${formattedDate}, ${formattedTime}`;

  // console.log("formattedEventDate : ", formattedEventDate);



  return (
    <>
      {loading && <Loader />}

      {/* for bigger screen */}
      <div className='flex h-[90vh] lg:h-[80vh]  overflow-y-hidden '>

        {fancyModalOpen?.isOpen && <FancyModal fancyData={FancyData} eventId={id} fancyModal={fancyModalOpen} setFancyModalOpen={setFancyModalOpen} />}


        <div className='w-[18vw] hidden lg:block' >
          <h1 className='bg-black text-white text-sm px-4 py-1'>Sports</h1>
          <h1 className='text-sm px-4 py-[2px] bg-white hover:bg-[#f8f3e6] cursor-pointer'>All Sports</h1>
          <h1 className='bg-black text-white text-sm px-4 py-1 cursor-pointer'>Kabaddi</h1>
          <h1 className='bg-[#dddcd6] text-black text-sm px-4 py-1 '>Commons</h1>
          <h1 className='bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer'>Others</h1>
        </div>

        <div className='lg:w-[57vw] w-screen  overflow-y-scroll lg:px-3 bg-[#eeeeee]' >
          {/* <News /> */}
          {/* <img src={Score1} className='w-full border-t border-white ' alt="" />
          <img src={Score2} className={`${isScoreCardShown ? "block" : "hidden"}`} alt="" /> */}

          <iframe
            id="animscore"
            // src={eid != 4 ? `https://stream.1ex99.in/sportRadarScore?eventId=${gameId}` : `https://centerpanel.ons3.co/owner/score/?market_id=${marketId}`}
            src={`https://winx777.com/score/sportRadar/?eventId=${id}`}
            className="iframestyle"
            title="Match Score"
            style={{ display: "none" }}
          />
          <div className="score_area">
            <iframe
              id="cricketScore"
              // src={eid != 4 ? `https://stream.1ex99.in/sportRadarScore?eventId=${gameId}` : `https://centerpanel.ons3.co/owner/score/?market_id=${marketId}`}
              src={`https://winx777.com/score/sportRadar/?eventId=${id}`}
              style={{
                height: "100%",
                width: "100%",
                background: "url(/images/score-bg.png)no-repeat"
              }}
              className="iframestyle"
              title="Match Score"
            />
          </div>



          {/* <div onClick={() => setIsScoreCardShown(prev => !prev)} className='bg-[#232323] cursor-pointer flex justify-center py-2 '> {isScoreCardShown ? <IoIosArrowUp className='text-[#ffce3b] text-xl' /> : <IoIosArrowDown className='text-[#ffce3b] text-xl' />}  </div> */}
          <div>

            <div className='w-full bg-[#eee] lg:bg-white  flex flex-col mb-4  lg:border-0  lg:mb-0'>
              <div className='flex gap-[1px] mx-auto '>
                <div className='w-[100px] h-[25px] flex justify-center bg-[#3b5160] items-center gap-1 py-1 ' style={{ background: bookmarkerData.length > 0 ? `url(${unPinBg})` : `url(${PinBg})`, cursor: 'pointer' }} onClick={() => { BookmarkingFunc(1) }}> <MdPushPin className='size-[13px] text-white' /> <span className='text-white text-xs font-semibold mb-1'>Pin</span> </div>
                <div className='w-[100px] h-[25px] flex justify-center bg-[#3b5160] items-center gap-1 py-1' style={{ background: `url(${ReloadBg})`, cursor: 'pointer' }} onClick={() => { setReloadPin(true); setLoading(true); }} > <img src={ReloadIcon} className='size-[13px]' alt="" /> <span className='text-white text-xs font-semibold '>Reload</span> </div>
              </div>
              <div className='hidden lg:flex justify-between items-center'>
                <div className='flex gap-2 items-center'>
                  <h1 className='bg-[#c5d0d7] text-sm font-semibold py-1 px-2 '>Match Odds</h1>
                  {isLive ? <img src={ClockIconGreen} className='rounded' alt="" /> : ''}

                  <p className={`text-xs text-${isLive ? '[#508d0e]' : '[#c5d0d8]'} `}>{isLive ? 'In-Play' : formattedEventDate}</p>
                </div>
                <span className='bg-[#bed5d8] text-[#315195] text-xs flex items-center h-fit rounded-[3px] px-1 mr-14'>Max {FilterData2?.length === 1 ? FilterData2?.[0]?.maxOddLimit : filterData?.[0]?.maxOddLimit}</span>
                <div className='text-[13px] font-[#243a48]' >Matched <span className='text-[#1e1e1e] font-bold px-1'> {currency?.[0]?.CurrencyName} {totalMatched} </span>  </div>
                <div onClick={() => { setIsTvIsShown(prev => !prev) }} className={`flex items-center ${selectedEvent?.isTvAvailable ? "block" : "hidden"}  gap-1 cursor-pointer text-xs text-white p-[2px] mr-2 rounded-sm`} style={isTvShown ? { background: `linear-gradient(180deg, #df4000 0%, #cd3a00 100%)` } : { background: `linear-gradient(180deg, #1e5f89 0%, #194f72 100%)` }} > <img src={isTvShown ? CloseTvImg : OpenTvImg} alt="" />  Live </div>
                {/* <PiMonitorPlayBold className='text-lg' /> */}
              </div>
            </div>
          </div>

          {/* the heading of the match odds */}
          <span className='lg:hidden text-[#ffb200] py-2 px-3 rounded-full font-bold text-sm' style={{ background: "linear-gradient(180deg, #474747 0%, #070707 100%)" }}>Match Odds</span>

          {/* <video className={`h-[270px] w-full ${isTvShown ? "block" : "hidden"}`} poster={TvCoverImg} src=""></video> */}
          <div className={`h-[270px] w-full ${isTvShown ? "block" : "hidden"}`}>
            <iframe
              id="tvFrame"
              style={{ width: "100%", height: 250 }}
              // src={`https://stream.1ex99.in/tv2?EventId=${gameId}`}
              src={`https://winx777.com/tv/?EventId=${id}`}
            />
          </div>

          {/*  the match odd table */}
          <MatchOddTable
            needHeader={false}
            minStake={FilterData2?.length === 1 ? FilterData2?.[0]?.minOddLimit : filterData?.[0]?.minOddLimit}
            maxStake={FilterData2?.length === 1 ? FilterData2?.[0]?.maxOddLimit : filterData?.[0]?.maxOddLimit}
            OddMatchData={odds}
            EventNameBetSlip={OddMatchData?.[0].runnerName}
            eid={eid}
            eventId={id}
            setLoading={setLoading}
            matchId={OddMatchMatchId}
            marketId={isUserLoggedIn === "true" && state?.marketId}
            delayTime={betDelay}
            matchOddBetProfits={matchOddBetProfit}
            IsBetLock={IsBetLock}
            CountMORunner={CountMORunner}
            matchName={matchName}
            series={series}
          />

          <BookmakerTable
            needHeader={true}
            minStake={FilterData2?.length === 1 ? FilterData2?.[0]?.minBookmakerLimit : filterData?.[0]?.minBookmakerLimit}
            maxStake={FilterData2?.length === 1 ? FilterData2?.[0]?.maxBookmakerLimit : filterData?.[0]?.maxBookmakerLimit}
            eid={eid}
            matchId={OddMatchMatchId}
            EventNameBetSlip={data?.Odds && data.Odds.length > 0 ? data.Odds[0].matchName : ''}
            marketId={isUserLoggedIn === "true" && state?.marketId}
            delayTime={bookMDelay}
            eventId={id}
            setLoading={setLoading}
            BookmakerData={bm}
            bookMBetProfits={bookMBetProfit}
            IsBetLock={IsBetLock}
            CountBMRunner={CountBMRunner}
            matchName={matchName}
            series={series}
          />

          <FancyTable
            headerType={""}
            minStake={FilterData2?.length === 1 ? FilterData2?.[0]?.minFancyLimit : filterData?.[0]?.minFancyLimit}
            maxStake={FilterData2?.length === 1 ? FilterData2?.[0]?.maxFancyLimit : filterData?.[0]?.maxFancyLimit}
            eid={eid}
            eventId={id}
            setFancyModalOpen={setFancyModalOpen}
            setLoading={setLoading}
            marketId={isUserLoggedIn === "true" && state?.marketId}
            delayTime={fancyDelay}
            EventNameBetSlip={data?.Odds && data.Odds.length > 0 ? data.Odds[0].matchName : ''}
            FancyData={fancy}
            IsBetLock={IsBetLock}
            matchName={matchName}
            series={series}
          />



          {/*  footer for sport pages */}
          <div className='hidden lg:block'>
            <MiniFooter />
          </div>

        </div>

        <div className='lg:block hidden bg-white w-[25vw] ml-2 border-t'>
          <h1 className='bg-[#274252] text-white text-xs  font-semibold p-1 flex justify-between'>Bet Slip <img src={MinusIcon} className='h-4' alt="" /></h1>


          {
            betSlipData !== undefined ? <BetSlipListItem
              SelectedRowName={betSlipData?.EventName}   // name o
              EventNameBetSlip={data?.Odds[0]?.matchName}
              minStake={betSlipData?.dataType === "match odds" ? FilterData2?.length === 1 ? FilterData2?.[0]?.minBookmakerLimit : filterData?.[0]?.minBookmakerLimit : FilterData2?.length === 1 ? FilterData2?.[0]?.minBookmakerLimit : filterData?.[0]?.minBookmakerLimit}
              maxStake={betSlipData?.dataType === "match odds" ? FilterData2?.length === 1 ? FilterData2?.[0]?.maxOddLimit : filterData?.[0]?.maxOddLimit : FilterData2?.length === 1 ? FilterData2?.[0]?.maxBookmakerLimit : filterData?.[0]?.maxBookmakerLimit}
              type={betSlipData?.type}
              eid={eid}
              eventId={id}
              setLoading={setLoading}
              marketId={isUserLoggedIn === "true" && state?.marketId}
              market={betSlipData?.market}
              selectionId={betSlipData?.selectionId}
              priceBetSlip={betSlipData?.value}
              TypeOfData={betSlipData?.dataType} />

              : <h1 className='p-3 text-xs text-center'>Click on the odds to add selections to the betslip.</h1>
          }

        </div>
      </div>
    </>
  )
}

export default SportDetail
