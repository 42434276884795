import React from 'react'
import { Audio, RotatingLines } from "react-loader-spinner";
import Loading from "../images/loading.gif";

const Loader = ({styling}) => {

  let defaultStyle = `h-[100vh] absolute overflow-hidden w-full z-50 flex bg-transparent justify-center items-center`;
  return (
    <div className={`${styling ? styling : defaultStyle }`} >
        
        <div className='bg-white py-2 px-10 rounded-lg '  style={{boxShadow:"0 5px 10px rgba(0,0,0,.5)"}}>
        <img src={Loading} alt="" className='w-[38px] h-[22px] mx-auto' />
          <p className='pt-2'>Loading..</p>
        </div>
      
     

    </div>
  )
}

export default Loader
