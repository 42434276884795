import React, { useContext, useEffect } from 'react'
import { IoIosArrowForward } from "react-icons/io";
import UserIcon from "../images/user-icon.svg";
import EnterIcon from "../images/enter-icon.svg";
import GlobalContext from '../context/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import Cookie from "js-cookie";
import axios from 'axios';
import Cookies from 'js-cookie';

const AccountMobile = () => {

  console.log("AccountDetail of Mobile is rendering!");

  const context = useContext(GlobalContext);
  const { LoginUserDetail, setIsUserLoggedIn } = context;

  const navigate = useNavigate();

  let userId2 = Cookies.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON.parse(userId2);


  const handleLogOut = async () => {
    console.log('Account Mobile logout user Id : ', userId?.Id)
    try {
      let res = await axios.post('https://api.betcare.live/logout', {
        UserId: userId?.Id
      }, {
        headers: { "Content-Type": "application/json" }
      });

      if (res.status === 200) {
        localStorage.removeItem('isUserLoggedIn');
        localStorage.removeItem("LoginUserDetail");

        Cookies.remove('isLoggedIn');
        Cookies.remove('LoginUserDetail');
        Cookies.remove('userIP');
        setIsUserLoggedIn(false);
        navigate("/");

      } else {
        alert(`error in api / failed to logout!`);
      }



    } catch (error) {
      alert("failed to logged out !!");
    }

  }


  ////console.log(LoginUserDetail);

  return (
    <div className='h-screen w-full bg-[#eee]' >
      <div className='flex bg-black p-2 border-t-2 border-white text-white items-center justify-between' style={{fontFamily: 'Helvetica, Tahoma, sans-serif'}}>
        <div className=' flex gap-2 '>
          <img src={UserIcon} alt="" />
          <h1 className='text-white font-bold' style={{fontSize: '14.7333px'}} > {LoginUserDetail?.UserName} </h1>
        </div>
        <h2 className='font-bold' style={{fontSize: '14.7333px'}}>GMT+5:30</h2>
      </div>

      <ul className='text-[#2789ce] border-b bg-white border-[#7e97a7] ' style={{marginBottom: '5.3333333333vw'}}>
        <Link to="/account" className='text-[4vw] font-bold p-2 border-b border-[#e0e6e6] flex justify-between items-center' style={{fontFamily: 'Helvetica, Tahoma, sans-serif'}} >My Profile <IoIosArrowForward className='text-black font-bold h-5 w-5 rounded-sm border border-[#e0e6e6]' /> </Link>
        <Link to="/account/balanceOverview" className='text-[4vw] font-bold p-2 border-b border-[#e0e6e6] flex justify-between items-center' style={{fontFamily: 'Helvetica, Tahoma, sans-serif'}}>Balance Overview <IoIosArrowForward className='text-black font-bold h-5 w-5 rounded-sm border border-[#e0e6e6]' /> </Link>
        <Link to="/account/mybet" state={{ clickedOption: 'current' }} className='text-[4vw] font-bold p-2 border-b border-[#e0e6e6] flex justify-between items-center' style={{fontFamily: 'Helvetica, Tahoma, sans-serif'}}>My Bets <IoIosArrowForward className='text-black font-bold h-5 w-5 rounded-sm border border-[#e0e6e6]' /> </Link>
        <Link to="/account/mybet" state={{ clickedOption: 'history' }} className='text-[4vw] font-bold p-2 border-b border-[#e0e6e6] flex justify-between items-center' style={{fontFamily: 'Helvetica, Tahoma, sans-serif'}}>Bet History <IoIosArrowForward className='text-black font-bold h-5 w-5 rounded-sm border border-[#e0e6e6]' /> </Link>
        <Link to="/account/mybet" state={{ clickedOption: 'profitAndLoss' }} className='text-[4vw] font-bold p-2 border-b border-[#e0e6e6] flex justify-between items-center' style={{fontFamily: 'Helvetica, Tahoma, sans-serif'}}>Profit & Loss <IoIosArrowForward className='text-black font-bold h-5 w-5 rounded-sm border border-[#e0e6e6]' /> </Link>
        <Link to="/account/activityLog" className='text-[4vw] font-bold p-2  flex justify-between items-center' style={{fontFamily: 'Helvetica, Tahoma, sans-serif'}}>Activity Log <IoIosArrowForward className='text-black font-bold h-5 w-5 rounded-sm border border-[#e0e6e6]' /></Link>
      </ul>

      <button onClick={handleLogOut} className='h-12 w-full border border-[#8a0011] font-semibold flex justify-center items-center gap-1 text-[4.3vw] text-white' style={{ background: "linear-gradient(-180deg, #e93522 0%, #be2414 100%)" }} >LOGOUT <img src={EnterIcon} alt="" /> </button>

    </div>
  )
}

export default AccountMobile
