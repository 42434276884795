import React, { useEffect, useState } from 'react'
import DottedCircleImg from "../../images/dotted-circle.svg";
import MinusIcon from "../../images/minus-icon-white.png";
import ClockIcon from "../../images/clock-icon-green.png";
import ReloadIcon from "../../images/reload-icon.svg";
import BackAllImg from "../../images/bg-backall.png";
import BackAllDisableImg from "../../images/bg-backall-disabled.png";
import LayAllImg from "../../images/bg-layall.png";
import LayAllDisableImg from "../../images/bg-layall-disabled.png";
import DisabledImg from "../../images/bg-disabled.png";
import { Link } from 'react-router-dom';
import { MdPushPin } from "react-icons/md";
import GreenPinIcon from "../../images/pin-icon-green.svg";
import ReportIcon from "../../images/report-icon.svg";
import PIcon from "../../images/p-icon.svg";
import { IoIosArrowForward } from "react-icons/io";
// import News from '../../components/News';
import InMatchTable from '../../components/tables/InMatchTable';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';




const MultiMarketListItem = ({ marketId, EventName, EventId, SportId, setRender, series, isInPlay, eventDate, isLive, IsBetLock }) => {

  console.log('MultiMarketListItem length : ');

  const [isBlocked, setIsBlocked] = useState(false);

  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);

  const BookmarkingFunc = async () => {

    if (userId2 === undefined) {
      //console.log("stopped");
      return;
    }

    // alert("start" +userId?.Id + EventId );
    try {
      let res = await axios.post(`https://api.betcare.live/api/events/bookmark/${userId?.Id}/${EventId}`, {
        marketId: marketId,
        sportId: SportId,
        eventName: EventName,
        isActive: 0,
        series: series,
        isInPlay: isInPlay,
        eventDate: eventDate,
        isLive: isLive,
        IsBetLock: IsBetLock
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (res.status === 200) {
        // toast.success(`removed successfully!`, {
        //   autoClose: 1500,
        //   theme: "colored",
        // });
        setRender((prev) => !prev);
      } else {
        alert(`failed to update bookmark!`);
      }

    }
    catch (error) {
      alert(`error in api!`);
    }
  }

  console.log('bookMarked eventDate : ', EventName, " date :  ", eventDate);

  // Parse the event date to a Date object
  const eventDateF = new Date(eventDate);

  // Add 5 hours and 30 minutes
  eventDateF.setHours(eventDateF.getHours() + 5);
  eventDateF.setMinutes(eventDateF.getMinutes() + 30);

  // Format the new date back to the desired string format (if necessary)
  const updatedEventDate = eventDateF.toLocaleString();

  console.log('In Pc Update EventDate : ', updatedEventDate, ' of event : ', eventDateF)
  const compare = eventDateF <= new Date();
  console.log('comare eventDate : ', compare)


  return (
    <>
      <div className='bg-white border-b mb-4 border-[#7e97a7]' >

        <div className='bg-[#243a48] cursor-pointer text-white flex justify-between items-center' >

          <div className='flex gap-1 text-xs items-center'>

            <div className='p-1' style={{ background: "linear-gradient(0deg, rgba(101,183,19,1) 7%, rgba(73,155,30,1) 61%)" }} >
              <MdPushPin onClick={BookmarkingFunc} className='text-white h-5 w-5' />
            </div>
            <Link to={`/event/${EventId}/${SportId}`} state={{ marketId: marketId, IsBetLock: IsBetLock, matchName: EventName, series: series, isInPlay: isInPlay, eventDate: eventDateF, isLive: compare }}  >
              <h1>{EventName}</h1>
            </Link>
            <p className='bg-white text-black px-2 rounded-sm' >Match Odds</p>


            {compare ? <img src={ClockIcon} className='rounded-sm' alt="" /> : ''}
          </div>

          <img src={ReloadIcon} className='h-4 w-4 mr-2' alt="" />

        </div>


        <div className='flex text-xs justify-between border-b border-[#7e97a7] items-center mb-1 pb-[1px] mx-2'>
          <p className='text-[#7e97a7]'>2 selections</p>

          <p>0%</p>

          <div className='flex gap-[1px] cursor-pointer'>
            <div className={`relative mt-1 ${isBlocked ? "cursor-not-allowed" : "hover:brightness-[1.1]"}   `}>
              <img src={BackAllImg} className='' alt="" />
              {isBlocked ? <>
                <img src={BackAllDisableImg} className='absolute top-0 right-0' alt="" />
                <img src={BackAllDisableImg} className='absolute top-0 right-8' alt="" />
                <img src={BackAllDisableImg} className='absolute top-0 right-[66px]' alt="" />
                <img src={BackAllDisableImg} className='absolute top-0 ' alt="" />
              </>
                : <></>
              }

              <span className='absolute top-1 font-bold right-12' >Back all</span>
            </div>
            <div className={`relative  ${isBlocked ? "cursor-not-allowed" : "hover:brightness-[1.1]"} mt-1 hover:brightness-[1.1] `}>
              <img src={LayAllImg} className='' alt="" />
              {isBlocked ? <>
                <img src={LayAllDisableImg} className='absolute top-0 left-0' alt="" />
                <img src={LayAllDisableImg} className='absolute top-0 left-8' alt="" />
                <img src={LayAllDisableImg} className='absolute top-0 left-[66px]' alt="" />
                <img src={LayAllDisableImg} className='absolute top-0 right-0' alt="" />
              </>
                : <></>
              }
              <span className='absolute top-1 font-bold right-12' >Lay all</span>
            </div>
          </div>
          <p>0%</p>
        </div>
      </div>
    </>
  )
}




const MultiMarketList = ({ marketId, EventName, EventId, SportId, setRender, series, isInPlay, eventDate, isLive, IsBetLock }) => {

  console.log('multiMarketList length : ');
  const Game = ["", "Soccer", "Tennis", "", "Cricket"];

  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);

  const BookmarkingFunc = async () => {
    // alert("start" +userId?.Id + EventId );

    if (userId2 === undefined) {
      //console.log("stopped");
      return;
    }

    try {
      //console.log("still trying..");
      let res = await axios.post(`https://api.betcare.live/api/events/bookmark/${userId?.Id}/${EventId}`, {
        marketId: marketId,
        sportId: SportId,
        eventName: EventId,
        isActive: 0,
        series: series,
        isInPlay: isInPlay,
        eventDate: eventDate,
        isLive: isLive,
        IsBetLock: IsBetLock
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (res.status === 200) {

        // toast.success(`removed successfully!`, {
        //   autoClose: 1500,
        //   theme: "colored",
        // });
        setRender((prev) => !prev);

      } else {
        alert(`failed to update bookmark!`);
      }

    }
    catch (error) {
      alert(`error in api!`);
    }
  }

  const eventDateC = new Date(eventDate);
  const currentDate = new Date();
  // Format eventDate to only include the date part
  // Get the day of the week
  const options = { weekday: 'short', day: '2-digit', month: 'short' };
  const formattedDate = eventDateC.toLocaleDateString('en-US', options);

  // Get the time in 24-hour format
  const formattedTime = eventDateC.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

  // Combine date and time
  const formattedEventDate = `${formattedDate}, ${formattedTime}`;
  console.log('formatedate : ', formattedEventDate)

  // Parse the event date to a Date object
  const eventDateF = new Date(eventDate);

  // Add 5 hours and 30 minutes
  eventDateF.setHours(eventDateF.getHours() + 5);
  eventDateF.setMinutes(eventDateF.getMinutes() + 30);

  // Format the new date back to the desired string format (if necessary)
  const updatedEventDate = eventDateF.toLocaleString();

  console.log('In mobile Update EventDate : ', updatedEventDate, ' of event : ', eventDateF)

  // console.log('MultimarketList : ', eventDate)
  // const newE = new Date(eventDate)
  // const isoT = newE.toISOString()
  // console.log('MultimarketList to ISO : ', isoT)
  const compare = eventDateF <= new Date();
  console.log('comare eventDate : ', compare)

  return (
    <>
      <div className='text-white mb-3 border-white'>
        <Link to={`/event/${EventId}/${SportId}`} state={{ marketId: marketId, IsBetLock: IsBetLock, matchName: EventName, series: series, isInPlay: isInPlay, eventDate: eventDate, isLive: compare }} className='flex justify-between px-2 py-1' style={{ background: "linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)" }} >
          <h1>{Game[SportId]}</h1>
          <p>{compare ? 'In-Play' : updatedEventDate}</p>
        </Link>

        <div className='bg-black py-2 flex justify-between px-2' >
          <div className='flex items-center gap-1' >
            <img src={GreenPinIcon} onClick={BookmarkingFunc} alt="" />
            <Link to={`/event/${EventId}/${SportId}`} state={{ marketId: marketId, IsBetLock: IsBetLock, matchName: EventName, series: series, isInPlay: isInPlay, eventDate: eventDate, isLive: compare }}>
              <h1 className=''>{EventName?.length >= 25 ? EventName.slice(0, 26) + "..." : EventName}</h1>
            </Link>
          </div>
          <div className='flex gap-2 items-center' >
            <div className='bg-[#3b5160] rounded-sm'> <IoIosArrowForward className='h-6 w-6' /></div>
            <div><img src={ReloadIcon} alt="" /></div>
          </div>
        </div>

        <div className='flex px-2 justify-between font-semibold pb-1 items-end bg-white pt-2 border-b-2 border-[#7e97a7]'>
          <div className='flex gap-1 items-end  text-black' >
            <img src={ReportIcon} alt="" />
            <p className='text-xs' >Match Odds</p>
          </div>
          <div className='flex gap-10 mr-5 text-sm text-black' >
            <h1>Back</h1>
            <h1>Lay</h1>
          </div>
        </div>
      </div>
    </>
  )
}





const MultiMarket = () => {

  const [bookmarkData, setBookmarkData] = useState([]);
  const [render, setRender] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [loading, setLoading] = useState(false);

  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);

  // let isLoggedin = Cookies?.get("LoginUserDetail");
  ////console.log(isLoggedin);

  useEffect(() => {


    const getBookmarkDataFunc = async () => {
      try {
        setLoading(true)
        let res = await axios.get(`https://api.betcare.live/api/getBookmarkedData/${userId?.Id}`);
        let resData = await res.data;
        setBookmarkData(resData);
        //console.log(resData);
      } catch (error) {
        console.log('error while fetching bookmarked ..', error);
        setLoading(false)
      } finally {
        setLoading(false)
      }
    };

    if (userId2 !== undefined) {
      getBookmarkDataFunc();
      return;
    }
  }, [render])


  useEffect(() => {
    let heightOne = document.body.scrollHeight;
    let heightTwo = window.screen.height;

    setContentHeight(heightOne);
    setScreenHeight(heightTwo);

    console.log(`the height 1 is : ${heightOne} and height 2 is : ${heightTwo}`);
  }, [])

  console.log('MultiMarket length : ', bookmarkData.length);

  return (
    <div>
      {/* for smaller screen  */}
      {loading && <Loader />}

      {/* for bigger screen */}
      <div className='flex h-[80vh]  overflow-y-hidden '>

        <div className='w-[18vw] hidden lg:block' >
          <h1 className='bg-black text-white text-sm px-4 py-1'>Sports</h1>
          <Link to={'/cricket'}> <h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>Cricket</h1></Link>
          <Link to={'/soccer'}><h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>Soccer</h1></Link>
          <Link to={'/tennis'}><h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>Tennis</h1></Link>
          <h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>E-Soccer</h1>
          <h1 className={`bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer`}>Kabaddi</h1>
        </div>
        {/* linear-gradient(180deg, rgba(101,183,19,1) 7%, rgba(73,155,30,1) 61%) */}
        <div className='w-[100vw] lg:w-[57vw]   overflow-y-scroll lg:px-3 bg-[#eeeeee]' style={{ marginTop: '2px' }} >
          {/* <News /> */}


          {bookmarkData?.length <= 0 ? (
            window.innerWidth < 1000 ? (
              <div className='border bg-white border-[#7e97a7] mx-2 my-2 rounded-md ' style={{ marginTop: '1.5rem', padding: '2.6666666667vw 1.8666666667vw 5.3333333333vw' }}>
                <div className='flex gap-2 px-1 text-[#7e97a7] items-center' style={{ borderBottom: '1px solid #e0e6e6', marginBottom: '1.3333333333vw' }}>
                  <img src={DottedCircleImg} alt="No Markets" />
                  <h1 className=' text-center font-semibold leading-[2]' style={{ fontSize: '5.3333333333vw', padding: '1.8666666667vw 0', fontFamily: 'Helvetica, Tahoma, sans-serif' }}>
                    There are currently no followed <br /> multi markets.
                  </h1>
                </div>
                <p className='text-center text-[#7e97a7] mx-2' style={{ fontSize: '4.2666666667vw', fontFamily: 'Helvetica, Tahoma, sans-serif' }}>
                  Please add some markets from events.
                </p>
              </div>
            ) : (
              <>
                <h1 className='mt-3 text-[13px] font-bold'>Multi Markets</h1>
                <p className='text-[12px]'>There are currently no followed multi markets.</p>
              </>
            )
          ) : (
            <>
              {window.innerWidth >= 1020 ? <h1 className='mt-3 text-[13px] font-bold'>Multi Markets</h1> : ''}

              {bookmarkData.map((event) => {
                console.log('event date in bookmark map:', 'event:', event.EventName, ' ', event.eventDate);

                return event.isActive === 1 ? (
                  window.innerWidth >= 1020 ? (
                    <MultiMarketListItem
                      key={event.SrNo}
                      setRender={setRender}
                      EventName={event.EventName}
                      EventId={event.EventId}
                      SportId={event.SportId}
                      marketId={event.MarketId}
                      series={event.series}
                      isInPlay={event.isInPlay}
                      eventDate={event.eventDate}
                      isLive={event.isLive === 1}
                      IsBetLock={event.IsBetLock === 1}
                    />
                  ) : (
                    <MultiMarketList
                      key={event.SrNo}
                      setRender={setRender}
                      EventName={event.EventName}
                      EventId={event.EventId}
                      SportId={event.SportId}
                      marketId={event.MarketId}
                      series={event.series}
                      isInPlay={event.isInPlay}
                      eventDate={event.eventDate}
                      isLive={event.isLive === 1}
                      IsBetLock={event.IsBetLock === 1}
                    />
                  )
                ) : (
                  <h1></h1>
                );
              })}
            </>
          )}



          {/* <MultiMarketListItem/> */}


          {/*  footer for sport pages */}
          {/* <MiniFooter/> */}

        </div>

        <div className='lg:block hidden bg-white w-[25vw] ml-2 border-t'>
          <h1 className='bg-[#274252] text-white text-xs  font-semibold p-1 flex justify-between'>Bet Slip <img src={MinusIcon} className='h-4' alt="" /></h1>
          {bookmarkData.length === 0 && <h1 className='p-3 text-xs text-center'>Click on the odds to add selections to the betslip.</h1>}
        </div>

      </div>
    </div>
  )
}

export default MultiMarket
