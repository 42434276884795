import React, { useContext, useEffect, useState } from 'react'
import whatsAppIcon from "../images/whatsApp-icon-grey.png";
import skypeIcon from "../images/skype-icon-grey.png";
import emailIcon from "../images/email-icon-grey.png";
import igIcon from "../images/ig-icon-grey.png";
import betFairIcon from "../images/logo-betfair.png";
import accountIcon from "../images/account-icon-white.png";
import homeIcon from "../images/home-icon-white2.png";
import trophyIcon from "../images/trophy-icon-white.png";
import clockIcon from "../images/clock-icon-white.png";
import SportsGiff from "../images/gamesGiff.gif";
import headphoneIcon from "../images/headphone-icon-grey.png";
import gcIcon from "../images/gc-logo.png";
import phoneIcon from "../images/phone-icon.png";
import mailIcon from "../images/mail-icon.png";
import AndroidAppIcon from "../images/android-app-icon.png";
import browserIcon from "../images/icon-browser-B.png";
import { useLocation, Link } from 'react-router-dom';
import GlobalContext from '../context/GlobalContext';

const Footer = () => {

  //console.log("footer component is rendering !!");

  let location = useLocation();

  // pages where footer will be  shown
  const [NeededPages, setNeededPages] = useState(["/"]);

  const context = useContext(GlobalContext);
  const { isUserLoggedIn } = context;

  ////console.log(location);


  return (
    // ${location.pathname == "/inplay" ? "hidden" : "block"}
    <>
      {/* footer re-factor */}

      <div className={` ${NeededPages.includes(location.pathname) ? "block" : "hidden"} pb-10 lg:px-40 bg-[#eeee]`}>
        <div className='mx-5 lg:mx-36 pt-7'>
          <div className='lg:flex justify-between mb-2 '>
            <div className='py-3 bg-white lg:w-[49%] mb-5 lg:mb-0 flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086]'><img src={headphoneIcon} className='w-8' alt="" /> <span className='cursor-pointer hover:text-black' > Customer support1 </span> | <span className='cursor-pointer hover:text-black' > support2 </span></div>
            <div className='py-3 bg-white lg:w-[49%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086]'><img src={whatsAppIcon} className='w-7' alt="" /> <span className='cursor-pointer hover:text-black' >WhatsApp 3 </span> | <span className='cursor-pointer hover:text-black' > WhatsApp 4 </span></div>
          </div>

          <div className='w-full bg-white h-4 mb-2 rounded-lg border border-[#97979780]'></div>
          {/*  for pc view */}
          <div className='hidden  lg:flex justify-between gap-2'>
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={skypeIcon} className='w-7' alt="" /> betcareliveofficial</div>
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={emailIcon} className='w-7' alt="" /> info@betcarelive.com</div>
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={igIcon} className='w-7' alt="" /> officialbetcarelive</div>
          </div>
          {/*  for mobile view */}
          <div className='flex lg:hidden justify-between gap-2'>
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={skypeIcon} className='w-5' alt="" /> Skype</div>
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={emailIcon} className='w-5' alt="" /> Email</div>
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={igIcon} className='w-5' alt="" /> Instagram</div>
          </div>

          {/* only for pc */}
          <div className='hidden  border border-[#a6a6a6] mt-6 lg:flex rounded-lg' >
            <div className='w-2/5 flex items-center' >
              <div className='px-3 w-1/2 h-[200px] my-1 flex flex-col justify-center border-r-[1px] border-[#00000080]'>
                <p className='text-[#00000080] text-xs'>Powered by</p>
                <img src={betFairIcon} className='' alt="" />
              </div>

              <div className='w-1/2 p-2'>
                <img src={gcIcon} className=' bg-white' alt="" />
              </div>
            </div>

            <div className='3/5'>
              <div className='text-xs py-1 text-[#00000080]'>Betcare.live is operated by Betcare company <br /> incorporated under the laws of Curacao with company Registration number <br /> 091237 with registered office at Abraham de Veerstraat 9 , Curacao P.O Box <br /> 3421 and is licensed and regulated by the Curacao authority as the regulatory <br /> body responsible holding a (Sub-license with License number 365/JAZ Sub- <br /> License GLH- OCCHKTW0707072023 granted on 6.07.2023). <br />
                Players are requested not to contact any untrusted sources for <br /> https://betcare.live/ accounts as this is an online site and they can only <br /> register independently without any agents. Only deposit through the account <br /> details generated by the system or provided by our official support team.</div>

              <div className='flex justify-between border-t border-[#a6a6a6]'>
                <p className='pt-1 flex items-center text-xs text-[#00000080]'><img src={phoneIcon} className='h-4' alt="" /> +91 0000000000 / +91 0000000000</p>
                <p className='pt-1 flex items-center text-xs gap-1 underline text-[#00000080]'><img src={mailIcon} className='h-4' alt="" /> support@betcare.live</p>
              </div>
            </div>
          </div>
        </div>



        <div className='hidden lg:flex flex-col mt-7 items-center mb-2'>
          <div className='flex gap-2  '>
            <img src={browserIcon} alt="" />
            <img src="" alt="" />
          </div>

          <h1 className='text-[#00000099] text-xs'>Our website works best in the newest and last prior version of these browsers:</h1>

          <h1 className='text-[#00000099] text-xs'>Google Chrome. Firefox</h1>
        </div>

        <div className='flex flex-col justify-center lg:border-t mt-10 lg:mt-0 border-[#0000004d]'>
          {/* for pc view */}
          <p className='hidden lg:flex justify-center flex-wrap gap-2 mx-5 text-xs mt-2 text-[#00000099]'>
            - <Link className='underline' >Privacy Policy </Link>
            - <Link className='underline' >Terms and Conditions</Link>
            - <Link className='underline' >Rules and Regulations </Link>
            - <Link className='underline' > KYC</Link>
            - <Link className='underline' > Responsible Gaming</Link>
            - <Link className='underline' > About Us </Link>
            - <Link className='underline' >Self-exclusion Policy </Link>
            - <Link className='underline' > Underage Policy </Link> -
          </p>

          {/* for mobile view */}
          <p className='lg:hidden flex justify-center flex-wrap gap-1  text-xs mt-2 text-[#00000099]'>
            <Link className='underline' >Privacy Policy </Link>
            |<Link className='underline' >Terms and Conditions</Link>
            |<Link className='underline' >Rules and Regulations </Link>
            |<Link className='underline' > KYC</Link>
            |<Link className='underline' > Responsible Gaming</Link>
            |<Link className='underline' > About Us </Link>
            |<Link className='underline' >Self-exclusion Policy </Link>
            |<Link className='underline' > Underage Policy </Link>
          </p>

          {/* for pc view */}
          <div className='hidden lg:flex flex-col items-center mt-5'>
            <img src={AndroidAppIcon} className='w-40' alt="" />
            <p className='text-[#00000099] text-[10px]'>v1.11 - 2022-03-23 - 3.1MB</p>
          </div>

          {/* for mobile view */}
          <div className='flex lg:hidden flex-col items-center   mt-5'>
            <div className='border border-[#0000004d] p-2 rounded-lg'>
              <p className='text-[#00000099] text-[10px]'>Powered by</p>
              <img src={betFairIcon} className='w-32' alt="" />
            </div>
          </div>
        </div>

      </div>


      {/*    sticky navbar for mobileView */}
      <div className='sticky bottom-0 text-white lg:hidden flex items-end  bg-[#eee]'>
        <img src={SportsGiff} className='rounded-t-full min-[350px]:h-84 h-20 w-20 min-[350px]:w-24  absolute bg-[#020024]' style={{
          background: 'linear-gradient(-180deg, #243a48 20%, #172732 91%)',
          padding: '6px',
          paddingLeft: '10px',
          width: '21%',
          height: '9vh',
          marginLeft: '-2px'
        }} alt="" />
        <ul style={{ background: "linear-gradient(-180deg, #243a48 20%, #172732 91%)" }} className='grid grid-cols-5 bg-[#020024] pl-20 w-full '>

          <Link to="/sports" className='flex flex-col justify-center items-center ' style={location.pathname == "/sports" ? { background: "linear-gradient(0deg, rgba(4,29,83,1) 7%, rgba(44,88,115,1) 61%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' } : { background: "linear-gradient(-180deg, #243a48 20%, #172732 91%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' }} > <img src={trophyIcon} className='h-7 w-7' alt="" />Sports</Link>
          <Link to="/inplay" className='flex flex-col justify-center items-center' style={location.pathname == "/inplay" ? { background: "linear-gradient(0deg, rgba(4,29,83,1) 7%, rgba(44,88,115,1) 61%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' } : { background: "linear-gradient(-180deg, #243a48 20%, #172732 91%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' }} > <img src={clockIcon} className='h-7 w-7' alt="" />In-Play</Link>
          <Link to="/" className='flex flex-col justify-center items-center' style={location.pathname == "/" ? { background: "linear-gradient(0deg, rgba(4,29,83,1) 7%, rgba(44,88,115,1) 61%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' } : { background: "linear-gradient(-180deg, #243a48 20%, #172732 91%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' }}> <img src={homeIcon} className='h-7 w-7' alt="" />Home</Link>
          <Link to={"/multimarket"} className='flex flex-col justify-center items-center' style={location.pathname == "/multiMarketMobile" ? { background: "linear-gradient(0deg, rgba(4,29,83,1) 7%, rgba(44,88,115,1) 61%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' } : { background: "linear-gradient(-180deg, #243a48 20%, #172732 91%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' }}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path fill="rgb(255,255,255)" fillRule="evenodd" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm5.09-13.203c-.09 0-.18-.032-.27-.095-.49-.19-.802-.49-.936-.9L15.18 5.203v-.237c0-.253.222-.46.668-.617l.067-.048h.067c.536-.158.804-.426.804-.806 0-.443-.09-.72-.268-.83-.18-.11-.49-.166-.938-.166H9.42c-.447 0-.76.055-.938.166-.178.11-.268.387-.268.83 0 .38.268.648.804.806h.067l.067.048c.446.158.67.364.67.617v.237L8.615 10.8c-.134.412-.446.712-.937.902-.09.063-.18.095-.27.095-1.606.57-2.41 1.44-2.41 2.61 0 .378.067.64.2.78.135.144.403.215.804.215h5.425l.802 8.348h.536l.803-8.348h5.426c.4 0 .67-.063.803-.19.133-.126.2-.395.2-.806 0-1.17-.804-2.04-2.41-2.61z" /></svg>Multi..</Link>
          <Link to={isUserLoggedIn !== "true" ? "/login" : "/accountMobile"} className='flex flex-col justify-center items-center' style={location.pathname == "/accountMobile" ? { background: "linear-gradient(0deg, rgba(4,29,83,1) 7%, rgba(44,88,115,1) 61%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' } : { background: "linear-gradient(-180deg, #243a48 20%, #172732 91%)", fontSize: '3.2vw', lineHeight: '1.1', padding: '0px 2.66667vw 0.866667vw', height: '13.3333333333vw', fontFamily: 'Helvetica, Tahoma, sans-serif' }}> <img src={accountIcon} className='h-7 w-7' alt="" /> Acco..</Link>
        </ul>
      </div >
    </>
  )
}

export default React.memo(Footer) 
