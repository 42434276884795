import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'

import MinusIcon from "../../images/minus-icon-white.png";
import ClockIcon from "../../images/clock-icon-green.png";
import PIcon from "../../images/p-icon.svg";
import ReloadIcon from "../../images/reload-icon.svg";
import BackAllImg from "../../images/bg-backall.png";
import BackAllDisableImg from "../../images/bg-backall-disabled.png";
import LayAllImg from "../../images/bg-layall.png";
import LayAllDisableImg from "../../images/bg-layall-disabled.png";
import DisabledImg from "../../images/bg-disabled.png";
import { Link, useNavigate } from 'react-router-dom';
import { MdPushPin } from "react-icons/md";
import { TbArrowBigRightFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineBarChart } from "react-icons/ai";
import { MdOutlineInfo } from "react-icons/md";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { MdOutlineBackspace } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import GlobalContext from '../../context/GlobalContext';
import axios from "axios";
import Cookies from "js-cookie";
import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-hot-toast';

const FancyTable = ({ headerType, FancyData, minStake, setLoading, maxStake, eid, EventNameBetSlip, eventId, marketId, setFancyModalOpen, IsBetLock, matchName, series, delayTime }) => {

  //console.log("Fancy table component is rendering! : ",);

  //console.log('Fancy table data : ', FancyData)

  //console.log('Fancy table minStake : ', minStake)
  //console.log("Fancy table maxStake : ", maxStake)
  //  console.log(' Fancy eid : ', eid, ' eventId : ', eventId, ' marketId : ', marketId)




  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);
  ////console.log(`userId value is : ${userId?.Id}`);

  const queryFunction = async () => {
    let res = await axios.get(`https://api.betcare.live/api/getFancyLiability/${userId?.Id}/${eventId}`);
    let resData = await res.data;
    return resData;
  }

  // const {data,isLoading,isError} = useQuery({queryKey:['fancyLiability'],queryFn:queryFunction,refetchOnMount:"always"});

  // const mutation = useMutation(queryFunction, {
  //   onSuccess: () => {
  //     queryClient.refetchQueries('fancyLiability');
  //   },
  // });

  ////console.log(data);


  const [isBetClicked, setIsBetClicked] = useState();   // when somebody clicked the lay or back btn than it store some data init
  const [inputValue, setInputValue] = useState(0);
  //  const [stakeValue, setStakeValue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [liability, setLiability] = useState(0);
  // const [errorInBet, setErrorInBet] = useState(false);  // this state is for set , there is an error or not while setting bet
  // const [errorMessage, setErrorMessage] = useState("");
  // const [isSuccess, setIsSuccess] = useState(false);
  const [liabilityData, setLiabilityData] = useState([]);   // this will store the total of liability of each selection in fancy
  const [refectLiabilityData, setRefectLiabilityData] = useState(true);

  const [mostNegativeValueSid, setMostNegativeValueSid] = useState([])

  const context = useContext(GlobalContext);
  const { quickStakes, isUserLoggedIn, setIsLoginModeOpen, setBetSend , errorInBet, setErrorInBet, errorMessage, setErrorMessage, isSuccess, setIsSuccess} = context;

  const ArrQuickStakes = Object.values(quickStakes);

  let navigate = useNavigate();

  //  function that set the value of profit and liability 
  const funcToSetProfitAndLiability = (value) => {

    let betValue = isBetClicked?.rate / 100;
    // //console.log(betValue);
    if (isBetClicked?.betType === "back") {

      setProfit(value * betValue);      // setting value of profit      
      setLiability(value * -1);    // setting value of liability
    }
    else {
      setProfit(value);   // setting value of profit  
      setLiability((value * betValue) * -1);    // setting value of liability
    } return;
  }

  // this is a function that take some detail when somebody click on that lay or back btn and update some state that will update the UI


  // function to set a bet
  const onClickOfSettingBet = (event, n) => {
    let name = event.target.runnerName;

    //console.log(indexNumber);

    if (name === "fixedData") {
      setInputValue(n);
      funcToSetProfitAndLiability(n);

      return;
    }

    if (n === "x") {
      setInputValue(prev => prev <= 9 ? prev : Math.floor(prev / 10));
      let num = inputValue <= 9 ? inputValue : Math.floor(inputValue / 10);
      funcToSetProfitAndLiability(num);
      return;
    }

    if (n === "-") {
      setInputValue(prev => prev <= 0 ? prev : prev - 1);
      let num = inputValue <= 0 ? 0 : inputValue - 1;
      funcToSetProfitAndLiability(num);
      return;
    }

    if (n === "+") {
      setInputValue(prev => prev >= 1000000 ? prev : prev + 1);
      let num = inputValue >= 1000000 ? 1000000 : inputValue + 1;
      funcToSetProfitAndLiability(num);
      return;
    }

    if (inputValue > 1000000) {
      return;
    }

    if (n === "00") {
      setInputValue(prev => prev >= 100000 ? prev : prev * 100);
      let num = inputValue >= 100000 ? inputValue : inputValue * 100;
      funcToSetProfitAndLiability(num);
      return;
    }

    setInputValue(prev => prev + n);
    let num = (inputValue + n);
    funcToSetProfitAndLiability(num);
  }


  const handleOnClickBet = (e, betname, betvalue, selectionId, stakeRate) => {

    let tag = e.target.getAttribute("data-name");

    setIsBetClicked({ betType: tag, betName: betname, betValue: betvalue, selectionId: selectionId, delayTime: delayTime, rate: stakeRate });
    ////console.log(isBetClicked);
    ////console.log("clicked 8110! " + tag);
  }



  // function to set the data of placeBet
  const placeBetFuc = async () => {

    //console.log('place bet in betSlip table into Facny component...')

    let DelayedTime = isBetClicked?.delayTime * 1000;

    ////console.log(DelayedTime);

    // function for genrating betId
    function generateRandomAlphaNumeric(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    }

    const convertToIST = (dateString) => {
      const date = new Date(dateString);
      const ISTOffset = 330; // IST offset is UTC+5:30
      const ISTTime = new Date(date.getTime() + (ISTOffset * 60000));
      return ISTTime.toISOString().slice(0, 19).replace('T', ' '); // Format the date-time string 
    }

    let matchTimeData = convertToIST(new Date());

    if (inputValue < minStake || inputValue > maxStake) {
      setErrorInBet(true);
      setErrorMessage(`Enter stake between ${minStake} - ${maxStake}`);
      setIsSuccess(false);
      // alert(`Enter stake between ${minStake}  --  ${maxStake}`);
      setInputValue(0);

      setTimeout(() => {
        setErrorInBet(false);
      }, 3000);
      return;
    }

    setLoading(true);

    setTimeout(() => {

      ; (async () => {
        let res = await axios.post("https://api.betcare.live/saveBet", {
          BetId: generateRandomAlphaNumeric(30),
          SportId: eid,
          SeriesId: series,
          EventId: eventId,
          Event: isBetClicked?.betName,
          MarketId: marketId,
          Market: "Fancy",
          SelectionId: isBetClicked?.selectionId,
          Selection: isBetClicked?.betName,
          OddsType: 3,
          Type: isBetClicked?.betType,
          OddsRequest: isBetClicked?.betValue,
          AmountStake: inputValue,
          BetType: 2,
          IsSettlement: 2,
          IsDelete: 0,
          Status: true,
          UserId: userId?.Id,
          ResultType: null,
          ResultAmount: 0,
          IpAddress: "",
          IsMatched: true,
          EventName: matchName,
          Profit: profit,
          Liability: liability,
          MatchedTime: matchTimeData,
          settleTime: null,
          PlaceTime: convertToIST(new Date())
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (res.data.isSuccess) {
          // //console.log(res.status);
          //  alert(res.status);
          let checkBal = await fetch(`https://api.betcare.live/expo2/${userId?.Id}/${userId?.selfUser}`)
          let resCheckBal = await checkBal.json();

          if (resCheckBal.isSuccess) {
            if (resCheckBal.mainBalanceafterExpo <= 0) {
              setLoading(false);
              setErrorInBet(true);
              setIsSuccess(false);
              setErrorMessage(`Insufficient balance..`);
              setInputValue(0);

              //console.log('balance block delete bet id : ', betId)
              const deleteQuery = await fetch(`https://api.betcare.live/deleteUnmatchBet`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId.Id })
              });

              const queryResp = await deleteQuery.json();


              setTimeout(() => {
                setErrorInBet(false);
                // setSelectedBet({});
                // setBetSlipData();
                // setRemount((prev) => !prev); // it will update the value in match odds
              }, 3000)
              return;
            } else {
              const updateQuery = await fetch(`https://api.betcare.live/updateUnmatchBet`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId.Id, BetId: res.data.betId })
              });

              const queryResp2 = await updateQuery.json()
              if (queryResp2.isSuccess) {
                setLoading(false);
                setErrorInBet(true);
                setErrorMessage(`Bet is set successfull`);
                setIsSuccess(true);
                // alert(`Enter stake between ${minStake}  --  ${maxStake}`);
                setInputValue(0);
                setBetSend(true);
                setRefectLiabilityData((prev) => !prev);
              }
            }
          }
        }


        // .then(() => {
        //   setErrorMessage(`Bet is set, successfull`);
        //   setLoading(false);
        //   setErrorInBet(true);
        //   setInputValue(0);
        //   setIsSuccess(true);
        //   // setFancyBetSend(true);
        //   setBetSend(true);
        //   setRefectLiabilityData((prev) => !prev);
        // })
        // //console.log(res.status);
        //  alert(res.status);        
      })();

      setTimeout(() => {
        setErrorInBet(false);
        setIsBetClicked({});
      }, 3000);

    }, DelayedTime)
    setTimeout(() => {
      setErrorInBet(false);
    }, 3000);
  }

  const openFancyBtn = async () => {
    console.log("opern fancy button called ....")
    try {
      const response = await fetch(`https://api.betcare.live/GetBetHistory/${userId.Id}?betStatus=2`);
      const result = await response.json();

      // Filter by date and status
      const filteredData = result.data.filter(item =>
        item.eventId == eventId && item.isSettlement == 2 && item.isDelete == 0
      );

      const filterFancyAll = filteredData.filter(item =>
        item.market == "Fancy" && FancyData.some(facnyitem => facnyitem.SelectionId == item.selectionId)
      );

      ////console.log("filterFancyAll data : " + JSON.stringify(filterFancyAll));

      var allArray = [];
      var processedSids = new Set();

      filterFancyAll.forEach((itm) => {
        if (processedSids.has(itm.selectionId)) {
          return;
        }

        ////console.log("Fancy filter All itm : " + JSON.stringify(itm));

        // Set the oddsRequest values from itm directly
        const minOddsRequest = itm.oddsRequest;
        const maxOddsRequest = itm.oddsRequest;

        // BET CALCULATION FOR BACK
        const filterByBack = filterFancyAll.filter(item => item.type == "back" && item.selectionId == itm.selectionId);

        // Group items by id
        const groupedByIdBack = {};
        filterByBack.forEach(item => {
          if (!groupedByIdBack[item.id]) {
            groupedByIdBack[item.id] = [];
          }
          groupedByIdBack[item.id].push(item);
        });

        // Calculate the range and set the length dynamically
        const rangeBack = maxOddsRequest - minOddsRequest + 10;

        // Create FancyBookArray for each group
        const resultArraysBack = [];
        Object.keys(groupedByIdBack).forEach(groupId => {
          const groupItems = groupedByIdBack[groupId];
          const FancyBookArray = Array.from({ length: rangeBack }, (_, index) => {
            const request = index + minOddsRequest - 5;
            const pl = request >= groupItems[0].oddsRequest ? groupItems[0].profit : groupItems[0].liability;
            return { request, pl };
          });
          resultArraysBack.push({ id: groupId, FancyBookArray });
        });

        const redcBack = resultArraysBack.reduce((acc, current) => {
          current.FancyBookArray.forEach((entry) => {
            const existingEntry = acc.find((item) => item.request == entry.request);
            if (existingEntry) {
              existingEntry.pl += entry.pl;
            } else {
              acc.push({ request: entry.request, pl: entry.pl });
            }
          });
          return acc;
        }, []);

        // BET CALCULATION FOR LAY
        const filterByLay = filterFancyAll.filter(item => item.type == "lay" && item.selectionId == itm.selectionId);

        // Group items by id
        const groupedById = {};
        filterByLay.forEach(item => {
          if (!groupedById[item.id]) {
            groupedById[item.id] = [];
          }
          groupedById[item.id].push(item);
        });

        // Calculate the range and set the length dynamically
        const range = maxOddsRequest - minOddsRequest + 10;

        // Create FancyBookArray for each group
        const resultArrays = [];
        Object.keys(groupedById).forEach(groupId => {
          const groupItems = groupedById[groupId];
          const FancyBookArray = Array.from({ length: range }, (_, index) => {
            const request = index + minOddsRequest - 5;
            const pl = request >= groupItems[0].oddsRequest ? groupItems[0].liability : groupItems[0].profit;
            return { request, pl };
          });
          resultArrays.push({ id: groupId, FancyBookArray });
        });

        const redc = resultArrays.reduce((acc, current) => {
          current.FancyBookArray.forEach((entry) => {
            const existingEntry = acc.find((item) => item.request == entry.request);
            if (existingEntry) {
              existingEntry.pl += entry.pl;
            } else {
              acc.push({ request: entry.request, pl: entry.pl });
            }
          });
          return acc;
        }, []);

        let finalCalculatedArray = [];
        if (redcBack.length > 0) {
          // Combine redcBack and redc to create the final calculated array
          finalCalculatedArray = redcBack.map((entryBack) => {
            const entryLay = redc.find((entry) => entry.request == entryBack.request);
            if (entryLay) {
              entryBack.pl += entryLay.pl;
            }
            return entryBack;
          });
        } else {
          finalCalculatedArray = redc;
        }

        ////console.log("Final Calculated Array : " + JSON.stringify(finalCalculatedArray));

        // Find the most negative value in finalCalculatedArray
        let mostNegativeValue = null;
        if (finalCalculatedArray.length > 0) {
          mostNegativeValue = finalCalculatedArray.reduce((acc, entry) => {
            if (entry.pl < 0 && (acc === null || entry.pl < acc)) {
              return entry.pl;
            }
            return acc;
          }, null);
        }

        ////console.log("Most Negative Value: " + mostNegativeValue);
        let negativeValue = [{ sId: itm.selectionId, value: mostNegativeValue }];

        allArray.push({ sid: itm.selectionId, data: negativeValue });
        processedSids.add(itm.selectionId);
      });

      ////console.log('all Array is : ', allArray);

      setMostNegativeValueSid(allArray);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const betLockNotify = () => {
    toast.error("You are not able to place bets..", {
      duration: 1000
    })

  }

  // useEffect(() => {
  //   openFancyBtn();
  // }, [])

  // useEffect(() => {
  //   if (FancyData?.length > 0) {
  //     openFancyBtn();
  //   }
  // }, [FancyData])

  const fetchCount = useRef(0);

  useEffect(() => {
    const callFetchBetWithDelay = () => {
      if (fetchCount.current < 2) {
        setTimeout(() => {
          openFancyBtn();
          fetchCount.current += 1;
        }, 2000);
      }
    };

    callFetchBetWithDelay();
  }, [FancyData]);

  useEffect(() => {
    setInputValue(0);
    setProfit(0);
    setLiability(0);

  }, [isBetClicked]);


  useEffect(() => {

    ; (
      async () => {
        let res = await axios.get(`https://api.betcare.live/api/getFancyLiability/${userId?.Id}/${eventId}`);
        let resData = await res.data;
        ////console.log(`this is running !!`);
        setLiabilityData(resData);
      }
    )();
  }, [refectLiabilityData])

  useEffect(() => {
    openFancyBtn();
  }, [isSuccess, refectLiabilityData])

  // setInterval(() =>{
  //   openFancyBtn();
  // }, 10000)
  // useEffect(() => {
  // })

  return (
    <>

      {FancyData?.length > 0 ?
        <div className='bg-white border-b mb-4 border-[#7e97a7]' >
          <div className='bg-[#243a48] cursor-pointer text-white flex justify-between items-center' >
            <div className='flex gap-1 text-xs items-center'>
              {/* {background:"linear-gradient(0deg, rgba(101,183,19,1) 7%, rgba(73,155,30,1) 61%)"} */}
              <div className='p-1' style={{}} >
                <MdPushPin className='text-white h-5 w-5' />
              </div>
              <h1 style={{ fontWeight: 'bold' }}> {headerType === "pinned" ? "Agafonov, Egor v Chepik, Alexander" : "Fancy Bet"} </h1>
              <p className={`bg-white ${headerType === "pinned" ? "block" : "hidden"} text-black px-2 rounded-sm`} >Match Odds</p>
              <img src={ClockIcon} className={`${headerType === "pinned" ? "block" : "hidden"} rounded-sm`} alt="" />
            </div>
            <img src={ReloadIcon} className={` ${headerType === "pinned" ? "block" : "hidden"} h-4 w-4 mr-2`} alt="" />
          </div>
          <table className='w-full mb-1 '>
            <tr className='text-sm border-b border-[#7e97a7] font-bold' >
              <td className='invisible w-[50%]' >a</td>
              <td className='text-center w-[15%]'>No</td>
              <td className='text-center w-[15%]'>Yes</td>
              <td className='invisible hidden lg:table-cell' >b</td>
            </tr>
            {
              FancyData?.map((e, i) => {
                const correspondingItem = liabilityData?.find(obj => obj.Selection === e.RunnerName);
                const liability = correspondingItem ? correspondingItem.liability : "";
                let teamNamee = e.RunnerName;

                // Find the corresponding most negative value for this SelectionId
                const negativeValueItem = mostNegativeValueSid.find(item => item.sid == e.SelectionId);
                const negativeValue = negativeValueItem ? negativeValueItem.data[0].value : null;



                return (
                  <tbody key={e.SelectionId} className='' >
                    {/* {errorInBet && <tr>  <td colSpan={4} className={`${isSuccess ? "bg-green-500" : "bg-red-500"}  ${isBetClicked?.betName === e.RunnerName ? "table-cell" : "hidden"} text-sm font-bold text-white p-2 text-center`}>{errorMessage} </td> </tr>} */}

                    <tr className='lg:hidden bg-[#e4f1f9]'>
                      <td colSpan={4}>
                        <h1 className='text-[3.46vw] font-bold text-[#3b5160] p-1 flex justify-between items-center relative'>
                          {teamNamee?.length >= 26 ? teamNamee?.slice(0, 26) + "..." : teamNamee}
                          <MdOutlineInfo
                            className='size-4 cursor-pointer'
                            onClick={(e) => {
                              e.preventDefault();
                              const getId = document.getElementById(`fancyMinMax${i}`);
                              const allMinMaxDivs = document.querySelectorAll('.fancyMinMax');

                              // Hide all fancyMinMax divs
                              allMinMaxDivs.forEach((div) => {
                                if (div !== getId) {
                                  div.style.display = 'none';
                                }
                              });

                              // Toggle display of the clicked one
                              getId.style.display =
                                getId.style.display === 'none' ? 'flex' : 'none';
                            }}
                          />
                          <div
                            id={`fancyMinMax${i}`}
                            style={{ display: 'none', width: '8rem', left: '66%', padding: '1%', justifyContent: 'space-between', borderRadius: '5px', boxShadow: '0 6px 10px rgba(0, 0, 0)' }}
                            className="fancyMinMax absolute top-full mt-2 border border-gray-400 bg-white text-left z-50 shadow-lg"
                          >
                            <div><p className='text-[11px] font-normal text-[#7e97a7]'>Min/Max</p>
                              <p className='text-[12px] text-black'><span className='text-[12px] font-bold'>{minStake}</span>/ <span className='font-normal'>{maxStake}</span></p></div>
                            <div><RxCross2 onClick={(e) => {
                              e.preventDefault();
                              const getId = document.getElementById(`fancyMinMax${i}`);
                              getId.style.display = 'none'
                            }} style={{marginTop: '50%', fontSize: '1.1rem'}}/>
                            </div>


                          </div>
                        </h1>
                      </td>
                    </tr>
                    <tr className={`text-xs font-bold border-b border-[#7e97a7]`} >
                      <td className={`pl-3 visible`} > <div className='flex justify-between pr-10'> <section> <p className='hidden lg:block'> {teamNamee?.length >= 30 ? teamNamee?.slice(0, 30) + "..." : teamNamee} </p>  <span className='flex items-center text-red-500 font-medium'> {liability !== "" && <TbArrowBigRightFilled className='text-red-500' />}  {negativeValue !== null && negativeValue.toFixed(2)} </span> </section>  {liability !== "" && <button onClick={() => { setFancyModalOpen({ isOpen: true, Sid: e.SelectionId }) }} className='text-[#ffb600] bg-black p-1 px-2 rounded-md' style={{ background: "linear-gradient(180deg, #474747 0%, #070707 100%" }} >Book</button>}   </div>    </td>
                      <td data-name="lay"
                        onClick={
                          (event) =>
                            isUserLoggedIn !== "true"
                              ?
                              window.innerWidth < 1000
                                ?
                                navigate(`/login`)
                                :
                                setIsLoginModeOpen(true)
                              :
                              IsBetLock == true
                                ?
                                betLockNotify
                                :
                                handleOnClickBet(event, e.RunnerName, e.LayPrice1, e.SelectionId, e.LaySize1)
                        }
                        className={`${isBetClicked?.betName === e.RunnerName ? isBetClicked?.betType === "lay" ? "bg-[#f4496d] text-white" : "bg-[#faa9ba]" : "bg-[#faa9ba]"}  ${e.isOdd ? "hidden" : "block"} text-center hover:brightness-[1.1] cursor-pointer border-r relative border-white py-2`}
                        style={isBetClicked?.betName === e.RunnerName ? isBetClicked?.betType === "lay" ? { boxShadow: "inset 0 1px 3px rgba(0,0,0,.5)" } : {} : {}}
                      >
                        {/* div when bet is stopped ? reason can be all running or suspend */}
                        {e.GameStatus != '' ? <div onClick={(e) => { e.stopPropagation() }} className='bg-[#243a4866] cursor-text border border-[#7e97a7] text-white absolute top-0 h-full w-[200%] hover:bg-[#243a4899] flex justify-center items-center' style={{ textShadow: "0 1px 4px rgba(0,0,0,.5)" }} >
                          {e.GameStatus}
                        </div> : <div></div>}
                        {e.LayPrice1} <br />
                        <span data-name="lay" className='text-xs font-normal'>{e.LaySize1}</span>
                      </td>
                      <td data-name="back" onClick={IsBetLock == true ? betLockNotify : (event) => { isUserLoggedIn !== "true" ? window.innerWidth < 1000 ? navigate(`/login`) : setIsLoginModeOpen(true) : handleOnClickBet(event, e.RunnerName, e.BackPrice1, e.SelectionId, e.BackSize1) }} className={`${isBetClicked?.betName === e.RunnerName ? isBetClicked?.betType === "back" ? "bg-[#1a8ee1] text-white" : "bg-[#72bbef]" : "bg-[#72bbef]"}  text-center hover:brightness-[1.1] cursor-pointer py-2`} style={isBetClicked?.betName === e.RunnerName ? isBetClicked?.betType === "back" ? { boxShadow: "inset 0 1px 3px rgba(0,0,0,.5)" } : {} : {}} colSpan={e.isOdd ? 2 : 1} >{e.BackPrice1}<br /> <span data-name="back" className='text-xs font-normal'>{e.BackSize1}</span></td>
                      <td className='pl-2 text-xs font-normal hidden lg:table-cell '><p className='text-[10px] text-[#7e97a7]'>Min/Max</p><p className='text-[11px]'>{minStake}/ {maxStake}</p></td>
                    </tr>
                    {
                      isBetClicked?.betName === e.RunnerName ? (
                        <tr className='' >

                          {/* for smaller screen */}
                          <td colSpan={4} className={`${isBetClicked?.betName === e.RunnerName ? "table-cell" : "hidden"} lg:hidden overflow-hidden`}>
                            <div className={` ${isBetClicked?.betType === "back" ? "bg-[#dceaf4]" : "bg-[#f2e5e8]"} `}>
                              {/* for min-bet input */}
                              <div className='flex py-2 gap-2 mx-2'>
                                <div className='w-1/2 text-[#999] border border-[#bbb] rounded-md text-sm font-bold text-center py-3 bg-[#dcdcdc]' >{isBetClicked?.betValue}</div>
                                <div className='w-1/2 flex items-center' >
                                  <div onClick={(e) => onClickOfSettingBet(e, '-')} className='h-full w-10 border border-[#aaa] rounded-l flex items-center justify-center' style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} > <FaMinus className='text-[#1f72ac] text-xl' /> </div>
                                  <input type="number" readOnly maxLength={7} value={inputValue} name='' className='h-full w-[85px] border-y border-[#aaa] text-center focus:bg-[#fff0ca] focus:outline-none focus:shadow-3xl' />
                                  <div onClick={(e) => onClickOfSettingBet(e, '+')} className='h-full w-10 border border-[#aaa] rounded-r flex items-center justify-center' style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} > <FaPlus className='text-[#1f72ac] text-xl' /> </div>
                                </div>
                              </div>

                              <div className='flex mb-3 font-bold' >
                                {
                                  ArrQuickStakes?.map((stake) => {
                                    return <button onClick={(e) => { onClickOfSettingBet(e, stake) }} value={stake} name='fixedData' className='text-white flex-grow py-2 text-xs' style={{ background: "linear-gradient(-180deg, #32617f 20%, #1f4258 91%)" }} >{stake}</button>
                                  })
                                }
                              </div>

                              <div className='grid bg-white grid-cols-7 w-full text-sm mb-3' >
                                <button onClick={(e) => onClickOfSettingBet(e, 1)} disabled={inputValue.length >= 7} value={'1'} className='border border-[#aaa] border-b-0 py-2 ' >1</button>
                                <button onClick={(e) => onClickOfSettingBet(e, 2)} disabled={inputValue.length >= 7} className='border border-[#aaa] border-b-0 py-2 ' >2</button>
                                <button onClick={(e) => onClickOfSettingBet(e, 3)} disabled={inputValue.length >= 7} className='border border-[#aaa] border-b-0 py-2 ' >3</button>
                                <button onClick={(e) => onClickOfSettingBet(e, 4)} disabled={inputValue.length >= 7} className='border border-[#aaa] border-b-0 py-2 ' >4</button>
                                <button onClick={(e) => onClickOfSettingBet(e, 5)} disabled={inputValue.length >= 7} className='border border-[#aaa] border-b-0 py-2 ' >5</button>
                                <button onClick={(e) => onClickOfSettingBet(e, 6)} disabled={inputValue.length >= 7} className='border border-[#aaa] border-b-0 py-2 ' >6</button>
                                <button onClick={(e) => onClickOfSettingBet(e, "x")} className='row-span-2 flex justify-center items-center border-y border-[#aaa]' > <MdOutlineBackspace className='text-lg' /> </button>

                                <button onClick={(e) => onClickOfSettingBet(e, 7)} disabled={inputValue.length >= 7} className='border border-[#aaa] py-2 ' >7</button>
                                <button onClick={(e) => onClickOfSettingBet(e, 8)} disabled={inputValue.length >= 7} className='border border-[#aaa] py-2 ' >8</button>
                                <button onClick={(e) => onClickOfSettingBet(e, 9)} disabled={inputValue.length >= 7} className='border border-[#aaa] py-2 ' >9</button>
                                <button onClick={(e) => onClickOfSettingBet(e, 0)} disabled={inputValue.length >= 7} className='border border-[#aaa] py-2 ' >0</button>
                                <button onClick={(e) => onClickOfSettingBet(e, "00")} disabled={inputValue.length >= 6} className='border border-[#aaa] py-2 ' >00</button>
                                <button className='border border-[#aaa] py-2 ' >.</button>
                              </div>

                              <div className='flex gap-1 mx-2 w-full pb-3' >
                                <button onClick={() => { setIsBetClicked({}) }} className='w-[48%] border border-[#aaa] rounded py-2 text-sm' style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} >Cancel</button>
                                <button onClick={placeBetFuc} disabled={isUserLoggedIn !== "true" ? true : false} className={`w-[48%] text-[#ffb600] text-xs font-bold ${isUserLoggedIn !== "true" ? "bg-[#a6a6a6] text-[#ffd5a6]" : inputValue < 1 ? "bg-[#a6a6a6] text-[#ffd5a6]" : "bg-gradient-placeBet text-[#ffb200]"} px-8 rounded-[4px]`} style={isUserLoggedIn !== "true" ? {} : inputValue < 1 ? {} : { background: "linear-gradient(180deg, #474747 0%, #070707 100%" }}>Place Bets</button>
                              </div>
                            </div>
                          </td>



                          {/* for the bigger screen  */}
                          <td colSpan={4} className={` ${isBetClicked.betType === "lay" ? "bg-[#f3dce2]" : "bg-[#beddf4]"} hidden lg:table-cell `} >
                            <div className={`flex justify-between items-center border-b ${isBetClicked.betType === "lay" ? "border-[#dfa3b3]" : "border-[#67afe5]"} `}>
                              <div className='text-xs pl-3 flex gap-1' ><input type="checkbox" id='checkForBet' /> <label htmlFor="checkForBet">Accept Any Odds</label> </div>

                              <div className='flex  gap-1 pr-3 py-2' >
                                <button onClick={() => { setIsBetClicked({ betName: "" }) }} className='border border-[#bbb] text-sm rounded-[4px] font-semibold px-2 py-1 bg-gradient-to-t from-[#fff] to-[#eee]' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)" }}>Cancel</button>
                                <div className='bg-[#ffffff80] text-right rounded-[4px] px-1 text-xs font-bold pl-8 pr-1'><p>{isBetClicked.betType === "lay" ? e.LayPrice1 : e.BackPrice1}</p><p className='text-[11px] font-medium text-[#222222] opacity-50'>{isBetClicked.betType === "lay" ? e.LaySize1 : e.BackSize1}</p></div>
                                <input type="number" className='w-[70px] rounded-[4px]' value={inputValue <= 0 ? 0 : inputValue} onChange={(e) => { setInputValue(e.target.value) }} dir='rtl' name="" id="" style={{ boxShadow: "inset 0px 1px 0px rgba(0,0,0,.5)" }} />
                                <button onClick={placeBetFuc} disabled={isUserLoggedIn !== "true" ? true : false} className={`text-[#ffb600] text-xs font-bold ${isUserLoggedIn !== "true" ? "bg-[#a6a6a6] text-[#ffd5a6]" : inputValue < 1 ? "bg-[#a6a6a6] text-[#ffd5a6]" : "bg-gradient-placeBet text-[#ffb200]"} px-8 rounded-[4px]`} style={isUserLoggedIn !== "true" ? {} : inputValue < 1 ? {} : { background: "linear-gradient(180deg, #474747 0%, #070707 100%" }}>Place Bets</button>
                              </div>
                            </div>

                            <div className={`bg-[#faeff2] flex justify-end gap-2 border-b border-[#7e97a7] py-1`} >
                              <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' name='fixedData' onClick={(e) => { onClickOfSettingBet(e, 100) }} style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>100</button>
                              <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' name='fixedData' onClick={(e) => { onClickOfSettingBet(e, 200) }} style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>200</button>
                              <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' name='fixedData' onClick={(e) => { onClickOfSettingBet(e, 500) }} style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>500</button>
                              <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' name='fixedData' onClick={(e) => { onClickOfSettingBet(e, 1000) }} style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>1000</button>
                              <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' name='fixedData' onClick={(e) => { onClickOfSettingBet(e, 5000) }} style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>5000</button>
                              <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' name='fixedData' onClick={(e) => { onClickOfSettingBet(e, 10000) }} style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>10000</button>
                            </div>
                          </td>
                        </tr>
                      ) : <h1 className='hidden'>1</h1>
                    }
                  </tbody>
                )
              })
            }

            {/* <tr className='text-xs font-bold border-b border-[#7e97a7]' >
     <td className='pl-3' >AFG Will Win The Toss Bhav</td>
     <td className='bg-[#faa9ba] text-center hover:brightness-[1.1] cursor-pointer border-r border-white py-1'>1 <br /> <span className='text-xs font-normal'>110</span></td>
     <td className='bg-[#72bbef] text-center hover:brightness-[1.1] cursor-pointer py-1'>1 <br /> <span className='text-xs font-normal'>90</span></td>
     <td className='pl-2 text-xs font-normal'><p className='text-[10px] text-[#7e97a7]'>Min/Max</p><p className='text-[11px]'>8.00/ 7,844.00</p></td>
   </tr> */}

            {/* note - there should be a condition that determine to combine the two col in this
              if we combine that first col as class hidden and second will update it's colspan to 2   */}
            {/* <tr className='text-xs font-bold border-b border-[#7e97a7]' >
     <td className='pl-3' >AFG Will Win The Toss Bhav</td> */}
            {/* <td className='bg-[#faa9ba] text-center border-r border-white py-1'>1 <br /> <span className='text-xs font-normal'>110</span></td> */}
            {/* <td className='bg-[#72bbef] text-center hover:brightness-[1.1] cursor-pointer py-1 ' colSpan={2} >100 <br /> <span className='text-xs font-normal'>odds</span></td>
     <td className='pl-2 text-xs font-normal'><p className='text-[10px] text-[#7e97a7]'>Min/Max</p><p className='text-[11px]'>8.00/ 7,844.00</p></td>
   </tr> */}
          </table>
        </div >
        :
        ""
      }

    </>
  )
}

export default React.memo(FancyTable) 
