import React from 'react'
import BetIcon from "../images/bet-icon-gold.svg";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Link, useLocation, useParams } from 'react-router-dom';

const CurrentBetMobile = () => {

    let userId2 = Cookies.get("LoginUserDetail");
    let userId = userId2 === undefined ? "" : JSON.parse(userId2);

    const params = useParams();  
    const Eventid = params?.id;

    console.log('currentBetMobile event Id : ', Eventid)
   
    
    const {state} = useLocation();

    const typeOfData = state?.type;   // it can be singleEvent or it can be allEvent 

   console.log('type of data : ',typeOfData);
   console.log('user id is : ', userId?.Id)

     
    const {data,isLoading,isError} = useQuery({queryKey:["currentDataForMobile", typeOfData],queryFn:async()=>{
        let res = await axios.get(`https://api.betcare.live/api/getCurrentBetForMobile/${userId?.Id}/${Eventid}/${typeOfData}`);
        let resData = await res.data;
        return resData;
    }});

console.log('getCurrentBetForMobile : ',data);

  return (
      <main className='absolute h-[100vh] overflow-y-scroll top-0 overflow-hidden z-[60] w-full bg-white'>

       <header className='bg-black text-[#ffb200] text-base py-2 flex justify-between items-center px-3'>
        <section className='flex gap-2'>
       <img src={BetIcon} alt="" />
       <h2>Open Bets</h2>
       </section>
       <Link to={-1}>
       <IoCloseSharp className='text-[#ffb200]' />  
       </Link>  
       </header>
      
      {
         typeOfData === "allEvent" &&
         <ul className='text-[#2789ce] border-b bg-white border-[#7e97a7] ' >
           {
            data?.map((game)=>{
                let EventName = game.EventName;
                let trimName = EventName?.slice(0,35)
                return <Link to={`/currentBetMobile/${game.EventId}`} state={{type:'singleEvent'}} className='text-[4vw] font-bold p-2 border-b border-[#e0e6e6] flex justify-between items-center' >{EventName?.length <= 35 ? EventName : trimName+"..." } <IoIosArrowForward className='text-black font-bold h-5 w-5 rounded-sm border border-[#e0e6e6]' /> </Link>
            })
           }
        </ul>
      }

      { 
        typeOfData === "singleEvent" &&  <>
         <h1></h1>
         <h1 className='text-white p-2 font-semibold' style={{background:"linear-gradient(-180deg, #32617f 20%, #1f4258 91%)"}} >Matched</h1>
        
        {data?.length === 0 ?  <h1>No Bet Placed!</h1> :
        data?.map((event)=>{
            let betIdValue = event.BetId;
            let trimBetId = betIdValue?.slice(0,25);

            let selecetionValue = event.Selection;
            let trimSelection = selecetionValue?.slice(0,10);

            let date = new Date(event.PlaceTime);
            const options = { month: 'long', day: 'numeric', year: 'numeric' };
            const formattedDate = date.toLocaleDateString('en-US', options);

            const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
            const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

            return   <table key={event.Id}  className={`table w-full ${event.Type === "back" ? "bg-[#beddf4] border-[#3d9ae0]" : "bg-[#f7e7eb] border-[#dd325d]"} border-b`}>
            <thead className='bg-white'>
                <tr className=' w-full text-[16px]'>
                <th className='w-[50%] text-left pl-3'>{event.Type}</th>
                <th className='px-1'>Odds</th>
                <th className='px-1'>Stake</th>
                <th className='px-1'>Profit</th>
                </tr>
            </thead>
            <tbody>
                 <tr>
                    <td className={`text-[15px] pl-2 ${event.Type === "back" ? "bg-[#ddf0ff]" : "bg-[#fdf1f3]"}`} colSpan={4}>  <span className='ml-2'>{formattedDate},</span> <span className='ml-2'>{formattedTime}</span></td>
                 </tr>
                <tr className={``}> 
                 <td className='flex pl-3 items-center gap-2'> <span className={` ${event.Type === "back" ? "bg-[#72bbef]" : "bg-[#faa9ba]"} rounded-md my-2 px-2 py-1`} >{event.Type}</span> <p className='my-2'> { selecetionValue <= 20 ? event.Selection : event.Selection } </p> </td>    
                 <td className='text-center' >{event.OddsRequest}</td>
                 <td className='text-center' >{event.AmountStake}</td>
                 <td className='text-center' >{event.Profit}</td>
                </tr>
            </tbody>
        </table>
        })
             }
        </>
      }


      </main>
  )
}

export default CurrentBetMobile
