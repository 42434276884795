import React, { useState } from 'react'
import CricketPoster from "../images/cricket-poster.webp";
import MinusIcon from "../images/minus-icon-white.png";
import MiniFooter from '../components/MiniFooter';
// import News from '../components/News';
import SportListItem from '../components/SportListItem';
import { Link } from 'react-router-dom';





const Cricket = ({ CricketEvents }) => {

  //console.log("Cricket Component is rendering !!!");

  const [bets, setBets] = useState([]);


  return (
    <>
      {/* for smaller screen  */}
      <div className='h-screen lg:hidden'>
        <h1>Page Not Exist</h1>
      </div>


      {/* for bigger screen */}
      <div className='hidden lg:flex h-[80vh]  overflow-y-hidden '>

        <div className='w-[18vw]' >
          <h1 className='bg-black text-white text-sm px-4 py-1'>Sports</h1>
          <Link>  <h1 className='text-sm px-4 py-[2px] bg-white hover:bg-[#f8f3e6] cursor-pointer'>All Sports</h1> </Link>
          <h1 className='bg-black text-white text-sm px-4 py-1 cursor-pointer'>Kabaddi</h1>
          <h1 className='bg-[#dddcd6] text-black text-sm px-4 py-1 '>Commons</h1>
          <h1 className='bg-white border border-[#f8f3e6] hover:bg-[#f8f3e6] text-black text-sm px-4 py-1 cursor-pointer'>Others</h1>
        </div>

        <div className='w-[57vw]  overflow-y-scroll px-3 bg-[#eeeeee]' >
          {/* <News /> */}
          <img src={CricketPoster} className='w-full border-t border-white h-[177px]' alt="" />
          <h1 className='flex justify-between mt-3 text-sm px-2 bg-[#67f0c2] p-1'> <span className='font-semibold'>Sports Highlights</span> <div className='flex gap-2'><p>View by</p><select className='bg-[#67f0c2] border rounded-sm border-black' name="" id=""><option className='bg-white' value="">Time</option><option className='bg-white' value="">Matched</option><option className='bg-white' value="">Competition</option></select></div></h1>
          <table className='hidden lg:block text-white  w-full border-collapse border-b bg-white border-black'>
            <thead>
              <tr className='bg-[#ced5da] text-sm font-light '>
                {/* <th className='py-2 bg-[#ced5da]  text-[#1e1e1e] pr-20 border-gray-400' ></th> */}
                <th className='font-light text-[#1e1e1e] text-right  border-gray-400 w-[45vw]' >Matched</th>
                <th className={`font-light text-[#1e1e1e] border-gray-400`} >1</th>
                <th className={`font-light text-[#1e1e1e] border-gray-400`} >x</th>
                <th className={`font-light text-[#1e1e1e] border-gray-400`} >2</th>
                <th className='text-transparent' >{"pinq"}</th>
              </tr>
            </thead>
            <tbody>

              {CricketEvents?.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))?.map((match) => {
                let matchName = "example";
                matchName = match?.eventName;
                let eventName = matchName.substring(0, 35);
                const eventDate = new Date(match.eventDate);
                const currentDate = new Date();
                // Format eventDate to only include the date part
                const formattedEventDate = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                // Determine if the eventDate is tomorrow
                const isTomorrow = (eventDate.getDate() === currentDate.getDate() + 1) &&
                  (eventDate.getMonth() === currentDate.getMonth()) &&
                  (eventDate.getFullYear() === currentDate.getFullYear());

                // Append "tomorrow" to the formattedEventDate if it's tomorrow
                const displayDate = isTomorrow ? `Tomorrow ${formattedEventDate}` : formattedEventDate;
                return <SportListItem key={match.SrNo} marketId={match.marketId} eid={4} eventId={match.gameId} title={eventName?.length > 30 ? eventName + "..." : eventName} matchName={match.eventName} series={match.tournamentId} OneBackValue={match.back1} OneLayValue={match.lay1} XBackValue={match.back11} XLayValue={match.lay11} TwoBackValue={match.back12} TwoLayValue={match.lay12} greyLine={new Date(match.eventDate) <= new Date ? "In-Play" : displayDate} isInPlay={true} isFancy={match.Fancy === 1 ? true : false} isBookMarked={match.BookMaker === 1 ? true : false} isLive={new Date(match.eventDate) <= new Date ? true : false} eventDate={match.eventDate} totalMatched={match.totalMatched}/>
              })}

            </tbody>
          </table>


          {/*  footer for sport pages */}
          <MiniFooter />

        </div>

        <div className='lg:block hidden bg-white w-[25vw] ml-2 border-t'>
          <h1 className='bg-[#274252] text-white text-xs  font-semibold p-1 flex justify-between'>Bet Slip <img src={MinusIcon} className='h-4' alt="" /></h1>
          {bets.length === 0 && <h1 className='p-3 text-xs text-center'>Click on the odds to add selections to the betslip.</h1>}
        </div>

      </div>
    </>
  )
}

export default Cricket
