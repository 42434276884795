import React, { useCallback, useContext, useEffect, useState } from 'react'

import MinusIcon from "../../images/minus-icon-white.png";
import ClockIcon from "../../images/clock-icon-green.png";
import PIcon from "../../images/p-icon.svg";
import ReloadIcon from "../../images/reload-icon.svg";
import BackAllImg from "../../images/bg-backall.png";
import BackAllDisableImg from "../../images/bg-backall-disabled.png";
import LayAllImg from "../../images/bg-layall.png";
import LayAllDisableImg from "../../images/bg-layall-disabled.png";
import DisabledImg from "../../images/bg-disabled.png";
import { MdPushPin } from "react-icons/md";
import { TbArrowBigRightFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineBarChart } from "react-icons/ai";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { MdOutlineBackspace } from "react-icons/md";
import GlobalContext from '../../context/GlobalContext';
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from 'react-hot-toast';


const MatchOddTable = ({ needHeader, EventNameBetSlip, OddMatchData, EventName, setLoading, minStake, maxStake, eid, eventId, marketId, delayTime, matchOddBetProfits, IsBetLock, CountMORunner, matchName, series }) => {

  // console.log('Matchodds Com, EventId : ', eventId, ' eid : ', eid, ' marketId : ', marketId, 'EventName is : ', matchName, 'Series Id is : ', series)

  let userId2 = Cookies?.get("LoginUserDetail");
  let userId = userId2 === undefined ? "" : JSON?.parse(userId2);

  //console.log("Match odds page randering!!");

  // alert(UserMatchMinStake);

  //console.log('userId is  : ', userId.Id);
  //console.log('eid is : ', eid);
  //console.log('eventId is : ', eventId);
  //console.log('market id is : ', marketId);
  // console.log('matchOddBetProfit from sport detail is : ', matchOddBetProfits);
  //  console.log('min stake matchodds : ', minStake);
  //  console.log('max stake matchodds : ', maxStake);



  const [selectedBet, setSelectedBet] = useState({});
  const [inputValue, setInputValue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [odds, setOdds] = useState([]);
  const [bm, setBM] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [matchOddBetProfit, setmatchOddBetProfit] = useState(0)
  const [bookMBetProfit, setBookMBetProfit] = useState(0)
  const [openBetTable, setOpenBetTable] = useState([]);
  // const [matchOddBetProfit, setmatchOddBetProfit] = useState(0);


  const [liability, setLiability] = useState(0);
  // const [errorInBet, setErrorInBet] = useState(false);  // this state is for set , there is an error or not while setting bet
  // const [errorMessage, setErrorMessage] = useState("");   // this state will hold the message of error while setting bet
  // const [isSuccess, setIsSuccess] = useState(false);


  const context = useContext(GlobalContext);
  // setBetSlipData,
  const { isUserLoggedIn, setBetSlipData, quickStakes, remount, setIsLoginModeOpen, betSend, setBetSend, errorInBet, setErrorInBet, errorMessage, setErrorMessage, isSuccess, setIsSuccess } = context;
  const ArrQuickStakes = Object.values(quickStakes);

  let navigate = useNavigate();

  const setBettingDataFunc = useCallback((RowName, MatchName, Type, Value, selectionId, indexId) => {
    //  alert(minStake);


    setBetSlipData({ matchName: matchName, series: series, EventName: MatchName, type: Type, value: Value, dataType: "match odds", minStake: minStake, maxStake: maxStake, market: "Matchodds", selectionId: selectionId, DelayTime: delayTime, indexId: indexId });
    setSelectedBet({ title: MatchName, type: Type, value: Value, selectionId: selectionId, selection: RowName, DelayTime: delayTime, indexId: indexId });
    // setTypeSt(setTypeSt);
    // getProfitAndLoss(Type);
  }, [])


  //  function that set the value of profit and liability 
  const funcToSetProfitAndLiability = (value) => {

    if (selectedBet?.type === "back") {
      // setInputValue(Number(inputValue));
      setProfit(((selectedBet?.value - 1) * value));      // setting value of profit   
      setLiability(inputValue < 0 ? 0 : value * -1);    // setting value of liability

      console.log('Profit : ', ((selectedBet?.value - 1) * value))
      console.log('Liability : ', inputValue < 0 ? 0 : value * -1)

    }
    else {
      setProfit(((selectedBet?.value - 1) * value) * -1);   // setting value of profit
      setLiability(value * 1);    // setting value of liability
    }
    console.log('Profit : ', ((selectedBet?.value - 1) * value) * -1)
    console.log('Liability : ', value * 1)
  };



  // function to set the data of placeBet
  const placeBetFuc = async () => {

    //console.log('place bet in betSlip table into MatchOdds component...')

    let DelayedTime = selectedBet?.DelayTime * 1000;

    console.log('Delayed time in MO tAble : ', selectedBet?.DelayTime, " ", DelayedTime);

    // function to genrate BetId
    function generateRandomAlphaNumeric(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    }

    const convertToIST = (dateString) => {
      const date = new Date(dateString);
      const ISTOffset = 330; // IST offset is UTC+5:30
      const ISTTime = new Date(date.getTime() + (ISTOffset * 60000));
      return ISTTime.toISOString().slice(0, 19).replace('T', ' '); // Format the date-time string 
    }

    let matchTimeData = convertToIST(new Date());


    if (inputValue < minStake || inputValue > maxStake) {
      setErrorInBet(true);
      setErrorMessage(`Enter stake between ${minStake} - ${maxStake}`);
      setIsSuccess(false);
      // alert(`Enter stake between ${minStake}  --  ${maxStake}`);
      setInputValue(0);

      setTimeout(() => {
        setErrorInBet(false);
        // fetchBet();
      }, 3000);
      return;
    }

    const currentBhaw = document.getElementById(selectedBet?.indexId).innerHTML;
    console.log('index Id is : ', currentBhaw)

    if (currentBhaw != selectedBet?.value) {
      toast.error("Odds change..!", {
        duration: 1000
      })
      return;
    }

    setLoading(true);

    setTimeout(() => {
      ; (async () => {
        let res = await axios.post("https://api.betcare.live/saveBet", {
          BetId: generateRandomAlphaNumeric(30),
          SportId: eid,
          SeriesId: series,
          EventId: eventId,
          Event: matchName,
          MarketId: marketId,
          Market: "Matchodds",
          SelectionId: selectedBet?.selectionId,
          Selection: selectedBet?.title,
          OddsType: 1,
          Type: selectedBet?.type,
          OddsRequest: selectedBet?.value,
          AmountStake: inputValue,
          BetType: 2,
          IsSettlement: 2,
          IsDelete: 0,
          Status: true,
          UserId: userId?.Id,
          ResultType: null,
          ResultAmount: 0,
          IpAddress: "",
          IsMatched: true,
          EventName: matchName,
          Profit: selectedBet?.type == 'back' ? profit.toFixed(2) : liability.toFixed(2),
          Liability: selectedBet?.type == 'back' ? liability.toFixed(2) : profit.toFixed(2),
          MatchedTime: matchTimeData,
          settleTime: null,
          PlaceTime: convertToIST(new Date())
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (res.data.isSuccess) {

          // //console.log(res.status);
          //  alert(res.status);
          let checkBal = await fetch(`https://api.betcare.live/expo2/${userId?.Id}/${userId?.selfUser}`)
          let resCheckBal = await checkBal.json();

          if (resCheckBal.isSuccess) {
            if (resCheckBal.mainBalanceafterExpo <= 0) {
              setLoading(false);
              setErrorInBet(true);
              setIsSuccess(false);
              setErrorMessage(`Insufficient balance..`);
              setInputValue(0);

              //console.log('balance block delete bet id : ', betId)
              const deleteQuery = await fetch(`https://api.betcare.live/deleteUnmatchBet`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId.Id })
              });

              const queryResp = await deleteQuery.json();


              setTimeout(() => {
                setErrorInBet(false);
                setSelectedBet({});
                // setBetSlipData();
                // setRemount((prev) => !prev); // it will update the value in match odds
              }, 3000)
              return;
            } else {
              const updateQuery = await fetch(`https://api.betcare.live/updateUnmatchBet`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId.Id, BetId: res.data.betId })
              });

              const queryResp2 = await updateQuery.json()
              if (queryResp2.isSuccess) {
                setLoading(false);
                setErrorInBet(true);
                setErrorMessage(`Bet is set successfull`);
                setIsSuccess(true);
                // alert(`Enter stake between ${minStake}  --  ${maxStake}`);
                setInputValue(0);
                setBetSend(true);



              }
            }
          }

        }

      })();

      setTimeout(() => {
        setErrorInBet(false);
        setIsSuccess(false);
        setSelectedBet({});
        // fetchBet();
      }, 3000);

    }, DelayedTime)

    setTimeout(() => {
      setErrorInBet(false);
    }, 3000);
  }



  // function to set a bet
  const onClickOfSettingBet = (event, n, indexNumber) => {
    let name = event.target.runnerName;

    //console.log(indexNumber);

    if (name === "fixedData") {
      setInputValue(n);
      funcToSetProfitAndLiability(n);

      return;
    }

    if (n === "x") {
      setInputValue(prev => prev <= 9 ? prev : Math.floor(prev / 10));
      let num = inputValue <= 9 ? inputValue : Math.floor(inputValue / 10);
      funcToSetProfitAndLiability(num);
      return;
    }

    if (n === "-") {
      setInputValue(prev => prev <= 0 ? prev : prev - 1);
      let num = inputValue <= 0 ? 0 : inputValue - 1;
      funcToSetProfitAndLiability(num);
      return;
    }

    if (n === "+") {
      setInputValue(prev => prev >= 1000000 ? prev : prev + 1);
      let num = inputValue >= 1000000 ? 1000000 : inputValue + 1;
      funcToSetProfitAndLiability(num);
      return;
    }

    if (inputValue > 1000000) {
      return;
    }

    if (n === "00") {
      setInputValue(prev => prev >= 100000 ? prev : prev * 100);
      let num = inputValue >= 100000 ? inputValue : inputValue * 100;
      funcToSetProfitAndLiability(num);
      return;
    }

    if (n == 1 || n == 2 || n == 3 || n == 4 || n == 5 || n == 6 || n == 7 || n == 8 || n == 9 || n == 0) {
      setInputValue(prev => {
        const prevDigits = Array.from(String(prev), Number);
        prevDigits.push(n);
        const newValue = Number(prevDigits.join(''));
        funcToSetProfitAndLiability(newValue);
        return newValue;
      });
      return;
    }

    setInputValue(prev => prev + n);
    let num = (inputValue + n);
    funcToSetProfitAndLiability(num);


  }


  const tableApi = async () => {
    try {
      const response = await fetch(`https://api.betcare.live/checkGetMatchOdds/${marketId}/${eventId}`);
      const result = await response.json();

      ////console.log("Table api data of matchOdds : ", result);

      if (result.isSuccess) {

        if (Array.isArray(result.Odds)) {
          const firstOddsItem = result.Odds[0];
          // setTotalMatched(firstOddsItem.totalMatched)

          if (Array.isArray(firstOddsItem.runners)) {
            const runnersData = firstOddsItem.runners;
            setOdds(runnersData);
            //console.log('odds data : ', runnersData)
          } else {
            console.error('No runners data available.');
          }
        } else {
          console.error('Invalid data format:', result);
        }

        if (Array.isArray(result.Bookmaker)) {
          //console.log("BookMaker data:", JSON.stringify(result.Bookmaker));
          setBM(result.Bookmaker);
          //console.log('BM data : ', result.Bookmaker)
        } else {
          console.error('No Bookmaker data available.');
        }

        if (Array.isArray(result.Fancy)) {
          //console.log("Fancy data:", JSON.stringify(result.Fancy));
          setFancy(result.Fancy);
          //console.log('Fancy data : ', result.Fancy)
        } else {
          console.error('No Fancy data available.');
        }

      }


    } catch (error) {
      console.error('Error fetching Table API data:', error);
    }
  };



  const fetchBet = async () => {
    //console.log("calling fetchbet..");
    try {
      const response = await fetch(`https://api.betcare.live/fetchLastBet/${userId.Id}/${eventId}`);

      const result = await response.json();
      // console.log("Bet result : " + JSON.stringify(result))

      if (result.isSuccess && result.data.length > 0) {

        const filteredData = result.data;
        // console.log('result data in matcODdds : ', result.data)
        // NEXT TEAM For BACK /////////////

        const filterODD = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "Matchodds" &&
            item.type == "back" &&
            odds.some(oddsitem => oddsitem.selectionId == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterODDSelectionIds = new Set(filterODD.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterOddAgainForOdd = odds.filter(item => !filterODDSelectionIds.has(item.selectionId));

        // console.log("Same Id data: " + JSON.stringify(filterODD))
        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterOddAgainWithProfitLossOdd = filterOddAgainForOdd.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayForOdd = [...filterODD, ...filterOddAgainWithProfitLossOdd];


        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionId = {};
        const totalLossBySelectionId = {};

        // Calculate net profit for each selectionId
        combinedArrayForOdd.forEach(item => {
          const selectionId = item.selectionId;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionId.hasOwnProperty(selectionId)) {
            totalProfitBySelectionId[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionId.hasOwnProperty(selectionId)) {
            totalLossBySelectionId[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionId[selectionId] += profit;
          totalLossBySelectionId[selectionId] += loss;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionId = {};
        Object.keys(totalProfitBySelectionId).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelections = Object.keys(totalLossBySelectionId)
            .filter(otherSelectionId => otherSelectionId != selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionId[otherSelectionId], 0);

          const profit = totalProfitBySelectionId[selectionId];
          // const loss = totalLossBySelectionId[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionId[selectionId] = profit + totalLossToOtherSelections;
        });

        // NEXT TEAM For LAY /////////////

        const filterODDLay = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "Matchodds" &&
            item.type == "lay" &&
            odds.some(oddsitem => oddsitem.selectionId == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterODDLaySelectionIds = new Set(filterODDLay.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterOddAgain = odds.filter(item => !filterODDLaySelectionIds.has(item.selectionId));

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterOddAgainWithProfitLoss = filterOddAgain.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArray = [...filterODDLay, ...filterOddAgainWithProfitLoss];

        //console.log("Combined Both Array: " + JSON.stringify(combinedArray));

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdLay = {};
        const totalLossBySelectionIdLay = {};

        // Calculate net profit for each selectionId
        combinedArray.forEach(item => {
          const selectionId = item.selectionId;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdLay.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdLay[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdLay.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdLay[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdLay[selectionId] += loss;
          totalLossBySelectionIdLay[selectionId] += profit;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdLay = {};
        Object.keys(totalProfitBySelectionIdLay).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelections = Object.keys(totalLossBySelectionIdLay)
            .filter(otherSelectionId => otherSelectionId !== selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLay[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdLay[selectionId];
          // const loss = totalLossBySelectionIdLay[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdLay[selectionId] = profit + totalLossToOtherSelections;
        });

        // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
        const finalProfitMatchOdd = {};

        Object.keys(netProfitBySelectionId).forEach(selectionId => {
          // Sum the corresponding values for each selectionId
          finalProfitMatchOdd[selectionId] = (netProfitBySelectionId[selectionId] || 0) + (netProfitBySelectionIdLay[selectionId] || 0);
        });

        if (Object.keys(netProfitBySelectionId).length === 0) {
          // Object is empty
          Object.keys(netProfitBySelectionIdLay).forEach(selectionId => {
            // Sum the corresponding values for each selectionId
            // console.log("Selection ID of final profit match odd: ", selectionId);
            finalProfitMatchOdd[selectionId] = netProfitBySelectionIdLay[selectionId] || 0;
          });
        }
        console.log("Final Profit for Match Odd on MatchOddTbl : ", finalProfitMatchOdd);

        setmatchOddBetProfit(finalProfitMatchOdd)

      } else {
        setmatchOddBetProfit(0)
        setBookMBetProfit(0)
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const betLockNotify = () => {
    toast.error("You are not able to place bets..", {
      duration: 1000
    })
  }

  useEffect(() => {
    setInputValue(0);
    setProfit(0);
    setLiability(0);
  }, [selectedBet, errorInBet])


  // useEffect(() => {
  //   fetchBet();
  // }, [OddMatchData, remount])

  // useEffect(() => {
  //   tableApi();
  // });

  // useEffect(() => {
  //   // tableApi();
  //   // fetchBet();
  // }, [])

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     fetchBet();
  //   }, 2000);

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);


  return (
    <>

      <style>
        {`
          @media (max-width: 768px) {
            .mobile-view-td {
             font-size: 3.5vw;
             font-weight: 600;
            }
          }
        `}
      </style>
      {/* {errorInBet && <div style={{width: '100%'}}>
        <span className={`${isSuccess ? "bg-green-500" : "bg-red-500"} text-sm font-bold text-white p-2 text-center`}>{errorMessage}
        </span>
      </div>} */}
      <div className='bg-white border-b mb-4 border-[#7e97a7] mt-5' >
        <div className={`bg-[#243a48] ${needHeader ? "" : "hidden"} cursor-pointer text-white flex justify-between items-center`} >
          <div className='flex gap-1 text-xs items-center'>
            <div className='p-1' style={{ background: "linear-gradient(0deg, rgba(101,183,19,1) 7%, rgba(73,155,30,1) 61%)" }} >
              <MdPushPin className='text-white h-5 w-5' />
            </div>
            <h1>Agafonov, Egor v Chepik, Alexander</h1>
            <p className='bg-white text-black px-2 rounded-sm' >Match Odds</p>
            <img src={ClockIcon} className='rounded-sm' alt="" />
          </div>
          <img src={ReloadIcon} className='h-4 w-4 mr-2' alt="" />
        </div>
        <table className='w-full text-xs'>
          <tr>
            <td className='text-black' >{CountMORunner} selection  {minStake} & {maxStake} </td>
            <td className={`w-[150px] hidden lg:table-cell `} ></td>

            <td className='flex justify-between w-[150px]' >
              <div className=' w-1/2 bg-img-back bg-cover font-bold h-[20px] flex justify-center items-center'  > Back all </div>
              <div className=' w-1/2 bg-img-lay bg-cover font-bold  h-[20px] flex justify-center items-center'  > Lay all </div>
            </td>
            <td className={`w-[150px] hidden lg:table-cell text-end`}></td>
          </tr>
          {
            OddMatchData?.map((e, i) => {
              return (
                <tbody key={e.selectionId}>
                  {/* {errorInBet && <tr>
                    <td colSpan={4} className={`${isSuccess ? "bg-green-500" : "bg-red-500"}  ${selectedBet?.title === e.runnerName ? "table-cell" : "hidden"} text-sm font-bold text-white p-2 text-center`}>{errorMessage}
                    </td>
                  </tr>} */}

                  <tr className='border-b border-[#7e97a7]'>

                    <td className='font-normal text-xs pl-2' >
                      <div className='flex items-center gap-1 mobile-view-td'> <AiOutlineBarChart className='text-xl' /> {e.runnerName}
                      </div>

                      {
                        // first we will checking if the anybody is selected or not any option of bookmaker
                        selectedBet.title === undefined ?  // it means not selected so,
                          // we will show the total value by calculating it's previous bets
                          <span className={`${matchOddBetProfits[e.selectionId] === undefined ? "text-[#198754]" : matchOddBetProfits[e.selectionId] >= 0 ? "text-[#198754]" : "text-[#d0021b]"} flex items-center`}>
                            {/* checking if calculation is undifined means 0 s */}
                            <TbArrowBigRightFilled />{matchOddBetProfits[e.selectionId] === undefined ?
                              0   // if 0 
                              : Number(matchOddBetProfits[e.selectionId]).toFixed(2)}</span>   // if not 0
                          // if anybody selected any option from matchodds
                          : matchOddBetProfits[e.selectionId] === undefined ?   // checking if calculated value is undefined or not 
                            // if value is undefined means value is 0 so ,
                            selectedBet.title === e.runnerName ?   //  now checking if this row is selected or not  
                              <span className={`${profit >= 0 ? "text-[#198754]" : "text-[#d0021b]"} flex items-center`} > <TbArrowBigRightFilled />{profit.toFixed(2)} </span> // row is selected so , we will show the profit to it 
                              : <span className={`${liability >= 0 ? "text-[#198754]" : "text-[#d0021b]"} flex items-center`}><TbArrowBigRightFilled />{liability.toFixed(2)}</span>  // row is not selected so, we will show the liability to it
                            // if calculated value is not 0
                            : <span className={`flex items-center`}> <TbArrowBigRightFilled />  {selectedBet?.title === e.runnerName ? <span className={`${Number(matchOddBetProfits[e.selectionId] + profit).toFixed(2) >= 0 ? "text-[#198754]" : "text-[#d0021b]"}`}>{Number(matchOddBetProfits[e.selectionId] + profit).toFixed(2)}</span> : <span className={`${Number(matchOddBetProfits[e.selectionId] + liability).toFixed(2) >= 0 ? "text-[#198754]" : "text-[#d0021b]"}`} >{Number(matchOddBetProfits[e.selectionId] + liability).toFixed(2)}</span>}</span>
                      }

                    </td>
                    <td className='w-[150px] hidden lg:table-cell'>
                      {/* ${ ? "cursor-not-allowed" : ""} */}
                      <div className={`flex text-xs  w-full `}>
                        <div className='bg-[#d7e8f4]'>
                          {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                          <div className=' font-bold  text-center w-[75px] h-[40px] pt-[1px] border-r border-white' >  <br /> <span className='font-medium text-[11px]' > </span> </div>
                        </div>
                        <div className='bg-[#b7d5eb]'>
                          {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                          <div className=' font-bold text-center w-[75px] h-[40px] pt-[1px]'  > <br /> <span className='font-medium text-[11px]' >   </span> </div>
                        </div>
                      </div>
                    </td>
                    <td className='w-[150px] '>
                      {/* ${e.isDisabled ? "cursor-not-allowed" : ""} */}
                      <div className={`flex text-xs `}>
                        <div
                          className={`${selectedBet?.title === e.runnerName ? selectedBet?.type === "back" ? "bg-[#2395e6] lg:bg-[#72bbef] shadow-selectedBox lg:shadow-none text-white lg:text-black" : "bg-[#72bbef]" : "bg-[#72bbef]"} `}
                          onClick={() =>
                            isUserLoggedIn !== "true"
                              ? window.innerWidth < 1000
                                ? navigate(`/login`)
                                : setIsLoginModeOpen(true)
                              : IsBetLock === true ||
                                e?.ex?.availableToBack[0]?.price > 3 ||
                                e?.ex?.availableToBack[0]?.price < 1.01
                                ? betLockNotify()
                                : setBettingDataFunc(
                                  EventName,
                                  e.runnerName,
                                  "back",
                                  e?.ex?.availableToBack[0]?.price,
                                  e.selectionId,
                                  `backPrice${i}`
                                )
                          }
                        >
                          {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                          <div className=' font-bold  text-center w-[75px] h-[40px] pt-[1px] border-r border-white' style={{ padding: '5px' }}>
                            <span id={`backPrice${i}`}>{e?.ex?.availableToBack[0]?.price} </span> <br /> <span className='font-medium text-[11px]' > {e?.ex?.availableToBack[0]?.size} </span> </div>
                        </div>
                        <div
                          className={`${selectedBet?.title === e.runnerName ? selectedBet?.type === "lay" ? "bg-[#f4496d] lg:bg-[#faa9ba] lg:shadow-none shadow-selectedBox lg:text-black text-white" : "bg-[#faa9ba]" : "bg-[#faa9ba]"} `}
                          // onClick={IsBetLock == true || e?.ex?.availableToLay[0]?.price > 3 || e?.ex?.availableToLay[0]?.price < 1.01 ? betLockNotify : () => { isUserLoggedIn !== "true" ? window.innerWidth < 1000 ? navigate(`/login`) : setIsLoginModeOpen(true) : setBettingDataFunc(EventName, e.runnerName, "lay", e?.ex?.availableToLay[0]?.price, e.selectionId, `layPrice${i}`) }}

                          onClick={() =>
                            isUserLoggedIn !== "true"
                              ?
                              window.innerWidth < 1000
                                ?
                                navigate(`/login`)
                                :
                                setIsLoginModeOpen(true)
                              :
                              IsBetLock === true ||
                                e?.ex?.availableToLay[0]?.price > 3 ||
                                e?.ex?.availableToLay[0]?.price < 1.01
                                ?
                                betLockNotify()
                                :
                                setBettingDataFunc(EventName, e.runnerName, "lay", e?.ex?.availableToLay[0]?.price, e.selectionId, `layPrice${i}`)
                          }
                        >
                          {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                          <div className=' font-bold  text-center w-[75px] h-[40px] pt-[1px]' style={{ padding: '5px' }} >
                            <span id={`layPrice${i}`}>{e?.ex?.availableToLay[0]?.price}</span>
                            <br /> <span className='font-medium text-[11px]' > {e?.ex?.availableToLay[0]?.size} </span> </div>
                        </div>
                      </div>
                    </td>
                    <td className='w-[150px] hidden lg:table-cell'>
                      {/* ${e.isDisabled ? "cursor-not-allowed" : ""} */}
                      <div className={`flex text-xs`}>
                        <div className='bg-[#efd3d9]' >
                          {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                          <div className=' font-bold  text-center w-[75px]  h-[40px] pt-[1px] border-r border-white'  ><br /> <span className='font-medium text-[11px]' >  </span> </div>
                        </div>
                        <div className='bg-[#f6e6ea]' >
                          {/* style={ e.isDisabled ? {background:` #33333333 url(${DisabledImg})`} : {}} */}
                          <div className=' font-bold  text-center w-[75px] h-[40px] pt-[1px]' >  <br /> <span className='font-medium text-[11px]' > </span> </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  {selectedBet?.title === e.runnerName && <tr className={`lg:hidden  `} >
                    <td colSpan={4} className={`${selectedBet?.title === e.runnerName ? "table-cell" : "hidden"} overflow-hidden`}>
                      <div className={` ${selectedBet?.type === "back" ? "bg-[#dceaf4]" : "bg-[#f2e5e8]"} `}>
                        {/* for min-bet input */}
                        <div className='flex py-2 gap-2 mx-2'>
                          <div className='w-1/2 text-[#999] border border-[#bbb] rounded-md text-sm font-bold text-center py-3 bg-[#dcdcdc]' >{selectedBet?.value}</div>
                          <div className='w-1/2 flex items-center' >
                            <div onClick={(e) => { onClickOfSettingBet(e, "-", i) }} className='h-full w-10 border border-[#aaa] rounded-l flex items-center justify-center' style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} > <FaMinus className='text-[#1f72ac] text-xl' /> </div>
                            <input type="number" maxLength={7} value={inputValue} readOnly name='' className='h-full w-[85px] border-y border-[#aaa] text-center focus:bg-[#fff0ca] focus:outline-none focus:shadow-3xl' />
                            <div onClick={(e) => { onClickOfSettingBet(e, "+", i) }} className='h-full w-10 border border-[#aaa] rounded-r flex items-center justify-center' style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} > <FaPlus className='text-[#1f72ac] text-xl' /> </div>
                          </div>
                        </div>

                        <div className='w-full flex mb-3 font-bold' >
                          {
                            ArrQuickStakes?.map((stake) => {
                              return <button onClick={(e) => { onClickOfSettingBet(e, stake, i) }} value={stake} name='fixedData' className='text-white flex-grow py-2' style={{ background: "linear-gradient(-180deg, #32617f 20%, #1f4258 91%)" }} >{stake}</button>
                            })
                          }
                        </div>

                        <div className='grid bg-white grid-cols-7 w-full text-sm mb-3' >
                          <button onClick={(e) => { onClickOfSettingBet(e, 1, i) }} disabled={inputValue?.length >= 7} value={'1'} className='border border-[#aaa] border-b-0 py-2 ' >1</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, 2, i) }} disabled={inputValue?.length >= 7} value={'2'} className='border border-[#aaa] border-b-0 py-2 ' >2</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, 3, i) }} disabled={inputValue?.length >= 7} value={'3'} className='border border-[#aaa] border-b-0 py-2 ' >3</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, 4, i) }} disabled={inputValue?.length >= 7} value={'4'} className='border border-[#aaa] border-b-0 py-2 ' >4</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, 5, i) }} disabled={inputValue?.length >= 7} value={'5'} className='border border-[#aaa] border-b-0 py-2 ' >5</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, 6, i) }} disabled={inputValue?.length >= 7} value={'6'} className='border border-[#aaa] border-b-0 py-2 ' >6</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, "x", i) }} value={'x'} name='cut' className='row-span-2 flex justify-center items-center border-y border-[#aaa]' > <MdOutlineBackspace className='text-lg' /> </button>

                          <button onClick={(e) => { onClickOfSettingBet(e, 7, i) }} disabled={inputValue?.length >= 7} value={'7'} className='border border-[#aaa] py-2 ' >7</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, 8, i) }} disabled={inputValue?.length >= 7} value={'8'} className='border border-[#aaa] py-2 ' >8</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, 9, i) }} disabled={inputValue?.length >= 7} value={'9'} className='border border-[#aaa] py-2 ' >9</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, 0, i) }} disabled={inputValue?.length >= 7} value={"0"} className='border border-[#aaa] py-2 ' >0</button>
                          <button onClick={(e) => { onClickOfSettingBet(e, "00", i) }} disabled={inputValue?.length >= 6} value={"00"} className='border border-[#aaa] py-2 ' >00</button>
                          <button className='border border-[#aaa] py-2 ' >.</button>
                        </div>

                        <div className='flex gap-1 mx-2 w-full pb-3' >
                          <button onClick={() => { setSelectedBet({}) }} className={`w-[48%] border border-[#aaa]  rounded py-2 text-sm`} style={{ background: "linear-gradient(-180deg, #ffffff 0%, #eeeeee 89%)" }} >Cancel</button>
                          <button onClick={placeBetFuc} disabled={isUserLoggedIn !== "true" ? true : false} className={`w-[48%]  text-sm rounded py-2 ${isUserLoggedIn !== "true" ? "bg-[#a6a6a6] text-[#ffd5a6]" : "bg-gradient-placeBet text-[#ffb200]"}  font-semibold`} style={isUserLoggedIn !== "true" ? {} : { background: "linear-gradient(180deg, #474747 0%, #070707 100%" }}  >Place Bet</button>
                        </div>
                      </div>
                    </td>
                  </tr>}
                </tbody>
              )
            })
          }

        </table>

        {/* <img src={LayAllImg} alt="" /> */}

      </div>
    </>
  )
}

export default React.memo(MatchOddTable)

