import './App.css'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './layouts/Layout';
import InPlay from './pages/InPlay';
// import Kabaddi from './pages/Kabaddi';
import Login from './pages/auth/Login';
import GlobalContextState from './context/GlobalContextState.jsx';
import Sports from './pages/Sports.jsx';
import MultiMarketMobile from './pages/multimarket/MultiMarketMobile.jsx';
import AccountMobile from './pages/AccountMobile.jsx';
import MultiMarket from './pages/multimarket/MultiMarket.jsx';
import Cricket from './pages/Cricket.jsx';
import Soccer from './pages/Soccer.jsx';
import Tennis from './pages/Tennis.jsx';
import SportDetail from './pages/SportDetail.jsx';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountLayout from './layouts/AccountLayout.jsx';
import Profile from './pages/Account/Profile.jsx';
import BalanceOverview from './pages/Account/BalanceOverview.jsx';
import AccountStatement from './pages/Account/AccountStatement.jsx';
import AcitivityLog from './pages/Account/AcitivityLog.jsx';
import MyBet from './pages/Account/MyBet.jsx';
import Loader from './components/Loader.jsx';
import { useState } from 'react';
import CurrentBetMobile from './pages/CurrentBetMobile.jsx';
import { Toaster } from 'react-hot-toast';
import WalletGateway from './pages/Wallet/WalletGateway.jsx';
import SignUp from './pages/auth/SignUp.jsx';


const fetchingAllEventsData = async () => {
  let res = await axios.get(`https://api.s2bet.in/GetAllEvent`);
  let resData = await res.data;
  return resData;
}

function App() {

  //console.log("app is rendering !!");

  const { isPending, isLoading, isError, data: sports, error } = useQuery({
    queryKey: ['AllEvents'],
    queryFn: fetchingAllEventsData,
    staleTime: 600000,

  });



  const AllSportData = sports?.data;

  const CricketEvents = AllSportData?.filter((event) => {
    return event.eid === "4";
  });

  const SoccerEvents = AllSportData?.filter((event) => {
    return event.eid === "1";
  });

  const TennisEvents = AllSportData?.filter((event) => {
    return event.eid === "2";
  });

  return (
    <>
      <BrowserRouter>
        <GlobalContextState>
          <ToastContainer />
          <Toaster />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path='wallet-gateway' element={<WalletGateway />} />
              <Route path='inplay' element={<InPlay />} />
              {/* <Route path='kabaddi' element={<Kabaddi/>} /> */}
              <Route path='cricket' element={<Cricket CricketEvents={CricketEvents} />} />
              <Route path='soccer' element={<Soccer SoccerEvents={SoccerEvents} />} />
              <Route path='tennis' element={<Tennis TennisEvents={TennisEvents} />} />
              <Route path='sports' element={<Sports TennisEvents={TennisEvents} SoccerEvents={SoccerEvents} CricketEvents={CricketEvents} />} />
              <Route path='event/:id/:eid' element={<SportDetail />} />   {/* All the detailed information of the sport will be shown over this page */}
              <Route path="multiMarketMobile" element={<MultiMarketMobile />} />
              <Route path="currentBetMobile/:id" element={<CurrentBetMobile />} />
              <Route path="multiMarket" element={<MultiMarket />} />
              <Route path='accountMobile' element={<AccountMobile />} />
              <Route path='account' element={<AccountLayout />} >
                <Route index element={<Profile />} />
                <Route path='balanceOverview' element={<BalanceOverview />} />
                <Route path='accountStatement' element={<AccountStatement />} />
                <Route path='myBet' element={<MyBet />} />
                <Route path='activityLog' element={<AcitivityLog />} />
              </Route>

            </Route>
            <Route path='/login' element={<Login />} />
            <Route path='/sign-up' element={<SignUp />} />
          </Routes>
        </GlobalContextState>
      </BrowserRouter>
      {/* <Header/>
    <Home/>
    <Footer/> */}
    </>
  )
}

export default App
