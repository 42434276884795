import React from 'react'
import headphoneIcon from "../images/headphone-icon-grey.png";
import whatsAppIcon from "../images/whatsApp-icon-grey.png";
import skypeIcon from "../images/skype-icon-grey.png";
import emailIcon from "../images/email-icon-grey.png";
import igIcon from "../images/ig-icon-grey.png";
import AndroidAppIcon from "../images/android-app-icon.png";
import browserIcon from "../images/icon-browser-B.png";
import { Link } from 'react-router-dom';

const MiniFooter = () => {

   //console.log("MiniFooter is rendering!!");

  return (
    <div className='mb-10'>
    <div className='mx-5 mt-7'>
        <div className='flex justify-between mb-2 '>
            <div className='py-3 bg-white w-[49%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086]'><img src={headphoneIcon} className='w-8' alt="" /> <span className='cursor-pointer hover:text-black' > Customer support1 </span> | <span className='cursor-pointer hover:text-black' > support2 </span></div>
            <div className='py-3 bg-white w-[49%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086]'><img src={whatsAppIcon} className='w-7' alt="" /> <span className='cursor-pointer hover:text-black' >WhatsApp 3 </span> | <span className='cursor-pointer hover:text-black' > WhatsApp 4 </span></div>
        </div>

        <div className='w-full bg-white h-4 mb-2 rounded-lg border border-[#97979780]'></div>

        <div className='flex justify-between gap-2'> 
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={skypeIcon} className='w-7' alt="" /> betcareliveofficial</div>
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={emailIcon} className='w-7' alt="" /> info@betcarelive.com</div>
            <div className='py-4 bg-white  w-[48%] flex gap-2 justify-center items-center rounded-lg border border-[#97979780] text-sm text-[#00000086] hover:text-black cursor-pointer' > <img src={igIcon} className='w-7' alt="" /> officialbetcarelive</div>
        </div>
    </div>

    <div className='flex flex-col mt-7 items-center mb-2'>
        <div className='flex gap-2  '>
            <img src={browserIcon} alt="" />
            <img src="" alt="" />
        </div>

        <h1 className='text-[#00000099] text-xs'>Our website works best in the newest and last prior version of these browsers:</h1>

        <h1 className='text-[#00000099] text-xs'>Google Chrome. Firefox</h1>
    </div>

    <div className='flex flex-col justify-center border-t border-[#0000004d]'>
        <p className='flex justify-center flex-wrap gap-2 mx-5 text-xs mt-2 text-[#00000099]'>
           - <Link className='underline' >Privacy Policy </Link>
           - <Link className='underline' >Terms and Conditions</Link>
           - <Link className='underline' >Rules and Regulations </Link>
           - <Link className='underline' > KYC</Link>
           - <Link className='underline' > Responsible Gaming</Link>
           - <Link className='underline' > About Us </Link>
           - <Link className='underline' >Self-exclusion Policy </Link>
           - <Link className='underline' > Underage Policy </Link> -
        </p>

        <div className='flex flex-col items-center mt-5'>
            <img  src={AndroidAppIcon} className='w-40' alt="" />
            <p className='text-[#00000099] text-[10px]'>v1.11 - 2022-03-23 - 3.1MB</p>
        </div>
    </div>

  </div>
  )
}

export default React.memo(MiniFooter)
