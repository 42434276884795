import React, { useContext, useEffect, useState } from 'react'
import DottedCircleImg from "../../images/dotted-circle.svg";
import GlobalContext from '../../context/GlobalContext';
import GreenPinIcon from "../../images/pin-icon-green.svg";
import ReloadIcon from "../../images/reload-icon.svg";
import PIcon from "../../images/p-icon.svg";
import { IoIosArrowForward } from "react-icons/io";
import { MdPushPin } from "react-icons/md";
import ReportIcon from "../../images/report-icon.svg";




const InMatchPinList = ({ tableTitle, tableList, tableSubTitle }) => {

  const [selectedRow, setSeletedRow] = useState({});   // This selectedRow will store some data of the current selected list in this inMatchPinList table

  return (
    <>
      <div className='bg-white border-b mb-4 border-[#7e97a7]' >
        {/* <div className='bg-[#243a48]'> */}
        <h1 className='text-[#7e97a7] block lg:hidden text-sm pl-1 py-1 font-semibold' style={{ background: "linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)" }} >Cricket</h1>
        {/* </div> */}
        <div className='bg-[#243a48] py-2 lg:py-0 px-1 lg:px-0 cursor-pointer text-white flex justify-between items-center' >
          <div className='flex gap-1 lg:text-xs items-center'>
            <div className='lg:p-1 bg-[#6bbd11]  rounded-full lg:rounded-none' style={{ background: "linear-gradient(0deg, rgba(101,183,19,1) 7%, rgba(73,155,30,1) 61%)" }} >
              <MdPushPin className='text-white h-5 w-5' />
            </div>
            <h1 className='hidden lg:block'>{tableTitle}</h1>
            <h1 className='lg:hidden block'>{tableTitle?.length > 28 ? tableTitle.substring(0, 29) + "..." : tableTitle}</h1>
            {/* <img src={PIcon} className='rounded-sm bg-[#e4550f] h-4 w-4 ' alt="" /> */}
            {/* <p className='bg-white text-black px-2 rounded-sm' >1x2</p>        */}
          </div>
          <div className='flex gap-2 items-center' >
            <div className='bg-[#3b5160] lg:hidden rounded-sm'> <IoIosArrowForward className='h-6 w-6' /></div>
            <div><img src={ReloadIcon} className='lg:size-4 lg:mr-2' alt="" /></div>
          </div>
        </div>

        <h1 className='text-white bg-[#3b5160] font-xs p-1' >{tableSubTitle}</h1>
        {tableList?.map((e) => {
          return (
            <div key={e.title} >
              <div className='border-b hover:bg-[#eee] flex items-center border-[#7e97a7]' >
                <h1 className='font-semibold lg:font-bold pl-2 lg:text-xs w-[70%]' >{e.title}</h1>
                <div onClick={() => { setSeletedRow({ title: e.title, bet: e.bet }) }} className={` ${selectedRow.title === e.title ? "bg-[#16a660] text-white" : "bg-[#72e3a0] text-black"}  py-4 cursor-pointer lg:px-10 w-1/2 lg:w-fit  text-center font-bold text-xs`} style={selectedRow.title === e.title ? { boxShadow: " inset 0 1px 3px rgba(0,0,0,.5)" } : {}} > {e.bet} </div>
              </div>
              <div className={`${selectedRow.title === e.title ? "block" : "hidden"}`}>
                <div className={`flex justify-between items-center border-b bg-[#d3edd0]  border-[#9fd899]`}>
                  <div className='text-xs pl-3 hidden lg:flex gap-1 ' ><input type="checkbox" id='checkForBet' /> <label htmlFor="checkForBet">Accept Any Odds</label> <p className='bg-[#0000001a] py-[1px] px-3 font-bold rounded-[3px]'>Max</p> </div>

                  <div className='flex w-full lg:w-fit px-2 lg:px-0 gap-3 lg:gap-1 lg:pr-3 py-2' >
                    <button onClick={() => { setSeletedRow({}) }} className='border w-1/2 lg:w-fit border-[#bbb]  lg:text-sm rounded-[4px] font-semibold px-2 py-2 lg:py-1 bg-gradient-to-t from-[#fff] to-[#eee]' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%)" }}>Cancel</button>
                    <div className='bg-[#ffffff80] text-right hidden lg:block rounded-[4px] px-1 text-xs font-bold pl-8 pr-1'><p>{selectedRow.bet}</p><p className='text-[11px] font-medium text-[#222222] opacity-50'>odds</p></div>
                    <input type="number" className='w-[70px] hidden lg:block rounded-[4px]' dir='rtl' name="" id="" style={{ boxShadow: "inset 0px 1px 0px rgba(0,0,0,.5)" }} />
                    <button className='text-[#ffb600] w-1/2 lg:w-fit lg:text-xs font-semibold lg:font-bold px-8 rounded-[4px]' style={{ background: " linear-gradient(180deg, #474747 0%, #070707 100%)" }}>Place Bets</button>
                  </div>
                </div>

                <div className={`bg-[#e4f4e2] flex justify-end gap-2 border-b border-[#7e97a7] py-1`} >
                  <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>30</button>
                  <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>50</button>
                  <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>100</button>
                  <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>300</button>
                  <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>500</button>
                  <button className='border border-[#bbb] px-7 rounded-[4px] text-xs py-1' style={{ background: "linear-gradient(180deg, #ffffff 0%, #eeeeee 89%" }}>1000</button>
                </div>
              </div>

            </div>
          )
        })}

        {/* <div className='border-b hover:bg-[#eee] flex items-center border-[#7e97a7]' >
             <h1 className='font-bold pl-2 text-xs w-[70%]' >draw</h1>
             <div className='bg-[#72e3a0] py-4 cursor-pointer px-10 font-bold text-xs' > 1.54 </div>
        </div>
        <div className='border-b hover:bg-[#eee] flex items-center border-[#7e97a7]' >
             <h1 className='font-bold pl-2 text-xs w-[70%]' >Agropecuario Argentino</h1>
             <div className='bg-[#72e3a0] py-4 cursor-pointer px-10 font-bold text-xs' > 1.54 </div>
        </div> */}
      </div>
    </>
  )
}





const MultiMarketList = () => {
  return (
    <>
      <div className='text-white border-t-2 mb-3 border-white'>
        <div className='flex justify-between px-2 py-1' style={{ background: "linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%)" }} >
          <h1>Cricket</h1>
          <p>In-Play</p>
        </div>

        <div className='bg-black py-2 flex justify-between px-2' >
          <div className='flex items-center gap-1' >
            <img src={GreenPinIcon} alt="" />
            <h1 className='font-bold'>Mumbai v Vidarbha</h1>
          </div>
          <div className='flex gap-2 items-center' >
            <div className='bg-[#3b5160] rounded-sm'> <IoIosArrowForward className='h-6 w-6' /></div>
            <div><img src={ReloadIcon} alt="" /></div>
          </div>
        </div>

        <div className='flex px-2 justify-between font-semibold pb-1 items-end bg-white pt-2 border-b-2 border-[#7e97a7]'>
          <div className='flex gap-1 items-end  text-black' >
            <img src={ReportIcon} alt="" />
            <p className='text-xs' >Match Odds</p>
          </div>
          <div className='flex gap-10 mr-5 text-sm text-black' >
            <h1>Back</h1>
            <h1>Lay</h1>
          </div>
        </div>
      </div>
    </>
  )
}



const MultiMarketMobile = () => {

  // for responsive purpose and for footer navbar
  const [contentHeight, setContentHeight] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  const context = useContext(GlobalContext);
  const { multiMarketList, setMultiMarketList } = context;

  const ApiTestingData = [{ SrNo: 1, title: "Winner (incl. super over)", matchName: "New York Strikers v Rajasthan Kings", lists: [{ title: "New York Strikers", bet: "1.59" }, { title: "Rajasthan Kings", bet: "2.36" }] }];

  console.log('multiMarketList length : ', multiMarketList.length);


  useEffect(() => {
    let heightOne = document.body.scrollHeight;
    let heightTwo = window.screen.height;

    setContentHeight(heightOne);
    setScreenHeight(heightTwo);

    //console.log(`the height 1 is : ${heightOne} and height 2 is : ${heightTwo}`);
  }, [])





  return (
    <div className='bg-[#eee]' style={contentHeight < screenHeight ? { height: "90vh" } : { height: "auto" }} >

      {multiMarketList.length === 0 ? (
        <>
          <div className='border bg-white  border-[#7e97a7] mx-1 my-2 rounded-md'>
            <div className='flex gap-2 px-1 my-3 text-[#7e97a7] items-center'>
              <img src={DottedCircleImg} alt="" />
              <h1 className='text-xl text-center font-semibold leading-[2] mb-3'>There are currently no followed <br /> multi markets.</h1>
            </div>
            <p className='text-center text-[#7e97a7] border-t border-[#7e97a7] mb-6 mx-2' >Please add some markets from events.</p>
          </div>
        </>
      )
        : (
          <>
            <MultiMarketList />
            {/* <MultiMarketList/> */}
            {/* <MultiMarketList/> */}




          </>
        )
      }



    </div>
  )
}

export default MultiMarketMobile
